import {Component, OnInit} from '@angular/core';
import {
    AssetsService,
    DialogService,
    JobsService,
    SnackbarService
} from '../../services';
import 'firebase/storage';
import {HttpClient} from '@angular/common/http';
import {DownloadSelection} from '../../models';

@Component({
    selector: 'prism-download-selection-modal',
    templateUrl: './download-selection-modal.component.html',
    styleUrls: ['./download-selection-modal.component.scss']
})
export class DownloadSelectionModalComponent implements OnInit {
    selectedDownloadFormats: DownloadSelection = {
        print: true,
        web: false,
        watermark: false
    };
    shareUid: string;
    allAssetPaths: string[];
    relevantAssetPaths: string[];
    serviceId;
    jobId: number;
    hasDownloadOptions: boolean;
    hasWatermarkOptions: boolean;
    selectedAssets: string[];

    constructor(private dialogService: DialogService,
                private jobsService: JobsService,
                private http: HttpClient,
                private snackbarService: SnackbarService,
                private assetsService: AssetsService) {
    }

    ngOnInit() {
        const {id: jobId} = this.jobsService.selectedJob || {id: this.dialogService.inputData.jobId};
        this.jobId = jobId;

        if (!!this.dialogService.inputData) {
            this.shareUid = this.dialogService.inputData.shareUid;
            this.serviceId = this.dialogService.inputData.serviceId;
            this.selectedAssets = this.dialogService.inputData.selectedAssets;
            this.allAssetPaths = this.dialogService.inputData.allAssetPaths;
        }

        if (this.selectedAssets?.length > 0) {
            this.relevantAssetPaths = this.selectedAssets;
        } else {
            if (this.serviceId) {
                this.relevantAssetPaths = this.allAssetPaths.filter(path => path.includes(`${this.serviceId}`));
            } else {
                this.relevantAssetPaths = this.allAssetPaths.filter(path => path.includes(`/${this.jobId}/`));
            }
        }

        this.hasDownloadOptions = this.relevantAssetPaths.some(assetPath => assetPath.includes('/web_') || assetPath.includes('/watermark_'));
        this.hasWatermarkOptions = this.relevantAssetPaths.some(assetPath => assetPath.includes('/watermark_'));
    }

    closeDialog() {
        this.dialogService.closeAllDialogs();
    }

    requestDownload() {

        const {address} = this.jobsService.selectedJob || {address: this.dialogService.inputData.address};

        const finalDownloadAssetPaths: string[] = this.relevantAssetPaths.reduce((acc, filePath) => {
            if (this.selectedDownloadFormats.web && filePath.includes('/web_')) {
                acc.push(filePath);
                return acc;
            }

            if (this.selectedDownloadFormats.print && !filePath.includes('/web_') && !filePath.includes('/watermark_') && !(filePath.endsWith('.mp4') || filePath.endsWith('.mov'))) {
                acc.push(filePath);
                return acc;
            }

            if (this.selectedDownloadFormats.watermark && filePath.includes('/watermark_')) {
                acc.push(filePath);
                return acc;
            }

            if (filePath.endsWith('.mp4') || filePath.endsWith('.mov')) {
                acc.push(filePath);
                return acc;
            }

            return acc;
        }, []);
        this.assetsService.sendDownloadRequest(address, !!this.serviceId ? this.serviceId : `job_${this.jobId}`, finalDownloadAssetPaths);
        this.snackbarService.showDismissibleSnackbar();
        this.dialogService.closeAllDialogs();
    }

    changeDownloadTypeSelection(type: string, checked) {
        this.selectedDownloadFormats[type] = checked;
    }
}
