import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, InvoicesService, XeroConnectionService} from '../../services';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {InvoicesCsvFetchComponent} from './invoices-csv-fetch/invoices-csv-fetch.component';
import {debounceTime, distinctUntilChanged, switchMap, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'prism-invoices-page',
    templateUrl: './invoices-page.component.html',
    styleUrls: ['./invoices-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class InvoicesPageComponent implements OnInit, OnDestroy {
    xeroExpiryTimerSubject$: Observable<Observable<any>>;
    xeroExpiryTimer$: Observable<any>;
    subDestroyer$: Subject<void>;
    selectedInvoiceTab$: Observable<'synced' | 'unsynced'>;
    isGetInvoicesLoading$: Observable<boolean>;
    isPutInvoicesLoading$: Observable<boolean>;
    isDeleteInvoicesLoading$: Observable<boolean>;
    invoices$: BehaviorSubject<object[]> = new BehaviorSubject<object[]>(null);
    invoicesLength$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    queuedInvoicesCount$: Observable<number>;
    syncedInvoicesCount$: Observable<number>;
    queuedInvoicePagination$: Observable<any>;
    syncedInvoicePagination$: Observable<any>;

    constructor(private invoicesService: InvoicesService,
                private xeroConnectionService: XeroConnectionService,
                private dialogService: DialogService) {
        this.xeroExpiryTimerSubject$ = this.xeroConnectionService.getXeroExpiryTimer();
        this.subDestroyer$ = new Subject<void>();
        this.selectedInvoiceTab$ = this.invoicesService.selectedInvoiceTab$;
        this.isGetInvoicesLoading$ = this.invoicesService.isGetInvoicesLoading$;
        this.isPutInvoicesLoading$ = this.invoicesService.isPutInvoicesLoading$;
        this.isDeleteInvoicesLoading$ = this.invoicesService.isDeleteInvoicesLoading$;
        this.queuedInvoicesCount$ = this.invoicesService.invoicesInQueueCount;
        this.syncedInvoicesCount$ = this.invoicesService.syncedInvoicesCount;
        this.queuedInvoicePagination$ = this.invoicesService.queuedInvoicesPaginationData;
        this.syncedInvoicePagination$ = this.invoicesService.syncedInvoicesPaginationData;
    }

    ngOnInit() {
        this.getInvoiceData();

        this.xeroExpiryTimerSubject$
            .pipe(
                takeUntil(this.subDestroyer$)
            )
            .subscribe(timer => {
                this.xeroExpiryTimer$ = timer;
            });

    }

    ngOnDestroy() {
        this.subDestroyer$.next();
    }

    changeShowSynced(tab: 'synced' | 'unsynced') {
        this.invoicesService.setSelectedInvoiceTab(tab);
        this.invoices$.next(null);
    }

    openInvoiceDataFetchModal() {
        this.dialogService.openDialog(InvoicesCsvFetchComponent);
    }

    getInvoiceData() {
        this.invoicesService.getInvoicesCount()
            .subscribe(
                () => null,
                (err) => console.error(err)
            );
        this.invoicesService.getInvoicesQueueCount()
            .subscribe(
                () => null,
                (err) => console.error(err)
            );

        combineLatest([
            this.selectedInvoiceTab$,
            this.queuedInvoicesCount$,
            this.syncedInvoicesCount$,
        ])
            .pipe(
                takeUntil(this.subDestroyer$),
                debounceTime(0),
                distinctUntilChanged()
            )
            .subscribe(([invoicesType, queuedInvoicesCount, syncedInvoicesCount]) => {
                if (invoicesType === 'synced') {
                    this.invoicesLength$.next(syncedInvoicesCount);
                } else {
                    this.invoicesLength$.next(queuedInvoicesCount);
                }
            });

        combineLatest([
            this.selectedInvoiceTab$,
            this.queuedInvoicePagination$,
            this.syncedInvoicePagination$,
        ])
            .pipe(
                takeUntil(this.subDestroyer$),
                debounceTime(0),
                switchMap(([invoicesType, queuedInvoicesPaginationData, syncedInvoicePaginationData]) => {
                    if (invoicesType === 'synced') {
                        const {pageIndex, pageSize} = syncedInvoicePaginationData;
                        return this.invoicesService.getInvoices(pageSize, pageIndex);
                    } else {
                        const {pageIndex, pageSize} = queuedInvoicesPaginationData;
                        return this.invoicesService.getQueuedInvoices(pageIndex, pageSize);
                    }
                }))
            .subscribe((invoices) => {
                this.invoicesService.switchGetInvoicesLoaderOff();
                this.invoices$.next(invoices);
            });
    }
}
