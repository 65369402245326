<div class="mat-dialog-container notify-dialog-wrapper">
    <h1 mat-dialog-title>{{ accessedByAgency ? 'Share this Job' : 'Notify agent/s' }}</h1>

    <mat-dialog-content>
        <form [formGroup]="notifyAgentForm">
            <div class="field-group">
                <div class="form-field form-field-input form-field-input-chips">
                    <mat-label class="form-field-label">Notification Emails</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-chip-grid #chipListNotification>
                            <mat-chip-row
                                    *ngFor="let email of notificationEmails.controls; let i = index"
                                    [removable]="true"
                                    (removed)="removeNotificationEmail(i)"
                            >
                                {{ email.value }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip-row>
                            <input
                                    [matChipInputFor]="chipListNotification"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addNotificationEmail($event)"
                            />
                        </mat-chip-grid>
                    </mat-form-field>
                </div>

            </div>

            <div class="form-field form-field-textarea">
                <mat-label class="form-field-label">Comments</mat-label>

                <mat-form-field appearance="outline">
                <textarea
                        matInput
                        [placeholder]="accessedByAgency ? 'Comments to include in email' : 'Enter a comment for the agent(s)'"
                        type="email"
                        formControlName="comments"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="12"></textarea>
                </mat-form-field>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="share-actions">
            <button class="btn btn-red cancel-btn" mat-button (click)="closeDialog()">Cancel</button>

            <button mat-raised-button
                    class="btn btn-green"
                    (click)="shareAlbumWithAgent()"
                    [disabled]="notifyAgentForm.invalid || sendingMail || !notificationEmails.getRawValue().length">
                Share
                <ng-template [ngIf]="sendingMail">
                    <div class="lds-hourglass"></div>
                </ng-template>
            </button>

        </div>
    </mat-dialog-actions>
</div>
