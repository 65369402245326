import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {MemberService} from './member.service';
import {first, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {Response} from '../models/https-response';

@Injectable({
    providedIn: 'root'
})
export class TermsAndConditionsService {

    private _platformPolicy: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public platformPolicy$: Observable<string> = this._platformPolicy.asObservable();
    private _userPolicy: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public userPolicy$: Observable<string> = this._userPolicy.asObservable();

    constructor(private http: HttpClient, private memberService: MemberService) {
    }

    getPolicies() {
        return this.http.get(environment.apiBaseUrl + '/GetPolicy')
            .pipe(
                first(d => !!d),
                tap(
                    (policy: Response) => {

                        if (!this._platformPolicy.value) {
                            this._platformPolicy.next(policy.data.platformFileLink);
                        }

                        if (!this._userPolicy.value) {
                            this._userPolicy.next(policy.data.mediaFileLink);
                        }
                    },
                    err => {
                        console.error(err);
                    }
                )
            );
    }

    acceptAndUpdateMember(policyType: 'platform' | 'user') {
        const targetProperty = policyType === 'platform'
            ? 'acceptedPlatformPolicies'
            : 'acceptedUserPolicies';

        const body = {
            [targetProperty]: true
        };

        return this.memberService.updateMember(body);
    }
}
