import {Component, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {AgentService, SnackbarService} from '../../services';
import {AgentsTableComponent} from './agents-table/agents-table.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AddEditAgentComponent} from '../add-edit-agent/add-edit-agent.component';
import {Agent} from '../../models';

@Component({
    selector: 'prism-agents-page',
    templateUrl: './agents-page.component.html',
    styleUrls: ['./agents-page.component.scss']
})
export class AgentsPageComponent {
    activeAgentsFilter = true;
    selectedAgentIds: string[] = [];
    @ViewChild('agentsTable', {static: true}) agentsTable: AgentsTableComponent;

    dialogRef: MatDialogRef<AddEditAgentComponent>;

    constructor(private agentService: AgentService, private snackbarService: SnackbarService, private dialog: MatDialog) {
    }

    showActiveAgents(active: boolean) {
        this.activeAgentsFilter = active;
    }

    onSelectionUpdated(agencyIds: string[]) {
        this.selectedAgentIds = agencyIds;
    }

    onAgentClicked(agent: Agent) {
        this.displayAgentsModal(agent);
    }

    displayAgentsModal(agent: Agent = null) {
        this.dialogRef = this.dialog.open(AddEditAgentComponent, {data: agent});
        this.dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult && dialogResult.agentId) {
                this.agentsTable.getAgents();
            }
        });
    }

    updateAgentsActiveStatus(setStatus: boolean) {
        this.agentService.updateAgentsActiveStatus(this.selectedAgentIds, setStatus).subscribe(() => {
            const snackbarString = `${setStatus ? 'Activated' : 'Archived'} ${this.selectedAgentIds.length} agent${this.selectedAgentIds.length > 1 ? 's' : ''}`;
            this.snackbarService.showSnackbar(snackbarString);
            this.agentsTable.getAgents();
        });
    }
}
