import {Component, OnInit} from '@angular/core';
import {DialogService} from '../../services';
import {environment} from '../../../environments';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PrismHeaders} from '../../enums/headers.enum';

@Component({
    selector: 'prism-confirm-card',
    templateUrl: './confirm-default-card.component.html',
    styleUrls: ['./confirm-default-card.component.scss']
})
export class ConfirmDefaultCardComponent implements OnInit {

    last4: string;
    paymentId: string;
    saved: boolean = false;
    stripeId: string;

    constructor(private dialogService: DialogService, private http: HttpClient) {
    }

    ngOnInit() {
        this.last4 = this.dialogService.inputData.last4;
        this.paymentId = this.dialogService.inputData.payment_method_id;
        this.stripeId = this.dialogService.inputData.stripeId;
    }

    changePaymentMethod() {
        const paymentId = this.paymentId;
        const stripeId = this.stripeId;
        const headers = new HttpHeaders().set(PrismHeaders.FunctionHelperData, JSON.stringify({paymentId, stripeId}));
        return this.http.get(environment.apiBaseUrl + '/SetDefaultPaymentMethod', {headers}).toPromise().then((result) => {
            this.saved = true;
            this.dialogService.closeDialog();
        });
    }

}
