<div class="mat-dialog-container" *ngIf="lineItemsFormArray.controls?.length">
    <h1 id="dialog-container-title" mat-dialog-title>{{ productToEdit !== null ? 'Edit Product' : 'New Product' }}</h1>

    <mat-dialog-content (keydown)="ignoreEnterKey($event)">
        <form [formGroup]="createEditProductForm">
            <div class="field-group">
                <div class="name-price-row">
                    <div class="form-field form-field-input">
                        <mat-label class="form-field-label">Name</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput
                                   formControlName="ProductName">
                            <mat-error *ngIf="createEditProductForm.controls['ProductName'].hasError('required')">
                                A product name is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-field form-field-input">
                        <mat-label class="form-field-label">Total Price</mat-label>
                        <mat-form-field appearance="outline">
                            <input #priceInput
                                   matInput
                                   formControlName="Price">
                            <mat-error
                                    *ngIf="createEditProductForm.controls['Price'].hasError('required')">
                                A price is required
                            </mat-error>
                            <mat-error *ngIf="createEditProductForm.controls['Price'].hasError('min')">
                                Price cannot be lower than 0
                            </mat-error>
                            <mat-error *ngIf="createEditProductForm.controls['Price'].hasError('pattern')">
                                Enter a valid amount
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-field form-field-textarea">
                    <mat-label class="form-field-label">Description</mat-label>
                    <mat-form-field>
                        <textarea
                                matInput
                                formControlName="Description"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="12"
                        ></textarea>
                    </mat-form-field>
                </div>
                <div class="service-list-wrapper">
                    <h5 class="add-services-title">Add your product services</h5>
                    <prism-service-list
                            *ngIf="createEditProductForm.controls['services'].value"
                            (servicesUpdate)="setServices($event)">
                    </prism-service-list>

                </div>
                <div formArrayName="lineItems" class="invoice-setup-container" (keydown)="ignoreEnterKey($event)">
                    <h5 class="invoice-setup-title">Create your product Invoice</h5>
                    <div class="invoice-line-item-fields"
                         *ngFor="let invoiceControl of lineItemsFormArray.controls; let i = index">
                        <div class="line-item-header">
                            <h6 class="line-item-title">Line Item {{ i + 1 }}</h6>

                            <button mat-icon-button
                                    class="btn remove-line-btn"
                                    *ngIf="lineItemsFormArray.length > 1"
                                    (click)="removeLineItem(i)">
                                <img alt="Remove line item." src="/assets/icons/products/bin.svg">
                            </button>
                        </div>

                        <div class="line-item-field">
                            <mat-form-field appearance="outline">
                                <mat-label class="form-field-label">Description</mat-label>
                                <input matInput
                                       (input)="updateLineItem($event, invoiceControl, 'Description')"
                                       [formControl]="invoiceControl.value.Description"
                                       placeholder="Add item description">
                                <mat-error
                                        *ngIf="invoiceControl.value.Description.touched && invoiceControl.value.Description.hasError('required')">
                                    Item description is
                                    required
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="line-items-field-wrapper">
                            <div class="line-item-field">
                                <mat-form-field appearance="outline">
                                    <mat-label class="form-field-label">Quantity</mat-label>
                                    <input matInput
                                           (input)="updateLineItem($event, invoiceControl, 'Quantity', i)"
                                           [formControl]="invoiceControl.value.Quantity"
                                           placeholder="Enter the number of units">
                                    <mat-error
                                            *ngIf="invoiceControl.value.Quantity.touched && invoiceControl.value.Quantity.hasError('required')">
                                        Unit quantity is
                                        required
                                    </mat-error>
                                    <mat-error
                                            *ngIf="invoiceControl.value.Quantity.hasError('pattern') && !invoiceControl.value.Quantity.hasError('required')">
                                        Please enter a whole number
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="line-item-field">
                                <mat-form-field appearance="outline">
                                    <mat-label class="form-field-label">Unit Price</mat-label>
                                    <input matInput
                                           (input)="updateLineItem($event, invoiceControl, 'UnitPrice', i)"
                                           [formControl]="invoiceControl.value.UnitPrice"
                                           placeholder="Enter item unit price">
                                    <mat-error
                                            *ngIf="invoiceControl.value.UnitPrice.touched  && invoiceControl.value.UnitPrice.hasError('required')">
                                        Unit price is
                                        required
                                    </mat-error>
                                    <mat-error
                                            *ngIf="invoiceControl.value.UnitPrice.hasError('pattern') && !invoiceControl.value.UnitPrice.hasError('required')">
                                        Please enter a valid amount
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="line-items-field-wrapper">
                            <div class="line-item-field">
                                <mat-form-field appearance="outline">
                                    <mat-label class="form-field-label">Item Code</mat-label>
                                    <input matInput
                                           (input)="updateLineItem($event, invoiceControl, 'ItemCode')"
                                           [formControl]="invoiceControl.value.ItemCode"
                                           placeholder="Enter item code">
                                </mat-form-field>
                            </div>

                            <div class="line-item-field">
                                <mat-form-field appearance="outline">
                                    <mat-label class="form-field-label">Account Code</mat-label>
                                    <input matInput
                                           (input)="updateLineItem($event, invoiceControl, 'AccountCode')"
                                           [formControl]="invoiceControl.value.AccountCode"
                                           placeholder="Enter item account code">
                                    <mat-error
                                            *ngIf="invoiceControl.value.AccountCode.touched  && invoiceControl.value.AccountCode.hasError('required')">
                                        Accounting code is required
                                    </mat-error>
                                    <mat-error
                                            *ngIf="invalidAccountCodes.includes(invoiceControl.value.AccountCode.value?.toUpperCase())">
                                        Xero rejected "{{ invoiceControl.value.AccountCode.value }}"
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <button mat-button class="btn add-line-btn btn-blue" (click)="addLineItem()" type="button">+ Add
                        another line item
                    </button>
                </div>
            </div>
        </form>

    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button class="btn cancel-btn btn-red" (click)="closeModal()">Cancel</button>
        <button mat-raised-button
                class="btn btn-green"
                (click)="createUpdateMemberProducts()"
                [disabled]="createEditProductForm.invalid || (putLoading$ | async) || (postLoading$ | async) || !!invalidLineItem">
            {{ productToEdit !== null ? 'Save Product' : 'Add Product' }}
            <ng-template [ngIf]="(putLoading$ | async) || (postLoading$ | async)">
                <div class="lds-hourglass"></div>
            </ng-template>
        </button>
    </mat-dialog-actions>
</div>
