import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MultiUploaderJob} from '../models';

@Injectable({
    providedIn: 'root'
})
export class MultiUploaderService {

    private _selectedJobs: BehaviorSubject<{ [id: number]: MultiUploaderJob }> = new BehaviorSubject<{
        [id: number]: MultiUploaderJob
    }>({});

    get selectedJobs$() {
        return this._selectedJobs
            .asObservable();
    }

    addSelectedJob(job: MultiUploaderJob) {
        job.productsToAdd = [];
        this._selectedJobs.value[job.id] = job;
        this._selectedJobs.next(this._selectedJobs.value);
    }

    removeSelectedJob(jobId: number) {
        delete this._selectedJobs.value[jobId];
        this._selectedJobs.next(this._selectedJobs.value);
    }

    addProductToJob(jobId: number, product: any) {
        this._selectedJobs.value[jobId].productsToAdd.unshift(product);
        this._selectedJobs.next(this._selectedJobs.value);
    }
}
