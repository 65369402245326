<div class="mat-dialog-container">
    <div *ngIf="!contractorEmailFound">
        <h2 mat-dialog-title>Add Contractor</h2>
        <mat-dialog-content
                [ngStyle]="{ display: !contractorEmailFound ? 'block' : 'none' }"
        >
            <div class="form-group" [formGroup]="requestContractorEmailForm">
                <div class="form-field form-field-input">
                    <mat-label>Contractor Email</mat-label>
                    <mat-form-field>
                        <input
                                matInput
                                #validateContractorInput
                                (input)="trimText($event)"
                                type="text"
                                formControlName="email"
                        />
                        <mat-error
                                *ngIf="requestContractorEmailForm.get('email').hasError('required')"
                        >Email is required
                        </mat-error
                        >
                        <mat-error
                                *ngIf="
          !requestContractorEmailForm.get('email').hasError('required') &&
          requestContractorEmailForm.get('email').hasError('notEmail')
        "
                        >Please enter a valid email
                        </mat-error
                        >
                    </mat-form-field>
                </div>
            </div>
            <h5 class="contractor-not-found" *ngIf="contractorEmailNotFound">
                Contractor not found
            </h5>
            <mat-progress-bar
                    mode="indeterminate"
                    *ngIf="isValidating"
            ></mat-progress-bar>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button
                    class="btn btn-red cancel-btn"
                    mat-button
                    [disabled]="isPosting"
                    (click)="closeDialog()"
            >
                Cancel
            </button>
        </mat-dialog-actions>
    </div>

    <div *ngIf="contractorEmailFound">
        <h2 mat-dialog-title>
            {{ data && data.id ? "Update Contractor" : "New Contractor" }}
        </h2>
        <mat-dialog-content>
            <form class="add-contractor-form" [formGroup]="contractorForm">

                <div formGroupName="primaryDetails">
                    <div class="field-group">


                        <div class="form-field form-field-input">
                            <mat-label>First Name</mat-label>
                            <mat-form-field>
                                <input matInput type="text" formControlName="firstName"/>
                                <mat-error *ngIf="primaryDetailsFormGroup.get('firstName').invalid">
                                    Please enter a first name
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="form-field form-field-input">
                            <mat-label>Last Name</mat-label>
                            <mat-form-field>
                                <input matInput type="text" formControlName="lastName"/>
                                <mat-error *ngIf="primaryDetailsFormGroup.get('lastName').invalid">
                                    Please enter a last name
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="field-group">
                        <div class="form-field form-field-input">
                            <mat-label>Contact Number (optional)</mat-label>

                            <mat-form-field>
                                <input matInput type="text" formControlName="contactNumber"/>
                                <mat-error
                                        *ngIf="primaryDetailsFormGroup.get('contactNumber').errors?.pattern"
                                >
                                    Please enter a valid contact number
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-input">
                            <mat-label>ABN (optional)</mat-label>

                            <mat-form-field>
                                <input matInput type="text" formControlName="abn"/>
                            </mat-form-field>
                        </div>


                    </div>
                    <div class="field-group">
                        <div class="form-field form-field-input">
                            <mat-label>Address</mat-label>
                            <mat-form-field class="address-field">
                                <input
                                        type="text"
                                        placeholder="Pick one"
                                        aria-label="t"
                                        matInput
                                        formControlName="address"
                                        autocomplete="off"
                                        [matAutocomplete]="auto"
                                />
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option
                                            *ngFor="let option of autoCompleteOptions | async"
                                            [value]="option"
                                    >
                                        {{ option }}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="primaryDetailsFormGroup.get('address').invalid">
                                    Please enter a valid address
                                </mat-error>
                            </mat-form-field>
                            <mat-icon *ngIf="isVerifyingAddress | async">access_time</mat-icon>
                        </div>
                    </div>
                </div>
                <div formGroupName="emails">
                    <div class="field-group">

                        <div class="form-field form-field-input">
                            <mat-label>External Email Address</mat-label>
                            <mat-form-field>
                                <input matInput formControlName="secondaryEmail"/>
                                <mat-error *ngIf="emailsFormGroup.get('secondaryEmail').invalid">
                                    Please enter a valid email address
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="form-field form-field-input">
                            <mat-label>Login Email</mat-label>
                            <mat-form-field>
                                <input matInput type="text" formControlName="loginEmail"/>
                                <mat-error *ngIf="emailsFormGroup.get('loginEmail').invalid">
                                    Please enter a valid email address
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </mat-dialog-content>
        <mat-divider></mat-divider>
        <mat-dialog-actions>
            <button
                    class="btn btn-red cancel-btn"
                    mat-button
                    [disabled]="isPosting"
                    (click)="closeDialog()"
            >
                Cancel
            </button>
            <button
                    class="btn btn-green"
                    mat-raised-button
                    [disabled]="contractorForm.invalid || isPosting"
                    (click)="addContractor()"
            >
                {{ data && data.id ? "Update" : "Add" }}
                <ng-template [ngIf]="isPosting">
                    <div class="lds-hourglass"></div>
                </ng-template>
            </button>
        </mat-dialog-actions>
    </div>
</div>
