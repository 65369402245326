export const FLOORPLAN_SERVICES = {
    'SNAKE_CASE': 'floorplan_services',
    'DISPLAY': 'Floorplan Services',
};

export const VIDEO_SERVICES = {
    'SNAKE_CASE': 'video_services',
    'DISPLAY': 'Video Services',
};

export const IMAGE_PROCESSING = {
    'SNAKE_CASE': 'image_processing',
    'DISPLAY': 'Image Processing',
};
