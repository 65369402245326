<div class="briefs-wrappers">
    <mat-card appearance="outlined" class="brief-section-none" *ngIf="briefs?.length < 1">
        <div class="brief-inner-none" innerHTML="{{'<h1>No Brief Available</h1>'}}"></div>
    </mat-card>
    <mat-card appearance="outlined" *ngIf="briefs?.length > 0">
        <div class="brief-section" *ngFor="let brief of briefs">
            <div class="brief">
                <h6><strong>Date:</strong> {{ brief.briefTimestamp | date:'fullDate' }}</h6>
                <h6>
                    <strong>Number of Finals:</strong>
                    {{ brief.numOfFinals ? brief.numOfFinals : 'Number of finals not chosen' }}
                </h6>
                <h6><strong>Brief:</strong></h6>
                <div class="brief-inner" *ngIf="brief.brief; else noBriefText" [innerHTML]="brief.brief">
                </div>
                <ng-template #noBriefText>
                    <div class="no-brief">No Brief Provided</div>
                </ng-template>
            </div>
        </div>
    </mat-card>
</div>

