import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import isNan from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import cloneDeep from 'lodash/cloneDeep';
import {MatTableDataSource} from '@angular/material/table';
import {AuthenticationService, SnackbarService, XeroConnectionService} from '../../../services';
import {UUID} from 'angular2-uuid';

@Component({
    selector: 'prism-add-products-table',
    templateUrl: './add-products-table.component.html',
    styleUrls: ['./add-products-table.component.scss'],
})
export class AddProductsTableComponent implements AfterViewInit {
    products;
    lineItemsList: MatTableDataSource<any>;
    columnHeaders: string[];

    @Input('products')
    set lineItemsSetter(products: any[]) {
        this.products = products;
        if (!!products) {
            const createdLineItems = [];
            products.forEach((product) => {
                if (!!product['lineItems']) {
                    const productClone = cloneDeep(product);
                    productClone.lineItems.forEach((item) => {
                        item.lineItemUID = UUID.UUID();
                        item.createTime = productClone.createTime;
                        createdLineItems.push(item);
                    });
                } else {
                    const productClone = cloneDeep(product);
                    product.lineItemUID = UUID.UUID();
                    // Xero seems to have two variancies for this property name. UnitPrice | UnitAmount
                    product.UnitPrice = !!productClone.UnitPrice ? productClone.UnitPrice : productClone.UnitAmount || 0;
                    createdLineItems.push(product);
                }
            });
            this.lineItemsList.data = createdLineItems;
        }
    }

    @Input() charged: boolean;

    @Output() lineItemsUpdated = new EventEmitter();

    constructor(private authService: AuthenticationService,
                private xeroConnectionService: XeroConnectionService,
                private snackbarService: SnackbarService) {
        this.lineItemsList = new MatTableDataSource<any>([]);
        this.columnHeaders = ['Date', 'Product', 'ItemCode', 'AccountCode', 'Units', 'UnitPrice', 'Amount', 'Delete'];

    }

    ngAfterViewInit() {
        if (this.products) {
            setTimeout(() => {
                this.lineItemsUpdated.emit(this.lineItemsList.data);
            }, 0);
        }
    }

    addProduct() {
        // masterProductId of '0' indicates it has no master product, i.e. is a miscellaneous item
        this.lineItemsList.data.push({
            Description: '',
            ItemCode: '',
            Quantity: 1,
            UnitPrice: 0,
            LineAmount: 0,
            AccountCode: '200',
            lineItemUID: UUID.UUID(),
        });
        this.lineItemsList._updateChangeSubscription();
        this.lineItemsUpdated.emit(this.lineItemsList.data);
    }

    emitProductsUpdate(inputChange, selectedLineItem, lineItem?) {
        const id = inputChange.target.id;
        const value = inputChange.target.value;
        const lineItemToChangeIndex = this.lineItemsList.data.findIndex(currentLineItem => {
            return currentLineItem.lineItemUID === lineItem.lineItemUID;
        });
        const oldLineItems = this.lineItemsList.data;

        if (id.startsWith('Description')) {
            oldLineItems[lineItemToChangeIndex].Description = value;
        }

        if (id.startsWith('AccountCode')) {
            oldLineItems[lineItemToChangeIndex].AccountCode = value;
        }

        if (id.startsWith('Quantity')) {
            oldLineItems[lineItemToChangeIndex].Quantity = +value;
        }

        if (id.startsWith('ItemCode')) {
            oldLineItems[lineItemToChangeIndex].ItemCode = value;
        }

        if (id.startsWith('UnitPrice')) {
            oldLineItems[lineItemToChangeIndex].UnitPrice = +value;
        }
        this.lineItemsList.data = oldLineItems;
        this.lineItemsUpdated.emit(this.lineItemsList.data);
    }

    removeItem(selectedLineItem) {
        const relevantLineItemUid = selectedLineItem.lineItemUID;
        const relevantParentProductUID = selectedLineItem.parentProductUID;

        if (this.lineItemsList.data.filter(lineItem => lineItem.hasOwnProperty('lineItemUID')).length < 2) {
            this.snackbarService.showSnackbar('You need at least one lineItem');
        } else {
            let remainingItems = this.lineItemsList.data.filter(lineItem => {
                return lineItem.lineItemUID !== relevantLineItemUid;
            });

            // "masterProductId !== 0" refers to non misc line items, only they need to be considered here;
            if (selectedLineItem.masterProductId !== 0) {
                const remainingProductLineItems = remainingItems.filter(lineItem => {
                    return lineItem.parentProductUID === relevantParentProductUID;
                });

                if (remainingProductLineItems.length === 0) {
                    remainingItems = remainingItems.filter(lineItem => {
                        return !lineItem.hasOwnProperty('productLineUID') || lineItem.productLineUID !== relevantParentProductUID;
                    });
                }
            }

            this.lineItemsList.data = remainingItems;
            this.lineItemsUpdated.emit(remainingItems);
            this.lineItemsList._updateChangeSubscription();
        }
    }

    updateProductUnitData(prop: string, event, lineItem) {
        let value = event.target.value;
        const oldValue = lineItem[prop];

        if (prop === 'Quantity' && (!(+value) || +value <= 0 || !isNan(+value))) {
            if (!isNan(+value)) {
                lineItem[prop] = !!(+value) ? +value : 0;
                event.target.value = !!(+value) ? +value : 0;
            } else {
                lineItem[prop] = oldValue;
                event.target.value = oldValue;
            }
        } else {
            if (!isNan(+value)) {
                if (Number(value) === 0) {
                    lineItem[prop] = 0;
                    event.target.value = 0;
                } else if (Number(oldValue) === 0) {
                    lineItem[prop] = Number(value);
                } else {
                    lineItem[prop] = value;
                }

            } else {
                lineItem[prop] = oldValue;
                event.target.value = oldValue;
            }
        }
        lineItem.LineAmount = (+lineItem.UnitPrice * +lineItem.Quantity) || 0;
        this.lineItemsUpdated.emit(this.lineItemsList.data);
    }

    toString(val) {
        return isNumber(val) ? String(val) : val;
    }
}
