import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AngularEditorComponent, AngularEditorConfig} from '@kolkov/angular-editor';
import {UploadDropzoneComponent} from '../../upload-dropzone/upload-dropzone.component';
import {ServiceStatus, StorageTarget} from '../../../enums';
import {Job, Service} from '../../../models';
import {AgencyService, DialogService, JobsService} from '../../../services';
import {UploadService} from '../../../services/upload.service';
import {ServicesService} from '../../../services/services.service';

@Component({
    selector: 'prism-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageUploadComponent implements OnInit {
    storageDestination: StorageTarget;
    briefEditorConfig: AngularEditorConfig;
    closeServiceUploader: boolean;
    fileCount: number;
    serviceHandled: boolean;
    uploadLimited: any;
    allowedFile: string;
    loadingInProgress: boolean;
    serviceId: string;
    serviceForm: FormGroup;

    @Input() service: Service;
    @Input() productId: string;
    @Input() serviceIndex: number;
    @Input() parentJob: Job;
    @Input() onlyEdit: boolean;
    @Output() serviceBoxClosed: EventEmitter<{
        uploadComponent: any,
        onlyEdit: boolean,
        destination: string,
        service: any,
        cancelled: boolean
    }> = new EventEmitter<{ uploadComponent: any, onlyEdit: any, destination: any, service: any, cancelled: any }>();
    @Output() closeAllDialogHandler: EventEmitter<{ cancelled: boolean, fileCount: number, serviceId: string }> = new EventEmitter<{
        cancelled: boolean,
        fileCount: number,
        serviceId: string
    }>();
    @ViewChild('uploadDropzone', {static: true}) uploadDropzoneComponent: UploadDropzoneComponent;
    @ViewChild('angularEditor', {static: false}) angularEditor: AngularEditorComponent;

    constructor(private formBuilder: FormBuilder,
                private jobsService: JobsService,
                private agencyService: AgencyService,
                private dialogService: DialogService,
                private uploadService: UploadService,
                private servicesService: ServicesService
    ) {
        this.briefEditorConfig = {
            enableToolbar: false,
            placeholder: 'Enter brief',
            spellcheck: true,
            maxHeight: 'auto',
            editable: true,
            showToolbar: false,
            customClasses: [
                {
                    name: 'brief',
                    class: 'brief-editor'
                }
            ]
        };
        this.onlyEdit = false;
        this.closeServiceUploader = false;
        this.fileCount = 0;
        this.serviceHandled = false;
        this.uploadLimited = this.uploadService.uploadLimited$;
        this.allowedFile = 'image';
        this.loadingInProgress = false;
        this.serviceForm = this.formBuilder.group({
            id: new FormControl(null),
            service: new FormControl(null),
            processingRequired: new FormControl(true),
            DISPLAY: new FormControl(''),
            SNAKE_CASE: new FormControl(''),
            status: new FormControl(''),
            brief: new FormControl({value: null, disabled: false}),
            numOfFinals: new FormControl(null, [Validators.min(1), Validators.pattern(/^\d*?$/)])
        });
        this.fileCount = 0;
    }

    ngOnInit() {
        const job = this.jobsService.selectedJob;
        if (job.agency) {
            this.agencyService.getAgency(job.agency)
                .subscribe(agency => {
                    this.serviceForm.get('brief').setValue(JSON.parse(agency?.notes.replace(/\\n/g, `<br>`)) ?? '');
                });
        }
        this.serviceForm.get('DISPLAY').setValue(this.service.DISPLAY);
        this.serviceForm.get('SNAKE_CASE').setValue(this.service.SNAKE_CASE);
    }

    startUpload() {
        const required = this.serviceForm.get('processingRequired').getRawValue();
        if (required) {
            this.onProcessServiceClicked();
        } else {
            this.onSaveServiceClicked();
        }
    }

    handleProcessingRequiredSelection(required: boolean) {
        this.storageDestination = required
            ? StorageTarget.PROCESSOR
            : StorageTarget.MEMBER;
        this.serviceForm.markAsTouched();
    }

    setFileCount(count: number) {
        this.fileCount = count;
    }

    onSaveServiceClicked() {
        this.loadingInProgress = true;
        this.updateInputActiveState(false);

        if (!this.serviceForm.get('processingRequired').value) {
            this.serviceForm.controls['brief'].setValue('');
            this.serviceForm.get('numOfFinals').setValidators([]);
            this.serviceForm.controls['numOfFinals'].setValue(0);
        }
        this.uploadService.removeUploadLimited(this.service.id);
        this.serviceForm.get('status').setValue(ServiceStatus.noProcessing);
        this.closeServiceUploaderHandler(false, StorageTarget.MEMBER);
        if (!this.onlyEdit) {
            this.closeDialog(false);
        }
    }

    onProcessServiceClicked() {
        this.loadingInProgress = true;
        this.updateInputActiveState(false);
        this.uploadService.removeUploadLimited(this.service.id);
        this.serviceForm.get('status').setValue(ServiceStatus.uploadInProgress);
        this.closeServiceUploaderHandler(false, StorageTarget.PROCESSOR);

        if (this.service.version === 'v2') {
            if (!this.service.ticketId) {
                this.createTicket();
            } else {
                this.createSubTicket();
            }
        } else {
            this.closeDialog(false)
        }
    }

    onCancelClicked() {
        this.loadingInProgress = true;
        this.updateInputActiveState(false);
        this.uploadService.removeUploadLimited(this.service.id);
        this.closeServiceUploaderHandler(true, null);
        this.closeDialog(true);
    }

    closeServiceUploaderHandler(cancelled: boolean = false, destination: StorageTarget) {
        try {
            this.serviceForm.disable();
            this.closeServiceUploader = true;
            this.serviceId = this.service.id;
            this.serviceForm.get('id').setValue(this.serviceId);

            if (this.serviceForm.get('status').value !== ServiceStatus.uploadInProgress) {
                this.serviceForm.get('status').setValue(ServiceStatus.uploadInProgress);
            }

            this.serviceForm.updateValueAndValidity();
            this.serviceHandled = true;

            if (this.onlyEdit) {
                if (!cancelled) {
                    this.uploadDropzoneComponent.startUpload(this.parentJob.id, destination, this.serviceId);
                }
                this.dialogService.closeAllDialogs();

            } else {
                if (cancelled) {
                    this.serviceForm.reset();
                    this.serviceForm.get('id').setValue(this.serviceId);
                    this.serviceForm.get('status').setValue(ServiceStatus.draft);
                    this.serviceForm.get('DISPLAY').setValue(this.service.DISPLAY);
                    this.serviceForm.get('SNAKE_CASE').setValue(this.service.SNAKE_CASE);
                    this.serviceForm.updateValueAndValidity();
                }

                this.serviceBoxClosed.emit({
                    uploadComponent: this.uploadDropzoneComponent,
                    service: {...this.serviceForm.getRawValue(), version: this.service.version},
                    onlyEdit: this.onlyEdit,
                    destination,
                    cancelled
                });
            }
            this.loadingInProgress = false;
            this.updateInputActiveState(true);

        } catch (e) {
            console.error('Error closing upload modal:', e);
            this.loadingInProgress = false;
            this.updateInputActiveState(true);
        }
    }

    createTicket() {
        const formData = {};
        Object.entries(this.serviceForm.controls).forEach(([key, val]) => {
            formData[key] = val?.getRawValue() || null;
        });
        formData['address'] = this.parentJob.address;
        this.servicesService.createTicket(this.service, formData);
        this.closeDialog(false);
    }

    createSubTicket() {
        const formData = {
            address: this.parentJob.addressForProcessor
        };
        Object.entries(this.serviceForm.controls).forEach(([key, val]) => {
            formData[key] = val?.getRawValue() || null;
        });
        this.servicesService.createSubTicket(this.service, false);
        this.closeDialog(false);
    }

    updateInputActiveState(active: boolean) {
        if (active) {
            this.briefFormControl.enable();
            this.numOfFinalsFormControl.enable();
        } else {
            this.briefFormControl.disable();
            this.numOfFinalsFormControl.disable();
        }
    }

    closeDialog(cancelled: boolean) {
        this.closeAllDialogHandler.emit({cancelled: cancelled, fileCount: this.fileCount, serviceId: this.service.id});
    }

    get briefFormControl() {
        return this.serviceForm.get('brief') as FormControl;
    }

    get numOfFinalsFormControl() {
        return this.serviceForm.get('numOfFinals') as FormControl;
    }
}
