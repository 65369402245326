export const enum FCM_TASKS {
    REFRESH = 'refresh',
    NOTIFICATION = 'notification'
}

export const enum FCM_REFRESH_SUBJECTS {
    JOBS = 'memberJobs'
}

export const enum FCM_NOTIFICATION_SUBJECTS {
    INVOICE_SUCCESS = 'invoiceSyncSuccess'
}