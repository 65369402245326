import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import {Agency, Contractor} from '../../../models';
import {ContractorService, SnackbarService} from '../../../services';
import includes from 'lodash/includes';
import remove from 'lodash/remove';

@Component({
    selector: 'prism-contractors-table',
    templateUrl: './contractors-table.component.html',
    styleUrls: ['./contractors-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractorsTableComponent implements OnInit, OnDestroy {
    private subDestroyer$: Subject<void>;
    columnHeaders: string[];
    contractors: Observable<Contractor[]>;
    totalContractors: BehaviorSubject<number>;
    selectedContractorIds: Map<string, boolean>;
    isLoading: BehaviorSubject<boolean>;
    paginatorControl: { pageSize: number, pageSizeOptions: number[] };
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @Output() selectionUpdated = new EventEmitter<string[]>();
    @Output() rowClicked = new EventEmitter<Contractor>();

    constructor(
        private router: Router,
        private contractorService: ContractorService,
        private snackbarService: SnackbarService
    ) {
        this.columnHeaders = [
            'selected',
            'firstName',
            'lastName',
            'email',
            'loginEmail',
            'address',
            'contactNumber',
            'active',
            'edit'
        ];
        this.totalContractors = new BehaviorSubject<number>(0);
        this.selectedContractorIds = new Map<string, boolean>();
        this.isLoading = new BehaviorSubject<boolean>(false);
        this.paginatorControl = {
            pageSize: this.contractorService.contractorsTableSize,
            pageSizeOptions: [5, 10, 20, 50, 100]
        };
        this.subDestroyer$ = new Subject<void>();
    }

    _showActive = true;
    @Input('showActive')
    set showActive(value: boolean) {
        if (this._showActive !== value) {
            this._showActive = value;
            this.getContractors();
        }
    }

    ngOnInit() {
        this.getContractors();
        this.paginator.page
            .pipe(
                takeUntil(this.subDestroyer$)
            )
            .subscribe(() => {
                this.getContractors();
            });
    }

    getContractors() {
        this.isLoading.next(true);
        const pageSize = this.contractorService.contractorsTableSize;
        const pageIndex = this.paginator.pageIndex ? this.paginator.pageIndex : 0;
        this.contractors = this.contractorService
            .getContractors(
                'firstName',
                'asc',
                pageIndex,
                pageSize,
                this._showActive ? 'active' : 'archived'
            )
            .pipe(
                take(1),
                map(res => {
                    this.isLoading.next(false);
                    this.totalContractors.next(res.availableItems);
                    return res.data;
                })
            );
    }

    onContractorSelectedChanged(selected: boolean, id: string) {
        if (selected) {
            this.selectedContractorIds.set(id, true);
        } else {
            this.selectedContractorIds.delete(id);
        }
        this.selectionUpdated.emit(Array.from(this.selectedContractorIds.keys()));
    }

    putToggleActive(contractor: Contractor, activeStatus: boolean) {
        this.isLoading.next(true);
        this.snackbarService.showSnackbar(`${activeStatus ? 'Activating' : 'Archiving'} contractor...`);

        this.contractorService.updateContractorsActiveStatus([contractor.id], activeStatus)
            .toPromise()
            .then(() => {
                this.getContractors();
                this.snackbarService.dismissSnackbar();
            })
            .catch(err => {
                console.error('Put Contractor Error:', err);
                this.snackbarService.handleError(`There was an error while archiving your contractor. Please try again.`);
            });
    }

    onRowClicked(row) {
        this.rowClicked.emit(row);
    }

    paginate(event) {
        this.contractorService.updateContractorsTableSize = event.pageSize;
    }

    ngOnDestroy() {
        this.subDestroyer$.next();
    }
}
