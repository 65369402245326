import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';
import {BaseComponent} from '../../components';
import {EnableContractorComponent} from 'src/app/components/enable-contractor/enable-contractor.component';
import {LoggedInGuard} from '../../services';
import {AppModule} from '../modules';

const appRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/home/jobs'
    },
    {
        path: 'agent',
        loadChildren: () => import('../modules/agent.module').then(m => m.AgentModule)
    },
    {
        path: 'login',
        loadChildren: () => import('../modules/login.module').then(m => m.LoginModule)
    },
    {
        path: 'agent',
        loadChildren: () => import('../modules/agent.module').then(m => m.AgentModule)
    },
    {
        path: 'users/enable-contractor',
        component: EnableContractorComponent
    },
    {
        path: '',
        component: BaseComponent,
        children: [
            {
                path: 'home',
                canActivate: [LoggedInGuard],
                loadChildren: () => import('../modules/home-core.module').then(m => m.HomeCoreModule),
            },
            {
                path: 'users',
                canActivate: [LoggedInGuard],
                loadChildren: () => import('../modules/users.module').then(m => m.UsersModule)
            }
        ]
    },
    {
        path: 'setup',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('../modules/login-extra.module').then(m => m.LoginExtraModule)
    },
    {
        path: '**',
        redirectTo: '/home/jobs'
    }
];

export const AppRouting: ModuleWithProviders<AppModule> = RouterModule
    .forRoot(appRoutes, {
        preloadingStrategy: PreloadAllModules
    });
