import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import 'firebase/firestore';
import {BehaviorSubject, Subject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {combineLatest, first, map, take, takeUntil} from 'rxjs/operators';
import {Job, Response, JobProduct, MemberProduct} from '../../models';
import {
    AgencyService,
    AssetsService,
    AuthenticationService,
    DialogService,
    JobsService,
    ProductsService,
    SnackbarService
} from '../../services';
import {AddEditInvoiceComponent} from '../add-edit-invoice/add-edit-invoice.component';
import {AddJobProductComponent} from '../add-job-product/add-job-product.component';
import {DownloadSelectionModalComponent} from '../download-selection-modal/download-selection-modal.component';
import {NotifyAgentDialogComponent} from '../notify-agent-dialog/notify-agent-dialog.component';
import orderBy = require('lodash/orderBy');
import {FilterDownloadSelectionModalComponent} from '../filter-download-selection-modal/filter-download-selection-modal.component';
import {collection, doc, getFirestore, onSnapshot} from 'firebase/firestore';
import includes from 'lodash/includes';

@Component({
    selector: 'prism-job-album',
    templateUrl: './job-album.component.html',
    styleUrls: ['./job-album.component.scss']
})

export class JobAlbumComponent implements OnInit, OnDestroy {
    memberProductsLoading$ = this.productsService.areMemberProductsLoading$;
    jobProductsLoading$ = this.productsService.areJobProductsLoading$;
    jobProducts$: Observable<JobProduct[]> = this.productsService.jobProducts$;
    albumProducts$: Observable<any[]> = this.productsService.albumProducts$;
    selectedAssetsCount$: Observable<any> = this.assetsService.selectedAssetsCount$;
    private appDataSub: () => void;
    job: Job;
    jobLoading: boolean;
    productToAdd: BehaviorSubject<MemberProduct> = new BehaviorSubject<MemberProduct>(null);
    dialogRef: MatDialogRef<any>;
    subDestroyer$: Subject<void> = new Subject<void>();
    assetsData: any = {};
    assetPaths: string[] = [];
    agencyData: any;
    dropdownProductSelected = false;
    fetchingAssetPaths = false;
    jobDownloadingNotification: Subject<void> = new Subject<void>();
    memberProducts: MemberProduct[] = [];
    hasActiveProducts: boolean = true;
    jobInvoiced: boolean = true;
    placeholderText: string;
    loading;

    constructor(
        private jobsService: JobsService,
        private router: Router,
        private agencyService: AgencyService,
        private productsService: ProductsService,
        private snackbarService: SnackbarService,
        private dialogService: DialogService,
        private assetsService: AssetsService,
        private authService: AuthenticationService,
    ) {
    }

    ngOnInit() {
        this.productsService.memberProducts$
            .pipe(
                takeUntil(this.subDestroyer$),
                map(memberProducts => {
                    return orderBy(memberProducts, ['ProductName'], 'asc');
                })
            )
            .subscribe((memberProducts) => {
                this.memberProducts = memberProducts;
                this.hasActiveProducts = memberProducts.filter(product => product.active).length >= 1;
            });


        this.loading = this.productsService.areMemberProductsLoading$
            .pipe(
                takeUntil(this.subDestroyer$),
                combineLatest(this.productsService.areJobProductsLoading$)
            )
            .subscribe((loading) => {
                this.placeholderText = includes(loading, true) ? `Loading. Please wait...` : 'Select a product to add';
            });


        this.jobLoading = false;
        if (!this.jobsService.selectedJob) {
            let memberData = this.authService.memberCurrentData;
            let jobId = this.router.url.split('/')[3];
            this.jobLoading = true;
            this.jobsService.getJob(jobId, memberData.mainMemberUid).then((job) => {
                this.jobsService.setSelectedJob(job);
                this.jobInvoiced = this.isJobInvoiced(job);
                this.job = job;
                this.jobLoading = false;
                this.setup(jobId);
            });

        } else {
            this.job = this.jobsService.selectedJob;
            this.setup();
            this.jobInvoiced = this.isJobInvoiced(this.job);
        }
    }

    setup(jobId = null) {
        const {memberProfileUid, id, agency} = this.job;
        const firestoreRef = getFirestore();
        let docRef = doc(collection(firestoreRef, 'assetCounters'), `${memberProfileUid}_${id}`);

        this.appDataSub = onSnapshot(docRef,
            snapshot => {
                this.assetsData = snapshot.data();
            },
            (error) => {
                console.error(error);
            }
        );

        this.productsService.clearAllProducts();

        if (!!agency) {
            this.agencyService.getAgency(agency)
                .toPromise()
                .then(agency => {
                    this.agencyData = agency;
                })
                .catch(e => {
                    console.error('Error fetching agency data', e);
                    this.snackbarService.handleError('Error fetching agency data');
                });
        }

        this.productsService.getJobProducts(jobId)
            .pipe(
                takeUntil(this.subDestroyer$)
            )
            .subscribe(
                () => {
                    this.productsService.refreshAlbumPage();
                },
                (err) => console.error(err)
            );

        this.productsService.getMemberProducts({getAll: true})
            .pipe(
                takeUntil(this.subDestroyer$),
                map(memberProductsResponse => {
                    const memberProducts = memberProductsResponse.data || [];
                    memberProductsResponse.data = orderBy(memberProducts, ['productName'], 'asc');
                    return memberProductsResponse;
                })
            )
            .subscribe();
    }

    openShareModal() {
        this.dialogService.openDialog(NotifyAgentDialogComponent, {job: this.job}, {width: '600px', autoFocus: false});
    }

    navToJob() {
        const parsedUrl = this.router.routerState.snapshot.url.split('/');
        parsedUrl.pop();
        this.router.navigate(parsedUrl);
    }

    openAddProductDialog() {
        this.productsService.switchPostJobProductLoaderOn();
        const product = this.productToAdd.value;
        this.productsService.setProductToAdd(product);
        this.dialogRef = this.dialogService.openDialog(AddJobProductComponent, this.productToAdd.value, {
            panelClass: 'add-job-product-dialog'
        });
    }

    setSelectedProduct(product) {
        this.dropdownProductSelected = true;
        this.productToAdd.next(product);
    }

    navToMasterProducts() {
        this.router.navigate(['/home', 'products']);
    }

    downloadAllAssets() {
        this.fetchingAssetPaths = true;
        this.assetsService.getAssetsBucketPaths(this.job.id)
            .pipe(
                first(d => !!d)
            )
            .subscribe(
                (assetsResponse: Response) => {
                    this.assetPaths = assetsResponse.data;
                    let assets = [];
                    Object.values(this.assetsData).forEach((item) => {
                        Object.entries(item).forEach(([key, val]) => {
                            if (!key.endsWith('_watermarkFileCount') && !key.endsWith('_webFileCount')) {
                                assets = assets.concat(val);
                            }
                        });
                    });
                    this.fetchingAssetPaths = false;
                    this.dialogService.openDialog(FilterDownloadSelectionModalComponent, {
                        allAssetPaths: this.assetPaths,
                        assets: assets,
                        downloadPrefix: `job_${this.job.id}`
                    }, {
                        panelClass: 'filter-download-selection-dialog'
                    });
                },
                err => {
                    this.fetchingAssetPaths = false;
                    console.error('Error getting asset paths:', err);
                    this.snackbarService.handleError('Problem while collecting file paths. You will not be able to download your jobs files.');
                }
            );
    }

    downloadSelectedAssets() {
        this.fetchingAssetPaths = true;
        this.assetsService.getAssetsBucketPaths(this.job.id)
            .pipe(
                first(d => !!d)
            )
            .subscribe(
                (assetsResponse: Response) => {
                    let selectedAssets = this.assetsService.getSelectedAssets();
                    const filePathNames = selectedAssets.map((asset) => asset.finalPath);
                    this.assetPaths = assetsResponse.data;
                    let filteredAssets = [];

                    for (let filePath of filePathNames) {
                        const [mUid, jUid, pUid, serviceUid, finals, filenameTail] = filePath.split('/');
                        this.assetPaths.forEach((item) => {
                            if ((item.startsWith(`${mUid}/${jUid}/${pUid}/${serviceUid}`) && (item.endsWith(`/finals/${filenameTail}`) || item.endsWith(`/finals/web_${filenameTail}`) || item.endsWith(`/finals/watermark_${filenameTail}`)))) {
                                filteredAssets.push(item);
                            }
                        });
                    }
                    this.fetchingAssetPaths = false;
                    this.notifyJobDownload();
                    this.dialogService.openDialog(DownloadSelectionModalComponent, {allAssetPaths: filteredAssets}, {
                        panelClass: 'download-selection-dialog'
                    });
                },
                err => {
                    this.fetchingAssetPaths = false;
                    console.error('Error getting asset paths:', err);
                    this.snackbarService.handleError('Problem while collecting file paths. You will not be able to download your jobs files.');
                }
            );
    }

    isJobInvoiced(job) {
        return (
            ((!!job.invoices && job.invoices.length > 0) && (!job.productsToInvoice)) ||
            ((!!job.unsyncedInvoices && job.unsyncedInvoices.length > 0) && (!job.productsToInvoice)) ||
            this.authService.memberCurrentData.isContractor ||
            ((job.charged === false) && !job.productsToInvoice));
    }

    openInvoiceDialog(job) {
        this.jobsService.setSelectedJob(job);
        const dialogRef = this.dialogService.openDialog(AddEditInvoiceComponent, {
            job,
            memberProfileUid: this.authService.getUserUid()
        }, {width: '90vw'});

        dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe((result: 'charged' | 'not_charged' | 'canceled') => {
                if (result === 'charged') {
                    this.jobsService.setSelectedJobInvoicesPlaceholder();
                    this.job = this.jobsService.selectedJob;
                    this.jobInvoiced = true;
                } else if (result === 'not_charged') {
                    this.jobsService.setSelectedJobChargedState(false);
                    this.job = this.jobsService.selectedJob;
                    this.jobInvoiced = true;
                } else {
                    this.jobInvoiced = false;
                }
            });
    }

    notifyJobDownload() {
        this.jobDownloadingNotification.next();
    }

    ngOnDestroy() {
        this.assetsService.jobAlbumOnDestroy(this.job?.id);
        this.subDestroyer$.next();
        this.appDataSub();
    }
}
