import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {AlbumProduct, Contractor, Member, MemberProduct} from '../../models';
import {AddEditMemberProductComponent} from '../add-edit-member-product/add-edit-member-product.component';
import {
    AuthenticationService,
    DialogService,
    ProductsService,
    SnackbarService
} from '../../services';
import {map, tap} from 'rxjs/operators';

@Component({
    selector: 'prism-products-page',
    templateUrl: './products-page.component.html',
    styleUrls: ['./products-page.component.scss']
})
export class ProductsPageComponent implements OnInit, OnDestroy {
    memberData$: Observable<Member | Contractor>;
    isPostProductLoading$: Observable<boolean>;
    isGetProductsLoading$: Observable<boolean>;
    isPutProductsLoading$: Observable<boolean>;
    memberProducts$: Observable<MemberProduct[]>;
    fetchMemberProductsSub: Subscription;
    singleGetMemberProductsSub: Subscription;
    showActive: boolean;
    initialTableLoad: boolean;
    activeArchiveCheckboxArray: any;
    dialogRef: MatDialogRef<any>;

    constructor(
        private dialogService: DialogService,
        private authService: AuthenticationService,
        private productsService: ProductsService,
        private snackbarService: SnackbarService
    ) {
        this.initialTableLoad = true;
        this.memberData$ = this.authService.memberData$;
        this.isPostProductLoading$ = this.productsService.isPostMemberProductLoading$;
        this.isGetProductsLoading$ = this.productsService.isGetMemberProductsLoading$.pipe(tap((loading) => {
            if (loading === false) this.initialTableLoad = false;
        }));
        this.isPutProductsLoading$ = this.productsService.isPutMemberProductsLoading$;
        this.memberProducts$ = this.productsService.memberProducts$.pipe(
            map((products: MemberProduct[] = []) => {
                return this.showActive
                    ? products.filter(product => product.active)
                    : products.filter(product => !product.active);
            })
        );
        this.showActive = true;
        this.activeArchiveCheckboxArray = [];
    }

    ngOnInit() {
        this.fetchMemberProductsSub = this.productsService
            .getMemberProducts(
                this.productsService.setAndGetFetchMemberProductsOptions({
                    getActive: true,
                    getAll: false
                })
            )
            .subscribe(
                () => null,
                () => null,
                () => {
                    if (!!this.fetchMemberProductsSub) {
                        this.fetchMemberProductsSub.unsubscribe();
                    }
                }
            );
    }

    toggleActiveArchiveDisplay(getActiveToggle) {
        this.initialTableLoad = true;
        this.productsService
            .getMemberProducts(
                this.productsService.setAndGetFetchMemberProductsOptions({
                    getActive: getActiveToggle.value
                })
            )
            .subscribe(
                () => null,
                () => null,
                () => {
                    this.productsService.switchGetMemberProductsLoaderOff();
                    this.activeArchiveCheckboxArray = [];
                }
            );
    }

    openCreateEditProductDialog(edit: boolean = false, product?: AlbumProduct) {
        if (edit) {
            this.productsService.setMemberProductToEdit(product);
        } else {
            this.productsService.setMemberProductToEdit(null);
        }

        this.dialogRef = this.dialogService.openDialog(
            AddEditMemberProductComponent,
            {product}
        );
    }

    setArchiveArray(ids: number[]) {
        this.activeArchiveCheckboxArray = ids;
    }

    toggleProductActive(setActiveTo) {
        this.productsService.switchPutMemberProductLoaderOn();
        this.snackbarService.showSnackbar('Updating products...');
        if (this.activeArchiveCheckboxArray.length > 0) {
            this.productsService
                .changeMemberProductActiveState(
                    this.activeArchiveCheckboxArray,
                    setActiveTo
                )
                .toPromise()
                .then(() => {
                    this.productsService.switchPutMemberProductLoaderOff();
                    this.refreshMemberProducts();
                })
                .catch(err => {
                    console.error('Put Products Error:', err);
                    this.snackbarService.handleError(
                        `There was an error while archiving your products. Please try again.`
                    );
                    this.productsService.switchPutMemberProductLoaderOff();
                });
        } else {
            this.snackbarService.showSnackbar(`No products selected to be archived`);
        }
        this.activeArchiveCheckboxArray = [];
    }

    private refreshMemberProducts() {
        this.activeArchiveCheckboxArray = [];
        this.productsService.switchGetJobProductsLoaderOn();
        this.singleGetMemberProductsSub = this.productsService
            .getMemberProducts(
                this.productsService.setAndGetFetchMemberProductsOptions({
                    getActive: this.showActive
                })
            )
            .subscribe(
                () => {
                },
                () =>
                    this.snackbarService.handleError(
                        `Error getting updated products. Please Reload`
                    ),
                () => {
                    this.productsService.switchGetMemberProductsLoaderOff();
                    this.singleGetMemberProductsSub.unsubscribe();
                }
            );
    }

    ngOnDestroy() {
        if (this.fetchMemberProductsSub) {
            this.fetchMemberProductsSub.unsubscribe();
        }
    }
}
