<div class="xero-info">
    <div class="xero-badge"
         *ngIf="(xeroExpiryTimer$|async) > -1 && !((xeroLoading$ | async) || (loadingQueue$|async)); else loader"
         [ngClass]="{
         'invoices-un-synced': (invoiceQueueCount$ | async) > 0,
         'xero-disconnected': (xeroExpiryTimer$ | async) === 0,
         'xero-connected': (xeroExpiryTimer$ | async) !== 0}"
    >
    </div>
    <ng-template #loader>
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </ng-template>
    <div class="xero-logo">
        <img
                class="header-right-btn xero-toggle-button"
                src="../../../assets/icons/xero-logo.svg"
                alt="Xero"
                (click)="toggleXeroInfoDisplay()"
        />
    </div>
    <div class="xero-info-container">
        <div
                class="xero-info-content"
                [ngClass]="{ active: displayXeroControl }"
                *ngIf="(xeroExpiryTimer$ | async) !== 0; else xeroNotConnected"
        >
            <div class="content-wrapper">
                <button
                        class="error-button"
                        mat-icon-button
                        *ngIf="queueHasError$ | async"
                        (click)="navToUnsyncedQueue()"
                >
                    <mat-icon>error_outline</mat-icon>
                </button>
                <div [ngSwitch]="invoiceQueueCount$ | async">
                    <h5 class="xero-display-item organisation-info">Status:</h5>
                    <mat-progress-bar mode="indeterminate" *ngIf="loadingQueueCount"></mat-progress-bar>
                    <h4 *ngSwitchCase="null">Please recon</h4>
                    <h4 class="synced-content-text xero-display-item" *ngSwitchCase="0">
                        Invoices Synced
                    </h4>
                    <h4 *ngSwitchDefault>
                        You have {{ invoiceQueueCount$ | async }} un-synced invoices
                    </h4>
                </div>

                <h5 class="xero-display-item organisation-info">Connected as</h5>
                <h3 class="xero-display-item organisation-info">
                    {{ (organisation$ | async) }}
                </h3>
            </div>
            <div class="btn-wrapper">
                <button
                        mat-raised-button
                        class="btn btn-green"
                        (click)="reconInvoices()"
                        [disabled]="(reconBusySingle$|async) || (reconBusyQueue$|async) || (xeroExpiryTimer$ | async) === 0"
                >
                    Recon
                    <ng-template [ngIf]="(reconBusySingle$|async) || (reconBusyQueue$|async)">
                        <div class="lds-hourglass"></div>
                    </ng-template>
                </button>
                <button
                        mat-raised-button
                        class="btn btn-outline xero-display-item"
                        (click)="connectToXero()"
                >
                    Change Organisation
                </button>
            </div>
        </div>

        <ng-template #xeroNotConnected>
            <div
                    class="xero-info-content"
                    [ngClass]="{ active: displayXeroControl }"
            >
                <div class="content-wrapper" [ngSwitch]="invoiceQueueCount$ | async">
                    <h5 class="xero-display-item organisation-info">Status</h5>
                    <h4 *ngSwitchCase="null">Please recon</h4>
                    <h4 *ngSwitchCase="0">You have no un-synced invoices</h4>
                    <h4 *ngSwitchDefault>
                        You have {{ invoiceQueueCount$ | async }} un-synced invoices
                    </h4>
                </div>
                <button
                        mat-icon-button
                        *ngIf="queueHasError$ | async"
                        (click)="navToUnsyncedQueue()"
                >
                    <mat-icon>error_outline</mat-icon>
                </button>
                <ng-template #gettingInvoiceCount>
                    <mat-spinner [diameter]="20"></mat-spinner>
                </ng-template>
                <div class="btn-wrapper">
                    <button
                            mat-raised-button
                            class="btn btn-green"
                            (click)="reconInvoices()"
                            [disabled]="true"
                    >
                        Recon
                        <ng-template [ngIf]="(reconBusySingle$|async) || (reconBusyQueue$|async)">
                            <div class="lds-hourglass"></div>
                        </ng-template>
                    </button>
                    <button
                            mat-raised-button
                            class="btn btn-xero"
                            (click)="connectToXero()"
                    >
                        Connect to Xero
                    </button>
                </div>
            </div>
        </ng-template>
    </div>
</div>
