import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import * as Dropzone from 'dropzone';
import 'firebase/storage';
import moment from 'moment';
import {BehaviorSubject, fromEvent, Observable, Subject, timer} from 'rxjs';
import {catchError, debounceTime, delayWhen, retryWhen, take, takeUntil, tap} from 'rxjs/operators';
import {environment} from '../../environments';
import {ServiceStatus} from '../enums';
import {createNestedObject} from '../helpers';
import {Job, Service} from '../models';
import {JobsService} from './jobs.service';
import {ProductsService} from './products.service';
import {SnackbarService} from './snackbar.service';
import {AuthenticationService} from './authentication.service';
import {UUID} from 'angular2-uuid';
import isEmpty from 'lodash/isEmpty';

@Injectable({
    providedIn: 'root'
})
export class UploadService implements OnDestroy {
    private _uploadsType: BehaviorSubject<any> = new BehaviorSubject<any>({});
    // Stores the state of each asset (completed, progress, uploadRestartCancelData, failed)
    private _uploadVersion: BehaviorSubject<{ [fileUUID: string]: 'v1' | 'v2' }> = new BehaviorSubject<{
        [fileUUID: string]: 'v1' | 'v2'
    }>({});
    private uploads: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    // Stores the state of each upload session (completed, total)
    private uploadStats: BehaviorSubject<{ completed: number, total: number, totalSize: number }> = new BehaviorSubject<any>({
        completed: 0,
        total: 0,
        totalSize: 0
    });
    // Not sure
    private _activeUploads: BehaviorSubject<any> = new BehaviorSubject<any>({});
    // For upload status bar
    private _uploadingStatusSet: BehaviorSubject<{ [serviceId: string]: boolean }> = new BehaviorSubject<{ [p: string]: boolean }>({});
    // To place temporary placeholder images in service bucket
    private _uploadThumbPlaceholders: BehaviorSubject<any> = new BehaviorSubject<any>({});
    // Triggers ending all uploads in Offline event
    destroyed$ = new Subject<void>();
    // Triggers saveServiceAfterEdit in serviceUploadComponent
    editServiceComplete = new Subject<void>();
    onlineEvent: Observable<Event>;
    offlineEvent: Observable<Event>;
    uploadLimit$ = new BehaviorSubject({});

    constructor(private productsService: ProductsService,
                private jobService: JobsService,
                private http: HttpClient,
                private snackbarService: SnackbarService,
                private authService: AuthenticationService) {

        this.onlineEvent = fromEvent(window, 'online');

        this.onlineEvent
            .subscribe(() => {
                if (!!this.uploads.value) {
                    if (Object.keys(this.uploads.value).length > 0) {
                        setTimeout(() => {
                            this.resumeAllUploads(true);
                        }, 5000);
                        this.authService.setAppInternetConnection(true);
                        this.snackbarService.showSnackbar('Your connectivity has been restored!');
                    } else {
                        this.authService.setAppInternetConnection(true);
                        this.snackbarService.showSnackbar('Your connectivity has been restored!');
                    }
                } else {
                    this.authService.setAppInternetConnection(true);
                    this.snackbarService.showSnackbar('Your connectivity has been restored!');
                }


            });

        this.offlineEvent = fromEvent(window, 'offline');
        this.offlineEvent
            .subscribe(() => {
                this.destroyed$.next();
                this.authService.setAppInternetConnection(false);
                this.snackbarService.showSnackbar('You have lost connectivity');
            });
    }

    get activeUploads() {
        return this._activeUploads.value;
    }

    get uploadLimited$() {
        return this.uploadLimit$.asObservable();
    }

    get uploadThumbPlaceholders$() {
        return this._uploadThumbPlaceholders.asObservable();
    }

    get uploads$() {
        return this.uploads.asObservable();
    }

    get uploadStats$() {
        return this.uploadStats.asObservable();
    }

    get uploadingStatusSet$(): Observable<{ [serviceId: string]: boolean }> {
        return this._uploadingStatusSet.asObservable();
    }

    get uploadingStatusSet(): { [serviceId: string]: boolean } {
        return this._uploadingStatusSet.value;
    }

    async initializeUpload({
                               uploadUUID,
                               assets,
                               serviceData,
                               productId,
                               oldServiceStatus,
                               job,
                               uploadType,
                               processingType
                           }: {
        uploadUUID: string,
        assets: Dropzone.DropzoneFile[],
        serviceData: Service,
        productId: string,
        oldServiceStatus: string,
        job: Job,
        uploadType: string,
        processingType: string | null
    }) {
        if (this.uploads.value === null) {
            this.uploads.next({});
        }

        this.uploads.value[uploadUUID] = {};
        this.uploads.next(this.uploads.value);
        this.setUploadVersion(uploadUUID, serviceData.version || 'v1');
        this.uploadStats.value.total += assets.length;
        this.uploadStats.next(this.uploadStats.value);
        this._uploadsType.value[uploadUUID] = uploadType;
        this._uploadsType.next(this._uploadsType.value);
        let uploadTimestamp = moment().valueOf();
        this._uploadingStatusSet.next({
            ...this._uploadingStatusSet.value,
            [serviceData.id]: true
        });

        serviceData.jobId = job.id;
        this.updateProductService(serviceData, ServiceStatus.uploadInProgress)
            .then(() => {
                this.jobService.updateJobsOverviewData(serviceData.jobId, serviceData.id, ServiceStatus.uploadInProgress);
            })
            .catch(e => {
                console.error('Error updating status:', e);
                this.snackbarService.handleError('Error updating service status');
            });

        const serviceUploadsTotal = assets.length;
        const memberUid = this.getUploadMemberUid();

        let signedUrlList = null;
        let uploadDataList = null;
        let currentJobId: any;
        let currentJobAddress = 'some address, test';
        let addressForProcessor: string;

        if (!!job) {
            ({id: currentJobId, address: currentJobAddress, addressForProcessor: addressForProcessor} = job);
            serviceData.productId = productId;
        }

        if (!addressForProcessor) {
            serviceData.address = currentJobAddress.substr(0, currentJobAddress.indexOf(','));
        } else {
            serviceData.address = addressForProcessor;
        }
        serviceData.addressFull = currentJobAddress;

        this.objectSetup([uploadUUID, serviceData.address, serviceData.id], this.uploads.value);
        this.uploads.next(this.uploads.value);

        if (uploadType === 'MEMBER') {
            createNestedObject(this._uploadThumbPlaceholders.value, [serviceData.id]);

        }

        let [uploadInfoList, fileTypeList, fileSizeList] = assets.reduce(([a, b, c], {type, size, name}) => {
            if (uploadType === 'PROCESSOR') {
                a.push({
                    uploadPath: `${serviceData.id}_${uploadTimestamp}/${name ? name : 'brief.pdf'}`,
                    uploadUUID: UUID.UUID()
                });
            } else {
                a.push({
                    uploadPath: `${memberUid}/${currentJobId}/${productId}/${serviceData.id}/finals/${uploadTimestamp}_${name}`,
                    uploadUUID: UUID.UUID()
                });
            }

            b.push(type);
            c.push(size);
            return [a, b, c];
        }, [[], [], []]);

        await this.getSignedUrlList(uploadInfoList, uploadType, processingType, this.getUploadVersion(uploadUUID))
            .then((res) => {
                signedUrlList = res;
            })
            .catch(() => {
                this.snackbarService.handleError('Failed to upload service assets.');
                console.error('Failed to upload service assets. (signed url)');
                this.uploadStats.value.total -= assets.length;
                delete this._uploadThumbPlaceholders[serviceData.id];
                this._uploadThumbPlaceholders.next(this._uploadThumbPlaceholders.value);
                this.uploadStats.next(this.uploadStats.value);
                return;
            });

        // Resumable upload are initiated in client app to improve upload speeds due
        // to the upload taking place in the region it's initiated which could be anywhere.
        for (let i = 0; i < assets.length; i++) {
            this.uploads.value[uploadUUID][serviceData.address][serviceData.id][assets[i].name ? assets[i].name : 'brief.pdf'] = {
                progress: 0,
                completed: false,
                timestamp: uploadTimestamp,
                processingType: processingType
            };
        }
        this.uploads.next(this.uploads.value);

        this.getUploadUriList(signedUrlList, fileTypeList, fileSizeList, uploadType)
            .then((res: any) => {
                uploadDataList = res.filter((res: any, index: number) => {
                    if (res.uri) {
                        return true;
                    } else {
                        this.uploadFailureHandler(uploadUUID, signedUrlList[index].uploadUUID, null, serviceData, serviceUploadsTotal, uploadType, assets[index], currentJobId, productId);
                        return false;
                    }
                });
                if (!uploadDataList.length) {
                    delete this._uploadThumbPlaceholders[serviceData.id];
                    this._uploadThumbPlaceholders.next(this._uploadThumbPlaceholders.value);
                    return;
                }

                const [fileUriList, fileUUIDList] = uploadDataList.reduce(([a, b], {uri, uploadUUID}) => {
                    a.push(uri);
                    b.push(uploadUUID);
                    return [a, b];
                }, [[], []]);

                fileUriList.forEach((uri, i) => {
                    let binaryData: any;
                    binaryData = assets[i];
                    let asset = {
                        binaryData: binaryData,
                        name: assets[i].name,
                        size: assets[i].size,
                        type: assets[i].type
                    };

                    this._activeUploads.value[fileUUIDList[i]] = {
                        uri: uri
                    };
                    this._activeUploads.next(this._activeUploads.value);

                    if (uploadType === 'MEMBER') {
                        if (!this._uploadThumbPlaceholders.value[serviceData.id]) {
                            this._uploadThumbPlaceholders.value[serviceData.id] = {};
                        }
                        this._uploadThumbPlaceholders.value[serviceData.id][fileUUIDList[i]] = true;
                        this._uploadThumbPlaceholders.next(this._uploadThumbPlaceholders.value);
                    }

                    this.startUpload({
                        uploadUri: uri,
                        asset,
                        serviceData,
                        uploadUUID,
                        serviceUploadsTotal,
                        oldServiceStatus,
                        fileUUID: fileUUIDList[i],
                        resumeUpload: false,
                        currentJobId,
                        productId,
                        uploadType
                    });
                });
            })
            .catch(() => {
                this.snackbarService.handleError('Failed to upload service assets.');
                console.error('Failed to upload service assets. (signed uri)');
                return;
            });
    }

    async startUpload({
                          uploadUri,
                          asset,
                          serviceData,
                          uploadUUID,
                          serviceUploadsTotal,
                          oldServiceStatus,
                          fileUUID,
                          resumeUpload = false,
                          currentJobId,
                          productId,
                          uploadType
                      }) {

        return new Promise(async (resolve, reject) => {
            let terminateUpload = false;
            let lowerBound: any, upperBound: any;
            let {binaryData} = asset;
            let headers = new HttpHeaders({
                'X-Skip-Headers': ''
            });

            if (!this._uploadingStatusSet.value[serviceData.id]) {
                this._uploadingStatusSet.next({
                    ...this._uploadingStatusSet.value,
                    [serviceData.id]: true
                });
                await this.updateProductService(serviceData, ServiceStatus.uploadInProgress)
                    .then(() => {
                        this.jobService.updateJobsOverviewData(serviceData.jobId, serviceData.id, ServiceStatus.uploadInProgress);
                    })
                    .catch(e => {
                        console.error('Error updating status:', e);
                        this.snackbarService.handleError('Error updating service status');
                    });
            }

            if (resumeUpload) {
                await this.getResumableUploadData(uploadUri, asset)
                    .then(async () => {
                            // This indicates that the upload was completed.
                            this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'].failed = false;
                            this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'].completed = true;
                            this.uploadStats.value.completed++;
                            delete this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'].uploadRestartCancelData;
                            delete this._activeUploads.value[fileUUID];
                            this.uploads.next(this.uploads.value);
                            this._activeUploads.next(this._activeUploads.value);
                            this.uploadStats.next(this.uploadStats.value);

                            let serviceUploadsCompleted = Object.values(this.uploads.value[uploadUUID][serviceData.address][serviceData.id])
                                .filter((file: any) => {
                                    return file.completed === true;
                                })
                                .length;
                            if (serviceUploadsCompleted === serviceUploadsTotal) {
                                await this.updateServiceStatus(serviceData, uploadType, oldServiceStatus);
                                this.editServiceComplete.next();
                            }
                            terminateUpload = true;
                        }
                    ).catch((res) => {
                        lowerBound = res ? res.lowerBound : null;
                        upperBound = res ? res.upperBound : null;
                        if (lowerBound && upperBound) {
                            binaryData = asset.binaryData.slice(upperBound, asset.size);
                            headers = new HttpHeaders({
                                'X-Skip-Headers': '',
                                'Content-Range': `bytes ${upperBound}-${asset.size - 1}/${asset.size}`
                            });
                        }
                    });
            }
            // When connection is restored and the upload got interrupted during finalising this prevents trying to upload the file again.
            if (terminateUpload) {
                return;
            }

            this.http.put(uploadUri, binaryData, {
                headers: headers,
                observe: 'events',
                reportProgress: true
            })
                .pipe(
                    debounceTime(0),
                    takeUntil(this.destroyed$),
                    catchError((err) => {
                        console.error('Upload request error in catchError:', err);
                        this.uploadFailureHandler(uploadUUID, fileUUID, uploadUri, serviceData, serviceUploadsTotal, uploadType, asset, currentJobId, productId);
                        throw err;
                    }),
                    tap(async (uploadData: any) => {
                        if (uploadData.type === HttpEventType.UploadProgress) {
                            this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'].progress = ((uploadData.loaded + (asset.size - uploadData.total)) / asset.size) * 100;
                            this.uploads.next(this.uploads.value);
                            this.uploads.next(this.uploads.value);
                        }
                        if (uploadData.type === HttpEventType.Response) {
                            // Updates the number of complete uploads
                            this.uploadStats.value.completed++;
                            this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'].completed = true;
                            this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'].progress = 100;
                            delete asset.binaryData;
                            binaryData = null;
                            this.uploads.next(this.uploads.value);
                            this.uploadStats.next(this.uploadStats.value);

                            delete this._activeUploads.value[fileUUID];
                            this._activeUploads.next(this._activeUploads.value);

                            let serviceUploadsCompleted = Object.values(this.uploads.value[uploadUUID][serviceData.address][serviceData.id])
                                .filter((file: any) => {
                                    return file.completed === true;
                                })
                                .length;

                            // If all the uploads are completed, the status must be set
                            if (serviceUploadsCompleted === serviceUploadsTotal) {
                                let promiseList = [];
                                const uploadTimestamp = this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'].timestamp;
                                if (uploadType === 'PROCESSOR') {
                                    serviceData.numOfRaws = serviceUploadsTotal - 1;
                                    const sendServiceToProcessorPromise = this.sendServiceToProcessor(serviceData, uploadTimestamp);
                                    promiseList.push(sendServiceToProcessorPromise);
                                }
                                const updateServicePromise = this.updateServiceStatus(serviceData, uploadType, oldServiceStatus);
                                promiseList.push(updateServicePromise);
                                try {
                                    await Promise.all(promiseList);
                                } catch (e) {
                                    reject(e);
                                }

                            }
                            resolve(asset);
                        }
                    })
                ).subscribe(
                () => {
                },
                (err: any) => {
                    resolve(null);
                    console.error('Upload error message:', err.message);
                },
                () => {
                    if (this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'].completed === true) {
                        this.uploadStats.value.totalSize -= asset.size;
                        this.uploadStats.next(this.uploadStats.value);
                    } else {
                        let uploadType = this._uploadsType.value[uploadUUID];
                        this.uploadFailureHandler(uploadUUID, fileUUID, uploadUri, serviceData, serviceUploadsTotal, uploadType, asset, currentJobId, productId);
                    }
                }
            );
        });
    }

    async getUploadUri(url, headers) {
        return new Promise((resolve, reject) => {
            this.http.post(url, null, {
                headers: headers,
                observe: 'response'
            })
                .pipe(
                    retryWhen(errors => {
                        return errors.pipe(
                            delayWhen(() => timer(5000))
                        );
                    }),
                    catchError((err) => {
                        console.error('ERROR:', err);
                        throw err;
                    }),
                    take(1)
                )
                .subscribe(
                    (response) => {
                        let uri = response.headers.get('Location');
                        resolve(uri);
                    },
                    (err) => {
                        reject(err);
                    });
        });
    }

    getSignedUrlList = async (uploadPathList, uploadType: string, processingType: string, version: 'v1' | 'v2') => {
        return new Promise(async (resolve, reject) => {
            this.http.post(`${environment.apiBaseUrl}/StartResumableUpload`, {uploadPathList, uploadType, processingType, version})
                .pipe(
                    retryWhen(errors => {
                        return errors.pipe(
                            delayWhen(() => timer(5000))
                        );
                    })
                )
                .subscribe(
                    (data: any) => {
                        resolve(data.data);

                    },
                    (error: any) => {
                        reject(error);
                    }
                );
        });
    };

    getUploadUriList = (signedUrlList, fileTypeList, fileSizeList, uploadType) => {
        return Promise.all(
            signedUrlList.map(async ({uploadUUID, url}, index) => {
                try {
                    let headers = {
                        'x-goog-resumable': 'start',
                        'Content-Type': `${fileTypeList[index]}`,
                        'x-goog-meta-processingStatus': (uploadType === 'MEMBER') ? 'no-process' : 'process',
                        'x-goog-meta-fileUUID': `${uploadUUID}`,
                        'X-Skip-Headers': ''
                    };

                    let uri = await this.getUploadUri(url[0], headers);
                    this.uploadStats.value.totalSize += fileSizeList[index];
                    let response = {
                        uri: uri,
                        uploadUUID: uploadUUID
                    };
                    return Promise.resolve(response);

                } catch (e) {
                    let response = {
                        uri: null,
                        uploadUUID: uploadUUID
                    };
                    return Promise.resolve(response);
                }
            })
        );
    };

    resumeAllUploads = async (resume = false) => {
        let allFailures = [];
        for (let upload in this.uploads.value) {
            // O(1)
            for (let address in this.uploads.value[upload]) {
                // O(1)
                for (let id in this.uploads.value[upload][address]) {
                    // O(M)
                    for (let name in this.uploads.value[upload][address][id]) {

                        if (this.uploads.value[upload][address][id][name].uploadRestartCancelData) {
                            let restartData = this.uploads.value[upload][address][id][name].uploadRestartCancelData;
                            allFailures.push(restartData);
                            this.uploads.value[upload][address][id][name].failed = false;
                            this.uploads.next(this.uploads.value);

                        }
                    }
                }
            }
        }

        if (window.performance['memory']) {
            let heapSizeLimit = window.performance['memory'].jsHeapSizeLimit;
            for (let i = 0; i < allFailures.length; i++) {

                let currentHeapSize = window.performance['memory'].totalJSHeapSize;

                if (!allFailures[i].asset.binaryData) {
                    allFailures[i].asset.binaryData = await allFailures[i].asset.arrayBuffer();
                } else if (!(allFailures[i].asset.binaryData instanceof ArrayBuffer)) {
                    allFailures[i].asset.binaryData = await allFailures[i].asset.binaryData.arrayBuffer();
                }
                if (currentHeapSize + allFailures[i].asset.size < (heapSizeLimit / 2)) {
                    this.resumeUpload({
                        ...allFailures[i]
                    }, allFailures[i].uploadType, resume);
                } else {
                    await this.resumeUpload({
                        ...allFailures[i]
                    }, allFailures[i].uploadType, resume);
                }
            }
        } else {
            for (let i = 0; i < allFailures.length; i++) {
                if (!allFailures[i].asset.binaryData) {
                    allFailures[i].asset.binaryData = await allFailures[i].asset.arrayBuffer();
                } else if (!(allFailures[i].asset.binaryData instanceof ArrayBuffer)) {
                    allFailures[i].asset.binaryData = await allFailures[i].asset.binaryData.arrayBuffer();
                }

                let result = await this.resumeUpload({
                    ...allFailures[i]
                }, allFailures[i].uploadType, resume);

                if (result) {
                    result = null;
                }
            }
        }
    };

    resumeUpload = async (uploadRestartCancelData: {
        uploadUri: string;
        timestamp: any;
        uploadUUID: string;
        currentJobId: any;
        productId: string;
        serviceData: Service;
        asset: any;
        serviceUploadsTotal: number;
        processingType: string;
        oldServiceStatus: string;
        fileUUID: string;
        resumeUpload: boolean;
        uploadType: string;
    }, uploadType: string, resume: boolean) => {
        if (this.authService.appHasInternetConnection) {
            let {
                uploadUUID,
                currentJobId,
                productId,
                serviceData,
                asset,
                serviceUploadsTotal,
                timestamp,
                processingType
            } = uploadRestartCancelData;

            // When a upload fails during signedUrl/Uri creation step and therefore doesnt not have a upload URI.
            if (!uploadRestartCancelData.uploadUri) {
                let uploadPathList: { uploadPath: string; uploadUUID: string; }[];
                const memberUid = this.getUploadMemberUid();
                if (uploadType === 'MEMBER') {
                    uploadPathList = [
                        {
                            uploadPath: `${memberUid}/${currentJobId}/${productId}/${serviceData.id}/finals/${timestamp}_${asset.name}`,
                            uploadUUID: UUID.UUID()
                        }
                    ];
                } else {
                    uploadPathList = [
                        {
                            uploadPath: `${serviceData.id}_${uploadRestartCancelData.timestamp}/${asset.name}`,
                            uploadUUID: UUID.UUID()
                        }
                    ];
                }

                try {
                    let signedUrlPromise = await this.getSignedUrlList(uploadPathList, uploadType, processingType, this.getUploadVersion(uploadUUID));
                    let uploadUriPromise: any = await this.getUploadUriList(signedUrlPromise, [asset.type], [asset.size], uploadType);
                    if (uploadUriPromise[0].uri) {
                        delete this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'].uploadRestartCancelData;
                        this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'].failed = false;
                        this.uploads.next(this.uploads.value);
                        return this.startUpload({
                            ...uploadRestartCancelData,
                            uploadUri: uploadUriPromise[0].uri,
                            resumeUpload: resume,
                            uploadType: this._uploadsType.value[uploadUUID]
                        });

                    } else {
                        this.uploadFailureHandler(uploadUUID, signedUrlPromise[0].uploadUUID, null, serviceData, serviceUploadsTotal, uploadType, asset, currentJobId, productId);
                        return false;
                    }
                } catch (e) {
                    console.error(e)
                }
            } else {
                delete this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'].uploadRestartCancelData;
                this.uploads.next(this.uploads.value);
                return this.startUpload({
                    ...uploadRestartCancelData, resumeUpload: resume, uploadType: this._uploadsType.value[uploadUUID]
                });
            }
        }
    };

    uploadFailureHandler = (uploadUUID: string, fileUUID: string, uploadUri: string, serviceData: Service, serviceUploadsTotal: number, uploadType: string, asset: Dropzone.DropzoneFile, currentJobId: number, productId: string) => {
        this.objectSetup([uploadUUID, serviceData.address, serviceData.id], this.uploads.value);
        if (asset.name) {
            if (!this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name]) {
                this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name] = {};
            }
        } else {
            if (!this.uploads.value[uploadUUID][serviceData.address][serviceData.id]['brief.pdf']) {
                this.uploads.value[uploadUUID][serviceData.address][serviceData.id]['brief.pdf'] = {};
            }
        }

        const {
            timestamp,
            processingType
        } = this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'];
        this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'].failed = true;
        this.uploads.value[uploadUUID][serviceData.address][serviceData.id][asset.name ? asset.name : 'brief.pdf'].uploadRestartCancelData = {
            asset,
            uploadUri,
            fileUUID,
            uploadUUID,
            serviceData,
            serviceUploadsTotal,
            uploadType: uploadType,
            currentJobId,
            productId,
            timestamp,
            processingType
        };
        this.uploads.next(this.uploads.value);
    };

    getResumableUploadData = async (uploadUri: string, asset: { size: any; }) => {
        let contentRange: string;
        let headers = new HttpHeaders({
            'X-Skip-Headers': '',
            'Content-Range': `bytes */${asset.size}`
        });
        return new Promise((resolve, reject) => {
            this.http.put(uploadUri, null, {
                headers: headers,
                observe: 'response',
                reportProgress: true
            })
                .pipe(
                    take(1)
                )
                .subscribe(
                    (data: any) => {
                        if (data.status === 200 && data.type === 4) {
                            resolve(true);
                        }
                    },
                    (err: any) => {
                        if (err.status === 308) {
                            if (err.headers.get('range')) {
                                contentRange = err.headers.get('range');
                                let myArr = contentRange.replace('bytes=', '').split('-');
                                let contentRangeBounds = {
                                    lowerBound: myArr[0],
                                    upperBound: myArr[1]
                                };
                                reject(contentRangeBounds);
                            } else {
                                reject(null);
                            }
                        }
                    }
                );

        });
    };

    updateServiceStatus = async (serviceData: Service, uploadType: string, oldServiceStatus: ServiceStatus) => {
        if (uploadType === 'PROCESSOR') {
            await this.updateProductService(serviceData, ServiceStatus.inProgress)
                .then(() => {
                    this.jobService.updateJobsOverviewData(serviceData.jobId, serviceData.id, ServiceStatus.inProgress);
                    this._uploadingStatusSet.next({
                        ...this._uploadingStatusSet.value,
                        [serviceData.id]: false
                    });
                })
                .catch(e => {
                    console.error('Error updating status:', e);
                    this.snackbarService.handleError('Error updating service status');
                });
        } else if (uploadType === 'MEMBER') {
            if (oldServiceStatus === ServiceStatus.inProgress) {
                await this.updateProductService(serviceData, ServiceStatus.inProgress)
                    .then(() => {
                        this.jobService.updateJobsOverviewData(serviceData.jobId, serviceData.id, ServiceStatus.inProgress);
                        this._uploadingStatusSet.next({
                            ...this._uploadingStatusSet.value,
                            [serviceData.id]: false
                        });
                    })
                    .catch(e => {
                        console.error('Error updating status:', e);
                        this.snackbarService.handleError('Error updating service status');
                    });
            } else if (oldServiceStatus === ServiceStatus.memberReview) {
                await this.updateProductService(serviceData, ServiceStatus.memberReview)
                    .then(() => {
                        this.jobService.updateJobsOverviewData(serviceData.jobId, serviceData.id, ServiceStatus.memberReview);
                        this._uploadingStatusSet.next({
                            ...this._uploadingStatusSet.value,
                            [serviceData.id]: false
                        });
                    })
                    .catch(e => {
                        console.error('Error updating status:', e);
                        this.snackbarService.handleError('Error updating service status');
                    });
            } else {
                await this.updateProductService(serviceData, ServiceStatus.noProcessing)
                    .then(() => {
                        this.jobService.updateJobsOverviewData(serviceData.jobId, serviceData.id, ServiceStatus.noProcessing);
                        this._uploadingStatusSet.next({
                            ...this._uploadingStatusSet.value,
                            [serviceData.id]: false
                        });
                    })
                    .catch(e => {
                        console.error('Error updating status:', e);
                        this.snackbarService.handleError('Error updating service status');
                    });
            }
        }
    };

    updateProductService(serviceData: Service, status: string) {
        return this.productsService.updateProductServiceStatus({
            targetStatus: status,
            serviceId: serviceData.id
        }).toPromise();
    }

    async sendServiceToProcessor(serviceData: Service, uploadTimestamp: any) {
        let serviceName = serviceData['id'].slice(serviceData.id.length - 6, serviceData.id.length);
        this.snackbarService.showSnackbar(`Sending ${serviceName} for processing.`);

        try {
            await this.http.post(`${environment.apiBaseUrl}/${serviceData.version === 'v2' ? 'SendProcessor2Job' : 'SendProcessorJob'}`, {
                serviceData,
                uploadTimestamp
            })
                .toPromise();
            this.snackbarService.showSnackbar(`${serviceName} has been sent for processing.`);
        } catch (err) {
            console.error('Error sending data to processor:', err);
            this.snackbarService.handleError(`${serviceName} could not be sent for processing.`);
        }
    }

    getUploadMemberUid() {
        // TODO: Make sure this works correct or replace.
        return this.authService.memberCurrentData.isContractor
            ? this.authService.memberCurrentData.mainMemberUid
            : this.authService.memberCurrentData.memberProfileUid;
    }

    getUploadVersion(uploadUUID: string) {
        return this._uploadVersion.getValue()[uploadUUID];
    }

    setUploadVersion(uploadUUID: string, version: 'v1' | 'v2') {
        this._uploadVersion.value[uploadUUID] = version;
        this._uploadVersion.next(this._uploadVersion.value);
    }

    deactivateThumbPlaceholder(thumbUUID: string, serviceId: string) {
        let placeholderUpdated = false;
        if (!!this._uploadThumbPlaceholders.value[serviceId] && !!this._uploadThumbPlaceholders.value[serviceId][thumbUUID]) {
            placeholderUpdated = true;
            delete this._uploadThumbPlaceholders.value[serviceId][thumbUUID];
        }
        if (isEmpty(this._uploadThumbPlaceholders.value[serviceId])) {
            placeholderUpdated = true;
            delete this._uploadThumbPlaceholders.value[serviceId];
        }
        if (placeholderUpdated) {
            this._uploadThumbPlaceholders.next(this._uploadThumbPlaceholders.value);
        }
    }

    clearUploadsData() {
        this.uploadStats.next({completed: 0, total: 0, totalSize: 0});
        this.uploads.next(null);
        this._uploadsType.next({});
    }

    removeServiceUploads(uploadUUID: string, jobKey: string, serviceId: string) {
        const uploadCount = Object.keys(this.uploads.value[uploadUUID][jobKey][serviceId]).length;
        this.uploadStats.value.completed = this.uploadStats.value.completed - uploadCount;
        this.uploadStats.value.total = this.uploadStats.value.total - uploadCount;
        this.uploadStats.next(this.uploadStats.value);
        delete this.uploads.value[uploadUUID];
        delete this._uploadVersion.value[uploadUUID];
        this.uploads.next(this.uploads.value);
        this._uploadVersion.next(this._uploadVersion.value);
    }

    uploadLimited(limit: boolean, serviceId: string) {
        this.uploadLimit$.value[serviceId] = limit;
        this.uploadLimit$.next(this.uploadLimit$.value);
    }

    removeUploadLimited(serviceId: string) {
        delete this.uploadLimit$.value[serviceId];
        this.uploadLimit$.next(this.uploadLimit$.value);
    }

    objectSetup(objectList, obj, t = 0) {
        if (t === objectList.length - 1) {
            obj[objectList[t]] = !obj[objectList[t]] ? {} : obj[objectList[t]];
            return;
        } else {
            if (!obj[objectList[t]]) {
                obj[objectList[t]] = {};
                this.objectSetup(objectList, obj[objectList[t]], ++t);
                return;
            }
            this.objectSetup(objectList, obj[objectList[t]], ++t);
            return;
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    terminateUploads() {
        this.destroyed$.next();
    }
}

