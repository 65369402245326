<div class="slide-container">
    <div class="swiper mySwiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let asset of assetsList" [ngClass]="asset?.contentType.split('/')[0]"
            >
                <img class="cover"
                     [src]="asset?.url"
                     *ngIf="asset?.contentType.startsWith('image/')"
                >
                <video
                    #videoPlayer
                    *ngIf="asset?.contentType.startsWith('video/')"
                    [src]="asset?.url"
                    controls
                    controlsList="nodownload"
                ></video>
            </div>
        </div>
    </div>
    <div [ngClass]="'assets-counter'">
        <span>{{
                assetsList[swiperIndex].originalFileName.substring(assetsList[swiperIndex].originalFileName.indexOf('_') + 1,
                    assetsList[swiperIndex].originalFileName.lastIndexOf('.'))
            }}</span>
        <span>{{ (swiperIndex) + 1 }} / {{ assetsList.length }}</span>
    </div>
</div>