import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DialogService, InvoicesService, SnackbarService, XeroConnectionService} from '../../../services';
import fileSaver from 'file-saver';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'prism-invoices-table',
    templateUrl: './invoices-table.component.html',
    styleUrls: ['./invoices-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicesTableComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
    }

    @Input() sessionExpired;
    @Output() pageUpdated: EventEmitter<any> = new EventEmitter<any>();

    private subDestroyer$: Subject<void>;
    paginator: MatPaginator;
    loadingInvoices: boolean;
    showLoader: boolean;
    initialTableLoad: boolean;
    paginatorControl: {
        length: number;
        pageSize: number;
        pageSizeOptions: number[]
    };
    columnHeaders: string[];
    invoiceDataSource: MatTableDataSource<any>;

    @Input()
    set invoicesLength(count) {
        this.paginatorControl = {
            length: count,
            pageSize: this.invoicesService.invoiceTableSize,
            pageSizeOptions: [10, 20, 40, 50, 100]
        };
    }

    @Input()
    set setShowLoader(showLoader: boolean) {
        this.showLoader = showLoader;
    }

    @Input()
    set invoices(invoices) {
        if (invoices) {
            this.initialTableLoad = false;
            this.invoiceDataSource.data = invoices.map((invoice: any) => {
                return {
                    ...invoice,
                    DueDateString: invoice.DueDate,
                    DateString: invoice.Date,
                };
            });
        }
    }

    constructor(private invoicesService: InvoicesService,
                private dialogService: DialogService,
                private xeroConnectionService: XeroConnectionService,
                private snackbarService: SnackbarService) {
        this.subDestroyer$ = new Subject<void>();
        this.loadingInvoices = true;
        this.invoiceDataSource = new MatTableDataSource<any>([]);
        this.initialTableLoad = true;
        this.showLoader = null;
        this.columnHeaders = ['invoiceNum', 'address', 'payee', 'dateCreated', 'dueDate', 'amount', 'status', 'download'];
    }

    ngOnInit() {
        this.xeroConnectionService.sessionExpired$
            .pipe(takeUntil(this.subDestroyer$))
            .subscribe(expired => {
                if (expired === true) {
                    this.sessionExpired = true;
                }
                if (expired === false) {
                    this.sessionExpired = false;
                }
            });
    }

    getPdf(invoice) {
        this.snackbarService.showSnackbar('Fetching pdf');
        this.invoicesService.getInvoicePdf(invoice.InvoiceID)
            .then(res => {
                const objectUrl = window.URL.createObjectURL(new Blob([res]));
                fileSaver.saveAs(objectUrl, `${invoice.InvoiceNumber}.pdf`);
            })
            .catch(err => console.error('Error getting pdf:', err));
    }

    paginate(pageEvent: PageEvent) {
        const {pageSize, pageIndex} = pageEvent;
        this.invoicesService.updateSyncedInvoiceListPagination({pageSize, pageIndex});
    }

    ngOnDestroy() {
        let {pageSize} = this.invoicesService.syncedInvoicesPaginationValue;
        this.invoicesService.updateSyncedInvoiceListPagination({pageSize, pageIndex: 0});
        this.subDestroyer$.next();
    }
}
