import {AfterViewInit, Component, ElementRef, Inject, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject, Subscription} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Response} from '../../models';
import {AssetsService, DialogService} from '../../services';
import {AgentDownloadSelectionModalComponent} from '../agent-download-selection-modal/agent-download-selection-modal.component';

@Component({
    selector: 'prism-service-bucket-dialog',
    templateUrl: './service-bucket-dialog.component.html',
    styleUrls: ['./service-bucket-dialog.component.scss']
})
export class ServiceBucketDialogComponent implements AfterViewInit {
    assets: any = [];
    currentIndex = null;
    isMobile: boolean = null;
    address: string = null;
    memberProfileUid: string = null;
    jobId: number = null;
    selectedAsset: any;
    selectedAsset$: Subject<any> = new Subject<any>();
    keyDownEvent$: Subscription;
    finalAsset: {
        finalLoaded: boolean;
        element: HTMLImageElement;
        type: 'image' | 'video' | 'video-preview-image';
        videoUrl: string;
    } = {
        finalLoaded: false,
        element: null,
        type: null,
        videoUrl: null
    };
    finalAssets: FinalAssets = {};
    @ViewChildren('scrollAssets') scrollAssets: QueryList<ElementRef<HTMLDivElement>>;
    @ViewChild('assetList') assetListElement: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<ServiceBucketDialogComponent>,
        private dialogService: DialogService,
        public assetsService: AssetsService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        const {jobData: {address, jobId}, product: {memberProfileUid}, isMobile} = this.data.data;
        this.isMobile = isMobile;
        this.address = address;
        this.memberProfileUid = memberProfileUid;
        this.jobId = jobId;
        this.assets = this.data.data.array;
        this.currentIndex = this.data.data.index;
        this.selectedAsset = this.assets[this.currentIndex];

        this.assets.forEach((asset, index) => {
            let image = new Image();
            image.src = asset.url;

            this.finalAssets[index] = {
                element: image,
                type: (asset.contentType.startsWith('video/')) ? 'video-preview-image' : 'image',
                finalLoaded: false,
                videoUrl: null
            };
        });

        this.selectedAsset$.pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            })
        ).subscribe((index: number) => {
            this.selectedAsset = this.assets[index];
            this.finalAsset = this.finalAssets[index];
        });

        this.dialogRef.disableClose = true;
        this.keyDownEvent$ = this.dialogRef.keydownEvents()
            .subscribe((event) => {
                const keyCode = event.keyCode;
                if (keyCode === 37 || keyCode === 38) {
                    this.previousToggle();
                }
                if (keyCode === 39 || keyCode === 40) {
                    this.nextToggle();
                }
            });

        let pathsToFinal = this.assets.map((asset: any) => {
            return asset['originalFilePath'];
        });

        this.assetsService.getProcessedAssetFinalSignedUrls(pathsToFinal)
            .subscribe((res: Response) => {
                let {pathsToFinal} = res.data;
                pathsToFinal.forEach((data, index) => {
                    const {url, contentType, width, height} = data;

                    if (contentType.startsWith('video')) {
                        this.finalAssets[index] = {
                            element: null,
                            type: 'video',
                            finalLoaded: true,
                            videoUrl: url
                        };
                        if (this.currentIndex === index) {
                            this.finalAsset = this.finalAssets[index];
                        }

                    } else {
                        this.assets[index].finalUrl = url;
                        this.assets[index].contentType = contentType;
                        this.assets[index].dimensions = {width, height};
                        let image = new Image();
                        image.onload = () => {
                            this.finalAssets[index] = {
                                element: image,
                                type: 'image',
                                finalLoaded: true,
                                videoUrl: null
                            };

                            if (this.currentIndex === index) {
                                this.finalAsset = this.finalAssets[index];
                            }
                        };
                        image.src = url;
                    }
                });
            });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.displayAsset(this.currentIndex);
        }, 10);
    }

    closeDialog() {
        this.keyDownEvent$.unsubscribe();
        this.selectedAsset$.unsubscribe();
        this.dialogRef.close();
    }

    displayAsset(index, arrowKeyUsed = false) {
        this.currentIndex = index;
        this.selectedAsset$.next(index);
    }

    nextToggle() {
        this.currentIndex === this.assets.length - 1 ? this.currentIndex = 0 : this.currentIndex++;
        this.selectedAsset$.next(this.currentIndex);
        const element: HTMLDivElement = this.scrollAssets.toArray()[this.currentIndex].nativeElement;
        element.scrollIntoView({
            behavior: 'auto',
            block: 'center'
        });
    }

    previousToggle() {
        this.currentIndex === 0 ? this.currentIndex = this.assets.length - 1 : this.currentIndex--;
        this.selectedAsset$.next(this.currentIndex);
        const element: HTMLDivElement = this.scrollAssets.toArray()[this.currentIndex].nativeElement;
        element.scrollIntoView({
            behavior: 'auto',
            block: 'center'
        });
    }

    downloadAsset() {
        this.dialogService.openDialog(AgentDownloadSelectionModalComponent, {
            assetData: this.selectedAsset,
            jobAddress: this.address,
            jobId: this.jobId,
            memberProfileUid: this.memberProfileUid
        }, {id: 'agent-download-dialog'});
    }
}

interface FinalAsset {
    finalLoaded: boolean;
    element: HTMLImageElement;
    type: 'image' | 'video' | 'video-preview-image';
    videoUrl: string;
}

interface FinalAssets {
    [index: number]: FinalAsset;
}