import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {AuthenticationService, SnackbarService} from '../../services';

@Component({
    selector: 'prism-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [UntypedFormBuilder],
})
export class LoginComponent {
    showLoaderSub: Subscription;
    showLoader$: Observable<boolean> = this.authService.showLoginLoader.asObservable();
    minPassChars = 6;
    loginForm: UntypedFormGroup = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: [
            '',
            [Validators.required, Validators.minLength(this.minPassChars)],
        ],
    });

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private authService: AuthenticationService,
        private snackbarService: SnackbarService
    ) {
        this.showLoaderSub = this.showLoader$.subscribe(
            () => null,
            () => console.warn(`Error occurred during login loader subscription`),
            () => this.showLoaderSub.unsubscribe()
        );
    }

    public onLogin(validState) {
        if (validState) {
            const email = this.loginForm.controls['email'].value;
            const password = this.loginForm.controls['password'].value;

            if (this.loginForm.valid) {
                this.loginForm.disable();
                this.authService.login(email, password).catch((error) => {
                    this.loginForm.enable();
                    this.snackbarService.handleError(error);
                });
            }
        }
    }
}
