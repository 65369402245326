import {Component, OnInit} from '@angular/core';
import {Brief} from '../../../models';
import {DialogService} from '../../../services';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'prism-brief-modal',
    templateUrl: './brief-modal.component.html',
    styleUrls: ['./brief-modal.component.scss']
})
export class BriefModalComponent implements OnInit {

    briefs: Brief[];

    constructor(private dialogService: DialogService, public dialogRef: MatDialogRef<BriefModalComponent>) {
    }

    ngOnInit() {
        const briefs = this.dialogService.inputData.briefs;
        if (!!briefs) {
            this.briefs = briefs;
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
