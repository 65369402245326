import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments';
import {PrismHeaders} from '../enums';
import {map} from 'rxjs/operators';
import {Response} from '../models';

@Injectable({
    providedIn: 'root'
})
export class ShareAlbumService {

    constructor(private http: HttpClient) {
    }

    shareJobAsAgency({shareLink, notificationEmails, comments, address}) {
        return this.http.post(`${environment.apiBaseUrl}/ShareAlbumAsAgency`, {shareLink, notificationEmails, comments, address});
    }

    shareAlbum(notificationData, {street}, {jobId, agencyId, agentId, mainMemberUid}) {
        return this.http.post(`${environment.apiBaseUrl}/ShareAlbum`, {notificationData, street, jobId, agencyId, agentId, mainMemberUid});
    }

    async getNotificationEmails({agency: agencyId, agent: agentId}) {
        if (!!agentId) {
            const headers = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify({agentId})).append(PrismHeaders.SkipHeaders, '');
            return this.http.get(`${environment.apiBaseUrl}/GetAgent`, {headers})
                .pipe(
                    map((res: Response) => res.data.notificationEmails)
                ).toPromise();
        } else if (!!agencyId) {
            const headers = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify({agencyId})).append(PrismHeaders.SkipHeaders, '');
            return this.http.get(`${environment.apiBaseUrl}/GetAgency`, {headers})
                .pipe(
                    map((res: Response) => res.data.notificationEmails)
                ).toPromise();
        } else {
            return Promise.resolve([]);
        }
    }
}
