import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ServiceEnum} from '../../../enums';
import {Agent, Job, Service} from '../../../models';
import {AgentService, JobsService} from '../../../services';
import {ServicesService} from '../../../services/services.service';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {FloorplanTicket, VideoTicket} from '../../../models/ticket';

@Component({
    selector: 'prism-service-brief-form',
    templateUrl: './service-brief-form.component.html',
    styleUrls: ['./service-brief-form.component.scss']
})
export class ServiceBriefFormComponent implements OnInit {
    @Input() service: Service;
    @Input() fileCount: number;
    @Output() closeFormHandler: EventEmitter<{ cancelled: boolean }> = new EventEmitter<{
        cancelled: boolean
    }>();
    protected readonly ServiceEnum = ServiceEnum;
    loadingInProgress: boolean;
    job: Job;
    parentJobAgent: Agent;
    parentJobAgencyName: string;
    showSpinner: boolean;
    videoLengthAccuracyPreferences: string[];
    videoLengthPreferences: string[];
    videoPacePreferences: string[];
    videoServicePremiumOptions: string[];
    videoServiceStandardOptions: string[];
    videoFeaturedPeopleOptions: string[];
    videoIncludedVideoAssetsOptions: string[];
    briefEditorConfig: AngularEditorConfig = {
        enableToolbar: false,
        placeholder: 'Enter Notes',
        spellcheck: true,
        maxHeight: 'auto',
        editable: true,
        showToolbar: false,
        customClasses: [
            {
                name: 'brief',
                class: 'brief-editor'
            }
        ]
    };
    standardRequiredResponseOptions: string[];
    orderPriorityOptions: string[];
    videoMusicOptions: string[];
    videoMusicGenreOptions: string[];
    agentsCounterOptions: string[];
    videoServiceFormExpanded: boolean;
    productStyleOptions: string[];
    outputFormatOption: string[];
    propertySizeOutputFormatOption: string[];
    videoFormGroup: FormGroup<VideoUploadForm>;
    floorplanFormGroup: FormGroup<FloorplanUploadForm>;
    hideService: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private jobsService: JobsService,
        private agentService: AgentService,
        private servicesService: ServicesService,
    ) {
        this.job = this.jobsService.selectedJob;
        this.loadingInProgress = false;
        this.videoServiceFormExpanded = false;
        this.hideService = false;
        this.showSpinner = false;
    }

    ngOnInit() {
        if (this.service.SNAKE_CASE === ServiceEnum.VIDEO_SERVICES) {
            const videoTicket = this.jobsService.getServiceTicket(this.service.id) as VideoTicket;
            if (videoTicket) {
                this.videoFormGroup = this.createJiraVideoForm(videoTicket) as FormGroup;
                if (this.videoServiceStandardOptions.includes(videoTicket.productType)) {
                    this.addStandardServiceFormOptions(videoTicket);
                }
            } else {
                this.videoFormGroup = this.createJiraVideoForm() as FormGroup;
            }
        } else if (this.service.SNAKE_CASE === ServiceEnum.FLOORPLAN_SERVICES) {
            const floorplanTicket = this.jobsService.getServiceTicket(this.service.id) as FloorplanTicket;
            if (floorplanTicket) {
                this.floorplanFormGroup = this.createJiraFloorplanForm(floorplanTicket);
            } else {
                this.floorplanFormGroup = this.createJiraFloorplanForm();
            }
        }
    }

    createTicket() {
        this.hideService = true;
        const formData = {};
        if (this.videoFormGroup?.controls) {
            Object.entries(this.videoFormGroup.controls).forEach(([key, val]) => {
                formData[key] = val?.getRawValue() || null;
            });
        } else {
            Object.entries(this.floorplanFormGroup.controls).forEach(([key, val]) => {
                formData[key] = val?.getRawValue() || null;
            });
        }
        this.servicesService.createTicket(this.service, formData);
        setTimeout(() => {
            this.closeFormHandler.emit({cancelled: false});
        }, 0);
    }

    onCancelClicked() {
        this.loadingInProgress = true;
        this.hideService = true;
        if (this.service.SNAKE_CASE === ServiceEnum.VIDEO_SERVICES) {
            this.jobsService.saveServiceTicket(this.service.id, this.videoFormGroup.getRawValue());
        } else if (this.service.SNAKE_CASE === ServiceEnum.FLOORPLAN_SERVICES) {
            this.jobsService.saveServiceTicket(this.service.id, this.floorplanFormGroup.getRawValue());
        }
        this.closeFormHandler.emit();
    }

    createJiraFloorplanForm(ticket?: FloorplanTicket) {
        this.productStyleOptions = this.servicesService.productStyleOptions;
        this.outputFormatOption = this.servicesService.outputFormatOption;
        this.propertySizeOutputFormatOption = this.servicesService.propertySizeOutputFormatOption;
        this.orderPriorityOptions = this.servicesService.orderPriorityOptions;
        if (this.job.agency) {
            this.parentJobAgencyName = this.job.agencyName;
        }
        return this.formBuilder.group({
            address: new FormControl<string>(this.job.address || null, [Validators.required]),
            orderPriority: new FormControl<string>(ticket?.orderPriority || this.orderPriorityOptions[0]),
            clientName: new FormControl<string>(ticket?.clientName || this.job.agencyName || null, [Validators.required]),
            productStyle: new FormControl<string>(ticket?.productStyle || null, [Validators.required]),
            outputFormat: new FormControl<string>(ticket?.outputFormat || null, [Validators.required]),
            propertySizeOutputFormat: new FormControl<string>(ticket?.propertySizeOutputFormat || null, [Validators.required]),
            otherInstructions: new FormControl<string>(ticket?.otherInstructions || null)
        });
    }

    createJiraVideoForm(ticket?: VideoTicket) {
        this.videoLengthAccuracyPreferences = this.servicesService.videoLengthAccuracyPreferences;
        this.videoLengthPreferences = this.servicesService.videoLengthPreferences;
        this.videoPacePreferences = this.servicesService.videoPacePreferences;
        this.videoServiceStandardOptions = this.servicesService.videoServiceStandardOptions;
        this.videoServicePremiumOptions = this.servicesService.videoServicePremiumOptions;
        this.videoFeaturedPeopleOptions = this.servicesService.featuredPeopleOptions;
        this.videoIncludedVideoAssetsOptions = this.servicesService.includedVideoAssetsOptions;
        this.standardRequiredResponseOptions = this.servicesService.standardRequiredResponseOptions;
        this.orderPriorityOptions = this.servicesService.orderPriorityOptions;
        this.agentsCounterOptions = this.servicesService.agentsCounterOptions;
        this.videoMusicGenreOptions = this.servicesService.musicGenreOptions;
        this.videoMusicOptions = this.servicesService.musicOptions;

        if (this.job.agency) {
            this.parentJobAgencyName = this.job.agencyName;
            if (this.job.agent) {
                this.agentService.getAgent(this.job.agent)
                    .subscribe(agent => {
                        this.parentJobAgent = agent;
                    });
            }
        }

        return this.formBuilder.group({
            address: new FormControl(this.job.address || null, [Validators.required]),
            suburb: new FormControl(ticket?.suburb || null, [Validators.required]),
            videoLengthAccuracy: new FormControl(ticket?.videoLengthAccuracy || null, [Validators.required]),
            videoLengthPreference: new FormControl(ticket?.videoLengthPreference || null, [Validators.required]),
            videoPace: new FormControl(ticket?.videoPace || null, [Validators.required]), //Default Don't Care
            totalClips: new FormControl(ticket?.totalClips || this.fileCount || null, [Validators.required]),
            productType: new FormControl(ticket?.productType || null, [Validators.required]),
            videoLinkReference: new FormControl(ticket?.videoLinkReference || null),
            keyFeatures: new FormControl(ticket?.keyFeatures || null),
            notes: new FormControl(ticket?.notes || null),
            allowBWRMSocialMedia: new FormControl<boolean>(ticket?.allowBWRMSocialMedia || true, [Validators.required])
        });
    }

    onServiceSelectionChange(serviceOption: string) {
        if (this.videoServiceStandardOptions.includes(serviceOption) && !this.videoServiceFormExpanded) {
            this.addStandardServiceFormOptions();
        } else if (!this.videoServiceStandardOptions.includes(serviceOption) && this.videoServiceFormExpanded) {
            this.removeStandardServiceFormOptions();
        }
    }

    addStandardServiceFormOptions(ticket?: Partial<VideoTicket>) {
        this.videoFormGroup.addControl('orderPriority', new FormControl(ticket?.orderPriority || this.orderPriorityOptions[1], [Validators.required]));
        this.videoFormGroup.addControl('agencyName', new FormControl(ticket?.agencyName || this.parentJobAgencyName || null));
        this.videoFormGroup.addControl('agentsCounter', new FormControl(ticket?.agentsCounter || null));
        this.videoFormGroup.addControl('requiresPropertyFeatureIcons', new FormControl(ticket?.requiresPropertyFeatureIcons || true));
        this.videoFormGroup.addControl('featuredPeople', new FormControl(ticket?.featuredPeople || null));
        this.videoFormGroup.addControl('includeVideoAssets', new FormControl(ticket?.includeVideoAssets || null));
        this.videoFormGroup.addControl('requiresLightLeaks', new FormControl(ticket?.requiresLightLeaks || this.standardRequiredResponseOptions[2], [Validators.required]));
        this.videoFormGroup.addControl('requiresAfterEffects', new FormControl<boolean>(ticket?.requiresAfterEffects || false));
        this.videoFormGroup.addControl('requiresMusic', new FormControl(ticket?.requiresMusic || this.videoMusicOptions[0], [Validators.required]));
        const propertyFeatureIcons = new FormGroup({
            beds: new FormControl(ticket?.propertyFeatureIcons?.beds || 0, [Validators.required]),
            baths: new FormControl(ticket?.propertyFeatureIcons?.baths || 0, [Validators.required]),
            cars: new FormControl(ticket?.propertyFeatureIcons?.cars || 0, [Validators.required]),
            area: new FormControl(ticket?.propertyFeatureIcons?.area || null, [Validators.required])
        });
        this.videoFormGroup.addControl('propertyFeatureIcons', propertyFeatureIcons);
        this.videoFormGroup.addControl('requiresGoogleEarthAnimation', new FormControl(ticket?.requiresGoogleEarthAnimation || false));

        if (ticket) {
            if (ticket.music) {
                const music = ticket.music;
                if (music.trackName && ticket.requiresMusic === this.videoMusicOptions[2]) {
                    const musicFormGroup = new FormGroup({
                        genre: new FormControl<string>(null),
                        trackName: new FormControl<string>(music.trackName || null, [Validators.required])
                    });
                    this.videoFormGroup.addControl('music', musicFormGroup);

                } else if (music.genre && ticket.requiresMusic === this.videoMusicOptions[1]) {
                    const musicFormGroup = new FormGroup({
                        genre: new FormControl<string>(music.genre || null, [Validators.required]),
                        trackName: new FormControl<string>(null)
                    });
                    this.videoFormGroup.addControl('music', musicFormGroup);
                }
            }

            if (ticket.afterEffects) {
                const afterEffects = ticket.afterEffects;
                this.requiresAfterEffectsFormControl.setValue(true);
                const afterEffectsFormGroup = new FormGroup({
                    afterEffectsElements: new FormControl(afterEffects.afterEffectsElements || 1, [Validators.required]),
                    requiresPropertyOutlines: new FormControl(!!afterEffects.requiresPropertyOutlines, [Validators.required]),
                    requiresLocationIcons: new FormControl(!!afterEffects.requiresLocationIcons, [Validators.required]),
                    requiresCalloutTitles: new FormControl(!!afterEffects.requiresCalloutTitles, [Validators.required]),
                    otherAfterEffectInformation: new FormControl(afterEffects.otherAfterEffectInformation || '')
                });
                this.videoFormGroup.addControl('afterEffects', afterEffectsFormGroup);

                if (ticket.afterEffects.calloutTitles && ticket.afterEffects.calloutTitles.length > 0) {
                    const afterEffectedElementFormControl = new FormControl(ticket.afterEffects.calloutTitles.length, [Validators.required]);
                    this.afterEffectsFormGroup.addControl('afterEffectsElements', afterEffectedElementFormControl);

                    const calloutTitlesFormArray = new FormArray<FormControl<string>>(ticket.afterEffects.calloutTitles.map((title) => {
                        return new FormControl(title, [Validators.required]);
                    }));
                    this.afterEffectsFormGroup.addControl('calloutTitles', calloutTitlesFormArray);
                }
            }

            if (ticket.requiresPropertyFeatureIcons) {
                this.requiresPropertyFeatureIconsFormControl.setValue(true);
                const propertyFeatureIcons = new FormGroup({
                    beds: new FormControl(ticket.propertyFeatureIcons.beds || 0, [Validators.required]),
                    baths: new FormControl(ticket.propertyFeatureIcons.baths || 0, [Validators.required]),
                    cars: new FormControl(ticket.propertyFeatureIcons.cars || 0, [Validators.required]),
                    area: new FormControl(ticket.propertyFeatureIcons.area || null, [Validators.required])
                });
                this.videoFormGroup.addControl('propertyFeatureIcons', propertyFeatureIcons);
            } else {
                this.requiresPropertyFeatureIconsFormControl.setValue(false);
            }

            if (ticket.agentsArray && ticket.agentsArray.length > 0) {
                const agentsArray = new FormArray<FormGroup<AgentFormGroup>>(ticket.agentsArray.map((agent, index) => {
                    return new FormGroup({
                            name: new FormControl(agent.name || null, [Validators.required]),
                            contactNumber: new FormControl(agent.contactNumber || null, [Validators.required]),
                            email: new FormControl(agent.email || null, [Validators.required]),
                        }
                    );
                }));
                this.videoFormGroup.addControl('agentsArray', agentsArray);
            }
        }
        this.videoServiceFormExpanded = true;

        setTimeout(() => {
            this.videoFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
        }, 100);
    }

    removeStandardServiceFormOptions() {
        this.videoServiceFormExpanded = false;
        this.videoFormGroup.removeControl('orderPriority');
        this.videoFormGroup.removeControl('agencyName');
        this.videoFormGroup.removeControl('agentsCounter');
        this.videoFormGroup.removeControl('requiresPropertyFeatureIcons');
        this.videoFormGroup.removeControl('featuredPeople');
        this.videoFormGroup.removeControl('includeVideoAssets');
        this.videoFormGroup.removeControl('requiresLightLeaks');
        this.videoFormGroup.removeControl('requiresAfterEffects');
        this.videoFormGroup.removeControl('requiresMusic');
        this.videoFormGroup.removeControl('propertyFeatureIcons');

        if (this.afterEffectsFormGroup) {
            this.afterEffectsFormGroup.removeControl('afterEffectsElements');
            this.afterEffectsFormGroup.removeControl('requiresPropertyOutlines');
            this.afterEffectsFormGroup.removeControl('requiresLocationIcons');
            this.afterEffectsFormGroup.removeControl('requiresCalloutTitles');
            this.afterEffectsFormGroup.removeControl('otherAfterEffectInformation');
        }

        if (this.propertyFeatureIconsFormGroup) {
            this.propertyFeatureIconsFormGroup.removeControl('beds');
            this.propertyFeatureIconsFormGroup.removeControl('baths');
            this.propertyFeatureIconsFormGroup.removeControl('cars');
            this.propertyFeatureIconsFormGroup.removeControl('area');
        }

        if (this.musicFormGroup) {
            this.musicFormGroup.removeControl('trackName');
            this.musicFormGroup.removeControl('genre');
        }
    }

    onAgentsCounterChange(option: string) {
        let agentsArrayFormArray: FormArray<FormGroup<AgentFormGroup>>;
        let currentAgentCounter: number;
        let agentFormGroup: FormGroup<AgentFormGroup>;

        const index = this.agentsCounterOptions.findIndex((counter) => counter === option);
        const newAgentsCounter = index + 1;

        if (!this.agentsArrayFormControl) {
            agentsArrayFormArray = new FormArray<FormGroup<AgentFormGroup>>([]);
            currentAgentCounter = 0;
        } else {
            agentsArrayFormArray = this.agentsArrayFormControl;
            currentAgentCounter = agentsArrayFormArray?.controls.length || 0;
        }

        if (index === 4) {
            for (let i = 0; i <= currentAgentCounter; i++) {
                agentsArrayFormArray.removeAt(currentAgentCounter - i);
            }
        } else if (currentAgentCounter > newAgentsCounter) {
            for (let i = currentAgentCounter; i > (currentAgentCounter - newAgentsCounter); i--) {
                agentsArrayFormArray.removeAt(i - 1);
            }
        } else if (currentAgentCounter < newAgentsCounter) {
            for (let i = 0; i < newAgentsCounter - currentAgentCounter; i++) {
                const firstAgent = (i === 0 && currentAgentCounter === 0);

                if (firstAgent && this.parentJobAgent) {
                    agentFormGroup = new FormGroup<AgentFormGroup>({
                        name: new FormControl<string>(`${this.parentJobAgent.firstName} ${this.parentJobAgent.lastName}`, [Validators.required]),
                        contactNumber: new FormControl<string>(this.parentJobAgent.contactNumber, [Validators.required]),
                        email: new FormControl<string>(this.parentJobAgent.email, [Validators.required])
                    });
                } else {
                    agentFormGroup = new FormGroup<AgentFormGroup>({
                        name: new FormControl<string>(null, [Validators.required]),
                        contactNumber: new FormControl<string>(null, [Validators.required]),
                        email: new FormControl<string>(null, [Validators.required])
                    });
                }
                agentsArrayFormArray.push(agentFormGroup);
            }
        }

        if (!this.agentsArrayFormControl && agentsArrayFormArray.controls) {
            this.videoFormGroup.addControl('agentsArray', agentsArrayFormArray);
        }
    }

    onRequirePropertyFeatureIconsChange(required: boolean) {
        if (required && !this.propertyFeatureIconsFormGroup) {
            const propertyFeatureIcons = new FormGroup({
                beds: new FormControl(0, [Validators.required]),
                baths: new FormControl(0, [Validators.required]),
                cars: new FormControl(0, [Validators.required]),
                area: new FormControl(null, [Validators.required])
            });
            this.videoFormGroup.addControl('propertyFeatureIcons', propertyFeatureIcons);
        } else if (!required) {
            if (this.propertyFeatureIconsFormGroup) {
                this.videoFormGroup.removeControl('propertyFeatureIcons');
                this.requiresGoogleEarthAnimationFormControl.patchValue(false);
            }
        }
    }

    onRequireAfterEffectsChange(required: boolean) {
        if (required && !this.afterEffectsFormGroup) {
            const afterEffects = new FormGroup({
                afterEffectsElements: new FormControl(1, [Validators.required]),
                requiresPropertyOutlines: new FormControl(false, [Validators.required]),
                requiresLocationIcons: new FormControl(false, [Validators.required]),
                requiresCalloutTitles: new FormControl(false, [Validators.required]),
                otherAfterEffectInformation: new FormControl(null)
            });
            this.videoFormGroup.addControl('afterEffects', afterEffects);

        } else if (!required && this.afterEffectsFormGroup) {
            this.videoFormGroup.removeControl('afterEffects');
        }
    }

    onRequireMusicChange(option: string) {
        if (option === this.videoMusicOptions[1]) {
            if (!this.musicFormGroup) {
                this.videoFormGroup.removeControl('music');
            }
            const musicFormGroup = new FormGroup({
                genre: new FormControl<string>(null, [Validators.required]),
                trackName: new FormControl<string>(null)
            });
            this.videoFormGroup.addControl('music', musicFormGroup);
        } else if (option === this.videoMusicOptions[2]) {
            if (!this.musicFormGroup) {
                this.videoFormGroup.removeControl('music');
            }
            const musicFormGroup = new FormGroup({
                genre: new FormControl<string>(null),
                trackName: new FormControl<string>(null, [Validators.required])
            });
            this.videoFormGroup.addControl('music', musicFormGroup);
        } else {
            if (this.musicFormGroup) {
                this.videoFormGroup.removeControl('music');
            }
        }
    }

    onRequireCalloutTitlesChange(required: boolean) {
        if (required) {
            const calloutTitlesFormArray = new FormArray<FormControl<string>>([]);
            this.afterEffectsFormGroup.addControl('calloutTitles', calloutTitlesFormArray);
            const calloutTitle = new FormControl<string>(null, [Validators.required]);
            calloutTitlesFormArray.push(calloutTitle);

        } else if (!required && this.afterEffectsFormGroup) {
            this.afterEffectsFormGroup.removeControl('calloutTitles');
        }
    }

    addCalloutTitle() {
        const calloutTitle = new FormControl<string>(null, [Validators.required]);
        this.calloutTitlesFormArray.push(calloutTitle);
    }

    removeCalloutTitle(index: number) {
        this.calloutTitlesFormArray.removeAt(index);
    }

    updateCount(formControl: FormControl<number>, count: -1 | 1) {
        const newCount = formControl.getRawValue() + count;

        if (newCount >= 0) {
            formControl.setValue(formControl.getRawValue() + count);
        }
    }

    onToggleChange(checked: boolean, formControl: FormControl<boolean>) {
        formControl.patchValue(checked);
    }

    //----------------- Address-----------------

    get addressFormControl() {
        return this.videoFormGroup.get('address') as FormControl<string>;
    }

    get suburbFormControl() {
        return this.videoFormGroup.get('suburb') as FormControl<string>;
    }

    //----------------- Video Duration -----------------

    get videoLengthAccuracyFormControl() {
        return this.videoFormGroup.get('videoLengthAccuracy') as FormControl<string>;
    }

    get videoLengthPreferenceFormControl() {
        return this.videoFormGroup.get('videoLengthPreference') as FormControl<string>;
    }

    get videoPaceFormControl() {
        return this.videoFormGroup.get('videoPace') as FormControl<string>;
    }

    //----------------- Total Clips -----------------

    get totalClipsFormControl() {
        return this.videoFormGroup.get('totalClips') as FormControl<string>;
    }

    //----------------- Product Type -----------------

    get productTypeFormControl() {
        return this.videoFormGroup.get('productType') as FormControl<string>;
    }

    //----------------- Priority -----------------

    get videoOrderPriorityFormControl() {
        return this.videoFormGroup.get('orderPriority') as FormControl<string>;
    }

    //----------------- Agency-----------------

    get agencyNameFormControl() {
        return this.videoFormGroup.get('agencyName') as FormControl<string>;
    }

    //----------------- Agents -----------------

    get agentsCounterFormControl() {
        return this.videoFormGroup.get('agentsCounter') as FormControl<string>;
    }


    get agentsArrayFormControl() {
        return this.videoFormGroup.get('agentsArray') as FormArray<FormGroup<AgentFormGroup>>;
    }


    //----------------- Property Feature Icons -----------------

    get requiresPropertyFeatureIconsFormControl() {
        return this.videoFormGroup.get('requiresPropertyFeatureIcons') as FormControl<boolean>;
    }

    get propertyFeatureIconsFormGroup() {
        return this.videoFormGroup.get('propertyFeatureIcons') as FormGroup<PropertyFeatureIconsFormGroup>;
    }

    get bedsFormControl() {
        return this.propertyFeatureIconsFormGroup.get('beds') as FormControl<number>;
    }

    get bathsFormControl() {
        return this.propertyFeatureIconsFormGroup.get('baths') as FormControl<number>;
    }

    get carsFormControl() {
        return this.propertyFeatureIconsFormGroup.get('cars') as FormControl<number>;
    }

    get areaFormControl() {
        return this.propertyFeatureIconsFormGroup.get('area') as FormControl<string>;
    }

    get requiresGoogleEarthAnimationFormControl() {
        return this.videoFormGroup.get('requiresGoogleEarthAnimation') as FormControl<boolean>;
    }

    //----------------- Video Properties -----------------

    get featuredPeopleFormControl() {
        return this.videoFormGroup.get('featuredPeople') as FormControl<string>;
    }

    get includeVideoAssetsFormControl() {
        return this.videoFormGroup.get('includeVideoAssets') as FormControl<string>;
    }

    get requiresLightLeaksFormControl() {
        return this.videoFormGroup.get('requiresLightLeaks') as FormControl<string>;
    }

    //----------------- After Effects -----------------

    get requiresAfterEffectsFormControl() {
        return this.videoFormGroup.get('requiresAfterEffects') as FormControl<boolean>;
    }

    get afterEffectsFormGroup() {
        return this.videoFormGroup.get('afterEffects') as FormGroup<AfterEffectFormGroup>;
    }

    get afterEffectsElementsFormControl() {
        return this.afterEffectsFormGroup.get('afterEffectsElements') as FormControl<number>;
    }

    get requiresPropertyOutlinesFormControl() {
        return this.afterEffectsFormGroup.get('requiresPropertyOutlines') as FormControl<boolean>;
    }

    get requiresLocationIconsFormControl() {
        return this.afterEffectsFormGroup.get('requiresLocationIcons') as FormControl<boolean>;
    }

    get requiresCalloutTitlesFormControl() {
        return this.afterEffectsFormGroup.get('requiresCalloutTitles') as FormControl<boolean>;
    }

    get calloutTitlesFormArray() {
        return this.afterEffectsFormGroup.get('calloutTitles') as FormArray<FormControl<string>>;
    }

    get otherAfterEffectInformationFormControl() {
        return this.afterEffectsFormGroup.get('otherAfterEffectInformation') as FormControl<string>;
    }

    //----------------- Music -------------------

    get requiresMusicFormControl() {
        return this.videoFormGroup.get('requiresMusic') as FormControl<string>;
    }

    get musicFormGroup() {
        return this.videoFormGroup.get('music') as FormGroup<MusicFormGroup>;
    }

    get musicGenreFormControl() {
        return this.musicFormGroup.get('genre') as FormControl<string>;
    }

    get trackNameFormControl() {
        return this.musicFormGroup.get('trackName') as FormControl<string>;
    }

    //----------------- Extra -------------------

    get videoLinkReferencesFormControl() {
        return this.videoFormGroup.get('videoLinkReference') as FormControl<string>;
    }

    get keyFeaturesFormControl() {
        return this.videoFormGroup.get('keyFeatures') as FormControl<string>;
    }

    get notesFormControl() {
        return this.videoFormGroup.get('notes') as FormControl<string>;
    }

    get allowBWRMSocialMediaFormControl() {
        return this.videoFormGroup.get('allowBWRMSocialMedia') as FormControl<boolean>;
    }

    //---------------- Floorplan -----------------//

    get propertyAddressFormControl() {
        return this.floorplanFormGroup.get('address') as FormControl<string>;
    }

    get floorplanOrderPriorityFormControl() {
        return this.floorplanFormGroup.get('orderPriority') as FormControl<string>;
    }

    get clientNameFormControl() {
        return this.floorplanFormGroup.get('clientName') as FormControl<string>;
    }

    get productStyleFormControl() {
        return this.floorplanFormGroup.get('productStyle') as FormControl<string>;
    }

    get outputFormatFormControl() {
        return this.floorplanFormGroup.get('outputFormat') as FormControl<string>;
    }

    get propertySizeOutputFormatFormControl() {
        return this.floorplanFormGroup.get('propertySizeOutputFormat') as FormControl<string>;
    }

    get otherInstructionsFormControl() {
        return this.floorplanFormGroup.get('otherInstructions') as FormControl<string>;
    }
}

interface AgentFormGroup {
    name: FormControl<string>;
    contactNumber: FormControl<string>;
    email: FormControl<string>;
}

interface FloorplanUploadForm {
    address: FormControl<string>;
    orderPriority: FormControl<string>;
    clientName: FormControl<string>;
    productStyle: FormControl<string>;
    outputFormat: FormControl<string>;
    propertySizeOutputFormat: FormControl<string>;
    otherInstructions: FormControl<string>;
}

interface VideoUploadForm {
    address: FormControl<string>;
    suburb: FormControl<string>;
    videoLengthAccuracy: FormControl<string>;
    videoLengthPreference: FormControl<string>;
    videoPace: FormControl<string>;
    totalClips: FormControl<string>;
    productType: FormControl<string>;
    orderPriority?: FormControl<string>;
    agencyName?: FormControl<string>;
    agentsCounter?: FormControl<string>;
    agentsArray?: FormArray<FormGroup<AgentFormGroup>>;
    requiresPropertyFeatureIcons?: FormControl<boolean>;
    propertyFeatureIcons?: FormGroup<PropertyFeatureIconsFormGroup>;
    requiresGoogleEarthAnimation?: FormControl<boolean>
    featuredPeople?: FormControl<string>;
    includeVideoAssets?: FormControl<string>;
    requiresLightLeaks?: FormControl<string>;
    requiresAfterEffects?: FormControl<boolean>;
    afterEffects?: FormGroup<AfterEffectFormGroup>;
    requiresMusic?: FormControl<string>;
    music?: FormGroup<MusicFormGroup>,
    videoLinkReference: FormControl<string>,
    keyFeatures: FormControl<string>,
    notes: FormControl<string>,
    allowBWRMSocialMedia: FormControl<boolean>;
}

interface AfterEffectFormGroup {
    afterEffectsElements: FormControl<number>,
    requiresPropertyOutlines: FormControl<boolean>,
    requiresLocationIcons: FormControl<boolean>,
    requiresCalloutTitles: FormControl<boolean>,
    calloutTitles?: FormArray<FormControl<string>>
    otherAfterEffectInformation: FormControl<string>
}

interface PropertyFeatureIconsFormGroup {
    beds: FormControl<number>,
    baths: FormControl<number>,
    cars: FormControl<number>,
    area: FormControl<string>
}

interface MusicFormGroup {
    genre: FormControl<string>,
    trackName: FormControl<string>
}