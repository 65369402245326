import {Pipe, PipeTransform} from '@angular/core';
import orderBy from 'lodash/orderBy';

@Pipe({
    name: 'orderBy'
})
export class OrderBy implements PipeTransform {

    transform(value: any, iteratees: string, order: 'asc' | 'desc'): any {
        return orderBy(value, [iteratees], order);
    }
}
