import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatRadioChange} from '@angular/material/radio';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/unionBy';
import moment from 'moment';
import {Subject} from 'rxjs';
import {Job} from '../../models';
import {DialogService, InvoicesService, JobsService, ProductsService, SnackbarService, XeroConnectionService} from '../../services';

@Component({
    selector: 'prism-fix-invoice',
    templateUrl: './fix-invoice.component.html',
    styleUrls: ['./fix-invoice.component.scss']
})
export class FixInvoiceComponent implements OnInit {
    validationErrors = [];
    updateInvoiceInProgress = false;
    templateSelection: 'A' | 'B' = 'A';
    selectedJob: Job = this.jobsService.selectedJob;
    dateCreated = moment().format('YYYY-MM-DD');
    dueDate = moment().add(30, 'd').format('YYYY-MM-DD');
    invoiceEmails: any;
    inputProducts = [];
    hasInvalidLineItem: boolean;
    updateInvoiceForm: UntypedFormGroup = new UntypedFormGroup({
        invoiceRecipient: new UntypedFormControl(
            '',
            [Validators.required]
        ),
        dateCreated: new UntypedFormControl(
            {value: '', disabled: true},
            [Validators.required]
        ),
        emails: new UntypedFormArray([], [Validators.required, Validators.minLength(1)]),
        dueDate: new UntypedFormControl(
            '',
            [Validators.required]
        ),
        invoiceNotes: new UntypedFormControl(
            '',
        ),
        lineItems: new UntypedFormArray(
            [],
            [Validators.required, Validators.minLength(1)]
        )
    });

    @ViewChild('chipListInvoice', {static: true}) chipListInvoice;
    @ViewChild('invoiceEmailInput', {static: true}) invoiceEmailInput: ElementRef<HTMLInputElement>;
    separatorKeysCodes = [ENTER, COMMA, SPACE];
    private subDestroyer$: Subject<void> = new Subject<void>();

    constructor(
        private invoicesService: InvoicesService,
        private xeroConnectionService: XeroConnectionService,
        private formBuilder: UntypedFormBuilder,
        private productsService: ProductsService,
        private dialogService: DialogService,
        private jobsService: JobsService,
        private snackbarService: SnackbarService) {
    }

    ngOnInit() {
        const {invoice: originalInvoice, templateSelection, invoiceEmails} = this.dialogService.inputData;
        this.templateSelection = templateSelection;
        this.validationErrors = uniqBy(originalInvoice.ValidationErrors, (e) => {
            return e.message;
        });
        const originalRecipient = originalInvoice.Contact?.Name;
        const originalDate = !!originalInvoice.DateString ? originalInvoice.DateString.split('T')[0] : originalInvoice.Date.split('T')[0];
        const originalDueDate = !!originalInvoice.DueDateString ? originalInvoice.DueDateString.split('T')[0] : originalInvoice.DueDate.split('T')[0];

        this.invoiceEmails = invoiceEmails;

        this.updateInvoiceForm.get('invoiceRecipient').setValue(!!originalRecipient ? originalRecipient : 'No agency');
        this.updateInvoiceForm.get('dateCreated').setValue(originalDate);
        this.updateInvoiceForm.get('dueDate').setValue(originalDueDate);
        this.inputProducts = originalInvoice.LineItems;

        this.updateInvoiceForm.updateValueAndValidity();
    }

    ngOnDestroy() {
        this.subDestroyer$.next();
    }

    addInvoiceEmail(event: MatChipInputEvent) {
        if ((event.value || '').trim()) {
            const emailString = event.value.trim();
            this.invoiceEmails.push(emailString);
            this.invoiceEmails = uniq(this.invoiceEmails);
            this.fillFormEmails();
            event.value = '';
        }

        this.invoiceEmailInput.nativeElement.value = '';
    }

    fillFormEmails() {
        const emailFromForm: any = this.updateInvoiceForm.controls.emails;
        emailFromForm.controls = [];
        emailFromForm.setValidators([Validators.required, Validators.minLength(1)]);
        uniq(this.invoiceEmails).forEach(mail => {
            const mailControl = new UntypedFormControl(mail);
            emailFromForm.controls.push(mailControl);
        });
        emailFromForm.updateValueAndValidity();
    }

    removeInvoiceEmail(index: number) {
        this.invoiceEmails.splice(index, 1);
        this.fillFormEmails();
    }

    updateProducts(lineItems) {
        this.hasInvalidLineItem = lineItems.some(lineItem => {
            return !lineItem.Description;
        });
        const lineItemsFromForm: any = this.updateInvoiceForm.controls.lineItems;
        lineItemsFromForm.controls = [];
        lineItemsFromForm.setValidators([Validators.required, Validators.minLength(1)]);

        lineItems.forEach(lineItem => {
            const lineItemControl = new UntypedFormControl(lineItem);
            lineItemsFromForm.controls.push(lineItemControl);
        });
        lineItemsFromForm.updateValueAndValidity();
    }

    closeModal() {
        this.dialogService.closeDialog();
    }

    setInvoiceTemplateSelection(event: MatRadioChange) {
        this.templateSelection = event.value;
    }

    updateInvoice() {
        this.updateInvoiceForm.disable();
        this.updateInvoiceInProgress = true;

        this.invoicesService.toggleLoadingQueue(true);
        this.invoicesService.updateInvoice({
            originalInvoiceID: this.dialogService.inputData.invoice.InvoiceID,
            invoiceEmails: this.invoiceEmails,
            updateInvoiceData: this.updateInvoiceForm.value,
            templateSelection: this.templateSelection
        })
            .toPromise()
            .then(() => {
                this.invoicesService.switchPutInvoicesLoaderOff();
                this.dialogService.closeAllDialogs();
                this.updateInvoiceInProgress = false;
                this.invoicesService.toggleLoadingQueue(false);
                this.snackbarService.showSnackbar('Invoice has been updated');
                this.updateInvoiceInProgress = false;
                this.invoicesService.refreshQueuedInvoicesTableIndex();
            })
            .catch(err => {
                console.error('Update invoice error:', err);
                this.updateInvoiceInProgress = false;
                this.invoicesService.toggleLoadingQueue(false);
                this.snackbarService.handleError('Something went wrong. Invoice could not be updated.');
                this.updateInvoiceForm.enable();
            });
    }
}
