<div class="synced-invoice-table">
    <table [dataSource]="invoiceDataSource"
           mat-table>
        <section>Invoice information</section>
        <ng-container matColumnDef="invoiceNum">
            <th mat-header-cell scope="col" *matHeaderCellDef> Invoice Number</th>
            <td mat-cell *matCellDef="let invoice"> {{ invoice.InvoiceNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th mat-header-cell scope="col" *matHeaderCellDef> Street</th>
            <td mat-cell *matCellDef="let invoice">
                <div class="invoice-name-details">
                    <span class="invoice-table-product-name">{{ invoice.Reference.substr(0, invoice.Reference.indexOf(',')) }}</span>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="payee">
            <th mat-header-cell scope="col" *matHeaderCellDef> Payee</th>
            <td mat-cell *matCellDef="let invoice">
                <span class="contact-name-cell">{{ invoice.Contact.Name }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="dateCreated">
            <th mat-header-cell scope="col" *matHeaderCellDef> Date Created</th>
            <td mat-cell *matCellDef="let invoice">
                {{ invoice.DateString.substr(0, invoice.DateString.indexOf('T')) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="dueDate">
            <th mat-header-cell scope="col" *matHeaderCellDef> Due Date</th>
            <td mat-cell *matCellDef="let invoice">
                {{ invoice.DueDateString.substr(0, invoice.DueDateString.indexOf('T')) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell scope="col" *matHeaderCellDef> Amount</th>
            <td mat-cell *matCellDef="let invoice">
                {{ invoice.Total | number:'.2' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell scope="col" *matHeaderCellDef> Status</th>
            <td mat-cell *matCellDef="let invoice">
                {{ invoice.Status === 'AUTHORISED' ? 'UNPAID' : invoice.Status }}
            </td>
        </ng-container>

        <ng-container matColumnDef="download">
            <th mat-header-cell scope="col" *matHeaderCellDef> PDF</th>
            <td mat-cell *matCellDef="let invoice">
                <button mat-icon-button [disabled]="sessionExpired" (click)="getPdf(invoice)">
                    <mat-icon>get_app</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnHeaders"></tr>
        <tr mat-row *matRowDef="let rowData; columns: columnHeaders;"> #</tr>
    </table>
    <mat-progress-bar *ngIf="showLoader === true"
                      mode="indeterminate">
    </mat-progress-bar>
    <mat-paginator
            [length]="paginatorControl.length"
            [pageSize]="paginatorControl.pageSize"
            [pageSizeOptions]="paginatorControl.pageSizeOptions"
            (page)="paginate($event)"
    >
    </mat-paginator>
</div>

