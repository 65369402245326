import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AuthenticationService} from '../../services';

@Component({
    selector: 'prism-first-time-account-setup',
    templateUrl: './first-time-account-setup.component.html',
    styleUrls: ['./first-time-account-setup.component.scss']
})
export class FirstTimeAccountSetupComponent implements OnInit, OnDestroy {

    stepsCompleted = {
        0: false,
        1: false,
        2: false
    };

    stepsSkipped = {
        0: false,
    };

    memberData$ = this.authService.memberData$;
    member: any;
    destroySub$ = new Subject();

    @ViewChild('stepper', {static: true}) stepper: MatStepper;

    constructor(private router: Router, private authService: AuthenticationService) {
    }

    ngOnInit() {
        this.memberData$.pipe(takeUntil(this.destroySub$)).subscribe(val => {
            this.member = val;
            const {acceptedPlatformPolicies} = this.member ?? {acceptedPlatformPolicies: false};

            if (acceptedPlatformPolicies && !this.stepsSkipped[0]) {
                this.completeStep(0);
            }

            if (val.firstName && val.lastName && val.secondaryEmail && val.contactNumber && !this.stepsSkipped[2]) {
                this.completeStep(1);
            }
        });

        this.stepper.selectionChange
            .subscribe(selection => {
                // INDEX 1 IS USER/MEDIA AGREEMENT
                if (selection.selectedIndex === 0 && this.member?.acceptedPlatformPolicies) {
                    this.completeStep(0);
                }
            });
    }

    ngOnDestroy() {
        this.destroySub$.next();
    }

    getMember(): Promise<any> {
        return new Promise(resolve => {
            this.memberData$.pipe(takeUntil(this.destroySub$)).subscribe(val => {
                this.member = val;
                resolve(this.member);
            });
        });
    }

    completeStep(stepIndex: number) {
        this.stepsCompleted[stepIndex] = true;

        setTimeout(() => {
            // INDEX 0 IS PLATFORM AGREEMENT
            if (stepIndex === 0) {
                this.member.acceptedPlatformPolicies = true;
            }

            this.stepper.next();
        }, 100);

        if (!this.member) {
            this.getMember().then(() => {
                this.checkMustNavHome();
            });
        } else if (!!this.member) {
            this.checkMustNavHome();
        }
    }

    checkMustNavHome() {
        if ((this.stepsSkipped[0] || this.stepsCompleted[0] || this.member?.acceptedPlatformPolicies) && this.member.lastName && this.member.firstName && this.member.loginEmail && this.member.stripeId) {
            this.goHome();
        }
    }

    goHome() {
        this.router.navigate(['home', 'jobs']);
    }

    skipStep(stepIndex: number) {
        this.stepsSkipped[stepIndex] = true;
        this.stepsCompleted[stepIndex] = true;

        setTimeout(() => {
            this.stepper.next();
        }, 100);

        if (!this.member) {
            this.getMember().then(() => {
                this.checkMustNavHome();
            });
        } else if (!!this.member) {
            this.checkMustNavHome();
        }
    }

    completeAccountDetails() {
        this.completeStep(2);

        if ((!!this.stepsCompleted[0] || this.stepsSkipped[0] || this.member?.acceptedPlatformPolicies) && (this.member.isContractor || !!this.member.stripeId))
            this.goHome();
    }
}
