import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AgenciesPageComponent} from 'src/app/components/agencies-page/agencies-page.component';
import {AgentsPageComponent} from 'src/app/components/agents-page/agents-page.component';
import {ContractorsPageComponent} from 'src/app/components/contractors-page/contractors-page.component';
import {UsersModule} from '../modules/users.module';

const userRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'agencies',
                component: AgenciesPageComponent
            },
            {
                path: 'contractors',
                component: ContractorsPageComponent
            },
            {
                path: 'agents',
                component: AgentsPageComponent
            },
            {
                path: '**',
                redirectTo: 'agencies'
            },
        ]
    }
];

export const UserRouting: ModuleWithProviders<UsersModule> = RouterModule.forChild(userRoutes);
