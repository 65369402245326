export enum PrismHeaders {
    SkipHeaders = 'X-Skip-Headers',
    UpdateCache = 'X-Update-Cache',
    UpdateCacheData = 'X-Update-Cache-Data',
    Authorization = 'Authorization',
    ProcessedFinalsPath = 'X-Processed-Finals-Path',
    ProcessedFinalsPathList = 'X-Processed-Finals-Path-List',
    AssetData = 'X-Asset-Data',
    QueryData = 'X-Query-Data',
    FunctionHelperData = 'X-Function-Helper-Data'
}