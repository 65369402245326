<div class="mat-dialog-container">
    <h1 mat-dialog-title>
        {{ (editJobBool$ | async) ? "Update Job" : "Create Job" }}
    </h1>
    <mat-dialog-content>
        <form class="add-job-form" [formGroup]="addEditJobForm">
            <div class="field-group">
                <div class="autoAddress" *ngIf="!manualAddressSelected; else manualAddress">
                    <div class="form-field form-field-input">
                        <mat-label class="form-field-label">Address</mat-label>
                        <mat-form-field class="address-field" appearance="outline"
                                        [ngClass]="{'field-has-value': addressValue}">
                            <input
                                    matInput
                                    placeholder="Start typing to find address"
                                    (input)="manuallyTypedIntoAutoAddress($event)"
                                    formControlName="addressControl"
                                    autocomplete="off"
                                    [matAutocomplete]="autoAddress"
                                    [(ngModel)]="addressValue"
                            />
                            <mat-icon *ngIf="isAddressVerifying | async">access_time</mat-icon>
                            <mat-error
                                    *ngIf="addEditJobForm.controls['addressControl'].hasError('required')">
                                Address is required
                            </mat-error>
                        </mat-form-field>
                        <mat-autocomplete #autoAddress="matAutocomplete">
                            <mat-option
                                    (onSelectionChange)="autoAddressSelectionMade(option)"
                                    *ngFor="let option of addressAutoCompleteOptions | async"
                                    [value]="option.address"
                            >
                                {{ option.address }}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <button
                            mat-button
                            class="btn btn-link manual-address-btn"
                            [disabled]="showProcessingLoader | async"
                            (click)="activateManualAddressInput()"
                    >
                        Manually add address
                    </button>
                    <div id="map"></div>
                </div>
                <ng-template #manualAddress>
                    <button
                            mat-button
                            class="btn btn-link look-up-btn"
                            [disabled]="showProcessingLoader | async"
                            (click)="deactivateManualAddressInput()"
                    >
                        Look up address automatically
                    </button>
                    <div class="manualAddress">
                        <div class="form-field form-field-input">
                            <mat-label class="form-field-label">Street</mat-label>
                            <mat-form-field appearance="outline">
                                <input
                                        matInput
                                        placeholder="Street"
                                        formControlName="streetControl"
                                />
                                <mat-error *ngIf="streetControl.hasError('required')">
                                    Street is required
                                </mat-error>
                                <mat-error *ngIf="streetControl.hasError('pattern')">
                                    Commas are not allowed
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-input">
                            <mat-label class="form-field-label">City</mat-label>
                            <mat-form-field appearance="outline">
                                <input
                                        matInput
                                        placeholder="City"
                                        formControlName="cityControl"
                                />
                                <mat-error *ngIf="cityControl.hasError('required')">
                                    City is required
                                </mat-error>
                                <mat-error *ngIf="cityControl.hasError('pattern')">Commas are not allowed</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-input">
                            <mat-label class="form-field-label">State</mat-label>
                            <mat-form-field appearance="outline">
                                <input
                                        matInput
                                        placeholder="State"
                                        formControlName="stateControl"
                                />
                                <mat-error *ngIf="cityControl.hasError('required')">
                                    State is required
                                </mat-error>
                                <mat-error *ngIf="cityControl.hasError('pattern')">Commas are not allowed</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-input">
                            <mat-label class="form-field-label">Country</mat-label>
                            <mat-form-field appearance="outline">
                                <input
                                        matInput
                                        placeholder="Country"
                                        formControlName="countryControl"
                                />
                                <mat-error *ngIf="countryControl.hasError('required')">
                                    Country is required
                                </mat-error>
                                <mat-error *ngIf="countryControl.hasError('pattern')">Commas are not allowed</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-input">
                            <mat-label class="form-field-label">Post Code</mat-label>
                            <mat-form-field appearance="outline">
                                <input
                                        matInput
                                        placeholder="Postcode"
                                        formControlName="postcodeControl"
                                />
                                <mat-error *ngIf="streetControl.hasError('required')">
                                    Postcode is required
                                </mat-error>
                                <mat-error *ngIf="streetControl.hasError('pattern')">
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                </ng-template>
            </div>
            <div class="field-group">
                <div *ngIf="agencyOptions.length > 0; else loadingAgenciesAndContractors">
                    <div class="form-field form-field-select">
                        <mat-label class="form-field-label">Contractor</mat-label>
                        <mat-form-field appearance="outline">
                            <mat-select
                                    placeholder="Please select"
                                    formControlName="contractorControl"
                                    [compareWith]="compareContractors"
                            >
                                <mat-option [value]="''">None</mat-option>
                                <mat-option
                                        *ngFor="let contractor of (contractorOptions | async)"
                                        [value]="contractor"
                                >
                                    {{ contractor.lastName }}, {{ contractor.firstName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="form-field form-field-select">
                        <mat-label class="form-field-label">Agency</mat-label>

                        <mat-form-field
                                appearance="outline"
                                *ngIf="agencyOptions.length > 0"
                        >
                            <mat-select
                                    placeholder="Please select"
                                    formControlName="agencyControl"
                                    [compareWith]="compareAgencies"
                                    (selectionChange)="onAgencySelect($event.value)"
                            >
                                <mat-option [value]="''">None</mat-option>
                                <mat-option *ngFor="let agency of agencyOptions" [value]="agency">
                                    {{ agency.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <ng-template #loadingAgenciesAndContractors>
                    <h6>Loading agencies and contractors...</h6>
                </ng-template>
                <div class="form-field form-field-select" *ngIf="agentsOptions.length > 0">
                    <mat-label class="form-field-label">Agent</mat-label>
                    <mat-form-field
                            appearance="outline"
                    >
                        <mat-select
                                placeholder="{{(loadingAgents === true) ? 'Loading...': (agentsOptions.length === 0) ? 'None' : 'Please select'}}"
                                formControlName="agentControl"
                                [compareWith]="compareAgents"
                        >
                            <mat-option [value]="''">Please select</mat-option>
                            <mat-option *ngFor="let agent of agentsOptions" [value]="agent">
                                {{ agent.firstName }} {{ agent.lastName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button
                class="btn btn-red cancel-btn"
                mat-button
                (click)="closeDialog()"
                [disabled]="showProcessingLoader | async"
        >
            Cancel
        </button>
        <button
                class="btn btn-green"
                mat-raised-button
                (click)="saveJob()"
                [disabled]="!addEditJobForm.valid || addEditJobForm.pristine || (showProcessingLoader | async) || (isAddressVerifying | async)"
        >
            Save
            <ng-template [ngIf]="showProcessingLoader | async">
                <div class="lds-hourglass"></div>
            </ng-template>
        </button>
    </mat-dialog-actions>
</div>
