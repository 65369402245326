import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatRadioChange} from '@angular/material/radio';
import uniq from 'lodash/uniq';
import moment from 'moment';
import {Subject} from 'rxjs';
import {combineLatest, takeUntil} from 'rxjs/operators';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {XeroPaymentTerms} from '../../enums/xero-payment-terms';
import {Job, Response} from '../../models';
import {DialogService, InvoicesService, JobsService, ProductsService, SnackbarService, XeroConnectionService} from '../../services';
import {TooltipPosition} from '@angular/material/tooltip';

@Component({
    selector: 'prism-add-edit-invoice',
    templateUrl: './add-edit-invoice.component.html',
    styleUrls: ['./add-edit-invoice.component.scss']
})
export class AddEditInvoiceComponent implements OnInit, OnDestroy {
    private subDestroyer$: Subject<void>;
    templateSelection: 'A' | 'B';
    loadingProducts: boolean;
    hasInvalidLineItem: boolean;
    fetchingDueDateData: boolean;
    sendingInvoice: boolean;
    charged: boolean;
    selectedJob: Job;
    dateCreated: string;
    dueDate: string;
    invoiceEmails: any;
    inputProducts: any;
    addEditInvoiceForm: FormGroup;
    checkboxLabelPosition: 'before' | 'after';
    tooltipPosition: TooltipPosition;
    tooltipText: string;
    separatorKeysCodes: number[];

    @ViewChild('chipListInvoice', {static: true}) chipListInvoice;
    @ViewChild('invoiceEmailInput', {static: true}) invoiceEmailInput: ElementRef<HTMLInputElement>;

    constructor(
        private invoicesService: InvoicesService,
        private xeroConnectionService: XeroConnectionService,
        private formBuilder: FormBuilder,
        private productsService: ProductsService,
        private dialogService: DialogService,
        private jobsService: JobsService,
        private snackbarService: SnackbarService
    ) {
        this.separatorKeysCodes = [ENTER, COMMA, SPACE];
        this.fetchingDueDateData = true;
        this.sendingInvoice = false;
        this.charged = true;
        this.selectedJob = this.jobsService.selectedJob;
        this.dueDate = moment().add(30, 'd').format('YYYY-MM-DD');
        this.dateCreated = moment().format('YYYY-MM-DD');
        this.loadingProducts = true;
        this.templateSelection = 'A';
        this.checkboxLabelPosition = 'after';
        this.tooltipText = `This job's invoice status will be marked as invoiced,\n but no invoice will be created or sent.`;
        this.tooltipPosition = 'right';
        this.subDestroyer$ = new Subject<void>();
        this.addEditInvoiceForm = new FormGroup({
            invoiceRecipient: new FormControl(
                this.selectedJob.agencyName ? this.selectedJob.agencyName : 'No agency',
                [Validators.required]
            ),
            dateCreated: new FormControl(
                {value: this.dateCreated, disabled: true},
                [Validators.required]
            ),
            emails: new FormArray([], [Validators.required, Validators.minLength(1)]),
            dueDate: new FormControl(
                '',
                [Validators.required]
            ),
            invoiceNotes: new FormControl(
                ''
            ),
            bankDetails: new FormControl(
                {
                    value: {'Bank': 'Multi Ulti Security Bank', 'Account Number': '123 456 789', 'Swift Code': '987 654 321'},
                    disabled: true
                },
                [Validators.required]
            ),
            lineItems: new FormArray(
                [],
                [Validators.required, Validators.minLength(1)]
            )
        });
    }

    ngOnInit() {
        let {agency, agent, agencyName} = this.selectedJob;

        this.xeroConnectionService.getOrganisationDueDate()
            .subscribe(
                (dueDateResponse: Response) => {
                    const dueDateData: { Day: string, Type: XeroPaymentTerms } = dueDateResponse.data;

                    if (dueDateData) {
                        const day = dueDateData.Day;
                        const type = dueDateData.Type;
                        switch (type) {
                            case XeroPaymentTerms.ofFollowingMonth: {
                                const dueDate = moment().add(1, 'month').format('YYYY-MM');
                                this.addEditInvoiceForm.get('dueDate').setValue(dueDate + `-${day}`);
                                this.addEditInvoiceForm.updateValueAndValidity();
                                break;
                            }
                            case XeroPaymentTerms.ofCurrentMonth: {
                                const dueDate = moment().add(1, 'month').format('YYYY-MM');
                                this.addEditInvoiceForm.get('dueDate').setValue(dueDate + `-${day}`);
                                this.addEditInvoiceForm.updateValueAndValidity();
                                break;
                            }
                            case XeroPaymentTerms.daysAfterBillDate: {
                                const dueDate = moment().add(day, 'day').format('YYYY-MM-DD');
                                this.addEditInvoiceForm.get('dueDate').setValue(dueDate);
                                this.addEditInvoiceForm.updateValueAndValidity();
                                break;
                            }
                            case XeroPaymentTerms.daysAfterBillMonth: {
                                const dueDate = moment().endOf('month').add(day, 'day').format('YYYY-MM-DD');
                                this.addEditInvoiceForm.get('dueDate').setValue(dueDate);
                                this.addEditInvoiceForm.updateValueAndValidity();
                                break;
                            }
                            default: {
                                const dueDate = moment().add(1, 'month').format('YYYY-MM-DD');
                                this.addEditInvoiceForm.get('dueDate').setValue(dueDate);
                                this.addEditInvoiceForm.updateValueAndValidity();
                            }
                        }
                    }
                },
                () => {
                    console.error('Error fetching due date data');
                    this.snackbarService.handleError('Something went wrong while trying set your due date');
                },
                () => {
                    this.fetchingDueDateData = false;
                }
            );

        if (!!agency) {
            this.addEditInvoiceForm.get('invoiceRecipient').setValue(`${agencyName}`);
            this.addEditInvoiceForm.updateValueAndValidity();
        }

        this.invoicesService.getUserInvoiceEmails({agency, agent})
            .then(invoiceEmails => {
                this.invoiceEmails = invoiceEmails;
                this.fillFormEmails();
                this.addEditInvoiceForm.updateValueAndValidity();
            })
            .catch(e => {
                console.error('Could not get user invoice emails:', e);
                this.snackbarService.handleError('Could not get user invoice emails');
            });

        this.productsService
            .getInvoiceJobProducts()
            .pipe(
                takeUntil(this.subDestroyer$),
                combineLatest(this.productsService.getInvoiceMemberProducts())
            )
            .subscribe(
                ([{data: products}, {data: memberProducts}]) => {
                    if (!!products) {
                        products.forEach((product: any) => {
                            const memberProductFound = memberProducts.find(memberProduct => memberProduct.id === product.masterProductId);
                            if (!!memberProductFound) {
                                product.lineItems = memberProductFound.lineItems;
                            } else {
                                product.lineItems = [];
                            }
                            product.createTime = !!product.createTime ? this.formatDate(product.createTime) : 'No Date';
                        });
                        this.inputProducts = products;
                    }
                    this.loadingProducts = false;
                },
                () => {
                },
                () => this.productsService.switchOffAllJobProductLoaders()
            );
    }

    ngOnDestroy() {
        this.subDestroyer$.next();
    }

    addInvoiceEmail(event: MatChipInputEvent) {
        if ((event.value || '').trim()) {
            const emailString = event.value.trim();
            this.invoiceEmails.push(emailString);
            this.invoiceEmails = uniq(this.invoiceEmails);
            this.fillFormEmails();
            event.value = '';
        }

        this.invoiceEmailInput.nativeElement.value = '';
    }

    fillFormEmails() {
        const emailFromForm: any = this.addEditInvoiceForm.controls.emails;
        emailFromForm.controls = [];
        emailFromForm.setValidators([Validators.required, Validators.minLength(1)]);
        uniq(this.invoiceEmails).forEach(mail => {
            const mailControl = new FormControl(mail);
            emailFromForm.controls.push(mailControl);
        });
        emailFromForm.updateValueAndValidity();
    }

    removeInvoiceEmail(index: number) {
        this.invoiceEmails.splice(index, 1);
        this.fillFormEmails();
    }

    updateProducts(lineItems) {
        this.hasInvalidLineItem = lineItems.some(lineItem => {
            return !lineItem.Description;
        });
        const lineItemsFromForm: any = this.addEditInvoiceForm.controls.lineItems;
        lineItemsFromForm.controls = [];
        lineItemsFromForm.setValidators([Validators.required, Validators.minLength(1)]);

        lineItems.forEach(lineItem => {
            const lineItemControl = new FormControl(lineItem);
            lineItemsFromForm.controls.push(lineItemControl);
        });
        lineItemsFromForm.updateValueAndValidity();
    }

    sendInvoice() {
        this.dialogService.closeResultDialog(this.charged ? 'charged' : 'not_charged');
        this.snackbarService.showSnackbar(`Processing invoice...`);
        this.sendingInvoice = true;
        const masterProductIds = uniq(this.inputProducts.map(product => product.masterProductId));
        this.invoicesService.postInvoice(
            {...this.addEditInvoiceForm.getRawValue(), invoiceReference: this.selectedJob.address},
            this.invoiceEmails,
            this.templateSelection,
            this.selectedJob.id,
            masterProductIds,
            this.charged
        )
            .then(() => {
                this.jobsService.jobInvoiced();
                this.sendingInvoice = false;
            })
            .catch(err => {
                console.error('Error posting invoice:', err);
                this.snackbarService.handleError('Strange... Something went wrong while sending your invoice');
            });
    }

    closeModal() {
        this.dialogService.closeResultDialog('canceled');
    }

    setInvoiceTemplateSelection(event: MatRadioChange) {
        this.templateSelection = event.value;
    }

    toggleAppendAgentName(checked: boolean, job: Job) {
        const {agencyName, agentName} = job;
        if (checked && !!agencyName) {
            this.addEditInvoiceForm.get('invoiceRecipient').setValue(`${agentName} - ${agencyName}`);
            this.addEditInvoiceForm.updateValueAndValidity();
        } else {
            this.addEditInvoiceForm.get('invoiceRecipient').setValue(`${agencyName}`);
            this.addEditInvoiceForm.updateValueAndValidity();
        }
    }

    setJobChargeStatus() {
        this.charged = !this.charged;

        if (!this.charged) {
            this.addEditInvoiceForm.get('invoiceRecipient').disable();
            this.addEditInvoiceForm.get('dateCreated').disable();
            this.addEditInvoiceForm.get('dueDate').disable();
        } else {
            this.addEditInvoiceForm.get('invoiceRecipient').enable();
            this.addEditInvoiceForm.get('dateCreated').enable();
            this.addEditInvoiceForm.get('dueDate').enable();
        }
    }

    formatDate(date: Date): string {
        let d = new Date(date);
        return [
            d.getFullYear(),
            ('0' + (d.getMonth() + 1)).slice(-2),
            ('0' + d.getDate()).slice(-2),
        ].join('/');
    }
}
