import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UploadsStats} from '../../../models';
import {UploadService} from '../../../services/upload.service';
import {debounceTime, pluck, tap} from 'rxjs/operators';
import isEqual from 'lodash/isEqual';

@Component({
    selector: 'prism-uploader-display',
    templateUrl: './uploader-display.component.html',
    styleUrls: ['./uploader-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploaderDisplayComponent implements OnDestroy {
    displayUploaderControl = true;
    completedTracker = {};
    failedUploadTracker = [];
    totalFiles = 0;
    overallProgress = new BehaviorSubject<number>(0);
    uploadingStatusSet$: any = this.uploadService.uploadingStatusSet$;
    uploads$: Observable<UploadsStats> = this.uploadService.uploads$
        .pipe(
            tap((uploads: UploadsStats) => {
                const jobUploads = !!uploads ? Object.entries(uploads) : null;
                let allFailedUploads = [];
                let totalUploadProgress = 0;
                if (this.failedUploadTracker.length > 0) {
                    totalUploadProgress = this.failedUploadTracker.length * 100;
                }
                if (!!jobUploads) {
                    jobUploads.forEach(([uploadUUID, job]) => {
                        Object.entries(job).forEach(([address, service]) => {
                            Object.entries(service).forEach(([serviceId, uploads]) => {
                                let completedCounter = 0;
                                Object.values(uploads).forEach((value) => {
                                    if (!value['failed']) {
                                        totalUploadProgress += value['progress'];
                                    } else {
                                        allFailedUploads.push(uploads);
                                    }
                                    if (value['completed'] === true) {
                                        completedCounter++;
                                    }
                                });
                                this.completedTracker[uploadUUID] = ((completedCounter === Object.values(uploads).length) && (Object.values(uploads).length > 0));
                            });
                        });
                    });
                    if (!isEqual(this.failedUploadTracker, allFailedUploads)) {
                        let newFailures = allFailedUploads.length - this.failedUploadTracker.length;
                        totalUploadProgress += newFailures * 100;
                    }
                    if (this.totalFiles > 0) {
                        let currUploadProgress = Math.ceil(totalUploadProgress / this.totalFiles);
                        this.overallProgress.next(Math.ceil(currUploadProgress));
                        this.failedUploadTracker = allFailedUploads;
                    } else {
                        this.overallProgress.next(100);
                        this.failedUploadTracker = allFailedUploads;
                    }

                }
            })
        );
    uploadStats$: Observable<{ completed: number, total: number }> = this.uploadService.uploadStats$;

    constructor(private uploadService: UploadService) {
    }

    ngOnInit() {
        this.uploadService.uploadStats$.subscribe({
            next: (val) => {
                this.totalFiles = val.total;
            },
            error: (err) => {
            },
            complete: () => {
            }
        });
    }

    ngOnDestroy(): void {
        this.uploadService.clearUploadsData();
    }

    toggleUploaderInfoDisplay() {
        this.displayUploaderControl = !this.displayUploaderControl;
    }

    preventDisplayCloseOnClick(event: MouseEvent) {
        event.stopPropagation();
    }

    onClickedOutsideUploader(event) {
        if (this.displayUploaderControl) {
            this.displayUploaderControl = false;
        }
    }

    canUploaderOpen(hasValues: boolean) {
        if (!hasValues) {
            this.displayUploaderControl = false;
        }
        return hasValues ? 'auto' : 'none';
    }

    clearServiceUploads(event: MouseEvent, jobKey: string, serviceId: string, uploadUUID: string) {
        event.stopImmediatePropagation();
        delete this.completedTracker[uploadUUID];
        this.uploadService.removeServiceUploads(uploadUUID, jobKey, serviceId);
    }

    restartUpload(uploadRestartCancelData) {
        this.uploadService.resumeUpload(uploadRestartCancelData, uploadRestartCancelData.uploadTarget, false);
    }

    retryAllUploads() {
        const allUploadRestartDataArray = this.failedUploadTracker.map(upload => upload.uploadRestartCancelData);
        this.uploadService.resumeAllUploads(false);

    }
}
