<div class="products-container content-wrapper">
    <h1 class="page-title">Products</h1>
    <div class="products-body">
        <div class="toolbar products-toolbar">
            <mat-button-toggle-group
                    [(value)]="showActive"
                    (change)="toggleActiveArchiveDisplay($event)">
                <mat-button-toggle [value]="true"
                                   [disabled]="(isGetProductsLoading$ | async) === true ||
                           (isPostProductLoading$ | async) === true ||
                           (isPutProductsLoading$ | async) === true">
                    Active
                </mat-button-toggle>
                <mat-button-toggle [value]="false"
                                   [disabled]="(isGetProductsLoading$ | async) === true ||
                           (isPostProductLoading$ | async) === true ||
                           (isPutProductsLoading$ | async) === true">
                    Archived
                </mat-button-toggle>
            </mat-button-toggle-group>
            <div class="toolbar-right products-toolbar-right"
                 *ngIf="!!(memberData$|async) && !(memberData$|async)?.isContractor">
                <button class="btn btn-red"
                        mat-raised-button
                        [disabled]="(memberProducts$ | async)?.length <= 0 ||
                activeArchiveCheckboxArray?.length === 0 ||
                (isGetProductsLoading$ | async) === true ||
                (isPostProductLoading$ | async) === true ||
                (isPutProductsLoading$ | async) === true"
                        (click)="toggleProductActive(!showActive)">
                    {{ showActive ? 'Archive Selected' : 'Activate Selected' }}
                </button>
                <button class="btn btn-green"
                        mat-raised-button
                        [disabled]="(isGetProductsLoading$ | async) === true ||
                (isPostProductLoading$ | async) === true ||
                (isPutProductsLoading$ | async) === true"
                        (click)="openCreateEditProductDialog()">
                    New Product
                </button>
            </div>
        </div>
        <div class="progress-bar" *ngIf="(isGetProductsLoading$ | async) === true && initialTableLoad === true">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="initialTableLoad === false"
             [ngClass]="!((memberProducts$ | async)?.length <= 0) ? 'products-table-container' : 'no-products-placeholder'">
            <prism-products-table *ngIf="!((memberProducts$ | async)?.length <= 0); else noProducts"
                                  [showActive]="showActive"
                                  [products]="(memberProducts$ | async)"
                                  (setForArchive)="setArchiveArray($event)"
                                  [setShowLoader]="(isGetProductsLoading$ | async) === true || (isPutProductsLoading$ | async) === true"
                                  (openProductEdit)="openCreateEditProductDialog(true, $event)">
            </prism-products-table>
            <ng-template #noProducts>
                <div
                        class="products-list-empty"
                        *ngIf="(memberProducts$ | async)?.length <= 0 && !(isGetProductsLoading$ | async)"
                >
                    <h5>No {{ showActive ? "active" : "archived" }} products to display...</h5>
                </div>
            </ng-template>
        </div>
    </div>
</div>

