import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

import {SnackbarService} from '../snackbar.service';
import {AuthenticationService} from '../authentication.service';
import {XeroConnectionService} from '../xero-connection.service';

@Injectable({providedIn: 'root'})
export class AuthGuard {

    constructor(private authService: AuthenticationService,
                private xeroConnectService: XeroConnectionService,
                private router: Router
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        if (this.router.url.startsWith('/users/enable-contractor')) {
            return true;
        }
        return this.authService.isLoggedIn();
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        return this.canActivate(next, state);
    }
}
