import {Component, HostListener, OnDestroy} from '@angular/core';
import {environmentShared} from '../../../environments';
import {AuthenticationService} from '../../services';
import {ComponentCanDeactivate} from '../../models';
import {Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {UploadService} from '../../services/upload.service';

@Component({
    selector: 'prism-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements ComponentCanDeactivate, OnDestroy {
    env = environmentShared;
    appHasInternetConnection$ = this.authService.appHasInternetConnection$;
    initials;
    subDestroyer$ = new Subject<void>();
    memberData$ = this.authService.memberData$
        .pipe(
            takeUntil(this.subDestroyer$),
            tap((memberData) => {
                if (!!memberData) {
                    const {firstName, lastName} = memberData;
                    this.initials = !!firstName && !!lastName
                        ? `${firstName[0].toLocaleUpperCase()}${lastName[0].toLocaleUpperCase()}`
                        : '?';
                }
            })
        );
    memberEmail: string;

    constructor(private authService: AuthenticationService,
                private uploadsService: UploadService) {
    }

    ngOnDestroy(): void {
        this.subDestroyer$.next();
    }

    @HostListener('window:beforeunload', ['$event'])
    canDeactivate(): boolean {
        return !(Object.keys(this.uploadsService.activeUploads).length
            || Object.values(this.uploadsService.uploadingStatusSet).some(finalising => finalising));
    }
}
