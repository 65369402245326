<button class="btn btn-link btn-nav" mat-button (click)="navToJob()">
    <img src="../../../assets/icons/jobs/arrow-back.svg" alt="Arrow Back">
    <span>Go to Job</span>
</button>

<div class="content-wrapper job-album-wrapper" *ngIf="!!job">
    <div class="job-info-wrapper">
        <h1 class="page-title" id="job-detail-header">
            <span class="job-detail-id">#{{ job.id }}:</span>
            <span class="job-detail-address">{{ job.address }}</span>
        </h1>
    </div>
    <div class="toolbar job-album-toolbar">
        <div class="toolbar-left job-album-toolbar-left">
            <div class="form-field form-field-select add-product-wrapper">
                <mat-form-field appearance="outline"
                                class="select-service-field"
                                *ngIf="hasActiveProducts || (memberProductsLoading$ | async) || (jobProductsLoading$ | async)">
                    <mat-select
                            [placeholder]="placeholderText"
                            (selectionChange)="setSelectedProduct($event.value)"
                            [disabled]="(jobProductsLoading$ | async) || (memberProducts)?.length <= 0">
                        <div *ngFor="let product of (memberProducts)">
                            <mat-option *ngIf="product.active"
                                        [value]="product">
                                {{ product.ProductName }}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button
                        class="btn btn-green add-product-btn"
                        (click)="openAddProductDialog()"
                        [disabled]="!dropdownProductSelected || (jobProductsLoading$ | async) || (memberProductsLoading$ | async)">
                    Add Product
                    <ng-template [ngIf]="jobProductsLoading$ | async">
                        <div class="lds-hourglass"></div>
                    </ng-template>
                </button>
            </div>
        </div>
        <div class="btn-group-right toolbar-right job-album-toolbar-right">
            <div class="icon-btn-group">
                <button mat-icon-button class="btn share-album-btn" (click)="openShareModal()"
                        [disabled]="(albumProducts$ | async) === null || (jobProductsLoading$ | async) || (memberProductsLoading$ | async)">
                    <svg
                            width="30px"
                            height="30px"
                            viewBox="0 0 16 14"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#FFFFFF"
                            stroke-width="0.4"

                    >
                        <g
                                id="Symbols"
                                fill="none"
                                fill-rule="evenodd"
                        >
                            <g
                                    id="Jobs-Table"
                                    transform="translate(-1137.000000, -78.000000)"
                                    fill="#2F9BBF"
                                    fill-rule="nonzero"
                            >
                                <g
                                        id="Group-31-Copy"
                                        transform="translate(1100.000000, 20.000000)"
                                >
                                    <g
                                            id="Group-7-Copy"
                                            transform="translate(37.000000, 58.000000)"
                                    >
                                        <g
                                                id="0800-share"
                                                transform="translate(0.736842, 0.000000)"
                                        >
                                            <path
                                                    d="M10.6809684,0.1533 C10.4798105,-0.05145 10.1528,-0.05145 9.95164211,0.1533 C9.75048421,0.35805 9.75048421,0.6909 9.95164211,0.89565 L13.1969895,4.19895 L6.70526316,4.19895 C4.92991579,4.19895 3.2536,4.84995 1.98578947,6.0333 C0.7056,7.2282 0,8.8137 0,10.49895 L0,12.77395 C0,13.06375 0.231073684,13.29895 0.515789474,13.29895 C0.800505263,13.29895 1.03157895,13.06375 1.03157895,12.77395 L1.03157895,10.49895 C1.03157895,7.6041 3.57648421,5.24895 6.70526316,5.24895 L13.1969895,5.24895 L9.95164211,8.55225 C9.75048421,8.757 9.75048421,9.08985 9.95164211,9.2946 C10.0527368,9.3975 10.1847789,9.4479 10.3168211,9.4479 C10.4488632,9.4479 10.5809053,9.39645 10.682,9.2946 L14.8083158,5.0946 C15.0094737,4.88985 15.0094737,4.557 14.8083158,4.35225 L10.6809684,0.1533 Z"
                                                    id="Shape"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>

                <button mat-icon-button class="invoice-album-btn" (click)="openInvoiceDialog(job)"
                        [ngClass]="{'invoice-exists': jobInvoiced}">

                    <img class="invoice-icon" *ngIf="(job | jobInvoiced) else noInvoice" alt='Invoiced state.'
                         src="/assets/icons/invoices/invoice.svg">

                    <ng-template #noInvoice>
                        <img class="invoice-icon" alt='Not invoiced state.'
                             src="/assets/icons/invoices/no-invoice.svg">
                    </ng-template>
                </button>
            </div>
            <button *ngIf="(selectedAssetsCount$ | async) > 0" mat-raised-button class="btn btn-blue"
                    [disabled]="fetchingAssetPaths"
                    (click)="downloadSelectedAssets()">Download Selected Assets
                <ng-template [ngIf]="fetchingAssetPaths">
                    <div class="lds-hourglass"></div>
                </ng-template>
            </button>
            <button *ngIf="(selectedAssetsCount$ | async) === 0" mat-raised-button class="btn btn-blue"
                    [disabled]="fetchingAssetPaths"
                    (click)="downloadAllAssets()">Download All
                <ng-template [ngIf]="fetchingAssetPaths">
                    <div class="lds-hourglass"></div>
                </ng-template>
            </button>
            <button class="btn btn-link btn-nav"
                    mat-button
                    [routerLink]="['/home', 'products']"
                    (click)="navToMasterProducts()"
                    [disabled]="(jobProductsLoading$ | async) || (memberProductsLoading$ | async)"
                    *ngIf="!hasActiveProducts && !((memberProductsLoading$ | async) || (jobProductsLoading$ | async))">
                <span>Add A Master Product</span>
            </button>
        </div>
    </div>
</div>
<mat-progress-bar
        *ngIf="(albumProducts$ | async) === null || (jobProductsLoading$ | async) || (memberProductsLoading$ | async) || jobLoading"
        mode="indeterminate"></mat-progress-bar>
<div class="product-bucket-wrapper"
     *ngIf="(!!(albumProducts$ | async) || !(jobProductsLoading$ | async) || !(memberProductsLoading$ | async)) && ((jobProducts$ | async).length > 0)">
    <div *ngFor="let product of (jobProducts$ | async)">
        <prism-product-bucket
                [fetchingDownloadData]="fetchingAssetPaths"
                [product]="product"
                [assetsRef]="assetsData"
                [job]="job"
                [agencyData]="agencyData"
                [jobDownloading]="jobDownloadingNotification.asObservable()"
        >
        </prism-product-bucket>
    </div>
    <h1 *ngIf="(jobProducts$ | async)?.length <= 0 && !((jobProductsLoading$ | async) || (memberProductsLoading$ | async))">
        No Products. Add some now!</h1>
</div>

