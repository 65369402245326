<div class="add-service-actions">
    <div class="form-field form-field-select select-service-field">
        <mat-form-field appearance="outline" class="service-form-field">
            <mat-select #selectService id="select-service" placeholder="Add a Service" multiple disableRipple
                        (selectionChange)="checkForNone($event); ensureNonZero($event)">
                <mat-select-trigger>
                    {{ serviceSelectionDisplay[0]?.DISPLAY }}
                    <span *ngIf="serviceSelectionDisplay.length > 1" class="multiple-selected-display">
                        (+{{ serviceSelectionDisplay.length - 1 }} {{ serviceSelectionDisplay.length === 2 ? 'other service type' : 'other service types' }}
                        )
                    </span>
                </mat-select-trigger>
                <mat-option id="none-selection">None</mat-option>
                <mat-option id="select-option" #serviceOption *ngFor="let service of services"
                            [value]="service">
                    <span class="service-name">{{ service.DISPLAY }}</span>

                    <div class="service-counter-wrapper">
                        <button mat-icon-button (click)="decreaseServiceCount($event, service, serviceOption)">
                            <svg width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                <g id="Processor" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Processor---Job-Detail" transform="translate(-1199.000000, -289.000000)">
                                        <g id="Group-14" transform="translate(877.000000, 213.000000)">
                                            <g id="Group-9">
                                                <g id="Group-7" transform="translate(322.000000, 71.000000)">
                                                    <g id="Group-8-Copy-3" transform="translate(0.000000, 5.000000)">
                                                        <circle id="Oval-5" fill="#2F9BBF" cx="9" cy="9" r="9"></circle>
                                                        <path d="M3.6815254,9.39999998 C3.415593,9.39999998 3.20001221,9.19852812 3.20001221,8.94999999 C3.20001221,8.70147186 3.415593,8.5 3.6815254,8.5 L14.3184994,8.5 C14.5844318,8.5 14.8000126,8.70147186 14.8000126,8.94999999 C14.8000126,9.19852812 14.5844318,9.39999998 14.3184994,9.39999998 L3.6815254,9.39999998 Z"
                                                              id="Line-2" fill="#FFFFFF" fill-rule="nonzero"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                        <input class="num-input"
                               matInput
                               #serviceCount
                               type="text"
                               [value]="service['count'] ? service['count'] : 0"
                               (input)="inputServiceCount($event.target['value'], service, serviceOption)"
                               (click)="clickOnInput($event)">
                        <button mat-icon-button (click)="increaseServiceCount($event, service, serviceOption)">
                            <svg width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                <g id="Processor" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Processor---Job-Detail" transform="translate(-1276.000000, -289.000000)">
                                        <g id="Group-14" transform="translate(877.000000, 213.000000)">
                                            <g id="Group-9">
                                                <g id="Group-7" transform="translate(322.000000, 71.000000)">
                                                    <g id="Group-8" transform="translate(77.000000, 5.000000)">
                                                        <circle id="Oval-5" fill="#2F9BBF" cx="9" cy="9" r="9"></circle>
                                                        <path d="M14.1842105,8.36842105 L9.26315789,8.36842105 L9.26315789,3.44736842 C9.26315789,3.20042105 9.06273684,3 8.81578947,3 C8.56884211,3 8.36842105,3.20042105 8.36842105,3.44736842 L8.36842105,8.36842105 L3.44736842,8.36842105 C3.20042105,8.36842105 3,8.56884211 3,8.81578947 C3,9.06273684 3.20042105,9.26315789 3.44736842,9.26315789 L8.36842105,9.26315789 L8.36842105,14.1842105 C8.36842105,14.4311579 8.56884211,14.6315789 8.81578947,14.6315789 C9.06273684,14.6315789 9.26315789,14.4311579 9.26315789,14.1842105 L9.26315789,9.26315789 L14.1842105,9.26315789 C14.4311579,9.26315789 14.6315789,9.06273684 14.6315789,8.81578947 C14.6315789,8.56884211 14.4311579,8.36842105 14.1842105,8.36842105 Z"
                                                              id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>

                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <button class="btn btn-blue" mat-raised-button (click)="addServices(); emitFormsArray()"
            [disabled]="serviceMatSelection.value === undefined">Add
    </button>
</div>

<div class="services-container">
    <div class="service-item" *ngFor="let service of servicesControls.value; let i = index">
        <form [formGroup]="service">
            <div class="service-header">
                <div class="mat-headline-6">{{ service.value.DISPLAY }}</div>

                <button mat-icon-button class="delete-service-btn" (click)="removeService(i)">
                    <svg width="15px" height="20px" viewBox="0 0 15 20" xmlns="http://www.w3.org/2000/svg">
                        <g id="Member" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Member---Uploader" transform="translate(-1327.000000, -213.000000)" fill="#DC5A5A"
                               fill-rule="nonzero">
                                <g id="Group-26" transform="translate(222.000000, 194.000000)">
                                    <g id="Group-25" transform="translate(1105.000000, 19.000000)">
                                        <g id="0130-trash2">
                                            <path d="M13.1625,2 L9.75,2 L9.75,1.5 C9.75,0.673 9.093825,0 8.2875,0 L6.3375,0 C5.531175,0 4.875,0.673 4.875,1.5 L4.875,2 L1.4625,2 C0.656175,2 0,2.673 0,3.5 L0,4.5 C0,5.152 0.40755,5.708 0.975,5.914 L0.975,18.5 C0.975,19.327 1.631175,20 2.4375,20 L12.1875,20 C12.993825,20 13.65,19.327 13.65,18.5 L13.65,5.914 C14.21745,5.708 14.625,5.152 14.625,4.5 L14.625,3.5 C14.625,2.673 13.968825,2 13.1625,2 Z M5.85,1.5 C5.85,1.224 6.0684,1 6.3375,1 L8.2875,1 C8.5566,1 8.775,1.224 8.775,1.5 L8.775,2 L5.85,2 L5.85,1.5 Z M12.1875,19 L2.4375,19 C2.1684,19 1.95,18.776 1.95,18.5 L1.95,6 L12.675,6 L12.675,18.5 C12.675,18.776 12.4566,19 12.1875,19 Z M13.65,4.5 C13.65,4.776 13.4316,5 13.1625,5 L1.4625,5 C1.1934,5 0.975,4.776 0.975,4.5 L0.975,3.5 C0.975,3.224 1.1934,3 1.4625,3 L13.1625,3 C13.4316,3 13.65,3.224 13.65,3.5 L13.65,4.5 Z"
                                                  id="Shape"></path>
                                            <path d="M10.2375,7 C9.9684,7 9.75,7.224 9.75,7.5 L9.75,17.5 C9.75,17.776 9.9684,18 10.2375,18 C10.5066,18 10.725,17.776 10.725,17.5 L10.725,7.5 C10.725,7.224 10.5066,7 10.2375,7 Z"
                                                  id="Shape"></path>
                                            <path d="M7.3125,7 C7.0434,7 6.825,7.224 6.825,7.5 L6.825,17.5 C6.825,17.776 7.0434,18 7.3125,18 C7.5816,18 7.8,17.776 7.8,17.5 L7.8,7.5 C7.8,7.224 7.5816,7 7.3125,7 Z"
                                                  id="Shape"></path>
                                            <path d="M4.3875,7 C4.1184,7 3.9,7.224 3.9,7.5 L3.9,17.5 C3.9,17.776 4.1184,18 4.3875,18 C4.6566,18 4.875,17.776 4.875,17.5 L4.875,7.5 C4.875,7.224 4.6566,7 4.3875,7 Z"
                                                  id="Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
        </form>
    </div>
</div>