import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import uniq from 'lodash/uniq';
import {DeviceDetectorService} from 'ngx-device-detector';
import {BehaviorSubject, Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from '../../../environments';
import {PrismHeaders} from '../../enums';
import {Response} from '../../models/https-response';
import {AgentService, AssetsService, DialogService, ProductsService, SnackbarService} from '../../services';
import {DownloadSelectionModalComponent} from '../download-selection-modal/download-selection-modal.component';
import {NotifyAgentDialogComponent} from '../notify-agent-dialog/notify-agent-dialog.component';

@Component({
    selector: 'prism-agent-job-view',
    templateUrl: './agent-job-view.component.html',
    styleUrls: ['./agent-job-view.component.scss']
})
export class AgentJobViewComponent implements OnInit, OnDestroy {
    isSelecting = false;
    showLoader = true;
    mobileData: { isMobile: boolean, browser: string, os: string } = null;
    shareUid: string;
    downloadOptions = {
        web: false,
        watermark: false
    };
    urls = new BehaviorSubject(null);
    jobProductsData$: Observable<Response>;
    subDestroyer$ = new Subject<void>();
    assetsSelected = [];
    servicesLoading = [];
    assetPaths$ = this.assetsService.currentJobAssetsPaths$
        .pipe(
            tap(paths => {
                this.downloadOptions.web = paths.some(path => path.includes('web'));
                this.downloadOptions.watermark = paths.some(path => path.includes('watermark'));
            })
        );

    constructor(private productsService: ProductsService,
                private agentService: AgentService,
                private http: HttpClient,
                private activatedRoute: ActivatedRoute,
                private dialogService: DialogService,
                private assetsService: AssetsService,
                private deviceDetector: DeviceDetectorService) {
        this.shareUid = this.activatedRoute.snapshot.params.shareUid || '';
    }

    ngOnInit() {
        const os = this.deviceDetector.os;

        this.mobileData = {
            isMobile: os === 'Android' || os === 'iOS',
            browser: this.deviceDetector.browser,
            os
        };

        this.jobProductsData$ = this.http.get(`${environment.apiBaseUrl}/GetAgentJobProducts`, {
            headers: new HttpHeaders()
                .append(PrismHeaders.QueryData, JSON.stringify({shareUid: this.shareUid}))
        })
            .pipe(
                tap((jobData: Response) => {
                    const services = jobData.data.products
                        .map(product => product.services);

                    this.assetsService.getAgencyAssetsBucketPaths(this.shareUid, services);
                }),
                takeUntil(this.subDestroyer$),
                map((d: Response) => {
                    return d.data;
                })
            );
    }

    ngOnDestroy() {
        this.subDestroyer$.next();
    }

    openDownloadAllOptions(jobData) {
        this.dialogService.openDialog(DownloadSelectionModalComponent, {
            shareUid: this.shareUid,
            address: jobData.address,
            jobId: jobData.jobId,
            allAssetPaths: this.assetsService.currentJobAssetPaths
        }, {
            panelClass: 'download-selection-dialog'
        });
    }

    changeLocation() {
        window.open('https://bwrm.com.au/');
    }

    openShareModal(job) {
        this.dialogService.openDialog(NotifyAgentDialogComponent, {job, asAgency: true, mobileData: this.mobileData}, {
            panelClass: 'mobile-dialog-container'
        });
    }

    toggleSelect() {
        this.isSelecting = !this.isSelecting;
        this.assetsSelected = [];
    }

    updateAssetsSelected({assetData, selected}) {
        if (selected) {
            this.assetsSelected.push(assetData);
        } else {
            this.assetsSelected = this.assetsSelected.filter(asset => {
                return asset.originalFilePath !== assetData.originalFilePath;
            });
        }
    }

    updateLoader(servicesLoadIdData, loaded: boolean) {
        if (loaded) {
            this.servicesLoading = this.servicesLoading.filter(id => id !== servicesLoadIdData);
        } else {
            this.servicesLoading = uniq([...this.servicesLoading, ...servicesLoadIdData]);
        }
        this.showLoader = !!this.servicesLoading.length;
    }

    downloadSelected(jobData) {
        let downloadPaths = [];
        const allJobAssetPaths = this.assetsService.currentJobAssetPaths;
        this.assetsSelected.forEach(asset => {
            const currentAssetPaths = allJobAssetPaths.filter(path => {
                return path.includes(asset.originalFileName);
            });

            downloadPaths = downloadPaths.concat(currentAssetPaths);
        });
        this.dialogService.openDialog(DownloadSelectionModalComponent, {
            shareUid: this.shareUid,
            address: jobData.address,
            jobId: jobData.jobId,
            selectedAssets: downloadPaths
        }, {
            panelClass: 'download-selection-dialog'
        });
    }
}
