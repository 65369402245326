import {Component} from '@angular/core';
import {AuthenticationService} from '../../services';

@Component({
    selector: 'prism-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
    memberData$ = this.authService.memberData$;

    constructor(private authService: AuthenticationService) {
    }

}
