<prism-image-upload
        *ngIf="!(service.SNAKE_CASE === VIDEO_SERVICES.SNAKE_CASE || service.SNAKE_CASE === FLOORPLAN_SERVICES.SNAKE_CASE) else googleForm"
        [service]="service"
        [serviceIndex]="0"
        [productId]="productId"
        [parentJob]="job"
        [onlyEdit]="true"
        (closeAllDialogHandler)="closeAllDialogHandler($event)"
>
</prism-image-upload>
<ng-template #googleForm>
    <prism-video-floorplan-upload
            [service]="service"
            [productId]="productId"
            [parentJob]="job"
            [onlyEdit]="true"
            [productsLoading]="productsLoading$ | async"
            (closeAllDialogHandler)="closeAllDialogHandler($event)">
    </prism-video-floorplan-upload>
</ng-template>