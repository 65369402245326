<mat-progress-bar *ngIf="loading" class="overall-status-progress" mode="indeterminate"></mat-progress-bar>
<h3>Payment Method</h3>
<div *ngIf="!setupPage">
    <div *ngIf="loading">Loading your cards...</div>
    <mat-form-field *ngIf="!loading">
        <mat-label>Card</mat-label>
        <mat-select style="width: 250px" [value]="defaultCard">
            <div>
                <mat-option *ngFor="let card of userCards"
                            [value]="card" (onSelectionChange)="confirmDefault($event)">
                    <span>

                    {{ stars }} {{ card.card.last4 }}  {{ card.card.exp_month }}/{{ card.card.exp_year }}</span>
                </mat-option>
            </div>

        </mat-select>

    </mat-form-field>
</div>

<button mat-raised-button class="btn btn-green" (click)="showStripe()">Add new card</button>
<div *ngIf="finishAccountSetupAlert && setupPage">
    <p style="color: red">Please finish setting up your account before attempting to add a card</p>
</div>
