<prism-header></prism-header>

<div class="main-wrapper">
    <div class="sidebar-wrapper">
        <prism-sidebar></prism-sidebar>
    </div>

    <div class="content-wrapper">
        <router-outlet></router-outlet>
    </div>
</div>

