import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';

import {ContractorService, DialogService, SnackbarService} from '../../../services';

@Component({
    selector: 'prism-request-contractor',
    templateUrl: './request-contractor.component.html',
    styleUrls: ['./request-contractor.component.scss']
})
export class RequestContractorComponent {

    requestContractorForm = this.formBuilder.group({
        firstName: new UntypedFormControl('', Validators.required),
        lastName: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', Validators.required)
    });
    loadingRequest = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private dialogService: DialogService,
        private snackbarService: SnackbarService,
        private contractorService: ContractorService) {
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }

    sendContractorRequest() {
        this.loadingRequest = true;
        this.requestContractorForm.disable();
        const contractorFirstName = this.requestContractorForm.get('firstName').value;
        const contractorLastName = this.requestContractorForm.get('lastName').value;
        const contractorEmail = this.requestContractorForm.get('email').value;

        if (!!contractorFirstName && !!contractorLastName) {
            this.contractorService.requestContractor({firstName: contractorFirstName, lastName: contractorLastName, contractorEmail})
                .then(() => {
                    this.loadingRequest = false;
                    this.closeDialog();
                    this.snackbarService.showSnackbar('Your request has been submitted!');
                })
                .catch(err => {
                    this.loadingRequest = false;
                    this.requestContractorForm.enable();
                    console.error('Error submitting contractor request:', err);
                    this.snackbarService.handleError('There was an error submitting your request.');
                });
        } else {
            this.requestContractorForm.enable();
            this.snackbarService.handleError('There seems to be some missing information in your contractor request form.');
        }
    }
}
