import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {AssetsService, DialogService, JobsService, ProductsService, SnackbarService} from '../../../services';
import {JobProduct, Service} from '../../../models';
import {Observable} from 'rxjs';
import {ServicesService} from '../../../services/services.service';
import {UploadService} from '../../../services/upload.service';
import {AssetDisplayComponent} from '../asset-display/asset-display.component';
import {Router} from '@angular/router';
import {BriefModalComponent} from '../brief-modal/brief-modal.component';
import moment from 'moment';
import {ServiceBriefDialogComponent} from '../../service-brief/service-brief-dialog/service-brief-dialog.component';
import {ServiceEnum} from '../../../enums';

@Component({
    selector: 'prism-job-detail-table',
    templateUrl: './job-detail-table.component.html',
    styleUrls: ['./job-detail-table.component.scss']
})
export class JobDetailTableComponent implements OnInit {
    jobProductsLoading$ = this.productsService.areJobProductsLoading$;
    putJobLoading$: Observable<boolean> = this.jobsService.isPutJobLoading$;
    paginator: MatPaginator;
    servicesList = new MatTableDataSource<Service>([]);
    columnHeaders = ['id', 'product', 'created-at', 'status', 'brief', 'assets', 'album', 'edit'];
    uploadingStatusSet$: any = this.uploadService.uploadingStatusSet$;
    tableSizeData = this.jobsService.jobDetailsTableSize;
    paginatorControl = {
        length: this.servicesList.data.length,
        pageSize: this.tableSizeData,
        pageSizeOptions: [10, 20, 40, 50, 100]
    };
    productsCollectionForServiceEdit: any[];
    serviceStatuses$: Observable<any> = this.jobsService.jobServicesStatuses$;
    loadingBriefs = {};
    serviceTicketUpdate: Observable<{ [serviceId: string]: string }>;

    @Input()
    set products(products: JobProduct[]) {
        if (!!products) {
            this.productsCollectionForServiceEdit = products;
            this.servicesList.data = products.reduce((acc, curr: any) => {
                const services: Service[] = Object.values(curr.services);
                services.forEach(service => {
                    service.ProductName = curr.ProductName;
                    service.jobId = curr.jobId;
                    service.productId = curr.id;
                });
                return acc.concat(services);
            }, []);
        }
    }

    @Output()
    openJobAlbum = new EventEmitter<JobProduct>();

    @Output()
    openServiceEdit = new EventEmitter<{ service: Service, product: JobProduct }>();

    @ViewChild(MatPaginator, {static: true}) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    setDataSourceAttributes(): void {
        this.servicesList.paginator = this.paginator;
    }

    constructor(private productsService: ProductsService,
                private dialogService: DialogService,
                private uploadService: UploadService,
                private snackbarService: SnackbarService,
                private servicesService: ServicesService,
                private assetsService: AssetsService,
                private router: Router,
                private jobsService: JobsService) {
        this.serviceTicketUpdate = this.servicesService.serviceTicketUpdated$();
    }

    ngOnInit() {
        this.servicesService.serviceTicketUpdated$().subscribe((servicesTicketId) => {
            Object.entries(servicesTicketId).forEach(([serviceId, ticketId]) => {
                const index = this.servicesList.data.findIndex((service: Service) => {
                    return service.id === serviceId;
                });

                if (index > -1 && (!this.servicesList.data[index].ticketId || this.servicesList.data[index].ticketId === 'placeholder_id') ) {
                    this.servicesList.data[index].ticketId = ticketId;
                }
            });
        });
    }

    emitProductForAlbum(service: JobProduct): void {
        this.router.navigate(['/home', 'jobs', service.jobId, 'album']);
    }

    editService(service: Service): void {
        const product = this.productsCollectionForServiceEdit.find(product => product.id === service.productId);
        this.openServiceEdit.emit({service, product});
    }

    openRawAssetDialog(service: Service): void {
        const {jobId, productId, id: serviceId, ProductName, version} = service;
        this.assetsService.setProductNameToDisplay = service.ProductName;
        this.dialogService.openDialog(AssetDisplayComponent, {jobId, productId, serviceId, version, ProductName}, {width: '600px'});
    }

    openBriefs(service: Service): void {
        if (service.SNAKE_CASE !== ServiceEnum.IMAGE_PROCESSING && !service.ticketId && service.version === 'v2') {
            this.dialogService.openDialog(ServiceBriefDialogComponent, {service, fileCount: 0}, {
                panelClass: 'form-upload-dialog',
            });
        } else {
            this.loadingBriefs[service.id] = true;
            this.loadingBriefs['any'] = true;
            this.servicesService.getBriefs(service.id)
                .subscribe(
                    briefs => {
                        this.dialogService.openDialog(BriefModalComponent, {briefs}, {
                            autoFocus: false,
                            maxWidth: '832px'
                        });
                    },
                    e => {
                        console.error('Could not get service Briefs', e);
                        this.snackbarService.handleError(e);
                        return e;
                    },
                    () => {
                        this.loadingBriefs[service.id] = false;
                        this.loadingBriefs['any'] = false;
                    }
                );
        }
    }

    toDate(timestamp: number): string {
        return moment(timestamp).format('YYYY/MM/DD');
    }

    paginate(event: PageEvent): void {
        let {pageSize} = event;
        this.jobsService.updateJobsDetailsTableSize(pageSize);
    }
}
