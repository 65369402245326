import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {DialogService, InvoicesService} from '../../../services';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Response} from '../../../models';
import moment from 'moment';
import {Moment} from 'moment';
import {saveAs} from 'file-saver';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';

const CUSTOM_DATE_FORMATS = {
    display: {
        dateInput: 'YYYY/M/D',
        monthYearLabel: 'YYYY/M/D',
    }
};

@Component({
    selector: 'prism-invoices-csv-fetch',
    templateUrl: './invoices-csv-fetch.component.html',
    styleUrls: ['./invoices-csv-fetch.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}
    ]
})
export class InvoicesCsvFetchComponent implements AfterViewInit, OnDestroy {
    currentDate: string = moment().format();
    fetchingCSVData: boolean = false;
    S;
    startDateSelected: string = null;
    endDateSelected: string = this.currentDate;

    subDestroyer$: Subject<void> = new Subject<void>();

    @ViewChild('startDatePicker', {static: true}) startDatePicker: MatDatepicker<Moment>;
    @ViewChild('endDatePicker', {static: true}) endDatePicker: MatDatepicker<Moment>;

    constructor(private dialogService: DialogService, private invoicesService: InvoicesService) {
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.subDestroyer$.next();
    }

    fetchInvoicesCSVData(fetchPeriod: boolean = false) {
        this.fetchingCSVData = true;
        let startDateFormattedForXero, endDateFormattedForXero;

        if (fetchPeriod) {
            startDateFormattedForXero = moment(this.startDateSelected).format('YYYY, M, D');
            endDateFormattedForXero = moment(this.endDateSelected).format('YYYY, M, D');
        }

        this.invoicesService.fetchInvoicesCSVDataFromXero(startDateFormattedForXero, endDateFormattedForXero)
            .pipe(
                takeUntil(this.subDestroyer$)
            )
            .subscribe(
                (invoiceDataResponse: Response) => {
                    const fileData = invoiceDataResponse.data;
                    var binaryData = [];
                    binaryData.push(fileData);
                    const objectUrl = window.URL.createObjectURL(new Blob(binaryData));
                    saveAs(objectUrl, 'invoices-report.csv');
                    this.fetchingCSVData = false;
                }
            );
    }

    closeModal() {
        this.dialogService.closeAllDialogs();
    }
}
