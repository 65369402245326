export const environmentShared = {
    version: '2408.1',
    updateDate: '22/08/2024',
    apiKey: '',
    baseUrl: 'https://member.bwrm.com',
    environmentName: 'LIVE',
    locationSearchBounds: {
        sw: {lat: -34.53691113562117, lng: 18.04938470973343},
        ne: {lat: -33.51257692467331, lng: 19.33478510035843}
    },
    apiBaseUrl: 'https://europe-west1-live-bwrm-prism.cloudfunctions.net',
    lastVisitedAddressKey: 'lastAddress',
    google_forms: {
        video: 'https://docs.google.com/forms/d/e/1FAIpQLSeFFKwkmyfHQShUf149I6I9XJnc-wwZ0yiHnL2ep9_5o-xgRg/viewform',
        floorplan: 'https://docs.google.com/forms/d/e/1FAIpQLSe7-h1EZoG3vCVvkxoyBnocEzAthc_xA5oOeeK6e8DIE_Fehg/formResponse',
        three_dimentional_services: 'https://docs.google.com/forms/d/14FKKMvq9UX_soS6ZwzDFeHnypNLwVnQMgnINzVhwpyg/edit?usp=sharing'
    },
    assetsServiceUrl: 'https://assets.api.bwrm.com/api/v1/member-assets'
};
