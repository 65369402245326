import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
    selector: 'prism-account-page',
    templateUrl: './account-page.component.html',
    styleUrls: ['./account-page.component.scss']
})
export class AccountPageComponent implements OnInit, OnDestroy {
    stripeComponent: boolean;
    toggleSelected: 'personal' | 'payment';
    selectedCard: { number: string, expires: string, selected: boolean };
    destroySubs$: Subject<void>;

    constructor() {
        this.stripeComponent = false;
        this.toggleSelected = 'personal';
        this.selectedCard = {number: '**** **** **** 7343', expires: '02/22', selected: true};
        this.destroySubs$ = new Subject<void>();
    }

    ngOnInit() {
    }

    toggleAccountPageView(selection: 'personal' | 'payment') {
        this.toggleSelected = selection;
    }

    ngOnDestroy() {
        this.destroySubs$.next();
    }
}
