<h1 class="page-title">Agencies</h1>

<div class="content-wrapper table-wrapper agencies-table-wrapper">
    <div class="toolbar table-toolbar agencies-table-toolbar">
        <div class="toolbar-left table-toolbar-left">
            <div class="active-toggle-field">
                <mat-button-toggle-group name="agencyStatus" [value]="activeAgenciesFilter">
                    <mat-button-toggle [value]="true" (click)="showActiveAgencies(true)">Active</mat-button-toggle>
                    <mat-button-toggle [value]="false" (click)="showActiveAgencies(false)">Archived</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="toolbar-right table-toolbar-right">
            <button class="btn btn-red" mat-raised-button [disabled]="!selectedAgencyIds.length"
                    (click)="activeAgenciesFilter ? updateAgenciesActiveStatus(false) : updateAgenciesActiveStatus(true)">{{ activeAgenciesFilter ? 'Archive' : 'Activate' }}
                Selected
            </button>
            <button class="btn btn-green" mat-raised-button (click)="displayAgencyModal()">New Agency</button>
        </div>
    </div>
    <prism-agencies-table #agenciesTable [showActive]="activeAgenciesFilter"
                          (selectionUpdated)="onSelectionUpdated($event)"
                          (rowClicked)="onAgencyClicked($event)">
    </prism-agencies-table>
</div>