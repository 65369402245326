import {Component, OnInit} from '@angular/core';
import {AssetsService, DialogService, JobsService, SnackbarService} from '../../../services';
import {first} from 'rxjs/operators';
import {Response} from '../../../models';
import {DOWNLOAD_SIZE_LIMIT} from '../../../enums';

@Component({
    selector: 'prism-raw-display',
    templateUrl: './asset-display.component.html',
    styleUrls: ['./asset-display.component.scss']
})
export class AssetDisplayComponent implements OnInit {
    downloadBundles = {};
    bundleSizes = {};
    ProductName: string;
    loading = true;
    noBundlesAvailable = false;
    downloadAllDisabled = false;
    version: 'v1' | 'v2';

    constructor(private assetsService: AssetsService, private dialogService: DialogService, private jobsService: JobsService, private snackbarService: SnackbarService) {
    }

    ngOnInit() {
        const {serviceId, version, ProductName} = this.dialogService.inputData;
        this.version = version || 'v1';
        this.ProductName = ProductName;
        this.assetsService.getRawDownloadData(serviceId, version)
            .pipe(
                first(d => !!d)
            )
            .subscribe((response: Response) => {
                this.downloadBundles = response.data;
                this.bundleSizes = Object.entries(this.downloadBundles).reduce((acc, [timestamp, downloadBatch]) => {
                    (<any[]>downloadBatch).forEach(({size}) => {
                        acc.all += size;
                        if (!acc[timestamp]) {
                            acc[timestamp] = 0;
                        }
                        acc[timestamp] += size;
                    });

                    return acc;
                }, {all: 0});

                this.downloadAllDisabled = this.bundleSizes['all'] > DOWNLOAD_SIZE_LIMIT;
                this.loading = false;
                this.noBundlesAvailable = Object.keys(this.downloadBundles).length === 0;
            });
    }

    download(signedUrl) {
        window.location.href = signedUrl;
    }

    downloadBundle(key) {
        let bucketPathNames = this.downloadBundles[key].map((file) => {
            return file.path;
        });

        let {memberProfileUid, id, address} = this.jobsService.selectedJob;
        this.assetsService.sendRawDownloadRequest(address, `job_${id}`, bucketPathNames, memberProfileUid, id, this.version);
        this.snackbarService.showDismissibleSnackbar();
    }

    downloadAllBundles() {
        let bucketPathNames = [];
        let {memberProfileUid, id, address} = this.jobsService.selectedJob;

        Object.keys(this.downloadBundles).forEach((key) => {
            this.downloadBundles[key].forEach((file) => {
                bucketPathNames.push(file.path);
            });
        });

        this.assetsService.sendRawDownloadRequest(address, `job_${id}`, bucketPathNames, memberProfileUid, id, this.version);
        this.snackbarService.showDismissibleSnackbar();
    }

    closeAssetsDialog() {
        this.dialogService.closeDialog();
    }

}
