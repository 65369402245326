<div class="mat-dialog-container">
    <h1 mat-dialog-title>Download Options</h1>

    <mat-dialog-content *ngIf="hasDownloadOptions">
        <div class="download-inner asset-filter-group">
            <h4 class="mat-dialog-sub-header">Select</h4>
            <mat-checkbox color="primary"
                          [checked]="selectedDownloadAssetTypes.visible && hasVisibleOptions"
                          [disabled]="!hasVisibleOptions"
                          (change)="changeDownloadTypeSelection('visible', $event.checked)">Visible
            </mat-checkbox>
            <mat-checkbox color="primary"
                          [checked]="selectedDownloadAssetTypes.hidden && hasHiddenOptions"
                          [disabled]="!hasHiddenOptions"
                          (change)="changeDownloadTypeSelection('hidden', $event.checked)">Hidden
            </mat-checkbox>
            <mat-checkbox color="primary"
                          [checked]="selectedDownloadAssetTypes.rejected && hasRejectedOptions"
                          [disabled]="!hasRejectedOptions"
                          (change)="changeDownloadTypeSelection('rejected', $event.checked)">Rejected
            </mat-checkbox>
        </div>
        <hr>
        <div class="download-inner asset-format-group">
            <h4 class="mat-dialog-sub-header">Format</h4>
            <mat-checkbox color="primary"
                          [checked]="selectedDownloadFormats.print"
                          [disabled]="!hasPrintOptions"
                          (change)="changeDownloadFormatSelection('print', $event.checked)">Print
            </mat-checkbox>
            <mat-checkbox color="primary"
                          [checked]="selectedDownloadFormats.web"
                          [disabled]="!hasWebOptions"
                          (change)="changeDownloadFormatSelection('web', $event.checked)">Web
            </mat-checkbox>
            <mat-checkbox color="primary"
                          [checked]="selectedDownloadFormats.watermark"
                          [disabled]="!hasWatermarkOptions"
                          (change)="changeDownloadFormatSelection('watermark', $event.checked)">Web+Watermark
            </mat-checkbox>
        </div>
    </mat-dialog-content>

    <mat-dialog-content class="zip-processing-display-container" *ngIf="!hasDownloadOptions">
        <h5 class="mat-dialog-title">Start download</h5>
        <div class="download-inner-no-options">
            Start processing download?
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button class="btn btn-red cancel-btn"
                mat-button (click)="closeDialog()">Cancel
        </button>
        <button class="btn btn-green" mat-raised-button [disabled]="downloadDisabled" (click)="requestDownload()">
            Download
        </button>
    </mat-dialog-actions>
</div>

