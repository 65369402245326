import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {
    AgencyService,
    AgentService,
    ContractorService,
    DialogService,
    JobsService,
    SnackbarService,
    MapsAPILoader
} from '../../services';
import {
    MinimalAgency,
    MinimalAgent,
    Response
} from '../../models';
import {debounceTime, first, tap, takeUntil, filter} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Subject, Subscription} from 'rxjs';
import {NO_COMMA_REGEX, NUMBER_REGEX, refreshJobs$} from '../../helpers';
import {isString} from 'lodash';
import {HttpClient} from '@angular/common/http';
import '@angular/google-maps';
import isEqual from 'lodash/isEqual';

const parser = new DOMParser();

declare var google: any;

@Component({
    selector: 'prism-add-edit-job',
    templateUrl: './add-edit-job.component.html',
    styleUrls: ['./add-edit-job.component.scss']
})
export class AddEditJobComponent implements OnInit, OnDestroy, AfterViewInit {
    addressValue;
    cityValue;
    streetValue;
    stateValue;
    postcodeValue;
    countryValue;
    agentValue;
    agencyValue;
    contractorValue;

    editJobBool$: Observable<boolean>;
    isAddressVerifying: BehaviorSubject<boolean>;
    subDestroyer$: Subject<void>;
    manualAddressDestroyer$: Subject<void>;
    editJobSub: Subscription;
    agencies;
    contractors;
    agents;
    loadingAgents: boolean;
    manualAddressSelected: boolean;

    agencyOptions: MinimalAgency[];
    agentsOptions: MinimalAgent[];
    contractorOptions: BehaviorSubject<any>;
    agencySelected: BehaviorSubject<string>;

    autocompleteService: google.maps.places.AutocompleteService;
    geocoder: google.maps.Geocoder;
    map: google.maps.Map;
    mapListener;
    marker: google.maps.marker.AdvancedMarkerElement;
    markerListener;
    pinSvgString: string;
    markerSvg;

    addressAutoCompleteOptions: BehaviorSubject<AutoCompletePlace[]>;
    autoAddressValidSelectionMade: boolean;
    lastAutoSelectionMade: string;
    showProcessingLoader: Observable<boolean>;

    newAddressForProcessor: string;
    currentAddressForProcessor: string;

    countryCodes: string[];
    geocoderAddress: GeocoderAddress;
    addEditJobForm: FormGroup;

    constructor(
        private jobsService: JobsService,
        private dialogService: DialogService,
        private agencyService: AgencyService,
        private contractorsService: ContractorService,
        private agentService: AgentService,
        private snackbarService: SnackbarService,
        private mapsAPILoader: MapsAPILoader
    ) {
        this.countryCodes = ['au', 'nz'];
        this.geocoderAddress = {};
        this.showProcessingLoader = this.jobsService.areJobsLoading$.pipe(debounceTime(0));
        this.agencyOptions = [];
        this.agentsOptions = [];
        this.loadingAgents = true;
        this.manualAddressSelected = false;
        this.agencySelected = new BehaviorSubject<string>(null);
        this.addressAutoCompleteOptions = new BehaviorSubject<AutoCompletePlace[]>([]);
        this.contractorOptions = new BehaviorSubject<any[]>(null);
        this.isAddressVerifying = new BehaviorSubject<boolean>(false);
        this.subDestroyer$ = new Subject<void>();
        this.manualAddressDestroyer$ = new Subject<void>();
        this.addEditJobForm = new FormGroup({
            addressControl: new FormControl([null, Validators.required]),
            streetControl: new FormControl(null),
            cityControl: new FormControl(null),
            stateControl: new FormControl(null),
            countryControl: new FormControl(null),
            postcodeControl: new FormControl(null),
            contractorControl: new FormControl(null),
            agencyControl: new FormControl(null),
            agentControl: new FormControl(null),
        });
        this.editJobBool$ = this.jobsService.editJob$.pipe(
            tap(editing => editing && (this.autoAddressValidSelectionMade = true))
        );
        this.pinSvgString = '<svg width="28" height="35" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '    <path d="M10 27H18L14 35L10 27Z" fill="#2990B7"/>\n' +
            '    <circle cx="14" cy="14" r="14" fill="#2990B7"/>\n' +
            '    <circle cx="14" cy="14" r="4" fill="white"/>\n' +
            '</svg>';
        this.markerSvg =
            parser.parseFromString(this.pinSvgString, 'image/svg+xml').documentElement;

    }

    ngOnInit() {
        if (this.jobsService.selectedJob) {
            let {address, addressForProcessor} = this.jobsService.selectedJob;
            this.currentAddressForProcessor = addressForProcessor;
            this.addressValue = address;
            this.addressControl.setValue(address);
        }
        this.initJobOptions();
        this.addressControl.setValidators(Validators.required);
        this.addressControl.updateValueAndValidity();
    }

    ngAfterViewInit() {
        this.initMapsApi();
    }

    onAgencySelect({id}: { id: string }) {
        this.agentControl.reset('');

        if (id) {
            if (!!this.agents) {
                this.agentsOptions = this.agents.filter(agentEntry => agentEntry.agencyId === id);
                this.loadingAgents = false;
            }
        } else {
            this.agentControl.reset('');
            this.loadingAgents = false;
        }
    }

    deactivateManualAddressInput() {
        this.autoAddressValidSelectionMade = false;
        this.manualAddressDestroyer$.next();
        this.manualAddressSelected = false;

        this.addressControl.setValue(this.jobsService.selectedJob ? this.jobsService.selectedJob.address : null);
        this.addressValue = !!this.jobsService.selectedJob ? this.jobsService.selectedJob.address : '';
        this.addressControl.markAsPristine();
        this.addressControl.setValidators(Validators.required);
        this.addressControl.markAsPristine();
        this.addressControl.markAsUntouched();
        this.addressControl.updateValueAndValidity();

        this.streetControl.clearValidators();
        this.streetControl.updateValueAndValidity({onlySelf: true});

        this.cityControl.clearValidators();
        this.cityControl.updateValueAndValidity({onlySelf: true});

        this.stateControl.clearValidators();
        this.stateControl.updateValueAndValidity({onlySelf: true});

        this.countryControl.clearValidators();
        this.countryControl.updateValueAndValidity({onlySelf: true});

        this.postcodeControl.clearValidators();
        this.postcodeControl.updateValueAndValidity({onlySelf: true});

        if (!!this.lastAutoSelectionMade) {
            this.addEditJobForm.markAsDirty();
            this.autoAddressValidSelectionMade = true;
        }

        if (
            this.addressControl.pristine &&
            this.contractorControl.pristine &&
            this.agencyControl.pristine &&
            this.agentControl.pristine
        ) {
            this.addEditJobForm.markAsPristine({onlySelf: true});
        }
        this.manualAddressSelected = false;
        this.importMarkerLibrary().then(() => {
            this.createMap();
        }).catch((e) => {
            console.error(e);
        });
    }

    initJobOptions() {
        if (!!this.jobsService.selectedJob) {
            this.agencyValue = this.jobsService.selectedJob.agency;
            this.agentValue = this.jobsService.selectedJob.agent;
            this.contractorValue = this.jobsService.selectedJob.contractor;

            if (this.contractorValue) {
                this.contractorControl.patchValue(this.contractorValue);
            } else {
                this.agentControl.patchValue('');
            }
        }


        const agencyNames$ = this.agencyService.getAgencyNames();
        const contractorsNames$ = this.contractorsService.getContractorNames();
        const agentNames$ = this.agentService.getAgentNames();

        combineLatest([agencyNames$, contractorsNames$, agentNames$])
            .pipe(
                takeUntil(this.subDestroyer$)
            )
            .subscribe(([agencies, contractors, agents]) => {
                this.agents = agents;
                if (this.agencyValue) {
                    const jobAgency = agencies.find(agencyEntry => agencyEntry.id === this.agencyValue);
                    this.agencyControl.patchValue(jobAgency);

                    if (this.agentValue) {
                        const agentFound = agents.find(agentEntry => agentEntry.id === this.agentValue);
                        this.agentControl.patchValue(agentFound);
                    }

                    this.agencyOptions = agencies;
                    this.agentsOptions = agents.filter(agentEntry => agentEntry.agencyId === this.agencyValue);

                } else {
                    this.loadingAgents = false;
                    this.agencyControl.patchValue('');
                    this.agentControl.patchValue('');
                    this.agencyOptions = agencies;
                }

                if (this.contractorValue) {
                    this.contractorControl.patchValue(this.contractorValue);
                } else {
                    this.contractorControl.patchValue('');
                }
                this.contractorOptions.next(contractors);
            });
    }

    async activateManualAddressForm() {
        return new Promise((resolve) => {
            setTimeout(() => {
                this.addressControl.clearValidators();
                this.addressControl.updateValueAndValidity({onlySelf: true});

                this.streetControl.setValidators([
                    Validators.required,
                    Validators.pattern(NO_COMMA_REGEX)
                ]);
                this.streetControl.markAsPristine({onlySelf: true});
                this.streetControl.updateValueAndValidity();

                this.cityControl.setValidators([
                    Validators.required,
                    Validators.pattern(NO_COMMA_REGEX)
                ]);
                this.cityControl.markAsPristine({onlySelf: true});
                this.cityControl.updateValueAndValidity();

                this.stateControl.setValidators([
                    Validators.required,
                    Validators.pattern(NO_COMMA_REGEX)
                ]);
                this.stateControl.markAsPristine({onlySelf: true});
                this.stateControl.updateValueAndValidity();

                this.countryControl.setValidators([
                    Validators.required,
                    Validators.pattern(NO_COMMA_REGEX)
                ]);
                this.countryControl.markAsPristine({onlySelf: true});
                this.countryControl.updateValueAndValidity();

                this.postcodeControl.setValidators([
                    Validators.required,
                    Validators.pattern(NUMBER_REGEX)
                ]);
                this.postcodeControl.markAsPristine({onlySelf: true});
                this.postcodeControl.updateValueAndValidity();

                this.addEditJobForm.updateValueAndValidity();

                resolve(null);
            }, 0);
        });
    }

    activateManualAddressInput() {
        this.manualAddressSelected = true;
        this.autoAddressValidSelectionMade = true;
        this.newAddressForProcessor = null;

        this.activateManualAddressForm().then(() => {
            this.addEditJobForm.updateValueAndValidity({emitEvent: true});
            if (this.jobsService.selectedJob) {
                this.streetControl.valueChanges.pipe(takeUntil(this.manualAddressDestroyer$)).subscribe((value) => {
                    this.isEquivalent(this.streetValue, value, this.streetControl);
                });
                this.cityControl.valueChanges.pipe(takeUntil(this.manualAddressDestroyer$)).subscribe((value) => {
                    this.isEquivalent(this.cityValue, value, this.cityControl);
                });
                this.stateControl.valueChanges.pipe(takeUntil(this.manualAddressDestroyer$)).subscribe((value) => {
                    this.isEquivalent(this.stateValue, value, this.stateControl);
                });
                this.countryControl.valueChanges.pipe(takeUntil(this.manualAddressDestroyer$)).subscribe((value) => {
                    this.isEquivalent(this.countryValue, value, this.countryControl);
                });
                this.postcodeControl.valueChanges.pipe(takeUntil(this.manualAddressDestroyer$)).subscribe((value) => {
                    this.isEquivalent(this.postcodeValue, value, this.postcodeControl);
                });
            }

            if (this.streetControl.pristine &&
                this.cityControl.pristine &&
                this.stateControl.pristine &&
                this.countryControl.pristine &&
                this.postcodeControl.pristine &&
                this.contractorControl.pristine &&
                this.agencyControl.pristine &&
                this.agentControl.pristine
            ) {
                this.addEditJobForm.markAsPristine({onlySelf: true});
            }
        });
    }

    verifyGeocoderAddressResult = (resultList: google.maps.GeocoderResult[]) => {
        this.geocoderAddress = {};
        if (resultList && Array.isArray(resultList) && resultList.length) {
            const matchingResult = this.verifyGeocoderResults(resultList);
            if (matchingResult) {
                this.addMarker(matchingResult.geometry.location);

                matchingResult.address_components.forEach((component) => {
                    switch (component.types[0]) {
                        case 'street_number': {
                            this.geocoderAddress[`street_number`] = `${component.long_name}`;
                            break;
                        }
                        case 'route': {
                            this.geocoderAddress[`route`] = `${component.long_name}`;
                            break;
                        }
                        case 'administrative_area_level_1': {
                            this.geocoderAddress[`administrative_area_level_1`] = `${component.short_name}`;
                            break;
                        }
                        case 'postal_code': {
                            this.geocoderAddress[`postal_code`] = `${component.long_name}`;
                            break;
                        }
                    }
                });
                if (!isString(this.geocoderAddress[`postal_code`])) {
                    this.fetchPostcode(matchingResult.geometry.location);
                    return;

                } else {
                    this.newAddressForProcessor = this.formatProcessorAddress(this.geocoderAddress);
                    this.isAddressVerifying.next(false);
                    return;
                }
            }
            this.isAddressVerifying.next(false);
            return;
        } else {
            this.isAddressVerifying.next(false);
        }
    };

    verifyGeocoderLocationPostcode = (resultList: google.maps.GeocoderResult[]) => {
        if (resultList && Array.isArray(resultList) && resultList.length) {
            const matchingResult = this.verifyGeocoderResults(resultList);

            if (matchingResult) {
                matchingResult.address_components.forEach((component) => {
                    switch (component.types[0]) {
                        case 'route': {
                            this.geocoderAddress[`route`] = !!isString(this.geocoderAddress[`route`]) ?
                                this.geocoderAddress[`route`] :
                                `${component.long_name}`;
                            break;
                        }
                        case 'administrative_area_level_1': {
                            this.geocoderAddress[`administrative_area_level_1`] = !!isString(this.geocoderAddress[`administrative_area_level_1`]) ?
                                this.geocoderAddress[`administrative_area_level_1`] :
                                `${component.short_name}`;

                            break;
                        }
                        case 'postal_code': {
                            this.geocoderAddress[`postal_code`] = !!isString(this.geocoderAddress[`postal_code`]) ?
                                this.geocoderAddress[`postal_code`] :
                                `${component.long_name}`;
                            break;
                        }
                    }
                });

                this.newAddressForProcessor = this.formatProcessorAddress(this.geocoderAddress);
                this.isAddressVerifying.next(false);
                return;
            }
        }
        this.isAddressVerifying.next(false);
        return;
    };

    handleAddressAutocompleteResponse = placeList => {
        if (placeList && Array.isArray(placeList) && placeList.length) {
            this.addressAutoCompleteOptions.next(
                placeList.map((place) => {
                    return {
                        placeId: place.place_id,
                        address: place.description
                    };
                })
            );
        }
    };

    initMapsApi() {
        this.mapsAPILoader.load()
            .pipe(
                takeUntil(this.subDestroyer$)
            )
            .subscribe(() => {
                this.importMarkerLibrary()
                    .then(() => {
                        this.createMap();
                    })
                    .catch((e) => {
                        console.error(e);
                    });
                this.autocompleteService = new google.maps.places.AutocompleteService();
                this.geocoder = new google.maps.Geocoder();
                this.addressControl
                    .valueChanges
                    .pipe(
                        takeUntil(this.subDestroyer$),
                        debounceTime(100),
                        filter((input) => {
                            if (this.jobsService.selectedJob) {
                                let {address} = this.jobsService.selectedJob;
                                return !isEqual(address, input);
                            }
                            return true;
                        })
                    )
                    .subscribe(val => {
                        this.isAddressVerifying.next(!this.manualAddressSelected);
                        if (val) {
                            this.autocompleteService.getPlacePredictions(
                                {
                                    types: ['address'],
                                    componentRestrictions: {country: this.countryCodes},
                                    input: val
                                },
                                this.handleAddressAutocompleteResponse
                            );
                        } else {
                            this.isAddressVerifying.next(false);
                        }
                    });
            });
    }

    async importMarkerLibrary(): Promise<void> {
        const {AdvancedMarkerElement} = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary;
    }

    createMap() {
        this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
            center: {lat: -34.397, lng: 150.644},
            zoom: 3,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapId: '3f6043443c130a04'
        });

        this.mapListener = google.maps.event.addListener(this.map, 'click', (event) => {
            this.geocodeMapLocation(event.latLng);
        });
    }

    addMarker(position) {
        if (this.marker) {
            this.marker.removeEventListener('dragend', this.markerListener);
            this.marker.map = null;
        }
        this.marker = new google.maps.marker.AdvancedMarkerElement({
            position: position,
            map: this.map,
            content: this.markerSvg,
            gmpDraggable: true,
            title: 'A marker using a custom SVG image.'
        });

        if (this.map.getZoom() <= 17) {
            this.map.setZoom(17);
        }
        this.map.panTo(position);

        this.markerListener = this.marker.addListener('dragend', (event) => {
            this.geocodeMapLocation(event.latLng);
        });
    }

    validateCountryCode(response) {
        const {address_components: addressComponents}: { address_components: any[] } = response;
        return addressComponents.some((addressComponent) => {
            if (!!addressComponent.types.includes('country')) {
                return this.countryCodes.includes(addressComponent.short_name.toLowerCase());
            }
            return false;
        });
    }

    geocodeMapLocation(location) {
        this.geocoder
            .geocode({location: location})
            .then((response) => {
                if (response.results.length > 0) {
                    if (this.validateCountryCode(response.results[0])) {
                        this.addEditJobForm.markAsDirty();
                        this.addressControl.setValue(response.results[0].formatted_address);
                        this.addressControl.updateValueAndValidity();
                        this.geocoder.geocode(
                            {
                                address: response.results[0].formatted_address,
                            },
                            this.verifyGeocoderAddressResult
                        );
                        this.lastAutoSelectionMade = response.results[0].formatted_address;
                        this.autoAddressValidSelectionMade = !!response.results[0].formatted_address;
                    } else {
                        this.snackbarService.showSnackbar('Please select an valid location.', 2000);
                    }
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }

    autoAddressSelectionMade(option: AutoCompletePlace) {
        this.geocoder.geocode(
            {
                address: option.address,
            },
            this.verifyGeocoderAddressResult
        );
        this.lastAutoSelectionMade = option.address;
        this.autoAddressValidSelectionMade = !!option;
    }

    manuallyTypedIntoAutoAddress(event: Event) {
        const inputValue = (event.target as HTMLInputElement).value;
        this.autoAddressValidSelectionMade = inputValue === this.lastAutoSelectionMade;
    }

    fetchPostcode(location: google.maps.LatLng): void {
        this.geocoder.geocode(
            {
                location: location
            },
            this.verifyGeocoderLocationPostcode
        );
    }

    verifyGeocoderResults(resultList: google.maps.GeocoderResult[]): google.maps.GeocoderResult {
        return resultList.find(result => {
            if ((result.address_components && result.address_components.length >= 3)) {
                return this.validateCountryCode(result);
            }
            return false;
        });
    }

    formatProcessorAddress(address: GeocoderAddress): string {
        let formattedAddress = '';
        let addressEntries = Object.entries(address);

        addressEntries.forEach(([key, val], index) => {
            if (isString(val)) {
                formattedAddress += ((index === addressEntries.length - 1) || (key === 'street_number')) ? `${val} ` : `${val}, `;
            }
        });
        return formattedAddress.trim();
    }

    saveJob() {
        this.editJobSub = this.editJobBool$.pipe(first()).subscribe(edit => {
            this.addEditJobForm.disable({emitEvent: false});

            if (edit) {
                this.editJob();
                this.snackbarService.showSnackbar('Your job is being updated!');
            } else {
                this.createJob();
                this.snackbarService.showSnackbar('Saving your new job!');
            }
            refreshJobs$.next();
        });
    }

    editJob() {
        let address;

        if (!this.manualAddressSelected) {
            address = this.addressControl.value;
        } else {
            address = `${this.streetControl.value}, ${this.cityControl.value}, ${this.stateControl.value}, ${this.postcodeControl.value}, ${this.countryControl.value}`;
            this.newAddressForProcessor = `${this.streetControl.value}, ${this.stateControl.value}, ${this.postcodeControl.value}`;
        }

        this.jobsService
            .putJob({
                address,
                addressForProcessor: !!this.newAddressForProcessor ? this.newAddressForProcessor : this.currentAddressForProcessor,
                contractor: this.contractorControl.value,
                agency: this.agencyControl.value,
                agent: this.agentControl.value
            })
            .then((jobUpdated: Response) => {
                this.jobsService.setSelectedJob({
                    ...jobUpdated.data
                });
                this.jobsService.switchAllJobLoadersOff();
                this.closeDialog();
            })
            .catch(err => {
                console.error('Could not update job', err);
                this.jobsService.switchAllJobLoadersOff();
                this.addEditJobForm.enable({emitEvent: false});
            });
    }

    createJob() {
        let address;

        if (!this.manualAddressSelected) {
            address = this.addressControl.value;
        } else {
            const streetString = `${this.streetControl.value}`;
            const cityString = `${this.cityControl.value}`;
            const stateString = `${this.stateControl.value}`;
            const countryString = `${this.countryControl.value}`;
            const postcodeString = `${this.postcodeControl.value}`;
            address = `${streetString}, ${cityString}, ${stateString}, ${postcodeString}, ${countryString}`;
            this.newAddressForProcessor = `${streetString}, ${stateString}, ${postcodeString}`;
        }

        this.jobsService
            .postJob({
                id: null,
                memberProfileUid: null,
                address,
                addressForProcessor: this.newAddressForProcessor,
                contractor: this.addEditJobForm.controls['contractorControl'].value,
                agency: this.addEditJobForm.controls['agencyControl'].value,
                agent: this.addEditJobForm.controls['agentControl'].value,
                status: 'No Products',
                products: []
            })
            .then(() => {
                this.closeDialog();
            })
            .catch(err => {
                console.error('JOB CREATION ERROR:', err);
                this.addEditJobForm.enable({emitEvent: false});
            });
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }

    compareContractors(c1: any, c2: any): boolean {
        return c1 && c2 ? c1.firstName === c2.firstName : c1 === c2;
    }

    compareAgencies(c1: any, c2: any): boolean {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }

    compareAgents(c1: any, c2: any): boolean {
        return c1 && c2 ? c1.firstName === c2.firstName : c1 === c2;
    }

    isEquivalent(initialValue, newValue, control) {
        if (isEqual(initialValue, newValue)) {
            control.markAsPristine();
        }
    }

    get agencyControl() {
        return this.addEditJobForm.get('agencyControl');
    }

    get agentControl() {
        return this.addEditJobForm.get('agentControl');
    }

    get contractorControl() {
        return this.addEditJobForm.get('contractorControl');
    }

    get addressControl() {
        return this.addEditJobForm.get('addressControl');
    }

    get streetControl() {
        return this.addEditJobForm.get('streetControl');
    }

    get cityControl() {
        return this.addEditJobForm.get('cityControl');
    }

    get stateControl() {
        return this.addEditJobForm.get('stateControl');
    }

    get countryControl() {
        return this.addEditJobForm.get('countryControl');
    }

    get postcodeControl() {
        return this.addEditJobForm.get('postcodeControl');
    }

    ngOnDestroy() {
        this.subDestroyer$.next();
        if (!!this.editJobSub) {
            this.editJobSub.unsubscribe();
        }
    }
}

interface GeocoderAddress {
    'street_number'?: string,
    'route'?: string,
    'administrative_area_level_1'?: string,
    'postal_code'?: string
}

interface AutoCompletePlace {
    'placeId': string,
    'address': string
}