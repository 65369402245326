<div class="terms-container">
    <div class="terms-container-top">
        <h5>
            Please read and accept our
            <strong>{{ termsAndConditionsType === 'platform' ? 'BWRM Platform Agreement' : 'BWRM Media Services Agreement' }}</strong>:
        </h5>
    </div>

    <mat-progress-bar class="tc-loader"
                      *ngIf="!((termsAndConditionsType === 'platform' ? platformPolicy$ : userPolicy$) | async)"
                      color="primary" mode="indeterminate"></mat-progress-bar>

    <div class="terms-container-inner"
         *ngIf="((termsAndConditionsType === 'platform' ? platformPolicy$ : userPolicy$) | async) as link">
        <iframe [src]="getSafeUrl(link)"
                width="595"
                height="841"
                class="agreement-pdf-frame"
                frameborder="0"
                title="terms"></iframe>

        <div class="terms-buttons">
            <button class="btn btn-red cancel-btn" mat-button (click)="openCancelPolicyDialog()"
                    [disabled]="disableAndShowLoader">Cancel
            </button>
            <button class="btn accept-btn loader" mat-raised-button
                    (click)="acceptPolicy()"
                    [disabled]="!((termsAndConditionsType === 'platform' ? platformPolicy$ : userPolicy$) | async) || disableAndShowLoader">
                Accept
                <ng-template [ngIf]="disableAndShowLoader">
                    <div class="lds-hourglass"></div>
                </ng-template>
            </button>
        </div>
    </div>
</div>
