import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'shorten'
})
export class ShortenPipe implements PipeTransform {

    transform(value: any, cutoffLength: number): any {
        if (value.length > cutoffLength) {
            return `${value.substr(0, cutoffLength)} ...`;
        }
        return value;
    }

}
