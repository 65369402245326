import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

import {SnackbarService, AuthenticationService} from '../../services';

@Component({
    selector: 'prism-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

    resetEmailSentMessage = 'A reset password e-mail has been sent to you';

    constructor(private formBuilder: UntypedFormBuilder,
                private authService: AuthenticationService,
                private snackBar: MatSnackBar,
                private snackbarService: SnackbarService,
                private router: Router) {
    }

    resetForm: UntypedFormGroup = this.formBuilder.group({
        resetEmail: ['', [Validators.email, Validators.required]]
    });

    onPasswordReset() {
        this.authService.resetPassword(this.resetForm.controls['resetEmail'].value)
            .then(() => {
                this.snackBar.open(this.resetEmailSentMessage, null, {
                    duration: 4000
                });
            })
            .catch(error => this.snackbarService.handleError(error.message));
    }

    navigateBack() {
        this.router.navigate(['/login']);
    }
}
