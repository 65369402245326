import {
    ChangeDetectorRef,
    Component,
    EventEmitter, HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import differenceBy from 'lodash/differenceBy';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {first, take, takeUntil} from 'rxjs/operators';
import {DEFAULT_WEB_SIZE} from '../../../helpers';
import {Agent, Job, Response, Service} from '../../../models';
import {
    AcceptRejectService,
    AssetsService,
    AuthenticationService,
    DialogService,
    JobsService,
    ProductsService,
    SnackbarService
} from '../../../services';
import {ServicesService} from '../../../services/services.service';
import {UploadService} from '../../../services/upload.service';
import {DownloadSelectionModalComponent} from '../../download-selection-modal/download-selection-modal.component';
import {ProductCarouselComponent} from '../../product-carousel/product-carousel.component';
import {FilterDownloadSelectionModalComponent} from '../../filter-download-selection-modal/filter-download-selection-modal.component';
import {FormUploadDialogComponent} from '../../form-upload-dialog/form-upload-dialog.component';
import {ServiceEnum} from '../../../enums';
import {ServiceBriefDialogComponent} from '../../service-brief/service-brief-dialog/service-brief-dialog.component';

@Component({
    selector: 'prism-product-bucket',
    templateUrl: './product-bucket.component.html',
    styleUrls: ['./product-bucket.component.scss']
})

export class ProductBucketComponent implements OnInit, OnDestroy {
    thumbsLoading = false;
    hidingInProgress = false;
    hidingServiceInProgress = false;
    selectAllDisplay = true;
    bucketProduct = null;
    dragActive = false;
    checkboxDisplayed = false;
    downloadShareAgencyData;
    displayedAssets = {};
    servicesDataForNameDisplay = {};
    serviceConfig: ServiceBucketConfiguration = {};
    productServices = {};
    hidingAssetsLoading = {};
    imageLoaded = {};
    hiddenFromShareServices = {};
    placeholders = {};
    assetSelectedCounted = {};
    fetchingAssetPaths = {};
    accordionExpanded: { [x: string]: boolean; };
    jobProductsLoading$: Observable<boolean> = this.productsService.areJobProductsLoading$;
    downloadProgress$: Observable<any> = this.assetsService.downloadProgress$;
    rejectionPending$: Observable<any> = this.acceptRejectService.rejectionPending$;
    serviceStatuses$: Observable<any> = this.jobsService.jobServicesStatuses$;
    jobDownloading$: Subscription;
    downloadOptionsObject: BehaviorSubject<any> = new BehaviorSubject<any>({});
    subDestroyer$ = new Subject<void>();
    selectedJob: Job = this.jobsService.selectedJob;
    dragIconId: number;
    dropTileId: number;
    dropTileThumbUUID: number;

    @ViewChildren('productName') names: QueryList<any>;

    @HostListener('document:click', ['$event']) clickedOutside() {
        for (const value of Object.values(this.serviceConfig)) {
            if (value['displayFilterMenu']) {
                value['displayFilterMenu'] = false;
            }
        }
    }

    @Input() fetchingDownloadData = false;

    @Input()
    set product(product) {
        this.bucketProduct = product;
        this.bucketProduct.services.forEach(s => {
            this.fetchingAssetPaths[s.id] = false;
            this.servicesDataForNameDisplay[s.id] = s.alternativeName;
            if (!this.serviceConfig[s.id]) {
                this.serviceConfig[s.id] = {
                    editable: false,
                    selectable: false,
                    count: 0,
                    hiddenCount: 0,
                    visibleCount: 0,
                    format: {
                        print: true,
                        web: false,
                        watermark: false
                    },
                    filter: {
                        hidden: false,
                        visible: true,
                        rejected: false
                    },
                    displayFilterMenu: false
                };
            }
            if (!this.productServices[s.id]) {
                this.productServices[s.id] = [];
                this.displayedAssets[s.id] = [];
            }
        });
    }

    @Input() job;

    @Input() jobDownloading: Observable<void>;

    @Input()
    set assetsRef(data) {
        if (!!data) {
            const incomingServices = Object.entries(data[this.bucketProduct.id] ?? {});
            incomingServices.forEach(([propName, value]: [propName: string, value: any]) => {
                const serviceId = propName.split('_')[0];

                if (!this.downloadOptionsObject.value[serviceId]) {
                    this.downloadOptionsObject.value[serviceId] = {
                        watermark: false,
                        web: false
                    };
                }
                if (!this.displayedAssets[serviceId]) {
                    this.displayedAssets[serviceId] = [];
                }

                if (propName.includes('_watermarkFileCount')) {
                    this.downloadOptionsObject.value[serviceId].watermark = value > 0;
                } else if (propName.includes('_webFileCount')) {
                    this.downloadOptionsObject.value[serviceId].web = value > 0;
                } else {
                    const assets = value;
                    const serviceDataFound = this.bucketProduct.services.find(s => s.id === serviceId);
                    const newAssets = differenceBy((<any[]>assets), this.productServices[serviceId], 'thumbUUID');
                    if (newAssets.length) {
                        this.thumbsLoading = true;
                    }
                    let displayedAssetsLength = this.displayedAssets[serviceId].length;
                    let displayedAssetsIndex = 0;
                    newAssets.forEach((asset) => {
                        if (!!this.productServices[serviceId]) {
                            this.uploadService.deactivateThumbPlaceholder(asset.thumbUUID, serviceId);
                            this.productServices[serviceId].push(asset);
                            if (this.serviceConfig[serviceId]) {
                                let allowVisibleAsset = this.serviceConfig[serviceId].filter.visible && !asset.hidden;
                                let allowHiddenAsset = this.serviceConfig[serviceId].filter.hidden && asset.hidden && !(asset.finalRejectReason || asset.savedRejectReason);
                                let allowRejectedAsset = this.serviceConfig[serviceId].filter.rejected && !!(asset.finalRejectReason || asset.savedRejectReason);
                                if (allowVisibleAsset || allowHiddenAsset || allowRejectedAsset) {
                                    asset.selected = false;
                                    asset.tempId = (displayedAssetsLength + displayedAssetsIndex++);
                                    this.displayedAssets[serviceId].push(asset);
                                }
                            }
                        }
                    });

                    this.getAssets.emit({assets: this.productServices, serviceId});

                    if (!!serviceDataFound['serviceAssetsOrder']) {
                        this.productServices[serviceId] = this.orderAssets(this.productServices[serviceId], serviceDataFound['serviceAssetsOrder']);
                        this.displayedAssets[serviceId] = this.orderAssets(this.displayedAssets[serviceId], serviceDataFound['serviceAssetsOrder']);
                    }
                }
                this.thumbsLoading = false;
            });
        }
    }

    @Output()
    editProduct = new EventEmitter<any>();

    @Output()
    selectClicked = new EventEmitter<Agent>();

    @Output()
    getAssets = new EventEmitter<any>();

    @Input()
    set agencyData(agency) {
        if (!!agency) {
            this.downloadShareAgencyData = {
                hasWatermark: agency.hasWatermark || false,
                imageSize: agency.imageSize,
                watermarkDimensions: agency.watermarkDimensions,
                watermarkOffset: agency.watermarkOffset,
                watermarkOpacity: agency.watermarkOpacity,
                watermarkSize: agency.watermarkSize
            };
        } else {
            this.downloadShareAgencyData = {
                noAgencySelected: true,
                imageSize: DEFAULT_WEB_SIZE,
                watermarkDimensions: {
                    width: null,
                    height: null
                },
                watermarkSize: 50,
                watermarkOffset: {
                    offsetX: 0,
                    offsetY: 0
                },
                hasWatermark: false,
                watermarkOpacity: 0
            };
        }
    }

    constructor(
        public dialogService: DialogService,
        public uploadService: UploadService,
        private cd: ChangeDetectorRef,
        private authService: AuthenticationService,
        private jobsService: JobsService,
        private servicesService: ServicesService,
        private productsService: ProductsService,
        private assetsService: AssetsService,
        private snackbarService: SnackbarService,
        private acceptRejectService: AcceptRejectService) {
    }

    ngOnInit() {
        this.jobDownloading$ = this.jobDownloading.subscribe(() => {
            this.bucketProduct.services.forEach((service) => {
                this.clearAll(service.id);
                this.serviceConfig[service.id].selectable = false;
            });
        });
        this.accordionExpanded = {};
        this.bucketProduct.services.forEach((service) => {
            this.hiddenFromShareServices[service.id] = !!service.hideFromShare;
            this.accordionExpanded[service.id] = !service.hideFromShare;
            this.assetSelectedCounted[service.id] = 0;
        });
        this.uploadService.uploadThumbPlaceholders$.subscribe((data) => {
            this.placeholders = data;
        });

        this.assetsService.rejectedAssetNotification$
            .pipe(
                takeUntil(this.subDestroyer$)
            )
            .subscribe((data) => {
                let {serviceId, thumbUUID, reason} = data;

                if (!!this.productServices[serviceId]) {
                    this.productServices[serviceId].find((asset) => {
                        if (asset.thumbUUID === thumbUUID) {
                            asset.savedRejectReason = reason;
                            asset.hidden = true;
                            return true;
                        } else {
                            return false;
                        }
                    });
                    this.displayedAssets[serviceId].find((asset) => {
                        if (asset.thumbUUID === thumbUUID) {
                            asset.hidden = true;
                            if (!this.serviceConfig[serviceId].filter.rejected) {
                                if (asset.selected) {
                                    this.assetsService.removeSelectedAsset(this.jobsService.selectedJob.id, this.bucketProduct.id, serviceId, asset);
                                }
                                this.filterAssets(serviceId);
                            }
                            return true;
                        } else {
                            return false;
                        }
                    });
                }
            });
    }

    ngOnDestroy() {
        this.assetsService.setAssetsLoaded(false);
        this.subDestroyer$.next();
    }

    editService(serviceId: string) {
        const service = this.bucketProduct.services.find(({id}) => id === serviceId);
        this.productsService.setSelectedService(service);
        const dialogRef = this.dialogService.openDialog(FormUploadDialogComponent, {service, edit: true, product: this.bucketProduct},
            {
                panelClass: 'form-upload-dialog'
            });

        if (service.SNAKE_CASE !== ServiceEnum.IMAGE_PROCESSING && !service.ticketId && service.version === 'v2') {
            dialogRef.afterClosed().subscribe(({cancelled, fileCount}: {cancelled: boolean, fileCount: number}) => {
                if (!cancelled) {
                    this.dialogService.openDialog(ServiceBriefDialogComponent, {service, cancelled, fileCount},
                        {
                            panelClass: 'form-upload-dialog',
                        });
                }
            });
        }
    }

    orderAssets(arrayToSort, referenceArray) {
        const orderCollection = arrayToSort.reduce((acc, curr) => {
            const index = referenceArray.findIndex(filename => {
                return filename === curr.filename;
            });

            if (index > -1) {
                acc.order[index] = curr;

            } else {
                acc.noOrder.push(curr);
            }

            return acc;
        }, {
            order: {},
            noOrder: []
        });

        return [...Object.values(orderCollection.order), ...orderCollection.noOrder];
    }

    filterAssets(serviceId) {
        let noFilterSelected = this.serviceConfig[serviceId].filter.visible === false && this.serviceConfig[serviceId].filter.hidden === false && this.serviceConfig[serviceId].filter.rejected === false;
        this.productServices[serviceId].forEach((asset, i) => {
            let allowVisibleAsset = this.serviceConfig[serviceId].filter.visible && !asset.hidden;
            let allowHiddenAsset = this.serviceConfig[serviceId].filter.hidden && asset.hidden && !(asset.finalRejectReason || asset.savedRejectReason);
            let allowRejectedAsset = this.serviceConfig[serviceId].filter.rejected && !!(asset.finalRejectReason || asset.savedRejectReason);

            if (allowVisibleAsset || allowHiddenAsset || allowRejectedAsset || noFilterSelected) {
                let index = this.displayedAssets[serviceId].findIndex(item => {
                    return item.thumbUUID === asset.thumbUUID;
                });
                if (index === -1) {
                    if (i === 0) {
                        let copy = cloneDeep(asset);
                        copy.selected = !!this.displayedAssets[serviceId][index];
                        this.displayedAssets[serviceId].splice(0, 0, copy);
                    } else {
                        let position = null;
                        for (let j = i - 1; j >= 0; j--) {
                            position = this.displayedAssets[serviceId].findIndex(item => {
                                return this.productServices[serviceId][j] === item.thumbUUID;
                            });
                            if (position !== -1) {
                                let copy = cloneDeep(asset);
                                copy.selected = !!this.displayedAssets[serviceId][index];
                                this.displayedAssets[serviceId].splice(position + 1, 0, copy);
                                break;
                            }
                            if (j === 0) {
                                let copy = cloneDeep(asset);
                                copy.selected = !!this.displayedAssets[serviceId][index];
                                this.displayedAssets[serviceId].splice(i, 0, copy);
                                break;
                            }
                        }
                    }
                }

            } else {
                let index = this.displayedAssets[serviceId].findIndex(item => {
                    return item.thumbUUID === asset.thumbUUID;
                });
                if (index !== -1) {
                    if (this.displayedAssets[serviceId][index].selected) {
                        if (this.displayedAssets[serviceId][index].hidden) {
                            this.serviceConfig[serviceId].hiddenCount--;
                        } else {
                            this.serviceConfig[serviceId].visibleCount--;
                        }
                        this.displayedAssets[serviceId][index].selected = false;
                        this.assetSelectedCounted[serviceId]--;
                        this.assetsService.removeSelectedAsset(this.jobsService.selectedJob.id, this.bucketProduct.id, serviceId, asset);

                    }
                    this.displayedAssets[serviceId].splice(index, 1);
                }
            }
        });
        this.resetTileIds(this.displayedAssets[serviceId]);
    }

    resetTileIds(serviceAssets) {
        serviceAssets.map((asset, index) => {
            asset.tempId = index;
            return asset;
        });
    }

    filterAssetsSelected(selection: 'visible' | 'hidden' | 'rejected', serviceId) {
        this.serviceConfig[serviceId].filter[selection] = !this.serviceConfig[serviceId].filter[selection];
        this.filterAssets(serviceId);
    }

    checkboxSelected(event: any, thumbnail, serviceId): void {
        let productId = this.bucketProduct.id;
        let {id: jobId} = this.jobsService.selectedJob;
        thumbnail.selected = event.checked;

        if (event.checked) {
            if (thumbnail.hidden) {
                this.serviceConfig[serviceId].hiddenCount++;
            } else {
                this.serviceConfig[serviceId].visibleCount++;
            }
            this.assetSelectedCounted[serviceId]++;
            this.assetsService.addSelectedAsset(jobId, productId, serviceId, thumbnail);

        } else {
            if (thumbnail.hidden) {
                this.serviceConfig[serviceId].hiddenCount--;
            } else {
                this.serviceConfig[serviceId].visibleCount--;
            }
            this.assetSelectedCounted[serviceId]--;
            this.assetsService.removeSelectedAsset(jobId, productId, serviceId, thumbnail);
        }
    }

    selectAll(serviceId) {
        this.selectClicked.emit();
        this.checkboxDisplayed = true;

        for (const value of Object.values(this.displayedAssets[serviceId])) {
            if (!value['selected']) {
                if (this.selectAllDisplay) {
                    this.selectAllDisplay = false;
                }
                value['selected'] = true;
                if (value['hidden']) {
                    this.serviceConfig[serviceId].hiddenCount++;
                } else {
                    this.serviceConfig[serviceId].visibleCount++;
                }
                this.assetSelectedCounted[serviceId]++;
                this.assetsService.addSelectedAsset(this.jobsService.selectedJob.id, this.bucketProduct.id, serviceId, value);
            }
        }
    }

    displayCheckbox(serviceId) {
        if (this.serviceConfig[serviceId].selectable) {
            this.clearAll(serviceId);
        }
        this.serviceConfig[serviceId].selectable = !this.serviceConfig[serviceId].selectable;
    }

    clearAll(serviceId) {
        for (const value of Object.values(this.displayedAssets[serviceId])) {
            if (value['selected']) {
                if (value['hidden']) {
                    this.serviceConfig[serviceId].hiddenCount--;
                } else {
                    this.serviceConfig[serviceId].visibleCount--;
                }
                value['selected'] = false;
                if (!this.selectAllDisplay) {
                    this.selectAllDisplay = true;
                }
                this.assetSelectedCounted[serviceId]--;
                this.assetsService.removeSelectedAsset(this.jobsService.selectedJob.id, this.bucketProduct.id, serviceId, value);
            }
        }
    }

    hideAssetsSelected(serviceId: any, hidden) {
        this.hidingInProgress = true;
        let jobId = this.jobsService.selectedJob.id;
        let productId = this.bucketProduct.id;
        let memberProfileUUID = this.authService.getUserUid();
        let serviceAssets = cloneDeep(this.assetsService.getServiceAssets(jobId, productId, serviceId));
        if (serviceAssets.length === 0) {
            return;
        }
        let finalPath = `${memberProfileUUID}/${jobId}/${productId}/${serviceId}`;
        this.hideAssets(finalPath, hidden, serviceId, jobId, productId, serviceAssets);
    }

    hideAssets(finalPath, hidden, serviceId, jobId, productId, serviceAssets, undo = false) {
        let thumbUUIDList = [];
        this.serviceConfig[serviceId].selectable = false;

        this.hidingInProgress = true;

        for (const value of Object.values(serviceAssets)) {
            thumbUUIDList.push(value['thumbUUID']);
            this.hidingAssetsLoading[value['thumbUUID']] = true;
        }

        if (((this.serviceConfig[serviceId].filter.visible && !hidden) && !this.serviceConfig[serviceId].filter.hidden) || ((this.serviceConfig[serviceId].filter.hidden && hidden) && !this.serviceConfig[serviceId].filter.visible)) {
            this.displayedAssets[serviceId].forEach((value) => {
                if (this.hidingAssetsLoading[value['thumbUUID']]) {
                    delete this.hidingAssetsLoading[value['thumbUUID']];
                }
                if (thumbUUIDList.includes(value['thumbUUID'])) {
                    value['hidden'] = !hidden;
                    this.productServices[serviceId].forEach((asset) => {
                        if (asset.thumbUUID === value['thumbUUID']) {
                            asset.hidden = !hidden;
                        }
                    });
                    if (!hidden) {
                        this.serviceConfig[serviceId].visibleCount--;
                    } else {
                        this.serviceConfig[serviceId].hiddenCount--;
                    }
                    this.displayedAssets[serviceId] = this.displayedAssets[serviceId].filter((asset) => {
                        return asset.thumbUUID !== value['thumbUUID'];
                    });
                    this.assetSelectedCounted[serviceId]--;
                    this.assetsService.removeSelectedAsset(jobId, productId, serviceId, value);
                }
            });
        } else {
            for (const [key, value] of Object.entries(this.productServices[serviceId])) {
                if (this.hidingAssetsLoading[value['thumbUUID']]) {
                    delete this.hidingAssetsLoading[value['thumbUUID']];
                }
                if (thumbUUIDList.includes(value['thumbUUID'])) {
                    this.productServices[serviceId][key]['hidden'] = !hidden;
                    this.displayedAssets[serviceId].forEach((asset) => {
                        if (asset.thumbUUID === value['thumbUUID']) {
                            asset.hidden = !hidden;
                        }
                    });
                    if (!undo) {
                        if (!hidden) {
                            this.serviceConfig[serviceId].hiddenCount++;
                            this.serviceConfig[serviceId].visibleCount--;
                        } else {
                            this.serviceConfig[serviceId].hiddenCount--;
                            this.serviceConfig[serviceId].visibleCount++;
                        }
                    }
                }
            }
            this.filterAssets(serviceId);
        }
        this.clearAll(serviceId);
        this.assetsService.hideAssets(finalPath, thumbUUIDList, hidden)
            .pipe(
                take(1)
            )
            .subscribe(
                () => {
                    this.getAssets.emit({assets: this.productServices, serviceId});
                    this.hidingInProgress = false;
                },
                (err) => {
                    console.error(err);
                    this.hidingInProgress = false;
                    for (const value of Object.values(serviceAssets)) {
                        if (this.hidingAssetsLoading[value['thumbUUID']]) {
                            delete this.hidingAssetsLoading[value['thumbUUID']];
                        }
                    }
                }, () => {
                    let message = `You have ${hidden ? 'revealed' : 'hidden'} ${thumbUUIDList.length} assets`;
                    this.snackbarService.undoSnackbar(message, 'Undo')
                        .afterDismissed()
                        .toPromise()
                        .then((info) => {
                            if (info.dismissedByAction === true) {
                                this.hideAssets(finalPath, !hidden, serviceId, jobId, productId, serviceAssets, true);
                            }
                        });
                }
            );
    }

    imageLoadedHandler(thumbUUID) {
        this.imageLoaded[thumbUUID] = true;
    }

    preventClickThrough(event, serviceId) {
        event.stopPropagation();
        this.serviceConfig[serviceId].displayFilterMenu = !this.serviceConfig[serviceId].displayFilterMenu;
    }

    downloadServiceAssets(serviceId) {
        let filteredAssets = [];
        let filePathNames = [];
        this.fetchingAssetPaths[serviceId] = true;
        this.productServices[serviceId].forEach((item) => {
            filePathNames.push(item.finalPath);
        });
        this.assetsService.getAssetsBucketPaths(this.jobsService.selectedJob.id, `${this.bucketProduct.id}/${serviceId}`, this.selectedJob.memberProfileUid)
            .pipe(
                first(d => !!d)
            )
            .subscribe(
                (assetsResponse: Response) => {
                    const assetPaths = assetsResponse.data;
                    filePathNames.forEach(filePath => {
                        const [mUid, jUid, pUid, serviceUid, finals, filenameTail] = filePath.split('/');
                        assetPaths.forEach((item) => {
                            if ((item.startsWith(`${mUid}/${jUid}/${pUid}/${serviceUid}`) && (item.endsWith(`/finals/${filenameTail}`) || item.endsWith(`/finals/web_${filenameTail}`) || item.endsWith(`/finals/watermark_${filenameTail}`)))) {
                                filteredAssets.push(item);
                            }
                        });
                    });

                    this.fetchingAssetPaths[serviceId] = false;
                    this.dialogService.openDialog(FilterDownloadSelectionModalComponent, {
                        allAssetPaths: filteredAssets,
                        assets: this.productServices[serviceId],
                        downloadPrefix: serviceId,
                        inputFilter: this.serviceConfig[serviceId].filter
                    }, {
                        width: '400px',
                        panelClass: 'filter-download-selection-dialog'
                    });
                },
                err => {
                    this.fetchingAssetPaths[serviceId] = false;
                    console.error('Error getting asset paths:', err);
                    this.snackbarService.handleError('Problem while collecting file paths. You will not be able to download your jobs files.');
                },
                () => {

                }
            );
    }

    downloadSelectedServiceAssets(serviceId) {
        let jobId = this.jobsService.selectedJob.id;
        let productId = this.bucketProduct.id;
        this.fetchingAssetPaths[serviceId] = true;
        let selectedAssets = this.assetsService.getServiceAssets(jobId, productId, serviceId);
        let filePathNames = [];

        Object.values(selectedAssets).forEach((value: any) => {
            filePathNames.push(value.finalPath);
        });

        this.assetsService.getAssetsBucketPaths(this.jobsService.selectedJob.id, `${this.bucketProduct.id}/${serviceId}`, this.selectedJob.memberProfileUid)
            .pipe(
                first(d => !!d)
            )
            .subscribe(
                (assetsResponse: Response) => {
                    let assetPaths = assetsResponse.data;
                    let filteredAssets = [];

                    for (let filePath of filePathNames) {
                        const [mUid, jUid, pUid, serviceUid, finals, filenameTail] = filePath.split('/');
                        assetPaths.forEach((item) => {
                            if ((item.startsWith(`${mUid}/${jUid}/${pUid}/${serviceUid}`) && (item.endsWith(`/finals/${filenameTail}`) || item.endsWith(`/finals/web_${filenameTail}`) || item.endsWith(`/finals/watermark_${filenameTail}`)))) {
                                filteredAssets.push(item);
                            }
                        });
                    }

                    this.fetchingAssetPaths[serviceId] = false;
                    let dialogRef = this.dialogService.openDialog(DownloadSelectionModalComponent, {allAssetPaths: filteredAssets}, {
                        panelClass: 'download-selection-dialog'
                    });
                    this.clearAll(serviceId);
                    this.serviceConfig[serviceId].selectable = false;
                    dialogRef.afterClosed()
                        .pipe(take(1))
                        .subscribe(() => {
                            this.clearAll(serviceId);
                        });
                },
                err => {
                    this.fetchingAssetPaths[serviceId] = false;
                    console.error('Error getting asset paths:', err);
                    this.snackbarService.handleError('Problem while collecting file paths. You will not be able to download your jobs files.');
                },
                () => {

                }
            );
    }

    updateServiceNameOnBlur(focusEvent: any, product, service) {
        const target: any = focusEvent.target;
        const newServiceName = target.innerText.trim();
        target.innerText = target.innerText.trim();

        if (newServiceName !== this.servicesDataForNameDisplay[service.key]) {
            if (!newServiceName) {
                target.innerText = product.ProductName;
                this.servicesDataForNameDisplay[service.key] = target.innerText;
            }
            this.updateServiceName(product.id, service.key, newServiceName);
        } else if (newServiceName === this.servicesDataForNameDisplay[service.key]) {
            return;

        } else if (newServiceName !== product.ProductName) {
            if (!newServiceName) {
                target.innerText = product.ProductName;
                this.servicesDataForNameDisplay[service.key] = target.innerText;
            }
            this.updateServiceName(product.id, service.key, newServiceName);
        }
    }

    updateServiceName(productId: string, serviceId: string, newServiceName: string) {
        let backupServiceName = this.servicesDataForNameDisplay[serviceId];
        this.servicesDataForNameDisplay[serviceId] = newServiceName.trim();
        this.productsService.updateServiceName({productId, serviceId, newServiceName})
            .then(() => {
                this.snackbarService.undoSnackbar('Service name updated', 'Undo')
                    .afterDismissed()
                    .toPromise()
                    .then((info) => {
                        if (info.dismissedByAction === true) {
                            this.updateServiceName(productId, serviceId, backupServiceName);
                        }
                    });
            })
            .catch(
                () => {
                    this.servicesDataForNameDisplay[serviceId] = backupServiceName;
                }
            );
    }

    openCarousel(dragActive, serviceKeyValueRef, asset) {
        if (dragActive) {
            return;
        }
        let service: Service;
        let serviceObject = {};
        let serviceTypeSnakeCase = null;
        serviceObject['key'] = serviceKeyValueRef.key;
        serviceObject['value'] = this.productServices[serviceKeyValueRef.key];

        this.bucketProduct.services.find((item) => {
            if (item.id === serviceKeyValueRef.key) {
                serviceTypeSnakeCase = item.SNAKE_CASE;
                service = item;
                return true;
            }
            return false;
        });

        this.rejectionPending$
            .pipe(
                first()
            )
            .subscribe(
                pendingRejections => {
                    if (!pendingRejections[serviceKeyValueRef.id]) {
                        const assetsList = [];
                        Object.values(serviceObject['value']).forEach(assetItem => {
                            assetsList.push(assetItem);
                        });
                        const index = assetsList.findIndex(assetItem => assetItem.thumbUUID === asset.thumbUUID);
                        const {key: serviceId} = serviceKeyValueRef;
                        const {memberProfileUid, jobId, id: productId} = this.bucketProduct;
                        const assets = cloneDeep(serviceObject['value']);
                        this.acceptRejectService.setUpdatableRejectedServices(assets, serviceKeyValueRef.key);

                        const dialogRef = this.dialogService.openDialog(ProductCarouselComponent, {
                            assets,
                            service,
                            serviceKeyValueRef: serviceObject,
                            filter: this.serviceConfig[serviceKeyValueRef.key].filter,
                            serviceTypeSnakeCase: serviceTypeSnakeCase
                        }, {autoFocus: false});
                        dialogRef.componentInstance.pathToFinals = `${memberProfileUid}/${jobId}/${productId}/${serviceId}/finals`;
                        dialogRef.componentInstance.productTitle = this.bucketProduct.ProductName;
                        dialogRef.componentInstance.serviceId = serviceKeyValueRef.key;
                        dialogRef.componentInstance.initialIndex = index;
                        dialogRef.componentInstance.contentType = asset.contentType;
                    } else {
                        this.snackbarService.showSnackbar('Please wait till rejections have been finalised');
                    }
                }
            );
    }

    toggleServiceHide(service: any) {
        this.hidingServiceInProgress = true;
        let hideFS = this.bucketProduct.services.find((obj) => {
            return obj.id === service.key;
        });
        const hideTarget = !(hideFS.hideFromShare || false);

        this.hiddenFromShareServices[service.key] = hideTarget;
        this.bucketProduct.services.find(serviceIt => serviceIt.id === service.key).hideFromShare = hideTarget;
        this.cd.markForCheck();
        if (hideTarget) {
            if (this.accordionExpanded[service.key]) {
                this.accordionExpanded[service.key] = false;
            }
        } else {
            if (!this.accordionExpanded[service.key]) {
                this.accordionExpanded[service.key] = true;
            }
        }

        this.servicesService.toggleServiceHideFromShare(service.key, hideTarget)
            .toPromise()
            .then(() => {
                this.hidingServiceInProgress = false;
                this.snackbarService.showSnackbar(`Service ${service.key} will be ${hideTarget ? 'hidden' : 'displayed'} when sharing this album`);
            })
            .catch(e => {
                this.hidingServiceInProgress = true;
                this.snackbarService.handleError(`Could not toggle hide for service ${service.key}`);
                console.error(`Could not toggle hide for service ${service.key}:`, e);
            });

    }

    updateServiceNameOnKeyDown(keyEvent: KeyboardEvent, product, service, serviceNameElement) {
        if (keyEvent.code === 'Enter') {
            keyEvent.stopImmediatePropagation();
            const target: any = keyEvent.target;
            const newServiceName = target.outerText.trim();
            serviceNameElement.blur();

            if (newServiceName !== this.servicesDataForNameDisplay[service.key]) {
                if (!newServiceName) {
                    target.innerText = product.ProductName;
                    this.servicesDataForNameDisplay[service.key] = target.innerText;
                }
                this.serviceConfig[service.key].editable = false;
                this.updateServiceName(product.id, service.key, newServiceName);
            } else if (newServiceName === this.servicesDataForNameDisplay[service.key]) {
                this.serviceConfig[service.key].editable = false;
                return;
            } else if (newServiceName !== product.ProductName) {
                if (!newServiceName) {
                    target.innerText = product.ProductName;
                    this.servicesDataForNameDisplay[service.key] = target.innerText;
                }
                this.serviceConfig[service.key].editable = false;
                this.updateServiceName(product.id, service.key, newServiceName);
            }
        } else {
            keyEvent.stopImmediatePropagation();
        }
    }

    toggleServiceInput(event, serviceId) {
        event.stopPropagation();
        this.serviceConfig[serviceId].editable = !this.serviceConfig[serviceId].editable;
    }

    onIconDropped(ev) {
        ev.drag.dropFinished();
    }

    onEnterHandler(ev, serviceId): void {
        this.dropTileId = parseInt(ev.owner.element.nativeElement.id, 10);

        if (this.dragIconId === this.dropTileId) {
            return;
        }

        const dragIndex = this.displayedAssets[serviceId].findIndex(
            (iconObj) => iconObj.tempId === this.dragIconId
        );
        const dropIndex = this.displayedAssets[serviceId].findIndex(
            (iconObj) => {
                if (iconObj.tempId === this.dropTileId) {
                    this.dropTileThumbUUID = iconObj.thumbUUID;
                    return true;
                }
                return false;
            }
        );
        this.reorderAssets(this.displayedAssets[serviceId], dragIndex, dropIndex);
    }

    dragStartHandler(id: number): void {
        this.dragIconId = id;
    }

    dragEndHandler(dragRef: HTMLElement, serviceId: string, tempThumbUUID) {
        this.dragActive = false;
        dragRef.style.visibility = 'visible';

        const dragIndex = this.productServices[serviceId].findIndex(
            (item) => {
                return item.thumbUUID === tempThumbUUID;
            });

        const dropIndex = this.productServices[serviceId].findIndex(
            (item: { thumbUUID: number; }) => {
                return item.thumbUUID === this.dropTileThumbUUID;
            });

        this.reorderAssets(this.productServices[serviceId], dragIndex, dropIndex);

        this.servicesService.saveSortedServiceAssets(this.productServices[serviceId])
            .toPromise()
            .then()
            .catch(e => {
                console.error('Could not store service assets order:', e);
            });

    }

    ghostCreateHandler(dragRef: HTMLElement) {
        this.dragActive = true;
        dragRef.style.visibility = 'hidden';
    }

    private reorderAssets(serviceAssets, dragIndex: number, dropIndex: number) {
        const tempObj = serviceAssets[dragIndex];
        serviceAssets.splice(dragIndex, 1);
        serviceAssets.splice(dropIndex, 0, tempObj);
    }
}

interface ServiceBucketConfiguration {
    [serviceId: string]: {
        editable: boolean;
        selectable: boolean;
        count: number;
        hiddenCount: number;
        visibleCount: number;
        filter: AssetFilters;
        format: AssetFormats;
        displayFilterMenu: boolean;
    };
}

interface AssetFilters {
    hidden: boolean;
    visible: boolean;
    rejected: boolean;
}

interface AssetFormats {
    print: boolean;
    web: boolean;
    watermark: boolean;
}