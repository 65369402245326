import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments';
import {PrismHeaders} from '../enums/headers.enum';

@Injectable({
    providedIn: 'root'
})
export class StripeService {

    constructor(private http: HttpClient) {
    }

    retrieveCards(): Promise<any> {
        return this.http.get(environment.apiBaseUrl + '/RetrieveCards').toPromise();
    }

    retrieveCustomer(stripeId) {
        const headers = new HttpHeaders().set(PrismHeaders.FunctionHelperData, JSON.stringify({stripeId}));

        return this.http.get(environment.apiBaseUrl + '/RetrieveCustomer', {headers}).toPromise();
    }
}

