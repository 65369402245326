<mat-toolbar class="header">
    <div class="header-left">
        <div class="logo" (click)="changeLocation()">
            <img src="../../../assets/images/bwrm-new-logo.png" alt="BWRM">
        </div>
    </div>
</mat-toolbar>

<div class="agent-buckets-container" *ngIf="(jobProductsData$ | async) as jobData; else loading">
    <div class="content-wrapper job-album-wrapper">
        <div class="toolbar job-album-toolbar">
            <div class="toolbar-left job-album-toolbar-left">
                <h1 class="page-title" id="job-detail-header">
                    <span class="job-detail-address">
                    {{ jobData['address'] }}
                </span>
                </h1>
            </div>
            <div class="btns-container">
                <button mat-raised-button class="btn btn-blue agency-share-btn"
                        [disabled]="(assetPaths$|async)?.length < 1"
                        (click)="openShareModal(jobData)">
                    Share
                </button>
                <button mat-raised-button class="btn btn-blue agency-download-btn"
                        [disabled]="(assetPaths$|async)?.length < 1"
                        (click)="assetsSelected?.length ? downloadSelected(jobData) : openDownloadAllOptions(jobData)">
                    {{ assetsSelected?.length ? 'Download Selected' : 'Download All' }}
                </button>
                <button mat-raised-button class="btn btn-blue agency-share-btn" (click)="toggleSelect()">
                    {{ isSelecting ? 'Cancel Selection' : 'Select' }}
                </button>
            </div>
        </div>
    </div>
    <mat-progress-bar *ngIf="showLoader" mode="indeterminate"></mat-progress-bar>
    <div *ngFor="let product of jobData['products']">
        <prism-agent-service-bucket
                (assetToggled)="updateAssetsSelected($event)"
                (thumbsToLoad)="updateLoader($event, false)"
                (thumbLoaded)="updateLoader($event, true)"
                [isSelectingSetter]="isSelecting"
                [product]="product"
                [jobData]="jobData"
                [shareAlbumView]="true"
                [mobileData]="mobileData">
        </prism-agent-service-bucket>
    </div>
</div>
<ng-template #loading>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>

