<table mat-table [dataSource]="servicesList">
    <section>Job detail</section>
    <ng-container matColumnDef="id">
        <th class="product-id" scope="col" mat-header-cell *matHeaderCellDef> ID</th>
        <td class="product-id" mat-cell *matCellDef="let service"> {{ service.id | shortenServiceID }}</td>
    </ng-container>

    <ng-container matColumnDef="product">
        <th scope="col" mat-header-cell *matHeaderCellDef> PRODUCT</th>
        <td class="product-title" mat-cell *matCellDef="let service">
            <div class="service-name-details">
                <span class="table-service-name">{{ service.ProductName }}</span>
                <span class="table-product-name">{{ service.DISPLAY }}</span>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="created-at">
        <th class="product-created-at" scope="col" mat-header-cell *matHeaderCellDef> CREATED AT</th>
        <td mat-cell class="product-created-at" *matCellDef="let service">
            <div class="created-at-details">
                <span class="created-at-text">{{ service.received ? toDate(service.received) : 'No Date' }}</span>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th class="product-status" scope="col" mat-header-cell *matHeaderCellDef> STATUS</th>
        <td class="product-status" mat-cell *matCellDef="let service"
            ngSwitch="{{(serviceStatuses$|async)[service.id]}}">
            <div class="product-status-inner">
                <svg-icon *ngSwitchCase="'Draft'"
                          src="/assets/icons/product-status/draft-status.svg"></svg-icon>
                <svg-icon *ngSwitchCase="'In Progress'"
                          src="/assets/icons/product-status/in-progress-status.svg"></svg-icon>
                <svg-icon *ngSwitchCase="'Member Review'"
                          src="/assets/icons/product-status/member-review-status.svg"></svg-icon>
                <svg-icon *ngSwitchCase="'No Processing'"
                          src="/assets/icons/product-status/no-processing-status.svg"></svg-icon>
                <svg-icon *ngSwitchCase="'Uploading'"
                          src="/assets/icons/product-status/uploading-status.svg"></svg-icon>
                <svg-icon *ngSwitchCase="'Delivered'"
                          src="/assets/icons/product-status/delivered-status.svg"></svg-icon>
                <span class="status-name">{{ (serviceStatuses$|async)[service.id] }}</span>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="brief">
        <th class="product-brief" scope="col" mat-header-cell *matHeaderCellDef> BRIEF</th>
        <td class="product-brief" mat-cell *matCellDef="let service">
            <button mat-icon-button class="btn btn-green"
                    *ngIf="!loadingBriefs[service.id]"
                    [disabled]="
                    (service.SNAKE_CASE !== 'image_processing' && service.version === 'v2' && service.ticketId) ||
                    loadingBriefs['any'] ||
                    (putJobLoading$ | async) ||
                    (uploadingStatusSet$|async)[service.id]"
                    (click)="openBriefs(service)">
                <svg width="20px" height="23px" viewBox="0 0 20 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Member" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Member---Job" transform="translate(-787.000000, -230.000000)"
                           fill-rule="nonzero">
                            <g id="Group-19" transform="translate(785.000000, 177.000000)">
                                <g id="0217-document" transform="translate(2.000000, 53.000000)">
                                    <path d="M17.5526316,23 L1.81578947,23 C0.814684211,23 0,22.1853158 0,21.1842105 L0,1.81578947 C0,0.814684211 0.814684211,0 1.81578947,0 L17.5526316,0 C18.5537368,0 19.3684211,0.814684211 19.3684211,1.81578947 L19.3684211,21.1842105 C19.3684211,22.1853158 18.5537368,23 17.5526316,23 Z M1.81578947,1.21052632 C1.48168421,1.21052632 1.21052632,1.48168421 1.21052632,1.81578947 L1.21052632,21.1842105 C1.21052632,21.5183158 1.48168421,21.7894737 1.81578947,21.7894737 L17.5526316,21.7894737 C17.8867368,21.7894737 18.1578947,21.5183158 18.1578947,21.1842105 L18.1578947,1.81578947 C18.1578947,1.48168421 17.8867368,1.21052632 17.5526316,1.21052632 L1.81578947,1.21052632 Z"
                                          id="Shape" stroke="#FFFFFF" stroke-width="0.3" fill="#2F9BBF"></path>
                                    <path d="M12.7105263,4.84210526 L4.23684211,4.84210526 C3.90273684,4.84210526 3.63157895,4.57094737 3.63157895,4.23684211 C3.63157895,3.90273684 3.90273684,3.63157895 4.23684211,3.63157895 L12.7105263,3.63157895 C13.0446316,3.63157895 13.3157895,3.90273684 13.3157895,4.23684211 C13.3157895,4.57094737 13.0446316,4.84210526 12.7105263,4.84210526 Z"
                                          id="Shape" fill="#2F9BBF" stroke="#FFFFFF" stroke-width="0.3"></path>
                                    <path d="M15.1315789,7.26315789 L4.23684211,7.26315789 C3.90273684,7.26315789 3.63157895,6.992 3.63157895,6.65789474 C3.63157895,6.32378947 3.90273684,6.05263158 4.23684211,6.05263158 L15.1315789,6.05263158 C15.4656842,6.05263158 15.7368421,6.32378947 15.7368421,6.65789474 C15.7368421,6.992 15.4656842,7.26315789 15.1315789,7.26315789 Z"
                                          id="Shape" fill="#2F9BBF" stroke="#FFFFFF" stroke-width="0.3"></path>
                                    <path d="M15.1315789,9.68421053 L4.23684211,9.68421053 C3.90273684,9.68421053 3.63157895,9.41305263 3.63157895,9.07894737 C3.63157895,8.74484211 3.90273684,8.47368421 4.23684211,8.47368421 L15.1315789,8.47368421 C15.4656842,8.47368421 15.7368421,8.74484211 15.7368421,9.07894737 C15.7368421,9.41305263 15.4656842,9.68421053 15.1315789,9.68421053 Z"
                                          id="Shape" fill="#2F9BBF" stroke="#FFFFFF" stroke-width="0.3"></path>
                                    <path d="M10.2894737,12.1052632 L4.23684211,12.1052632 C3.90273684,12.1052632 3.63157895,11.8341053 3.63157895,11.5 C3.63157895,11.1658947 3.90273684,10.8947368 4.23684211,10.8947368 L10.2894737,10.8947368 C10.6235789,10.8947368 10.8947368,11.1658947 10.8947368,11.5 C10.8947368,11.8341053 10.6235789,12.1052632 10.2894737,12.1052632 Z"
                                          id="Shape" fill="#2F9BBF" stroke="#FFFFFF" stroke-width="0.3"></path>
                                    <path d="M15.1315789,16.9473684 L4.23684211,16.9473684 C3.90273684,16.9473684 3.63157895,16.6762105 3.63157895,16.3421053 C3.63157895,16.008 3.90273684,15.7368421 4.23684211,15.7368421 L15.1315789,15.7368421 C15.4656842,15.7368421 15.7368421,16.008 15.7368421,16.3421053 C15.7368421,16.6762105 15.4656842,16.9473684 15.1315789,16.9473684 Z"
                                          id="Shape" fill="#2F9BBF" stroke="#FFFFFF" stroke-width="0.3"></path>
                                    <path d="M12.7105263,19.3684211 L4.23684211,19.3684211 C3.90273684,19.3684211 3.63157895,19.0972632 3.63157895,18.7631579 C3.63157895,18.4290526 3.90273684,18.1578947 4.23684211,18.1578947 L12.7105263,18.1578947 C13.0446316,18.1578947 13.3157895,18.4290526 13.3157895,18.7631579 C13.3157895,19.0972632 13.0446316,19.3684211 12.7105263,19.3684211 Z"
                                          id="Shape" fill="#2F9BBF" stroke="#FFFFFF" stroke-width="0.3"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <mat-icon
                        *ngIf="(service.SNAKE_CASE !== 'image_processing') && (service.version === 'v2') && (!service.ticketId)"
                        color="warn" class="brief-warn" matPrefix>error
                </mat-icon>
            </button>
            <div *ngIf="loadingBriefs[service.id]" class="lds-hourglass loading-spinner-brief"></div>
        </td>
    </ng-container>

    <ng-container matColumnDef="assets">
        <th class="product-assets" scope="col" mat-header-cell *matHeaderCellDef> ASSETS</th>
        <td class="product-assets" mat-cell *matCellDef="let service">
            <button mat-icon-button
                    (click)="openRawAssetDialog(service)"
                    [disabled]="(putJobLoading$ | async) || (uploadingStatusSet$|async)[service.id]">
                <img alt='Draft status'
                     src="/assets/icons/jobs/assets.svg">
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="album">
        <th class="product-album" scope="col" mat-header-cell *matHeaderCellDef> ALBUM</th>
        <td class="product-album" mat-cell *matCellDef="let service">
            <a mat-icon-button class="table-btn album-btn" (click)="emitProductForAlbum(service)"
               [routerLink]="['/home', 'jobs', service['jobId'], 'album']">
                <svg-icon alt='Album link.' src="/assets/icons/jobs/album.svg"></svg-icon>
            </a>
        </td>
    </ng-container>

    <ng-container matColumnDef="edit">
        <th class="product-edit" scope="col" mat-header-cell *matHeaderCellDef> UPDATE</th>
        <td class="product-edit" mat-cell *matCellDef="let service">
            <button mat-icon-button
                    (click)="editService(service)"
                    [disabled]="(putJobLoading$ | async) || (uploadingStatusSet$|async)[service.id]">
                <img alt='Update product icon.'
                     src="/assets/icons/jobs/update.svg">
            </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnHeaders"></tr>
    <tr mat-row *matRowDef="let rowData; columns: columnHeaders;"> #</tr>
</table>
<mat-progress-bar *ngIf="(jobProductsLoading$ | async)" mode="indeterminate"></mat-progress-bar>
<mat-paginator [length]="paginatorControl.length"
               [pageSize]="paginatorControl.pageSize"
               [pageSizeOptions]="paginatorControl.pageSizeOptions"
               (page)="paginate($event)"
>
</mat-paginator>
