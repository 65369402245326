<mat-card appearance="outlined">
    <mat-card-title>Assets to be processed for {{ ProductName }}</mat-card-title>
    <div class="bundle-group">

        <div class="download-all-group">
            <button mat-raised-button class="btn btn-green download-all-btn" [disabled]="downloadAllDisabled"
                    (click)="downloadAllBundles()" *ngIf="!!bundleSizes['all']">
                <span>Download All ({{ bundleSizes['all'] | number:'1.0-2' }} MB)</span>
            </button>
            <span class="address-tooltip-text" *ngIf="downloadAllDisabled">Download size exceeded.</span>
        </div>

        <div class="download-btn-list" *ngFor="let bundle of (downloadBundles | keyvalue); let i = index">
            <button mat-raised-button class="btn btn-blue" (click)="downloadBundle(bundle.key)">
                {{ i + 1 }} ({{ bundleSizes[bundle.key] | number: '1.0-2' }} MB)
            </button>
            <div>
                {{ bundle.key | date:'yyyy/MM/d, h:mm a' }}
            </div>
        </div>
        <div class="no-bundles-text" *ngIf="noBundlesAvailable"><span>There are no raw files for processing.</span>
        </div>

    </div>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <mat-card-footer>
        <div class="btn-group">
            <button class="btn btn-red cancel-btn" mat-button (click)="closeAssetsDialog()">Close</button>
        </div>
    </mat-card-footer>
</mat-card>
