import {Injectable} from '@angular/core';
import {catchError, shareReplay} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MapsAPILoader {
    private readonly mapsApiLoader: Observable<Object>;

    constructor(private httpClient: HttpClient) {
        this.mapsApiLoader = this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyAoUzEEjxJnUEmgDdwRelXzWEvZ2Jds3L4&libraries=places,maps', 'callback')
            .pipe(
                shareReplay(1),
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    load() {
        return this.mapsApiLoader;
    }
}
