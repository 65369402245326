import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Agency, MinimalAgency} from '../models';
import {environment} from '../../environments';
import {map} from 'rxjs/operators';
import {PrismHeaders} from '../enums';

@Injectable({
    providedIn: 'root'
})
export class AgencyService {
    agencyTableSizePreference: number;

    constructor(private http: HttpClient) {
        this.agencyTableSizePreference = 10;

    }

    get agencyTableSize() {
        return this.agencyTableSizePreference;
    }

    set updateAgencyTableSize(size: number) {
        this.agencyTableSizePreference = size;
    }

    getAgencies(
        sort: string,
        order: string,
        page: number,
        limit: number,
        status: string,
        search: string = ''): Observable<{ data: Agency[], availableItems: number }> {
        return this.http.get<{ data: Agency[], availableItems: number }>(environment.apiBaseUrl + '/GetAgencies', {
            params: {sort, order, page: page.toString(), limit: limit.toString(), status, search}
        });
    }

    getAgency(id: string): Observable<Agency> {
        const headers = new HttpHeaders()
            .set(PrismHeaders.QueryData, JSON.stringify({agencyId: id}));
        if (id) {
            return this.http.get<{ data: Agency }>(environment.apiBaseUrl + '/GetAgency', {headers})
                .pipe(
                    map(res => res.data)
                );
        } else {
            return of();
        }
    }

    getAgencyNames(): Observable<MinimalAgency[]> {
        return this.http.get<{ data: MinimalAgency[] }>(environment.apiBaseUrl + '/GetAgencyNames').pipe(map(res => res.data));
    }

    postAgency(agency: Agency): Observable<string> {
        return this.http.post<{ ref: string }>(environment.apiBaseUrl + '/PostAgency', agency).pipe(map(val => val.ref));
    }

    putAgency(agency: Agency): Observable<string> {
        return this.http.put<{ ref: string }>(environment.apiBaseUrl + '/PutAgency', agency).pipe(map(val => val.ref));
    }

    updateAgenciesActiveStatus(agencyIds: string[], setActiveStatus: boolean) {
        const headers = new HttpHeaders()
            .append(PrismHeaders.FunctionHelperData, JSON.stringify({activeStatus: setActiveStatus}));

        return this.http.put(environment.apiBaseUrl + '/UpdateAgenciesActiveStatus', {ids: JSON.stringify(agencyIds)}, {headers});
    }
}
