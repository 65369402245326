<div class="upload-modal" [ngClass]="{'hide': hideService}">
    <mat-card class="card-container" appearance="outlined">
        <mat-card-header>
            <mat-card-title>{{ service.DISPLAY }}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="card-content">
            <mat-slide-toggle color="primary"
                              [disabled]="loadingInProgress"
                              [checked]="true"
                              (change)="handleProcessingRequiredSelection($event.checked)"
                              labelPosition="after">
                Processing Required
            </mat-slide-toggle>
            <div class="upload-wrapper">
                <prism-upload-dropzone
                    #uploadDropzone
                    [serviceIndex]="serviceIndex"
                    [allowedFileType]="allowedFileType"
                    [serviceType]="service.SNAKE_CASE"
                    [parentJob]="parentJob"
                    [onlyEdit]="onlyEdit"
                    [processorJobNotCreated]="doesNotExistOnProcessor"
                    [productId]="productId"
                    [service]="service"
                    [briefData]="brief"
                    [disableDropzone]="isProductsLoading || assetsHandled || loadingInProgress"
                    (fileCount)="setFileCount($event)"
                    (serviceUploading)="serviceUploading($event)"
                >
                </prism-upload-dropzone>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button class="btn btn-red cancel-btn" mat-button
                    (click)="onCancelClicked()"
                    [disabled]="loadingInProgress">
                Cancel
            </button>
            <button class="btn btn-green upload-btn"
                    mat-raised-button
                    [disabled]="loadingInProgress || (!(fileCount > 0) || assetsHandled) || (uploadLimited | async)[service.id]"
                    (click)="startUpload()">
                Upload
            </button>
        </mat-card-actions>
    </mat-card>
</div>
