import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {hexDecode} from '../../helpers';
import {AuthenticationService, ContractorService, SnackbarService} from '../../services/index';
import {CustomValidators} from '../../validators/index';
import {ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments';
import {PrismHeaders} from '../../enums';

@Component({
    selector: 'prism-enable-contractor',
    templateUrl: './enable-contractor.component.html',
    styleUrls: ['./enable-contractor.component.scss']
})
export class EnableContractorComponent implements OnInit {

    loadingEnableContractor = false;
    contractorAddComplete;
    enableContractorForm = this.formBuilder.group({
        contractorEmail: new UntypedFormControl('', [Validators.required, CustomValidators.validEmail]),
        memberEmail: new UntypedFormControl({value: '', disabled: true}, [Validators.required, CustomValidators.validEmail]),
        contractorPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(6)])
    });

    constructor(
        private formBuilder: UntypedFormBuilder,
        private snackbarService: SnackbarService,
        private route: ActivatedRoute,
        private http: HttpClient,
        private authenticationService: AuthenticationService,
    ) {
        this.contractorAddComplete = false;
    }


    ngOnInit() {
        const {me: memberEmail} = this.route.snapshot.queryParams;
        if (memberEmail) {
            this.enableContractorForm.get('memberEmail').setValue(hexDecode(memberEmail));
        }
    }

    enableContractor() {
        this.loadingEnableContractor = true;
        this.enableContractorForm.disable();
        const contractorEmail = this.enableContractorForm.get('contractorEmail').value;
        const contractorPassword = this.enableContractorForm.get('contractorPassword').value;
        const memberEmail = this.enableContractorForm.get('memberEmail').value;

        const headers = new HttpHeaders()
            .set(PrismHeaders.FunctionHelperData, JSON.stringify({contractorEmail, memberEmail, contractorPassword}))
            .set('Content-Type', 'application/json')
            .set('Access-Control-Allow-Origin', `${environment.baseUrl}`)
            .set(PrismHeaders.Authorization, `Bearer ${this.authenticationService.sessionTokenRaw}`);

        this.http.get(`${environment.apiBaseUrl}/ProvideContractor`, {headers})
            .subscribe(
                response => {
                    this.snackbarService.showSnackbar(`Contractor enabled for ${hexDecode(memberEmail)}`);
                    this.loadingEnableContractor = false;
                    this.contractorAddComplete = true;
                    this.enableContractorForm.reset();
                    this.enableContractorForm.enable();
                },
                error => {
                    this.loadingEnableContractor = false;
                    this.enableContractorForm.enable();

                    this.snackbarService.handleError(error.error.message);
                }
            );
    }
}
