import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DownloadProgressSnackbarComponent} from '../components';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    constructor(private snackBar: MatSnackBar) {
    }

    dismissSnackbar() {
        this.snackBar.dismiss();
    }

    handleError(errorMessage: string) {
        this.snackBar.open(errorMessage, null, {
            duration: 6000
        });
    }

    showSnackbar(message: string, duration?: number, action?: string) {
        this.snackBar.open(message, action, {
            duration: duration || 4000
        });
    }

    showDismissibleSnackbar() {
        this.snackBar.openFromComponent(DownloadProgressSnackbarComponent);
    }

    showDownloadSnackbar(message: string, action?: string, url?: string) {
        const snack = this.snackBar.open(message, action, {
            panelClass: 'dl-snack'
        });
        snack
            .onAction()
            .toPromise()
            .then(() => {
                    window.open(url);
                }
            );
    }

    undoSnackbar(message: string, action?: string) {
        return this.snackBar.open(message, action, {
            panelClass: 'dl-snack',
            duration: 4000
        });
    }
}
