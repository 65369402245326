<div class="login-container">
    <mat-card appearance="outlined" class="form-container" [formGroup]="loginForm">
        <div class="logo">
            <img src="../../../assets/images/bwrm-new-logo.png" alt="BWRM"/>
        </div>

        <div class="form-field form-field-input">
            <mat-label class="form-field-label">Email</mat-label>
            <mat-form-field appearance="outline">
                <input
                        matInput
                        placeholder="Enter your email address"
                        type="email"
                        formControlName="email"
                        (keyup.enter)="onLogin(loginForm.valid)"
                />
                <mat-error *ngIf="loginForm.controls['email'].hasError('email')">
                    Please enter a valid email address
                </mat-error>
                <mat-error
                        *ngIf="loginForm.controls['email'].hasError('required') && !loginForm.controls['email'].hasError('email')">
                    Please enter your email address
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-field form-field-input">
            <mat-label class="form-field-label">Password</mat-label>

            <mat-form-field appearance="outline">
                <input
                        matInput
                        placeholder="Enter your password"
                        type="password"
                        formControlName="password"
                        (keyup.enter)="onLogin(loginForm.valid)"
                />
                <mat-hint
                        *ngIf="
            loginForm.controls['password'].dirty &&
            loginForm.controls['password'].invalid
          "
                >Password must contain at least
                    {{ minPassChars }} characters
                </mat-hint
                >
                <mat-error *ngIf="loginForm.controls['password'].hasError('required')"
                >Please enter your password
                </mat-error
                >
                <mat-error
                        *ngIf="
            loginForm.controls['password'].hasError('minlength') &&
            !loginForm.controls['password'].hasError('required')
          "
                >Password must contain at least
                    {{ minPassChars }} characters
                </mat-error
                >
            </mat-form-field>
        </div>
        <button
                class="btn btn-green login-btn"
                mat-raised-button
                (click)="onLogin(loginForm.valid)"
                [disabled]="loginForm.invalid || (showLoader$ | async)"
        >
            Log In
        </button>
        <button
                class="btn forgot-password-btn btn-link"
                mat-button
                [routerLink]="['/setup', 'passwordreset']"
                *ngIf="!(showLoader$ | async)"
        >
            Forgot Password
        </button>
        <mat-progress-bar
                class="login-loader"
                mode="indeterminate"
                *ngIf="showLoader$ | async"
        ></mat-progress-bar>
    </mat-card>
</div>
