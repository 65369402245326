<div class="mat-dialog-container">
    <h1 mat-dialog-title>Download Options</h1>

    <mat-dialog-content>
        <div class="download-inner">
            <mat-checkbox color="primary"
                          [checked]="selectedDownloadTypes.print"
                          (change)="changeDownloadTypeSelection('print', $event.checked)">Print
            </mat-checkbox>
            <mat-checkbox color="primary"
                          [checked]="selectedDownloadTypes.web"
                          (change)="changeDownloadTypeSelection('web', $event.checked)">Web
            </mat-checkbox>
            <mat-checkbox color="primary"
                          [checked]="selectedDownloadTypes.watermark"
                          (change)="changeDownloadTypeSelection('watermark', $event.checked)">Web+Watermark
            </mat-checkbox>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button class="btn btn-red cancel-btn"
                mat-button (click)="closeDialog()">Cancel
        </button>
        <button class="btn btn-green" mat-raised-button (click)="requestDownload()">
            Download
        </button>
    </mat-dialog-actions>
</div>
