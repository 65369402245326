<div class="page-container">
    <mat-card appearance="outlined" class="form-container">
        <img src="../../../assets/images/bwrm-new-logo.png" alt="BWRM" id="bwrm-logo">
        <form [formGroup]="resetForm">
            <mat-form-field class="reset-password-input">
                <input matInput placeholder="email" type="email" formControlName="resetEmail"
                       (keyup.enter)="onPasswordReset()">
                <mat-error *ngIf="resetForm.controls['resetEmail'].hasError('email')">Please enter a valid email
                    address
                </mat-error>
                <mat-error
                        *ngIf="resetForm.controls['resetEmail'].hasError('required') && !resetForm.controls['resetEmail'].hasError('email')">
                    Please enter your email address
                </mat-error>
            </mat-form-field>

            <div class="reset-password-btns">
                <button mat-raised-button (click)="onPasswordReset()" color="primary" [disabled]="resetForm.invalid">
                    Reset Password
                </button>
                <button mat-button (click)="navigateBack()">Back</button>
            </div>
        </form>
    </mat-card>
</div>

