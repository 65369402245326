import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {BehaviorSubject, fromEvent, Observable, Subject} from 'rxjs';
import {debounceTime, map, startWith, take, takeUntil} from 'rxjs/operators';

import {AcceptRejectService, AssetsService, AuthenticationService, DialogService} from '../../services';
import {FinalDisplayComponent} from './final-display/final-display.component';
import {ConfirmRejectionComponent} from '../confirm-rejection/confirm-rejection.component';
import {Asset, Response, Service} from '../../models';
import _ from 'lodash';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'prism-product-carousel',
    templateUrl: './product-carousel.component.html',
    styleUrls: ['./product-carousel.component.scss']
})
export class ProductCarouselComponent implements OnInit, AfterViewInit, OnDestroy {
    memberData$ = this.authService.memberData$;
    pathToFinals;
    finalAsset: {
        finalLoaded: boolean;
        element: HTMLImageElement;
        type: 'image' | 'video' | 'video-preview-image';
        videoUrl: string
    };
    initialIndex: number;
    selectedImageTitle: string;
    serviceTypeSnakeCase: string;
    productTitle: string;
    currentIndex: number;
    service: Service;
    assets: Asset[];
    filteredAssets;
    filterConfig: { visible: boolean, hidden: boolean, rejected: boolean };
    displayFilterMenu: boolean;
    destroyer$: Subject<void>;
    getFinalSubDestroyer$: Subject<void>;
    rejectionReasonFocussed: boolean;
    rejectionsPending$: Observable<any>;
    contentType: string;
    filename: string;
    dimensions: { width: number, height: number };
    currentlyTyping: boolean;
    serviceKeyValueRef: {
        key: string,
        value: Asset[]
    };
    rejectedAssets: {
        [serviceId: string]: {
            [thumbUUID: string]: string
        }
    };
    rejectionTextRendered: BehaviorSubject<any>;
    resendValid;
    finalAssets: FinalAssets;
    selectedAssetUUID: string;

    @HostListener('document:click', ['$event']) clickedOutside() {
        this.displayFilterMenu = false;
    }

    @ViewChildren('rejectText') rejectTexts: QueryList<ElementRef<HTMLTextAreaElement>>;
    @ViewChildren('scrollAssets') scrollAssets: QueryList<ElementRef<HTMLDivElement>>;
    @ViewChild('finalDisplay', {static: false}) finalDisplayComponent: FinalDisplayComponent;

    constructor(
        private dialogService: DialogService,
        private assetsService: AssetsService,
        private authService: AuthenticationService,
        private acceptRejectService: AcceptRejectService,
        private dialogRef: MatDialogRef<ProductCarouselComponent>
    ) {
        this.displayFilterMenu = false;
        this.destroyer$ = new Subject<void>();
        this.getFinalSubDestroyer$ = new Subject<void>();
        this.rejectionReasonFocussed = false;
        this.rejectionsPending$ = this.acceptRejectService.rejectionPending$;
        this.memberData$ = this.authService.memberData$;
        this.serviceKeyValueRef = null;
        this.rejectionTextRendered = new BehaviorSubject<any>(null);
        this.rejectedAssets = {};
        this.resendValid = {};
        this.finalAssets = {};
        this.finalAsset = {
            finalLoaded: false,
            element: null,
            type: null,
            videoUrl: null
        };
        this.filterConfig = {
            visible: true,
            hidden: true,
            rejected: true
        };
    }

    ngOnInit() {
        let pathsToFinal = [];
        const {assets, service, serviceKeyValueRef, filter, serviceTypeSnakeCase}: {
            assets: Asset[],
            service: Service,
            serviceKeyValueRef: {
                key: string,
                value: Asset[]
            },
            filter: {
                visible: boolean,
                hidden: boolean,
                rejected: boolean
            },
            serviceTypeSnakeCase: string
        } = this.dialogService.inputData;

        this.filterConfig = _.cloneDeep(filter);
        this.serviceTypeSnakeCase = serviceTypeSnakeCase;
        this.assets = _.cloneDeep(assets);
        this.serviceKeyValueRef = serviceKeyValueRef;
        this.filteredAssets = this.filterAssets();
        this.assets.forEach((asset) => {
            let image = new Image();
            image.src = asset.url;
            this.finalAssets[asset.thumbUUID] = {
                element: image,
                type: (asset.contentType.startsWith('video/')) ? 'video-preview-image' : 'image',
                finalLoaded: false,
                videoUrl: null
            };
            pathsToFinal.push(asset.finalPath);
        });
        this.service = service;

        this.assetsService.getProcessedAssetFinalSignedUrls(pathsToFinal)
            .pipe(take(1))
            .subscribe((res: Response) => {
                let {pathsToFinal} = res.data;
                pathsToFinal.forEach((data, index) => {
                    const {url, contentType, width, height} = data;

                    if (contentType.startsWith('video')) {
                        this.finalAssets[this.assets[index].thumbUUID] = {
                            element: null,
                            type: 'video',
                            finalLoaded: true,
                            videoUrl: url
                        };
                        if (this.selectedAssetUUID === this.assets[index].thumbUUID) {
                            this.finalAsset = this.finalAssets[this.assets[index].thumbUUID];
                        }

                    } else {
                        this.assets[index].finalUrl = url;
                        this.assets[index].contentType = contentType;
                        this.assets[index].dimensions = {width, height};
                        let image = new Image();
                        image.onload = () => {
                            this.finalAssets[this.assets[index].thumbUUID] = {
                                element: image,
                                type: 'image',
                                finalLoaded: true,
                                videoUrl: null
                            };

                            if (this.selectedAssetUUID === this.assets[index].thumbUUID) {
                                this.finalAsset = this.finalAssets[this.assets[index].thumbUUID];
                            }
                        };
                        image.src = url;
                    }
                });
            });

        this.rejectedAssets[this.serviceKeyValueRef['key']] = {};
        this.resendValid[this.serviceKeyValueRef['key']] = false;
        this.rejectionTextRendered.pipe(debounceTime(0)).subscribe(() => {
            let rejectTextsList = this.rejectTexts.toArray();
            this.filteredAssets.forEach((item, index) => {
                if (this.rejectedAssets[this.service.id][item.thumbUUID]) {
                    if (rejectTextsList[index]) {
                        rejectTextsList[index].nativeElement.innerHTML = this.rejectedAssets[this.service.id][item.thumbUUID];
                    }
                }
            });
        });
    }

    ngAfterViewInit() {
        setTimeout(_ => {
            this.selectImageToDisplay(this.initialIndex, this.assets[this.initialIndex]);
        }, 10);
    }

    filterSelected(filter: 'visible' | 'hidden' | 'rejected') {
        this.filterConfig[filter] = !this.filterConfig[filter];
        this.filteredAssets = this.filterAssets();
        this.rejectionTextRendered.next(null);
        this.currentIndex = 0;
        if (this.filteredAssets.length > 0) {
            this.selectImageToDisplay(this.currentIndex, this.filteredAssets[this.currentIndex]);
        } else {
            this.getFinalSubDestroyer$.next();
        }
    }

    filterAssets() {
        return this.assets.filter((asset) => {
            let allowVisibleAsset = this.filterConfig.visible && !asset.hidden;
            let allowHiddenAsset = this.filterConfig.hidden && asset.hidden && !(asset.finalRejectReason || asset.savedRejectReason);
            let allowRejectedAsset = this.filterConfig.rejected && !!(asset.finalRejectReason || asset.savedRejectReason);
            return (allowVisibleAsset || allowHiddenAsset || allowRejectedAsset);
        });
    }

    preventClickThrough(event) {
        event.stopPropagation();
        this.displayFilterMenu = !this.displayFilterMenu;
    }

    selectImageToDisplay(i, thumb?) {
        if (thumb.filename !== this.selectedImageTitle) {
            this.selectedAssetUUID = thumb.thumbUUID;
            this.getFinalSubDestroyer$.next();
            this.contentType = thumb.contentType;
            this.filename = thumb.filename;
            this.finalAsset = this.finalAssets[thumb.thumbUUID];
            this.selectedImageTitle = this.filteredAssets[i];
            this.currentIndex = i;

            let elementArray = this.scrollAssets.toArray();
            if (elementArray.length > 0) {
                const element: HTMLDivElement = this.scrollAssets.toArray()[i].nativeElement;
                const rejectElement: HTMLTextAreaElement = this.rejectTexts.toArray()[i].nativeElement;
                rejectElement.focus({preventScroll: false});
                element.scrollIntoView({
                    behavior: 'auto',
                    block: 'center'
                });
            }
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    insertUrl(url) {
        return `url("${url}")`;
    }

    updateRejectReason(i, startValue) {
        this.rejectionReasonFocussed = true;
        this.currentlyTyping = true;
        const inputTarget = this.rejectTexts.toArray()[i].nativeElement;
        const input$ = fromEvent(inputTarget, 'input')
            .pipe(
                map((event: any) => {
                    return event.target.value;
                }),
                startWith(startValue),
                debounceTime(300),
                takeUntil(this.destroyer$)
            );

        input$
            .subscribe(
                reason => {
                    let thumbUUID = this.filteredAssets[i].thumbUUID;
                    if (!!reason) {
                        this.rejectedAssets[this.service.id][thumbUUID] = reason;
                    } else {
                        this.rejectedAssets[this.service.id][thumbUUID] = null;
                    }
                    this.resendValid[this.service.id] = Object.values(this.rejectedAssets[this.service.id]).length > 0;
                    this.acceptRejectService.modifyAssetRejection(this.service.id, reason, thumbUUID);
                    this.destroyer$.next();
                },
                err => console.error('Update Rejections error:', err)
            );
    }

    openRejectionConfirmation(serviceId: string) {
        this.dialogService.openDialog(ConfirmRejectionComponent, {
            service: this.service,
            pathToFinals: this.pathToFinals,
            serviceKeyValueRef: this.serviceKeyValueRef,
            rejectedAssets: this.rejectedAssets,
            serviceTypeSnakeCase: this.serviceTypeSnakeCase
        });
    }

    finalNavButtonClicked(target: 'previous' | 'next') {
        if (target === 'previous' && this.currentIndex - 1 > -1) {
            this.currentIndex -= 1;
            this.selectImageToDisplay(this.currentIndex, this.filteredAssets[this.currentIndex]);
        } else if (target === 'next' && this.currentIndex + 1 < this.assets.length) {
            this.currentIndex += 1;
            this.selectImageToDisplay(this.currentIndex, this.filteredAssets[this.currentIndex]);
        }
    }

    clickedInRejectionText() {
        this.rejectionReasonFocussed = true;
        this.currentlyTyping = true;
    }

    handleRejectionTextBlur() {
        this.rejectionReasonFocussed = false;
        this.currentlyTyping = false;
    }

    ngOnDestroy() {
        this.destroyer$.next();
    }


    @HostListener('window:keyup', ['$event'])
    checkCurrentlyTyping(event) {
        const keyCode = event.keyCode;

        if (keyCode === 8 && !event.target.value) {
            this.currentlyTyping = false;
            this.rejectionReasonFocussed = false;
        }
    }

    // NAVIGATION
    @HostListener('window:keydown', ['$event'])
    setNextSlide(event) {
        const keyCode = event.keyCode;

        if (!this.currentlyTyping || !this.rejectionReasonFocussed && !event.target.value) {
            let targetIndex;
            if (keyCode === 37 || keyCode === 38) {
                targetIndex = this.currentIndex - 1;
            }
            if (keyCode === 39 || keyCode === 40) {
                targetIndex = this.currentIndex + 1;
            }
            if (targetIndex > -1 && targetIndex < this.scrollAssets.length) {
                this.selectImageToDisplay(targetIndex, this.filteredAssets[targetIndex]);
            }
        }
    }
}

interface FinalAssets {
    [thumbUUID: string]: {
        finalLoaded: boolean;
        element: HTMLImageElement;
        type: 'image' | 'video' | 'video-preview-image'
        videoUrl: string
    };
}
