import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContractorService, MapsAPILoader, SnackbarService} from '../../services';
import {Contractor, Response} from '../../models';
import {BehaviorSubject, fromEvent, Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {EMAIL_REGEX, refreshJobs$, refreshJobsUsers$} from '../../helpers';
import {CustomValidators} from '../../validators';

declare var google: any;

@Component({
    selector: 'prism-add-edit-contractor',
    templateUrl: './add-edit-contractor.component.html',
    styleUrls: ['./add-edit-contractor.component.scss'],
})
export class AddEditContractorComponent implements OnInit, AfterViewInit {
    private initContractorId: any;
    isPosting: boolean;
    isValidating: boolean;
    contractorEmailFound: boolean;
    contractorEmailNotFound: boolean;
    isVerifyingAddress: BehaviorSubject<boolean>;
    addressVerified: BehaviorSubject<boolean>;
    autoCompleteOptions: BehaviorSubject<string[]>;
    subDestroyer$: Subject<void>;
    contractorForm: FormGroup;
    primaryDetailsFormGroup: FormGroup;
    emailsFormGroup: FormGroup;
    requestContractorEmailForm: FormGroup;
    autocompleteService: any;
    geocoder: any;
    validateEmail$: Subscription;
    @ViewChild('validateContractorInput', {static: false}) validateContractorInput: ElementRef;

    constructor(private dialogRef:
                    MatDialogRef<AddEditContractorComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: Contractor,
                private formBuilder: FormBuilder,
                private contractorService: ContractorService,
                private snackbarService: SnackbarService,
                private mapsAPILoader: MapsAPILoader
    ) {
        this.isValidating = false;
        this.isVerifyingAddress = new BehaviorSubject<boolean>(false);
        this.addressVerified = new BehaviorSubject<boolean>(false);
        this.autoCompleteOptions = new BehaviorSubject<string[]>([]);
        this.validateEmail$ = null;
        this.contractorEmailFound = !!this.data;
        this.contractorEmailNotFound = false;
        this.subDestroyer$ = new Subject<void>();

        this.requestContractorEmailForm = this.formBuilder.group({
            email: new UntypedFormControl('', [Validators.required, CustomValidators.validEmail])
        });

        this.primaryDetailsFormGroup = formBuilder.group({
            firstName: formBuilder.control(data ? data.firstName : '', [Validators.required]),
            lastName: formBuilder.control(data ? data.lastName : '', [Validators.required]),
            contactNumber: formBuilder.control(data ? data.contactNumber : ''),
            address: formBuilder.control(data ? data.address : '', [Validators.required]),
            abn: formBuilder.control(data ? data.abn : '')
        });

        this.emailsFormGroup = formBuilder.group({
            loginEmail: formBuilder.control({
                value: data ? data.loginEmail : '',
                disabled: true
            }, [Validators.required, Validators.email]),
            secondaryEmail: formBuilder.control(data ? data.secondaryEmail : '', [Validators.required, Validators.email])
        });

        this.contractorForm = formBuilder.group({
            primaryDetails: this.primaryDetailsFormGroup,
            emails: this.emailsFormGroup
        });
    }

    ngOnInit() {
        this.mapsAPILoader.load()
            .pipe(
                takeUntil(this.subDestroyer$)
            )
            .subscribe(() => {
                this.autocompleteService = new google.maps.places.AutocompleteService();
                this.geocoder = new google.maps.Geocoder();
                if (this.data && this.data.address) {
                    this.isVerifyingAddress.next(true);
                    this.geocoder.geocode({
                        address: this.data.address,
                    }, this.verifyGeocoderResult);
                }

                this.primaryDetailsFormGroup.get('address').valueChanges.pipe(debounceTime(100), distinctUntilChanged())
                    .subscribe(val => {
                        this.isVerifyingAddress.next(true);
                        if (val) {
                            this.geocoder.geocode({
                                address: val,
                            }, this.verifyGeocoderResult);

                            this.autocompleteService.getPlacePredictions({
                                types: ['address'],
                                componentRestrictions: {country: ['au', 'nz']},
                                input: val
                            }, this.handleAutocompleteResponse);
                        } else {
                            this.isVerifyingAddress.next(false);
                            this.addressVerified.next(false);
                        }
                    });
            });
    }

    ngAfterViewInit() {
        this.validateEmail$ = fromEvent(this.validateContractorInput.nativeElement, 'input')
            .pipe(
                debounceTime(500)
            )
            .subscribe(({target: {value: emailInput}}) => {
                this.validateContractor(emailInput);
            });
    }

    verifyGeocoderResult = (resultList) => {
        if (resultList && Array.isArray(resultList) && resultList.length) {
            const address = this.primaryDetailsFormGroup.get('address').value.toLowerCase();
            const matchingResult = resultList.find(result => {
                if (result.address_components && result.address_components.length >= 3) {
                    return [result.address_components[0], result.address_components[1], result.address_components[2]].every(component => {
                        return (address.includes(component.long_name.toLowerCase()) || address.includes(component.short_name.toLowerCase()));
                    });
                } else {
                    return false;
                }
            });
            if (matchingResult) {
                this.addressVerified.next(true);
            } else {
                this.addressVerified.next(false);
            }
            this.isVerifyingAddress.next(false);
        } else {
            this.addressVerified.next(false);
            this.isVerifyingAddress.next(false);
        }
    };

    handleAutocompleteResponse = (placeList) => {
        if (placeList && Array.isArray(placeList) && placeList.length) {
            this.autoCompleteOptions.next(placeList.map(place => place.description));
        }
    };

    closeDialog() {
        this.dialogRef.close();
    }

    validateContractor(emailValue) {

        if (EMAIL_REGEX.test(emailValue)) {
            this.isValidating = true;
            this.requestContractorEmailForm.disable();

            this.contractorService.validateContractor(emailValue)
                .toPromise()
                .then((validationResponse: Response) => {
                    this.initContractorId = validationResponse.data.contractorPlaceholderId;
                    this.emailsFormGroup.get('loginEmail').setValue(emailValue);
                    this.contractorEmailFound = true;
                    this.contractorEmailNotFound = false;
                })
                .catch(err => {
                    this.contractorEmailFound = false;
                    this.contractorEmailNotFound = true;
                    this.requestContractorEmailForm.enable();
                    this.isValidating = false;
                    console.error('Contractor could not be validated:', err);
                });
        }
    }

    addContractor() {
        const contractor: Contractor = {
            id: this.data ? this.data.id : null,
            active: this.data ? this.data.active : true,
            firstName: this.primaryDetailsFormGroup.get('firstName').value,
            lastName: this.primaryDetailsFormGroup.get('lastName').value,
            contactNumber: this.primaryDetailsFormGroup.get('contactNumber').value,
            address: this.primaryDetailsFormGroup.get('address').value,
            abn: this.primaryDetailsFormGroup.get('abn').value,
            loginEmail: this.emailsFormGroup.get('loginEmail').value,
            secondaryEmail: this.emailsFormGroup.get('secondaryEmail').value
        };

        if (contractor.id) {
            this.isPosting = true;
            this.contractorService.putContractor(contractor).subscribe(refId => {
                refreshJobs$.next();
                refreshJobsUsers$.next();
                this.snackbarService.showSnackbar(`${contractor.firstName} ${contractor.lastName} updated`);
                this.isPosting = false;
                this.dialogRef.close({contractorId: refId});
            }, err => {
                this.isPosting = false;
                console.error(err);
                this.snackbarService.handleError('Problem updating contractor');
            });
        } else {
            this.isPosting = true;
            contractor.id = this.initContractorId;
            this.contractorService.postContractor(contractor).subscribe(refId => {
                refreshJobsUsers$.next();
                this.snackbarService.showSnackbar(`${contractor.firstName} ${contractor.lastName} added`);
                this.isPosting = false;
                this.dialogRef.close({contractorId: refId});
            }, err => {
                this.isPosting = false;
                console.error(err);
                this.snackbarService.handleError('Problem adding contractor');
            });
        }
    }

    trimText(event) {
        event.target.value = event.target.value.trim();
    }
}
