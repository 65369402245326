import {Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {map} from 'rxjs/operators';
import {Response} from '../../../models';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {PrismHeaders, ServiceStatus} from '../../../enums';
import {environment} from '../../../../environments';
import {JobProduct} from '../../../models';
import {ActivatedRoute, Router} from '@angular/router';
import {AssetsService, SnackbarService, DialogService} from '../../../services';
import {ServiceBucketDialogComponent} from 'src/app/components/service-bucket-dialog/service-bucket-dialog.component';

@Component({
    selector: 'prism-agent-service-bucket',
    templateUrl: './agent-service-bucket.component.html',
    styleUrls: ['./agent-service-bucket.component.scss']
})
export class AgentServiceBucketComponent {
    @Input() shareAlbumView: boolean;
    openMobileViewer = {};
    bucketProduct;
    serviceThumbUrls;
    lastClicked = null;
    shareUid: string;
    isSelecting = false;
    selectedGalleryServiceId: string;
    @ViewChildren(MatCheckbox) assetCheckboxes: QueryList<MatCheckbox>;
    @Input() jobData: any;

    @Input()
    set isSelectingSetter(isSelecting) {
        this.isSelecting = isSelecting;
        if (!!this.assetCheckboxes) {
            this.assetCheckboxes.toArray().forEach(checkbox => {
                if (isSelecting) checkbox.checked = false;
            });
        }
    }

    @Input() mobileData: { isMobile: boolean, browser: string, os: string };

    @Input()
    set product(product) {
        this.bucketProduct = product;

        for (let serviceId in this.bucketProduct.services) {
            if (this.bucketProduct.services.hasOwnProperty(serviceId)) {
                const service = this.bucketProduct.services[serviceId];
                if (service.hideFromShare || service.status === ServiceStatus.draft) {
                    delete this.bucketProduct.services[serviceId];
                }
            }
        }
        this.getThumbs(this.bucketProduct);
    }

    productIdsToLoad = [];

    @Output() assetToggled: EventEmitter<any> = new EventEmitter<any>();
    @Output() thumbsToLoad: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() thumbLoaded: EventEmitter<string> = new EventEmitter<string>();

    constructor(private http: HttpClient,
                private activatedRoute: ActivatedRoute,
                private dialog: DialogService) {
        this.shareUid = this.activatedRoute.snapshot.params.shareUid || '';
    }

    amountOfMobileViewers = [];

    openBigPicture(event, service, i, title, assets?) {
        this.amountOfMobileViewers.push(1);
        this.lastClicked = i;
        let data = {
            array: this.serviceThumbUrls[service.value.id],
            title: title,
            index: i,
            product: this.bucketProduct,
            jobData: this.jobData
        };
        if (this.mobileData.isMobile) {
            this.selectedGalleryServiceId = service.key;
            this.openMobileViewer[service.key] = i;
        } else {
            this.dialog.openDialogDataShare(ServiceBucketDialogComponent, data, `agent-service-dialog`);
        }
    }

    orderAssets(arrayToSort, referenceArray) {
        const orderCollection = arrayToSort.reduce((acc, curr) => {
            const index = referenceArray.findIndex(filename => {
                return filename === curr.originalFileName;
            });

            if (index > -1) {
                acc.order[index] = curr;
            } else {
                acc.noOrder.push(curr);
            }

            return acc;
        }, {
            order: {},
            noOrder: []
        });


        return [...Object.values(orderCollection.order), ...orderCollection.noOrder];
    }

    getThumbs(product: JobProduct) {
        const servicesToLoad = Object.keys(product.services);
        this.productIdsToLoad = this.productIdsToLoad.concat(product.id);
        const {jobId, id} = product;
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({shareUid: this.shareUid, jobId, id, servicesToLoad}));
        this.http.get(`${environment.apiBaseUrl}/GetAgentProductThumbs`, {headers})
            .pipe(
                map((r: Response) => r.data)
            )
            .subscribe(
                thumbs => {
                    for (let serviceId in thumbs) {
                        if (thumbs.hasOwnProperty(serviceId)) {
                            const refArray = product.services[serviceId]?.serviceAssetsOrder;
                            if (!!refArray) {
                                const serviceThumbsCopy = thumbs[serviceId];
                                thumbs[serviceId] = this.orderAssets(serviceThumbsCopy, refArray);
                            }
                        }
                    }
                    this.serviceThumbUrls = thumbs;
                },
                err => console.error(err)
            );
    }

    selectAsset(assetData, checkboxEvent) {
        this.assetToggled.emit({assetData, selected: checkboxEvent.checked});
    }

    onThumbLoaded({id: productId}) {
        this.productIdsToLoad = this.productIdsToLoad.filter(id => id !== productId);
        this.thumbLoaded.emit(productId);
    }

    includeAssetSelection(assetCheckbox: MatCheckbox, assetData) {
        assetCheckbox.toggle();
        this.assetToggled.emit({assetData, selected: assetCheckbox.checked});
    }

    closeGallery(id: string) {
        for (let i = 0; i < this.amountOfMobileViewers.length; i++) {
            this.amountOfMobileViewers.pop();
        }
        this.openMobileViewer[id] = -1;
    }
}
