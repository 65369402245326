export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const MOBILE_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

export const NO_COMMA_REGEX = /^[^,]+$/;

export const NUMBER_REGEX = /^-?(0|[1-9]\d*)?$/;

export const FINANCIAL_NUMBER_REGEX = /^\$?([1-9]{1}[0-9]{0,2}([0-9]{3})*(\.[0-9]{0,2})?|[1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|(\.[0-9]{1,2})?)$/;

export const AUS_MOBILE_REGEX = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;