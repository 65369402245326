import {Component, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AgencyService, SnackbarService} from '../../services';
import {AgenciesTableComponent} from './agencies-table/agencies-table.component';
import {AddEditAgencyComponent} from '../add-edit-agency/add-edit-agency.component';
import {Agency} from '../../models';

@Component({
    selector: 'prism-agencies-page',
    templateUrl: './agencies-page.component.html',
    styleUrls: ['./agencies-page.component.scss']
})
export class AgenciesPageComponent {
    activeAgenciesFilter = true;
    selectedAgencyIds: string[] = [];

    @ViewChild('agenciesTable', {static: true}) agenciesTable: AgenciesTableComponent;

    dialogRef: MatDialogRef<AddEditAgencyComponent>;

    constructor(private agencyService: AgencyService, private snackbarService: SnackbarService, private dialog: MatDialog) {
    }

    showActiveAgencies(active: boolean) {
        this.activeAgenciesFilter = active;
    }

    onSelectionUpdated(agencyIds: string[]) {
        this.selectedAgencyIds = agencyIds;
    }

    onAgencyClicked(agency: Agency) {
        this.displayAgencyModal(agency);
    }

    displayAgencyModal(agency: Agency = null) {
        this.dialogRef = this.dialog.open(AddEditAgencyComponent, {data: agency, disableClose: true});
        this.dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult && dialogResult.agencyId) {
                this.agenciesTable.getAgencies();
            }
        });
    }

    updateAgenciesActiveStatus(setStatus: boolean) {
        this.agencyService.updateAgenciesActiveStatus(this.selectedAgencyIds, setStatus).subscribe(() => {
            const snackbarString = `${setStatus ? 'Activated' : 'Archived'} ${this.selectedAgencyIds.length} agenc${this.selectedAgencyIds.length > 1 ? 'ies' : 'y'}`;
            this.snackbarService.showSnackbar(snackbarString);
            this.selectedAgencyIds = [];
            this.agenciesTable.getAgencies();
        });
    }
}
