import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import {Agency, MemberProduct} from '../../../models';
import {AgencyService, SnackbarService} from '../../../services';
import includes from 'lodash/includes';
import remove from 'lodash/remove';

@Component({
    selector: 'prism-agencies-table',
    templateUrl: './agencies-table.component.html',
    styleUrls: ['./agencies-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgenciesTableComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @Output() selectionUpdated = new EventEmitter<number[]>();
    @Output() rowClicked = new EventEmitter<Agency>();

    private subDestroyer$: Subject<void>;
    columnHeaders: string[];
    _searchString: string;
    agencies: Observable<Agency[]>;
    totalAgencies: BehaviorSubject<number>;
    isLoading: BehaviorSubject<boolean>;
    selectedAgencyIds: Map<number, boolean>;
    paginatorControl: { pageSize: number, pageSizeOptions: number[] };
    pendingActiveArchiveAgencies: any[];
    _showActive: boolean;

    @Input('showActive')
    set showActive(value: boolean) {
        if (this._showActive !== value) {
            this._showActive = value;
            this.getAgencies();
        }
    }

    constructor(
        private router: Router,
        private agencyService: AgencyService,
        private snackbarService: SnackbarService) {
        this.pendingActiveArchiveAgencies = [];
        this.subDestroyer$ = new Subject<void>();
        this.columnHeaders = [
            'selected',
            'name',
            'address',
            'imageSize',
            'watermarkUrl',
            'active',
            'edit'
        ];
        this.totalAgencies = new BehaviorSubject<number>(0);
        this.selectedAgencyIds = new Map<number, boolean>();
        this.isLoading = new BehaviorSubject<boolean>(false);
        this.paginatorControl = {
            pageSize: this.agencyService.agencyTableSize,
            pageSizeOptions: [5, 10, 20, 50, 100]
        };
        this._searchString = '';
        this._showActive = true;
    }

    ngOnInit() {
        this.getAgencies();
        this.paginator.page
            .pipe(
                takeUntil(this.subDestroyer$)
            )
            .subscribe(() => {
                this.getAgencies();
            });
    }

    getAgencies() {
        this.isLoading.next(true);
        const pageSize = this.agencyService.agencyTableSize;
        const pageIndex = this.paginator.pageIndex ? this.paginator.pageIndex : 0;
        this.selectedAgencyIds = new Map<number, boolean>();
        this.agencies = this.agencyService
            .getAgencies(
                'name',
                'asc',
                pageIndex,
                pageSize,
                this._showActive ? 'active' : 'archived',
                this._searchString
            )
            .pipe(
                take(1),
                map(res => {
                    this.isLoading.next(false);
                    this.totalAgencies.next(res.availableItems);
                    return res.data;
                })
            );
    }

    onAgencySelectedChanged(selected: boolean, id: number) {
        if (selected) {
            this.selectedAgencyIds.set(id, true);
        } else {
            this.selectedAgencyIds.delete(id);
        }
        this.selectionUpdated.emit(Array.from(this.selectedAgencyIds.keys()));
    }

    putToggleActive(agency: Agency, activeStatus: boolean) {
        this.isLoading.next(true);
        this.snackbarService.showSnackbar(`${activeStatus ? 'Activating' : 'Archiving'} agency...`);

        if (includes(this.pendingActiveArchiveAgencies, agency.id)) {
            this.pendingActiveArchiveAgencies = remove(this.pendingActiveArchiveAgencies, agencyId => agencyId !== agency.id);
        }

        this.agencyService.updateAgenciesActiveStatus([agency.id], activeStatus)
            .toPromise()
            .then(() => {
                this.getAgencies();
                this.snackbarService.dismissSnackbar();
            })
            .catch(err => {
                console.error('Put Agency Error:', err);
                this.snackbarService.handleError(`There was an error while archiving your agency. Please try again.`);
            });
    }

    onRowClicked(row) {
        this.rowClicked.emit(row);
    }

    paginate(event) {
        this.agencyService.updateAgencyTableSize = event.pageSize;
    }

    ngOnDestroy() {
        this.subDestroyer$.next();
    }
}
