<mat-toolbar class="header">
    <div class="header-left">
        <div class="logo" [routerLink]="['/home/jobs']">
            <img src="../../../assets/images/bwrm-new-logo.png" alt="BWRM">
        </div>

    </div>
    <div class="header-right">
        <span class="version">ENV: {{ env.environmentName }} VERSION: {{ env.version }}
            LAST UPDATE: {{ env.updateDate }}</span>
        <div class="no-connection-logo-container">
            <img [ngStyle]="{display: !(appHasInternetConnection$|async) ? 'flex' : 'none'}"
                 src="../../../assets/icons/nowifi.png"
                 alt="No internet connection">

        </div>
        <prism-uploader-display *ngIf="!!(memberData$ | async)"></prism-uploader-display>
        <prism-xero-display class="slide-in-btn"
                            *ngIf="!!(memberData$ | async) && !(memberData$ | async)?.isContractor"></prism-xero-display>
        <prism-member-options class="slide-in-btn" *ngIf="!!(memberData$ | async)"
                              [initials]="initials"></prism-member-options>
    </div>
</mat-toolbar>
