<table
        mat-table
        [dataSource]="agents"
>
    <section>Agents Table</section>
    <ng-container matColumnDef="selected">
        <th mat-header-cell scope="col" *matHeaderCellDef></th>
        <td class="clickable-area" mat-cell *matCellDef="let agent">
            <mat-checkbox
                    [checked]="false"
                    (change)="onAgencySelectedChanged($event.checked, agent.id)"
            ></mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="firstName">
        <th mat-header-cell scope="col" *matHeaderCellDef>First Name</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let agent"
                (click)="onRowClicked(agent)"
        >
            {{ agent.firstName }}
        </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
        <th mat-header-cell scope="col" *matHeaderCellDef>Last Name</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let agent"
                (click)="onRowClicked(agent)"
        >
            {{ agent.lastName }}
        </td>
    </ng-container>

    <ng-container matColumnDef="agency">
        <th mat-header-cell scope="col" *matHeaderCellDef>Agency</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let agent"
                (click)="onRowClicked(agent)"
        >
            {{ agent.agency ? agent.agency.name : "None" }}
        </td>
    </ng-container>

    <ng-container matColumnDef="email">
        <th mat-header-cell scope="col" *matHeaderCellDef>Login Email</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let agent"
                (click)="onRowClicked(agent)"
        >
            {{ agent.email }}
        </td>
    </ng-container>

    <ng-container matColumnDef="contactNumber">
        <th mat-header-cell scope="col" *matHeaderCellDef>Contact Number</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let agent"
                (click)="onRowClicked(agent)"
        >
            {{ agent.contactNumber }}
        </td>
    </ng-container>

    <ng-container matColumnDef="active">
        <th mat-header-cell scope="col" *matHeaderCellDef>ACTIVE</th>
        <td mat-cell *matCellDef="let agent">
            <mat-slide-toggle class="slide-toggle"
                              [checked]="agent.active"
                              [disabled]="(isLoading | async) === true"
                              (change)="putToggleActive(agent, $event.checked)">
            </mat-slide-toggle>
        </td>
    </ng-container>

    <ng-container matColumnDef="edit">
        <th mat-header-cell scope="col" *matHeaderCellDef>Edit</th>
        <td mat-cell *matCellDef="let agent">
            <button mat-icon-button (click)="onRowClicked(agent)">
                <img alt='Update product icon.'
                     src="/assets/icons/jobs/update.svg">
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnHeaders"></tr>
    <tr mat-row *matRowDef="let rowData; columns: columnHeaders"></tr>
</table>

<mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoading | async"
></mat-progress-bar>
<mat-paginator
        [length]="totalAgents | async"
        [pageSize]="paginatorControl.pageSize"
        [pageSizeOptions]="paginatorControl.pageSizeOptions"
        (page)="paginate($event)"
        #paginator
>
</mat-paginator>
