import {Component, OnInit} from '@angular/core';
import {AcceptRejectService, AssetsService, DialogService, SnackbarService} from '../../services';
import {ServicesService} from '../../services/services.service';
import {Asset, Service} from '../../models';

@Component({
    selector: 'prism-confirm-rejection',
    templateUrl: './confirm-rejection.component.html',
    styleUrls: ['./confirm-rejection.component.scss']
})
export class ConfirmRejectionComponent implements OnInit {
    pathToFinals: string;
    serviceKeyValueRef: {
        key: string,
        value: Asset[]
    };
    rejectedAssets: {
        [serviceId: string]: {
            [thumbUUID: string]: string
        }
    };
    service: Service;
    serviceTypeSnakeCase: string;

    constructor(private snackbarService: SnackbarService, private assetsService: AssetsService, private dialogService: DialogService, private acceptRejectService: AcceptRejectService, private servicesServices: ServicesService) {
    }

    ngOnInit() {
        const {pathToFinals, service, serviceKeyValueRef, rejectedAssets, serviceTypeSnakeCase} = this.dialogService.inputData;
        this.pathToFinals = pathToFinals;
        this.serviceKeyValueRef = serviceKeyValueRef;
        this.rejectedAssets = rejectedAssets;
        this.serviceTypeSnakeCase = serviceTypeSnakeCase;
        this.service = service;
    }

    sendBackRejects() {
        this.snackbarService.showSnackbar(`Sending back assets. You will be notified when we're done.`);
        Object.entries(this.rejectedAssets[this.serviceKeyValueRef.key]).forEach(([thumbUUID, reason]) => {
            this.assetsService.updateAssetRejectionStatus(this.serviceKeyValueRef.key, thumbUUID, reason);
        });

        if (this.service.version === 'v2') {
            this.servicesServices.createSubTicket({SNAKE_CASE: this.serviceTypeSnakeCase, id: this.serviceKeyValueRef.key}, true);
        }

        this.acceptRejectService.rejectAndOrUpdateAssetsRejections(true, this.pathToFinals, this.serviceKeyValueRef);
        this.closeAllDialogs();
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }

    closeAllDialogs() {
        this.dialogService.closeAllDialogs();
    }
}
