import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {DownloadSelection} from '../../models';
import {
    AssetsService,
    DialogService,
    JobsService,
    SnackbarService
} from '../../services';

@Component({
    selector: 'prism-agent-download-selection-modal',
    templateUrl: './agent-download-selection-modal.component.html',
    styleUrls: ['./agent-download-selection-modal.component.scss']
})
export class AgentDownloadSelectionModalComponent implements OnInit {
    selectedDownloadTypes: DownloadSelection = {
        print: true,
        web: false,
        watermark: false
    };
    serviceId;
    address;
    jobId: number;
    hasDownloadOptions: boolean;
    hasOptions = {
        print: false,
        web: false,
        watermark: false
    };
    finalDownloadAssetPaths: any[] = [];
    filePaths = {
        print: null,
        web: null,
        watermark: null
    };

    constructor(private dialogService: DialogService,
                private jobsService: JobsService,
                private http: HttpClient,
                private snackbarService: SnackbarService,
                private assetsService: AssetsService) {
    }

    ngOnInit() {
        const {
            jobAddress,
            assetData: {webFilePath, watermarkFilePath, originalFilePath, serviceId}
        } = this.dialogService.inputData || {assetData: null};
        this.hasDownloadOptions = !!webFilePath || !!watermarkFilePath;
        this.address = jobAddress;
        this.serviceId = serviceId;
        this.filePaths = {
            print: `${originalFilePath}`,
            web: !!webFilePath ? `${webFilePath}` : null,
            watermark: !!watermarkFilePath ? `${watermarkFilePath}` : null
        };
        this.hasOptions = {
            print: !!originalFilePath,
            web: !!webFilePath,
            watermark: !!watermarkFilePath
        };

        this.finalDownloadAssetPaths.push(`${originalFilePath}`);
    }

    closeDialog() {
        this.dialogService.currentDialogRef.close();
    }

    requestDownload() {
        this.assetsService.sendDownloadRequest(this.address, !!this.serviceId ? this.serviceId : `job_${this.jobId}`, this.finalDownloadAssetPaths);
        this.snackbarService.showDismissibleSnackbar();
        this.dialogService.currentDialogRef.close();
    }

    changeDownloadTypeSelection(type: string, checked) {
        this.selectedDownloadTypes[type] = checked;

        this.finalDownloadAssetPaths = Object.keys(this.selectedDownloadTypes).reduce((acc, currSelection) => {
            if (this.selectedDownloadTypes[currSelection]) acc.push(this.filePaths[currSelection]);
            return acc;
        }, []);
    }

}
