import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FirstTimeAccountSetupComponent} from 'src/app/components/first-time-account-setup/first-time-account-setup.component';
import {ResetPasswordComponent} from 'src/app/components/reset-password/reset-password.component';
import {TermsAndConditionsComponent} from 'src/app/components/terms-and-conditions/terms-and-conditions.component';
import {UpdatePasswordComponent} from 'src/app/components/update-password/update-password.component';
import {LoginExtraModule} from '../modules/login-extra.module';

const loginExtraRoutes: Routes = [
    {
        path: '',
        component: FirstTimeAccountSetupComponent
    },
    {
        path: 'termsandconditions',
        component: TermsAndConditionsComponent
    },
    {
        path: 'passwordreset',
        component: ResetPasswordComponent
    },
    {
        path: 'update-password',
        component: UpdatePasswordComponent
    }
];

export const LoginExtraRouting: ModuleWithProviders<LoginExtraModule> = RouterModule.forChild(loginExtraRoutes);
