import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LoginComponent} from '../../components/login/login.component';
import {LoginModule} from '../modules/login.module';

const loginRoutes = [
    {
        path: '',
        component: LoginComponent,
    }
];

export const LoginRouting: ModuleWithProviders<LoginModule> = RouterModule.forChild(loginRoutes);
