import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthenticationService, SnackbarService} from '../../services';

@Component({
    selector: 'prism-update-password',
    templateUrl: './update-password.component.html',
    styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
    passwordsMatch = false;
    updatePasswordForm = this.formBuilder.group({
        password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
        passwordConfirm: new UntypedFormControl('', [Validators.required, Validators.minLength(6)])
    });
    destroySub$: Subject<void> = new Subject<void>();
    updatingPassword = false;

    constructor(private formBuilder: UntypedFormBuilder, private authenticationService: AuthenticationService, private snackbarService: SnackbarService) {
    }

    ngOnInit(): void {
        this.updatePasswordForm.valueChanges
            .pipe(
                takeUntil(this.destroySub$)
            )
            .subscribe(({password, passwordConfirm}) => {
                this.passwordsMatch = password === passwordConfirm && !!password;
            });
    }

    ngOnDestroy(): void {
        this.destroySub$.next();
    }

    updatePassword() {
        const newPassword = this.updatePasswordForm.get('passwordConfirm').value;

        if (this.passwordsMatch) {
            this.updatingPassword = true;
            this.authenticationService.updatePassword(newPassword)
                .then(value => {
                    if (value === false) {
                        this.updatingPassword = false;
                    }
                });
        } else if (!newPassword) {
            this.snackbarService.handleError('Your have not entered a password');
        } else {
            this.snackbarService.handleError('Your passwords do not match');
        }

    }

}
