<div class="mat-dialog-container add-edit-invoice-modal">
    <h1 mat-dialog-title>Update Invoice</h1>
    <mat-dialog-content>
        <div class="template-selection">
            <mat-radio-group (change)="setInvoiceTemplateSelection($event)">
                <mat-radio-button color="primary" value="A" [checked]="templateSelection === 'A'">
                    Display line item amounts
                </mat-radio-button>
                <mat-radio-button color="primary" value="B" [checked]="templateSelection === 'B'">
                    Hide line item amounts
                </mat-radio-button>
            </mat-radio-group>

            <span class="tutorial-text" *ngIf="templateSelection === 'B'">Please be sure that this template has been created as shown in
                <a href="#">tutorial</a>
            </span>
        </div>

        <form class="add-job-form" [formGroup]="updateInvoiceForm">
            <div class="field-group">
                <div class="form-field form-field-input">
                    <mat-label class="form-field-label">Invoiced To</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="invoiceRecipient"/>
                        <mat-error
                                *ngIf="updateInvoiceForm.controls['invoiceRecipient']?.hasError('required')"
                        >Recipient for invoice is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-field form-field-input">
                    <mat-label class="form-field-label">Date Created</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput type="date" formControlName="dateCreated"/>
                        <mat-error
                                *ngIf="updateInvoiceForm.controls['dateCreated']?.hasError('required')"
                        >Creation date is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-field form-field-input">
                    <mat-label class="form-field-label">Due Date</mat-label>

                    <mat-form-field appearance="outline" disabled>
                        <input
                                matInput
                                type="date"
                                min="{{ dateCreated }}"
                                formControlName="dueDate"
                        />
                        <mat-error
                                *ngIf=" updateInvoiceForm.controls['dueDate']?.hasError('required')"
                        >Due date is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-field form-field-input form-field-input-chips">
                <mat-label class="form-field-label">Invoice Emails</mat-label>

                <mat-form-field appearance="outline">
                    <mat-chip-grid #chipListInvoice>
                        <mat-chip-row
                                *ngFor="let email of invoiceEmails; let i = index"
                                [removable]="true"
                                (removed)="removeInvoiceEmail(i)"
                        >
                            {{ email }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-row>
                        <input
                            #invoiceEmailInput
                            [matChipInputFor]="chipListInvoice"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            formArrayName="emails"
                            [matChipInputAddOnBlur]="true"
                            (matChipInputTokenEnd)="addInvoiceEmail($event)"
                        />
                    </mat-chip-grid>
                    <mat-error> Invalid email address list</mat-error>
                </mat-form-field>
            </div>
        </form>

        <prism-add-products-table
                [products]="inputProducts"
                [charged]="true"
                (lineItemsUpdated)="updateProducts($event)"
        >
        </prism-add-products-table>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button class="btn btn-red cancel-btn"
                mat-button (click)="closeModal()" [disabled]="updateInvoiceInProgress">
            Cancel
        </button>
        <button
                mat-raised-button
                class="btn btn-green"
                [disabled]="updateInvoiceInProgress"
                (click)="updateInvoice()"
        >
            Update Invoice
            <ng-template [ngIf]="updateInvoiceInProgress">
                <div class="lds-hourglass"></div>
            </ng-template>
        </button>
    </mat-dialog-actions>
</div>
