import {Component, OnInit} from '@angular/core';
import {AuthenticationService, DialogService, InvoicesService} from '../../../services';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'prism-fix-member-account-code',
    templateUrl: './fix-member-account-code.component.html',
    styleUrls: ['./fix-member-account-code.component.scss']
})
export class FixMemberAccountCodeComponent implements OnInit {

    rejectedMiscAccountCode;
    updateLoading = false;
    fixAccountCodeForm = new UntypedFormGroup({
        miscAccountCode: new UntypedFormControl('', [Validators.required])
    });

    constructor(private authService: AuthenticationService, private invoicesService: InvoicesService, private dialogService: DialogService) {
    }

    ngOnInit() {
        this.fixAccountCodeForm.get('miscAccountCode').setValue(this.authService.memberCurrentData.miscAccountCode);
        this.fixAccountCodeForm.updateValueAndValidity();
        this.rejectedMiscAccountCode = this.authService.memberCurrentData.miscAccountCode;
    }

    updateAccountCode() {
        this.updateLoading = true;
        this.fixAccountCodeForm.disable();
        this.invoicesService.updateMiscAccountCode(this.fixAccountCodeForm.getRawValue())
            .toPromise()
            .then(() => {
                this.dialogService.currentDialogRef.close();
                this.updateLoading = false;
            })
            .catch(err => {
                console.error('update failed:', err);
                this.fixAccountCodeForm.enable();
                this.updateLoading = false;
            });
    }
}
