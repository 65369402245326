<div class="job-detail-container">
    <button class="btn btn-link btn-nav" mat-button (click)="navToJobs()">
        <img src="../../../assets/icons/jobs/arrow-back.svg" alt="Arrow back"/>
        <span>Back to Jobs</span>
    </button>
    <div class="content-wrapper table-wrapper job-detail-table-wrapper">
        <div class="toolbar table-toolbar job-detail-table-toolbar">
            <div class="toolbar-left table-toolbar-left job-detail-info-wrapper">
                <h1 class="page-title">
                    <span class="job-detail-id"> #{{ job?.id }}: </span>
                    <span class="job-detail-address">{{ job?.address }}</span>
                </h1>
            </div>
            <div class="toolbar-right table-toolbar-right">
                <div class="toolbar-product-left">
                    <button
                            class="btn btn-green add-master-product-btn"
                            mat-raised-button
                            [routerLink]="['/home', 'products']"
                            (click)="navToMasterProducts()"
                            [disabled]="(isJobProductsLoading$ | async) || (isMemberProductsLoading$ | async)"
                            *ngIf="!hasActiveProducts(memberProducts$ | async) && !((isMemberProductsLoading$ | async) || (isJobProductsLoading$ | async))"
                    >
                        <span>Add A Master Product</span>
                    </button>
                    <div class="form-field form-field-select select-service-field">
                        <mat-form-field
                                appearance="outline"
                                *ngIf="hasActiveProducts(memberProducts$ | async) || (isMemberProductsLoading$ | async) || (isJobProductsLoading$ | async)"
                        >
                            <mat-select
                                    [placeholder]="placeholderText"
                                    (selectionChange)="setSelectedProduct($event.value)"
                                    [disabled]="(isJobProductsLoading$ | async) ||(memberProducts$ | async)?.length <= 0">
                                <div *ngFor="let product of memberProducts$ | async">
                                    <mat-option *ngIf="product.active" [value]="product">
                                        {{ product.ProductName }}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <button
                            mat-raised-button
                            class="btn btn-green add-product-btn"
                            (click)="openAddProductDialog()"
                            [disabled]="!dropdownProductSelected || (isJobProductsLoading$ | async) || (isMemberProductsLoading$ | async)"
                    >
                        Add Product
                        <ng-template [ngIf]="isJobProductsLoading$ | async">
                            <div class="lds-hourglass"></div>
                        </ng-template>
                    </button>
                </div>
                <div class="toolbar-product-right">

                    <div class="icon-btn-group">
                        <button
                                mat-icon-button
                                class="btn share-album-btn"
                                (click)="openShareModal()"
                                [disabled]="(albumProducts$ | async) === null || (jobProductsLoading$ | async) || (memberProductsLoading$ | async)"
                        >
                            <svg
                                    width="30px"
                                    height="30px"
                                    viewBox="0 0 16 14"
                                    xmlns="http://www.w3.org/2000/svg"
                                    stroke="#FFFFFF"
                                    stroke-width="0.4"

                            >
                                <g
                                        id="Symbols"
                                        fill="none"
                                        fill-rule="evenodd"
                                >
                                    <g
                                            id="Jobs-Table"
                                            transform="translate(-1137.000000, -78.000000)"
                                            fill="#2F9BBF"
                                            fill-rule="nonzero"
                                    >
                                        <g
                                                id="Group-31-Copy"
                                                transform="translate(1100.000000, 20.000000)"
                                        >
                                            <g
                                                    id="Group-7-Copy"
                                                    transform="translate(37.000000, 58.000000)"
                                            >
                                                <g
                                                        id="0800-share"
                                                        transform="translate(0.736842, 0.000000)"
                                                >
                                                    <path
                                                            d="M10.6809684,0.1533 C10.4798105,-0.05145 10.1528,-0.05145 9.95164211,0.1533 C9.75048421,0.35805 9.75048421,0.6909 9.95164211,0.89565 L13.1969895,4.19895 L6.70526316,4.19895 C4.92991579,4.19895 3.2536,4.84995 1.98578947,6.0333 C0.7056,7.2282 0,8.8137 0,10.49895 L0,12.77395 C0,13.06375 0.231073684,13.29895 0.515789474,13.29895 C0.800505263,13.29895 1.03157895,13.06375 1.03157895,12.77395 L1.03157895,10.49895 C1.03157895,7.6041 3.57648421,5.24895 6.70526316,5.24895 L13.1969895,5.24895 L9.95164211,8.55225 C9.75048421,8.757 9.75048421,9.08985 9.95164211,9.2946 C10.0527368,9.3975 10.1847789,9.4479 10.3168211,9.4479 C10.4488632,9.4479 10.5809053,9.39645 10.682,9.2946 L14.8083158,5.0946 C15.0094737,4.88985 15.0094737,4.557 14.8083158,4.35225 L10.6809684,0.1533 Z"
                                                            id="Shape"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                        <button
                                mat-icon-button
                                class="invoice-album-btn"
                                (click)="openInvoiceModal()"
                                *ngIf="!!(memberData$ | async) && !(memberData$ | async)?.isContractor"
                                [disabled]="isJobProductsLoading$ | async"
                        >
                            <img class="invoice-icon" *ngIf="jobInvoiced else noInvoice" alt='Invoiced state.'
                                 src="/assets/icons/invoices/invoice.svg">

                            <ng-template #noInvoice>
                                <img class="invoice-icon" alt='Not invoiced state.'
                                     src="/assets/icons/invoices/no-invoice.svg">
                            </ng-template>

                        </button>
                    </div>
                    <button
                            mat-raised-button
                            class="btn edit-job-btn btn-outline share-btn"
                            (click)="openEditJobDialog()"
                            *ngIf="!!(memberData$ | async) && !(memberData$ | async)?.isContractor"
                            [disabled]="(isJobProductsLoading$ | async) || (isPutJobLoading$ | async)"
                    >
                        Update Job
                        <ng-template [ngIf]="isPutJobLoading$ | async">
                            <div class="lds-hourglass"></div>
                        </ng-template>
                    </button>
                </div>
            </div>
        </div>
        <div class="progress-bar" *ngIf="(isGetJobProductsLoading$ | async) === true && initialTableLoad === true">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div
                *ngIf="initialTableLoad === false"
                [ngClass]="!((jobProducts$ | async)?.length <= 0) ? 'job-detail-table-container' : 'placeholder'"
        >
            <prism-job-detail-table
                    *ngIf="(jobProducts$ | async | orderBy:'ProductName':'asc')?.length > 0; else noProducts"
                    [products]="jobProducts$ | async"
                    (openJobAlbum)="navToJobAlbum($event)"
                    (openServiceEdit)="openEditServiceDialog($event)"
            >
            </prism-job-detail-table>
            <ng-template #noProducts>
                <div
                        class="products-list-empty"
                        *ngIf="(jobProducts$ | async)?.length <= 0 && !(isGetJobProductsLoading$ | async)"
                >
                    <h5>No products added yet, add some!</h5>
                </div>
            </ng-template>
        </div>
    </div>
</div>

