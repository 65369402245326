import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import {Agency, Agent} from '../../../models';
import {AgentService, SnackbarService} from '../../../services';

@Component({
    selector: 'prism-agents-table',
    templateUrl: './agents-table.component.html',
    styleUrls: ['./agents-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentsTableComponent implements OnInit, OnDestroy {
    columnHeaders: string[];
    agents: Observable<Agent[]>;
    totalAgents: BehaviorSubject<number>;
    selectedAgentIds: Map<string, boolean>;
    isLoading: BehaviorSubject<boolean>;
    paginatorControl: { pageSize: number, pageSizeOptions: number[] };
    subDestroyer$: Subject<void>;
    _showActive: boolean;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @Output() selectionUpdated = new EventEmitter<string[]>();
    @Output() rowClicked = new EventEmitter<Agent>();

    constructor(
        private router: Router,
        private agentService: AgentService,
        private snackbarService: SnackbarService
    ) {
        this.columnHeaders = [
            'selected',
            'firstName',
            'lastName',
            'agency',
            'email',
            'contactNumber',
            'active',
            'edit'
        ];
        this.subDestroyer$ = new Subject<void>();
        this.totalAgents = new BehaviorSubject<number>(0);
        this.selectedAgentIds = new Map<string, boolean>();
        this.isLoading = new BehaviorSubject<boolean>(false);
        this.paginatorControl = {
            pageSize: this.agentService.agentsTableSize,
            pageSizeOptions: [5, 10, 20, 50, 100]
        };
        this._showActive = true;
    }

    @Input('showActive')
    set showActive(value: boolean) {
        if (this._showActive !== value) {
            this._showActive = value;
            this.getAgents();
        }
    }

    ngOnInit() {
        this.getAgents();
        this.paginator.page
            .pipe(
                takeUntil(this.subDestroyer$)
            )
            .subscribe(() => {
                this.getAgents();
            });
    }

    ngOnDestroy() {
        this.subDestroyer$.next();
    }

    getAgents() {
        this.isLoading.next(true);
        this.selectedAgentIds = new Map<string, boolean>();
        const pageSize = this.agentService.agentsTableSize;
        const pageIndex = this.paginator.pageIndex ? this.paginator.pageIndex : 0;
        this.agents = this.agentService
            .getAgents(
                'name',
                'asc',
                pageIndex,
                pageSize,
                this._showActive ? 'active' : 'archived'
            )
            .pipe(
                take(1),
                map(res => {
                    this.isLoading.next(false);
                    this.totalAgents.next(res.availableItems);
                    return res.data;
                })
            );
    }

    onAgencySelectedChanged(selected: boolean, id: string) {
        if (selected) {
            this.selectedAgentIds.set(id, true);
        } else {
            this.selectedAgentIds.delete(id);
        }
        this.selectionUpdated.emit(Array.from(this.selectedAgentIds.keys()));
    }

    putToggleActive(agency: Agency, activeStatus: boolean) {
        this.isLoading.next(true);
        this.snackbarService.showSnackbar(`${activeStatus ? 'Activating' : 'Archiving'} agent...`);

        this.agentService.updateAgentsActiveStatus([agency.id], activeStatus)
            .toPromise()
            .then(() => {
                this.getAgents();
                this.snackbarService.dismissSnackbar();
            })
            .catch(err => {
                console.error('Put Agent Error:', err);
                this.snackbarService.handleError(`There was an error while archiving your agent. Please try again.`);
            });
    }

    onRowClicked(row) {
        this.rowClicked.emit(row);
    }

    paginate(event) {
        this.agentService.updateAgentsTableSize = event.pageSize;
    }
}
