export const FirebaseConfig = {
  MemberAppConfig: {
    "projectId": "live-bwrm-prism",
    "appId": "1:1001308185462:web:5d2ed599daec50ed21d057",
    "databaseURL": "https://live-bwrm-prism.firebaseio.com",
    "storageBucket": "live-bwrm-prism",
    "locationId": "us-central",
    "apiKey": "AIzaSyDA23Wi5MaROVIMXgthn0o4M2sTKQTirfY",
    "authDomain": "live-bwrm-prism.firebaseapp.com",
    "messagingSenderId": "1001308185462",
    "measurementId": "G-JNF6SH3XX0"
  },
  memberFCMPublicVapidKey: 'BAG-obgBsSfp3AYR-K8DAx7y076Zw7MCVbiBJ36Lh2u2S16tP41ZlwdVIEGaBqhaPHdjA1JjYka0eEkmOCs5InI',
  ProcessorAppConfig: {
    apiKey: "AIzaSyDMAJy18HkkMo_MeOV269VepZU6UCwB5-o",
    authDomain: "live-bwrm-processor-prism.firebaseapp.com",
    databaseURL: "https://live-bwrm-processor-prism.firebaseio.com",
    projectId: "live-bwrm-processor-prism",
    storageBucket: "live-bwrm-processor-prism",
    messagingSenderId: "463395559657",
    appId: "1:463395559657:web:9c8b55f24eba9a635ef878"
  }
};
