<div class="invoice-products-table-wrapper">
    <table class="invoice-products-table" mat-table [dataSource]="lineItemsList" *ngIf="lineItemsList.data.length > 0">
        <caption class="table-caption">Invoice Products</caption>
        <ng-container matColumnDef="Product">
            <th class="product-cell" scope="col" mat-header-cell *matHeaderCellDef> PRODUCT</th>
            <td class="product-cell" mat-cell *matCellDef="let lineItem; let i = index">
                <div class="form-field form-field-input">
                    <mat-form-field appearance="outline">
                        <input matInput id="{{'Description' + i}}" type="text"
                               placeholder="Line item description"
                               [disabled]="!charged"
                               (input)="emitProductsUpdate($event, i, lineItem)" [value]="lineItem.Description">
                    </mat-form-field>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="Date">
            <th class="product-cell" scope="col" mat-header-cell *matHeaderCellDef>DATE</th>
            <td class="product-cell" mat-cell *matCellDef="let lineItem; let i = index">
                <span>
                    {{ lineItem.createTime ? lineItem.createTime : 'No Date' }}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="ItemCode">
            <th mat-header-cell scope="col" *matHeaderCellDef> Item Code</th>
            <td mat-cell *matCellDef="let lineItem; let i = index">
                <div class="form-field form-field-input">
                    <mat-form-field appearance="outline">
                        <input matInput
                               type="text"
                               id="{{'ItemCode' + i}}"
                               placeholder="Item Code (Optional)"
                               (input)="emitProductsUpdate($event, i, lineItem)"
                               [disabled]="!charged"
                               [value]="!!lineItem.ItemCode ? lineItem.ItemCode : ''">
                    </mat-form-field>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="AccountCode">
            <th mat-header-cell scope="col" *matHeaderCellDef> Account Code</th>
            <td mat-cell *matCellDef="let lineItem; let i = index">
                <div class="form-field form-field-input">
                    <mat-form-field appearance="outline">
                        <input matInput
                               type="text"
                               id="{{'AccountCode' + i}}"
                               placeholder="Account Code"
                               [disabled]="!charged"
                               (input)="emitProductsUpdate($event, i, lineItem)"
                               [value]="lineItem.AccountCode">
                    </mat-form-field>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="Units">
            <th mat-header-cell scope="col" *matHeaderCellDef> UNITS</th>
            <td mat-cell *matCellDef="let lineItem; let i = index">
                <div class="form-field form-field-input">
                    <mat-form-field appearance="outline">
                        <input matInput
                               type="text"
                               id="{{'Quantity' + i}}"
                               [disabled]="!charged"
                               [value]="lineItem.Quantity"
                               (input)="updateProductUnitData('Quantity', $event, lineItem)">
                    </mat-form-field>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="UnitPrice">
            <th mat-header-cell scope="col" *matHeaderCellDef> Unit Price</th>
            <td mat-cell *matCellDef="let lineItem; let i = index">
                <div class="form-field form-field-input">
                    <mat-form-field appearance="outline">
                        <input matInput
                               type="text"
                               id="{{'UnitPrice' + i}}"
                               [disabled]="!charged"
                               [value]="lineItem.UnitPrice"
                               (input)="updateProductUnitData('UnitPrice', $event, lineItem)">
                    </mat-form-field>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="Amount">
            <th mat-header-cell scope="col" *matHeaderCellDef> Total</th>
            <td mat-cell *matCellDef="let lineItem; let i = index">
                <div class="form-field form-field-input">
                    <mat-form-field appearance="outline">
                        <input matInput
                               type="text"
                               id="{{'LineAmount' + i}}"
                               [value]="toString(+lineItem.Quantity * +lineItem.UnitPrice)"
                               disabled>
                    </mat-form-field>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="Delete">
            <th class="delete-cell" scope="col" mat-header-cell *matHeaderCellDef></th>
            <td class="delete-cell" mat-cell *matCellDef="let lineItem; let i = index">
                <button mat-icon-button class="btn btn-red delete-invoice-product-btn" (click)="removeItem(lineItem)"
                        [disabled]="!charged">
                    <svg width="15px" height="20px" viewBox="0 0 15 20" xmlns="http://www.w3.org/2000/svg">
                        <g id="Member" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Member---Uploader" transform="translate(-1327.000000, -213.000000)" fill="#DC5A5A"
                               fill-rule="nonzero">
                                <g id="Group-26" transform="translate(222.000000, 194.000000)">
                                    <g id="Group-25" transform="translate(1105.000000, 19.000000)">
                                        <g id="0130-trash2">
                                            <path d="M13.1625,2 L9.75,2 L9.75,1.5 C9.75,0.673 9.093825,0 8.2875,0 L6.3375,0 C5.531175,0 4.875,0.673 4.875,1.5 L4.875,2 L1.4625,2 C0.656175,2 0,2.673 0,3.5 L0,4.5 C0,5.152 0.40755,5.708 0.975,5.914 L0.975,18.5 C0.975,19.327 1.631175,20 2.4375,20 L12.1875,20 C12.993825,20 13.65,19.327 13.65,18.5 L13.65,5.914 C14.21745,5.708 14.625,5.152 14.625,4.5 L14.625,3.5 C14.625,2.673 13.968825,2 13.1625,2 Z M5.85,1.5 C5.85,1.224 6.0684,1 6.3375,1 L8.2875,1 C8.5566,1 8.775,1.224 8.775,1.5 L8.775,2 L5.85,2 L5.85,1.5 Z M12.1875,19 L2.4375,19 C2.1684,19 1.95,18.776 1.95,18.5 L1.95,6 L12.675,6 L12.675,18.5 C12.675,18.776 12.4566,19 12.1875,19 Z M13.65,4.5 C13.65,4.776 13.4316,5 13.1625,5 L1.4625,5 C1.1934,5 0.975,4.776 0.975,4.5 L0.975,3.5 C0.975,3.224 1.1934,3 1.4625,3 L13.1625,3 C13.4316,3 13.65,3.224 13.65,3.5 L13.65,4.5 Z"
                                                  id="Shape"></path>
                                            <path d="M10.2375,7 C9.9684,7 9.75,7.224 9.75,7.5 L9.75,17.5 C9.75,17.776 9.9684,18 10.2375,18 C10.5066,18 10.725,17.776 10.725,17.5 L10.725,7.5 C10.725,7.224 10.5066,7 10.2375,7 Z"
                                                  id="Shape"></path>
                                            <path d="M7.3125,7 C7.0434,7 6.825,7.224 6.825,7.5 L6.825,17.5 C6.825,17.776 7.0434,18 7.3125,18 C7.5816,18 7.8,17.776 7.8,17.5 L7.8,7.5 C7.8,7.224 7.5816,7 7.3125,7 Z"
                                                  id="Shape"></path>
                                            <path d="M4.3875,7 C4.1184,7 3.9,7.224 3.9,7.5 L3.9,17.5 C3.9,17.776 4.1184,18 4.3875,18 C4.6566,18 4.875,17.776 4.875,17.5 L4.875,7.5 C4.875,7.224 4.6566,7 4.3875,7 Z"
                                                  id="Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnHeaders"></tr>
        <tr mat-row *matRowDef="let rowData; columns: columnHeaders;"></tr>
    </table>
    <button mat-button class="btn btn-link" (click)="addProduct()" [disabled]="!charged">
        <svg width="17px" height="17px" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
            <g id="Member" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Member---Jobs-List---Invoice-Modal-2" transform="translate(-466.000000, -502.000000)"
                   fill="#2F9BBF" fill-rule="nonzero">
                    <g id="Group-30" transform="translate(430.000000, 31.000000)">
                        <g id="Group-29" transform="translate(0.000000, 18.000000)">
                            <g id="Group-26" transform="translate(0.000000, 223.000000)">
                                <g id="Group-21" transform="translate(0.000000, 16.000000)">
                                    <g id="Group-20">
                                        <g id="Group-16" transform="translate(36.000000, 214.000000)">
                                            <g id="Group-13">
                                                <g id="0860-plus-circle">
                                                    <path d="M14.5108421,2.48915789 C12.9056842,0.884 10.7708421,0 8.5,0 C6.22915789,0 4.09521053,0.884 2.48915789,2.48915789 C0.883105263,4.09431579 0,6.22915789 0,8.49910526 C0,10.7690526 0.884,12.9038947 2.48915789,14.5099474 C4.09431579,16.116 6.22915789,16.9991053 8.5,16.9991053 C10.7708421,16.9991053 12.9047895,16.1151053 14.5108421,14.5099474 C16.1168947,12.9047895 17,10.7699474 17,8.49910526 C17,6.22826316 16.116,4.09431579 14.5108421,2.48915789 Z M8.5,16.1052632 C4.30636842,16.1052632 0.894736842,12.6936316 0.894736842,8.5 C0.894736842,4.30636842 4.30636842,0.894736842 8.5,0.894736842 C12.6936316,0.894736842 16.1052632,4.30636842 16.1052632,8.5 C16.1052632,12.6936316 12.6936316,16.1052632 8.5,16.1052632 Z"
                                                          id="Shape"></path>
                                                    <path d="M13.8684211,8.05263158 L8.94736842,8.05263158 L8.94736842,3.13157895 C8.94736842,2.88463158 8.74694737,2.68421053 8.5,2.68421053 C8.25305263,2.68421053 8.05263158,2.88463158 8.05263158,3.13157895 L8.05263158,8.05263158 L3.13157895,8.05263158 C2.88463158,8.05263158 2.68421053,8.25305263 2.68421053,8.5 C2.68421053,8.74694737 2.88463158,8.94736842 3.13157895,8.94736842 L8.05263158,8.94736842 L8.05263158,13.8684211 C8.05263158,14.1153684 8.25305263,14.3157895 8.5,14.3157895 C8.74694737,14.3157895 8.94736842,14.1153684 8.94736842,13.8684211 L8.94736842,8.94736842 L13.8684211,8.94736842 C14.1153684,8.94736842 14.3157895,8.74694737 14.3157895,8.5 C14.3157895,8.25305263 14.1153684,8.05263158 13.8684211,8.05263158 Z"
                                                          id="Shape"></path>
                                                </g>
                                                <g id="0860-plus-circle">
                                                    <path d="M14.5108421,2.48915789 C12.9056842,0.884 10.7708421,0 8.5,0 C6.22915789,0 4.09521053,0.884 2.48915789,2.48915789 C0.883105263,4.09431579 0,6.22915789 0,8.49910526 C0,10.7690526 0.884,12.9038947 2.48915789,14.5099474 C4.09431579,16.116 6.22915789,16.9991053 8.5,16.9991053 C10.7708421,16.9991053 12.9047895,16.1151053 14.5108421,14.5099474 C16.1168947,12.9047895 17,10.7699474 17,8.49910526 C17,6.22826316 16.116,4.09431579 14.5108421,2.48915789 Z M8.5,16.1052632 C4.30636842,16.1052632 0.894736842,12.6936316 0.894736842,8.5 C0.894736842,4.30636842 4.30636842,0.894736842 8.5,0.894736842 C12.6936316,0.894736842 16.1052632,4.30636842 16.1052632,8.5 C16.1052632,12.6936316 12.6936316,16.1052632 8.5,16.1052632 Z"
                                                          id="Shape"></path>
                                                    <path d="M13.8684211,8.05263158 L8.94736842,8.05263158 L8.94736842,3.13157895 C8.94736842,2.88463158 8.74694737,2.68421053 8.5,2.68421053 C8.25305263,2.68421053 8.05263158,2.88463158 8.05263158,3.13157895 L8.05263158,8.05263158 L3.13157895,8.05263158 C2.88463158,8.05263158 2.68421053,8.25305263 2.68421053,8.5 C2.68421053,8.74694737 2.88463158,8.94736842 3.13157895,8.94736842 L8.05263158,8.94736842 L8.05263158,13.8684211 C8.05263158,14.1153684 8.25305263,14.3157895 8.5,14.3157895 C8.74694737,14.3157895 8.94736842,14.1153684 8.94736842,13.8684211 L8.94736842,8.94736842 L13.8684211,8.94736842 C14.1153684,8.94736842 14.3157895,8.74694737 14.3157895,8.5 C14.3157895,8.25305263 14.1153684,8.05263158 13.8684211,8.05263158 Z"
                                                          id="Shape"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        <span>Add Miscellaneous Item</span>
    </button>
</div>
