import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {take} from 'rxjs/operators';
import {ServiceStatus} from '../../enums';
import {EMAIL_REGEX, validateEmail, validateEmailList} from '../../helpers';
import {Contractor} from '../../models';
import {AuthenticationService, DialogService, JobsService, ShareAlbumService, SnackbarService} from '../../services';
import {CustomValidators} from '../../validators';
import {Router} from '@angular/router';
import uniq = require('lodash/uniq');

@Component({
    selector: 'prism-notify-agent-dialog',
    templateUrl: './notify-agent-dialog.component.html',
    styleUrls: ['./notify-agent-dialog.component.scss']
})
export class NotifyAgentDialogComponent implements OnInit {
    private serviceId: string;
    private address: string;
    private agencyDownloadShareSpecs;
    private agencyId: string;
    private agentId: string;
    private job;
    mobileData: { browser: string, os: string, isMobile: boolean } = null;
    sendingMail = false;
    separatorKeysCodes = [ENTER, COMMA, SPACE];
    notificationEmails: UntypedFormArray;
    @ViewChild('chipListNotification', {static: true}) chipListNotification;
    notifyAgentForm: UntypedFormGroup;
    jobId: string | number;
    accessedByAgency = false;

    constructor(private dialogService: DialogService,
                private router: Router,
                private formBuilder: UntypedFormBuilder,
                private authService: AuthenticationService,
                private shareAlbumService: ShareAlbumService,
                private snackbarService: SnackbarService,
                private jobsService: JobsService) {
        this.notificationEmails = new UntypedFormArray([], [Validators.required, CustomValidators.emailList]);
        this.notifyAgentForm = this.formBuilder.group({
            notificationEmails: this.notificationEmails,
            comments: new UntypedFormControl('')
        });
    }

    ngOnInit() {
        const {job, asAgency, mobileData} = this.dialogService.inputData;
        this.accessedByAgency = asAgency;
        this.mobileData = mobileData;
        this.job = job;

        if (!!job) {
            let shareRequestUserObject = {agency: null, agent: null};
            if (!!job.agent) {
                shareRequestUserObject = {agency: null, agent: job.agent};
            } else if (!!job.agency) {
                shareRequestUserObject = {agency: job.agency, agent: null};
            }

            this.shareAlbumService.getNotificationEmails(shareRequestUserObject)
                .then((notificationEmails) => {
                    this.fillEmails(uniq(notificationEmails), this.notificationEmails);
                })
                .catch(e => {
                    console.error('Could not load notification emails:', e);
                    this.snackbarService.handleError('Could not load notification emails');
                });
            const {address, id} = job;
            this.jobId = id;
            this.address = address;
            this.serviceId = null;
            this.agencyId = job.agency;
            this.agentId = job.agent;
            this.agencyDownloadShareSpecs = null;
        }
        this.snackbarService.dismissSnackbar();
    }

    fillEmails(emails: string[], control: UntypedFormArray, clear: boolean = true) {
        if (clear) {
            while (control.length !== 0) {
                control.removeAt(0);
            }
        }
        if (emails) {
            for (const email of emails) {
                control.push(this.formBuilder.control(email, [Validators.email, Validators.pattern(EMAIL_REGEX)]));
            }
        }
    }

    addNotificationEmail(event: MatChipInputEvent) {
        if (this.notificationEmails.getRawValue().includes(event.value.trim())) {
            this.snackbarService.showSnackbar('This email has already been added');
            event.input.value = '';
        } else {
            if ((event.value || '').trim()) {
                const emailString = event.value.trim();
                if (validateEmail(emailString)) {
                    this.notificationEmails.push(this.formBuilder.control(event.value.trim(), [Validators.email, Validators.pattern(EMAIL_REGEX)]));
                    this.chipListNotification.errorState = !validateEmailList(this.notificationEmails);
                    event.input.value = '';
                } else {
                    this.chipListNotification.errorState = true;
                }
            } else {
                this.chipListNotification.errorState = !validateEmailList(this.notificationEmails);
            }
        }
    }

    removeNotificationEmail(index: number) {
        if (index >= 0 && index < this.notificationEmails.length) {
            this.notificationEmails.removeAt(index);
            this.chipListNotification.errorState = !validateEmailList(this.notificationEmails);
        }
    }

    shareAlbumWithAgent() {
        const notificationFormData = this.notifyAgentForm.getRawValue();
        notificationFormData.notificationEmails = this.notificationEmails.getRawValue();
        this.sendingMail = true;

        if (this.accessedByAgency) {
            this.shareAlbumService.shareJobAsAgency({
                shareLink: window.location.href,
                notificationEmails: this.notificationEmails.getRawValue(),
                address: this.address,
                comments: this.notifyAgentForm.get('comments').value
            })
                .toPromise()
                .then(() => {
                    this.dialogService.closeAllDialogs();
                    this.snackbarService.showSnackbar('Job album has been shared');
                })
                .catch(e => {
                    console.error('Could not share album:', e);
                    this.snackbarService.handleError('Could not share album');
                });
        } else {
            const memberData = this.authService.memberCurrentData;
            this.snackbarService.showSnackbar(`Your album is being shared...`);

            this.shareAlbumService.shareAlbum(notificationFormData, {street: this.address}, {
                jobId: this.jobId,
                agencyId: this.agencyId,
                agentId: this.agentId,
                mainMemberUid: memberData?.isContractor ? (<Contractor>memberData)?.mainMemberUid : null
            })
                .pipe(
                    take(1)
                )
                .subscribe(
                    () => {
                        if (!!this.job) {
                            let {services = {}, id: jobId} = this.job;
                            Object.entries(services).forEach(([serviceId, status]) => {
                                if ([ServiceStatus.memberReview, ServiceStatus.noProcessing].includes((<ServiceStatus>status))) {
                                    this.jobsService.updateJobsOverviewData(jobId, serviceId, ServiceStatus.delivered);
                                }
                            });
                            this.jobsService.getJobOverviewPanelData(memberData?.isContractor ? (<Contractor>memberData)?.mainMemberUid : this.authService.getUserUid(), memberData?.isContractor).subscribe();
                        }
                        this.snackbarService.showSnackbar(`Your job has been shared`);
                    },
                    err => {
                        this.snackbarService.handleError('Error occurred while sharing album');
                        console.error('Error occurred while sharing album:', err);
                    },
                    () => this.sendingMail = false
                );
            this.dialogService.currentDialogRef.close();
        }
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }

}
