<mat-dialog-content>
    <h1 class="mat-dialog-title">Fetch Invoice Data</h1>

    <div class="csv-period-selectors">
        <mat-form-field>
            <input matInput [matDatepicker]="startDatePicker" placeholder="Date: From" [max]="currentDate"
                   [value]="startDateSelected" [disabled]="fetchingCSVData">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker startView="month" [startAt]="currentDate"></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <input matInput [matDatepicker]="endDatePicker" placeholder="Date: Until" [max]="currentDate"
                   [value]="endDateSelected" [disabled]="fetchingCSVData">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker startView="month" [startAt]="currentDate"></mat-datepicker>
        </mat-form-field>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="fetchingCSVData"></mat-progress-bar>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button class="btn btn-red cancel-btn" (click)="closeModal()" [disabled]="fetchingCSVData">Cancel
    </button>
    <div class="main-action-btns">
        <button mat-raised-button class="btn btn-green" (click)="fetchInvoicesCSVData(true)"
                [disabled]="!startDateSelected?.valueOf() || !endDateSelected.valueOf() || fetchingCSVData">
            Fetch selected period's invoice data
        </button>
        <button mat-raised-button class="btn btn-green" (click)="fetchInvoicesCSVData()" [disabled]="fetchingCSVData">
            Fetch all invoice data
        </button>
    </div>
</mat-dialog-actions>