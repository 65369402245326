<h2 mat-dialog-title>Update miscellaneous account code</h2>

<mat-dialog-content>
    <form [formGroup]="fixAccountCodeForm">
        <mat-form-field>
            <mat-label>Misc. account Code</mat-label>
            <input matInput type="text" formControlName="miscAccountCode">
            <mat-error *ngIf="fixAccountCodeForm.get('miscAccountCode').hasError('required')">
                Account code required
            </mat-error>
        </mat-form-field>
    </form>
    <h6 *ngIf="fixAccountCodeForm.get('miscAccountCode').value === rejectedMiscAccountCode" class="account-code-error">
        Xero rejected account code "{{ rejectedMiscAccountCode }}"</h6>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button class="btn btn-red cancel-btn" [disabled]="updateLoading">Close</button>
    <button mat-raised-button
            class="btn btn-green"
            (click)="updateAccountCode()"
            [disabled]="fixAccountCodeForm.invalid || fixAccountCodeForm.get('miscAccountCode').value === rejectedMiscAccountCode || updateLoading">
        Update
    </button>
</mat-dialog-actions>
