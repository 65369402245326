export enum ServiceStatus {
    draft = 'Draft',
    inProgress = 'In Progress',
    delivered = 'Delivered',
    memberReview = 'Member Review',
    uploadInProgress = 'Uploading',
    awaitingApproval = 'Awaiting Approval',
    noProcessing = 'No Processing',
    approved = 'Approved'
}

export const JobStatusRank = {
    [ServiceStatus.memberReview]: 4,
    [ServiceStatus.inProgress]: 3,
    [ServiceStatus.draft]: 2,
    [ServiceStatus.noProcessing]: 1,
    'No Products': 0
};
