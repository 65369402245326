<div class="sidebar">
    <div class="sidebar-toggle">
        <div class="sidebar-toggle-item" routerLinkActive="active" [routerLink]="['/home']" #rlaHome="routerLinkActive">
            <img [src]="'../../../assets/icons/sidebar/home'+(rlaHome.isActive?'-active':'')+'.svg'" alt="">
        </div>

        <div class="sidebar-toggle-item"
             [ngStyle]="{'pointer-events': !(memberData$|async) || (memberData$|async)?.isContractor ? 'none' : 'all'}"
             routerLinkActive="active"
             [routerLink]="['/users']"
             #rlaUsers="routerLinkActive">
            <img [src]="'../../../assets/icons/sidebar/users'+(rlaUsers.isActive?'-active':'')+'.svg'" alt="">
        </div>

    </div>

    <div class="sidebar-menu-wrapper">
        <ul class="sidebar-menu" *ngIf="rlaHome.isActive">
            <li class="sidebar-menu-item"
                routerLinkActive="active"
                [routerLink]="['/home', 'jobs']">
                Jobs
            </li>
            <li class="sidebar-menu-item"
                routerLinkActive="active"
                *ngIf="!!(memberData$ | async) && !(memberData$ | async)?.isContractor"
                [routerLink]="['/home/invoices']"
                [routerLinkActiveOptions]="{exact: true}">
                Invoices
            </li>
            <li class="sidebar-menu-item"
                routerLinkActive="active"
                *ngIf="!!(memberData$ | async) && !(memberData$ | async)?.isContractor"
                [routerLink]="['/home/products']"
                [routerLinkActiveOptions]="{exact: true}">
                Products
            </li>
        </ul>
        <ul class="sidebar-menu" *ngIf="rlaUsers.isActive">
            <li class="sidebar-menu-item"
                routerLinkActive="active"
                [routerLink]="['/users/agencies']"
                [routerLinkActiveOptions]="{exact: true}">
                Agencies
            </li>
            <li class="sidebar-menu-item"
                routerLinkActive="active"
                [routerLink]="['/users/agents']"
                [routerLinkActiveOptions]="{exact: true}">
                Agents
            </li>
            <li class="sidebar-menu-item"
                routerLinkActive="active"
                [routerLink]="['/users/contractors']"
                [routerLinkActiveOptions]="{exact: true}">
                Contractors
            </li>
        </ul>
    </div>
</div>
