<div class="upload-modal" [ngClass]="{'hide': hideService}">
    <mat-card class="card-container" appearance="outlined">
        <mat-card-header>
            <mat-card-title class="brief-title">
                <div>{{ service.DISPLAY + ' Brief' }}</div>
                <div class="service-id">({{ service.id | shortenServiceID }})</div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content class="card-content">
            <ng-container *ngIf="this.service.SNAKE_CASE === ServiceEnum.VIDEO_SERVICES; else floorplanForm">
                <div class="form-wrapper">
                    <div class="field-group" [formGroup]="videoFormGroup">
                        <div class="form-field form-field-input">
                            <mat-label>Address</mat-label>
                            <mat-form-field appearance="outline">
                                <input matInput [formControl]="addressFormControl" placeholder="Enter an address">
                                <mat-error>Address is required</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-input">
                            <mat-label>Suburb</mat-label>
                            <mat-form-field appearance="outline">
                                <input matInput [formControl]="suburbFormControl" placeholder="Confirm the suburb">
                                <mat-error>Confirm your suburb</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-select">
                            <mat-label>Video Length Accuracy</mat-label>
                            <mat-form-field appearance="outline">
                                <mat-select [formControl]="videoLengthAccuracyFormControl" placeholder="Select">
                                    <mat-option *ngFor="let preference of videoLengthAccuracyPreferences"
                                                [value]="preference">{{ preference }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-select">
                            <mat-label>Video Length Preference</mat-label>
                            <mat-form-field appearance="outline">
                                <mat-select [formControl]="videoLengthPreferenceFormControl"
                                            placeholder="Select your video length preference">
                                    <mat-option *ngFor="let preference of videoLengthPreferences"
                                                [value]="preference">{{ preference }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-select">
                            <mat-label>Video Pace</mat-label>
                            <mat-form-field appearance="outline">
                                <mat-select [formControl]="videoPaceFormControl" placeholder="Select your video pace">
                                    <mat-option *ngFor="let preference of videoPacePreferences"
                                                [value]="preference">{{ preference }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-input">
                            <mat-label>Total Clips</mat-label>
                            <mat-form-field appearance="outline">
                                <input matInput [formControl]="totalClipsFormControl"
                                       placeholder="Enter the total number of clips">
                                <mat-error>Total clips required</mat-error>
                            </mat-form-field>
                        </div>
                        <hr>
                        <div class="form-field form-field-select">
                            <mat-label>Service Type</mat-label>
                            <mat-form-field appearance="outline">
                                <mat-select [formControl]="productTypeFormControl"
                                            (selectionChange)="onServiceSelectionChange($event.value)"
                                            placeholder="Select">
                                    <mat-option *ngFor="let preference of videoServiceStandardOptions"
                                                [value]="preference">{{ preference }}
                                    </mat-option>
                                    <mat-option *ngFor="let preference of videoServicePremiumOptions"
                                                [value]="preference">{{ preference }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <ng-container *ngIf="videoServiceFormExpanded">
                            <div class="form-field form-field-select">
                                <mat-label>Order Priority</mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select [formControl]="videoOrderPriorityFormControl" placeholder="Select">
                                        <mat-option *ngFor="let preference of orderPriorityOptions"
                                                    [value]="preference">{{ preference }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>Order priority is required</mat-error>
                                </mat-form-field>
                            </div>
                            <hr>
                            <div class="form-field form-field-input">
                                <mat-label>Agency Name</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput type="text" [formControl]="agencyNameFormControl"
                                           placeholder="Enter you agency name">
                                </mat-form-field>
                            </div>
                            <div class="form-field form-field-select">
                                <mat-label>Number of Agents</mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select [formControl]="agentsCounterFormControl"
                                                (selectionChange)="onAgentsCounterChange($event.value)"
                                                placeholder="Select if required">
                                        <mat-option *ngFor="let preference of agentsCounterOptions"
                                                    [value]="preference">{{ preference }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="agentsArrayFormControl">
                                <div class="agents-wrapper" formArrayName="agentsArray"
                                     *ngFor="let agent of agentsArrayFormControl.controls; let i = index;">
                                    <hr>
                                    <div class="field-group" [formGroupName]="i">
                                        <h6 class="agent-title">{{ 'Agent ' + (i + 1) }}</h6>
                                        <div class="form-field form-field-input">
                                            <mat-label>Agent Name</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput type="text" formControlName="name"
                                                       placeholder="Enter your agent's name">
                                                <mat-error>Agent name is required</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="form-field form-field-input">
                                            <mat-label>Contact Number</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput type="text" formControlName="contactNumber"
                                                       placeholder="Enter your agent's contact number">
                                                <mat-error>Agent name is required</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="form-field form-field-input">
                                            <mat-label>Email</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput type="text" formControlName="email"
                                                       placeholder="Enter your agent's email">
                                                <mat-error>Email is required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="form-field test-field">
                                <span>Requires Property Feature Icons</span>
                                <mat-slide-toggle color="primary"
                                                  [formControl]="requiresPropertyFeatureIconsFormControl"
                                                  (change)="onRequirePropertyFeatureIconsChange($event.checked)"
                                >
                                </mat-slide-toggle>
                            </div>
                            <div *ngIf="requiresPropertyFeatureIconsFormControl.value else requireGoogleEarthAnimations">
                                <div class="form-field counter-item">
                                    <span class="sub-section-title">Beds</span>
                                    <div class="counter-wrapper">
                                        <button class="counter-btn" mat-icon-button
                                                (click)="updateCount(bedsFormControl, -1)">
                                            <svg-icon src="/assets/icons/products/minus.svg"></svg-icon>
                                        </button>
                                        <input class="num-input"
                                               matInput
                                               type="text"
                                               [formControl]="bedsFormControl"
                                               [value]="bedsFormControl.valueChanges | async"
                                        >
                                        <button class="counter-btn" mat-icon-button
                                                (click)="updateCount(bedsFormControl, 1)">
                                            <svg-icon src="/assets/icons/products/plus.svg"></svg-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-field counter-item">
                                    <span class="sub-section-title">Baths</span>
                                    <div class="counter-wrapper">
                                        <button class="counter-btn" mat-icon-button
                                                (click)="updateCount(bathsFormControl, -1)">
                                            <svg-icon src="/assets/icons/products/minus.svg"></svg-icon>
                                        </button>
                                        <input class="num-input"
                                               matInput
                                               type="text"
                                               [formControl]="bathsFormControl"
                                               [value]="bathsFormControl.valueChanges | async"
                                        >
                                        <button class="counter-btn" mat-icon-button
                                                (click)="updateCount(bathsFormControl, 1)">
                                            <svg-icon src="/assets/icons/products/plus.svg"></svg-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-field counter-item">
                                    <span class="sub-section-title">Cars</span>
                                    <div class="counter-wrapper">
                                        <button class="counter-btn" mat-icon-button
                                                (click)="updateCount(carsFormControl, -1)">
                                            <svg-icon src="/assets/icons/products/minus.svg"></svg-icon>
                                        </button>
                                        <input class="num-input"
                                               matInput
                                               type="text"
                                               [formControl]="carsFormControl"
                                               [value]="carsFormControl.valueChanges | async"
                                        >
                                        <button class="counter-btn" mat-icon-button
                                                (click)="updateCount(carsFormControl, 1)">
                                            <svg-icon src="/assets/icons/products/plus.svg"></svg-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-field form-field-input">
                                    <mat-label class="sub-section-title">Square Metreage</mat-label>
                                    <mat-form-field appearance="outline">
                                        <input matInput [formControl]="areaFormControl"
                                               placeholder="Enter the square metreage">
                                        <mat-error>Square metreage is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <ng-template #requireGoogleEarthAnimations>
                                <div *ngIf="requiresPropertyFeatureIconsFormControl.value === false"
                                     class="form-field form-field-select">
                                    <mat-label>Do You Require Google Earth Animation</mat-label>
                                    <mat-form-field appearance="outline">
                                        <mat-select [formControl]="requiresGoogleEarthAnimationFormControl"
                                                    placeholder="Select">
                                            <mat-option [value]="true">Yes</mat-option>
                                            <mat-option [value]="false">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </ng-template>
                            <hr>
                            <div class="form-field form-field-select">
                                <mat-label>Featured People</mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select [formControl]="featuredPeopleFormControl" placeholder="Select" multiple>
                                        <mat-option *ngFor="let preference of videoFeaturedPeopleOptions"
                                                    [value]="preference">{{ preference }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-field form-field-select">
                                <mat-label>Assets To Be Included In Video</mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select [formControl]="includeVideoAssetsFormControl" placeholder="Select"
                                                multiple>
                                        <mat-option *ngFor="let preference of videoIncludedVideoAssetsOptions"
                                                    [value]="preference">{{ preference }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-field form-field-select">
                                <mat-label>Would You Like Light Leaks Used?</mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select [formControl]="requiresLightLeaksFormControl" placeholder="Select">
                                        <mat-option *ngFor="let preference of standardRequiredResponseOptions"
                                                    [value]="preference">{{ preference }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <hr>
                            <div class="form-field test-field">
                                <span>Do you require After Effects?</span>
                                <mat-slide-toggle color="primary"
                                                  [formControl]="requiresAfterEffectsFormControl"
                                                  (change)="onRequireAfterEffectsChange($event.checked)"
                                >
                                </mat-slide-toggle>
                            </div>
                            <div *ngIf="requiresAfterEffectsFormControl.value">
                                <div class="field-group" [formGroup]="afterEffectsFormGroup">
                                    <div class="form-field counter-item">
                                        <span class="sub-section-title">Number of After Effects required?</span>
                                        <div class="counter-wrapper">
                                            <button class="counter-btn" mat-icon-button
                                                    (click)="updateCount(afterEffectsElementsFormControl, -1)">
                                                <svg-icon src="/assets/icons/products/minus.svg"></svg-icon>
                                            </button>
                                            <input class="num-input"
                                                   matInput
                                                   type="text"
                                                   [formControl]="afterEffectsElementsFormControl"
                                                   [value]="afterEffectsElementsFormControl.valueChanges | async"
                                            >
                                            <button class="counter-btn" mat-icon-button
                                                    (click)="updateCount(afterEffectsElementsFormControl, 1)">
                                                <svg-icon src="/assets/icons/products/plus.svg"></svg-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-field form-field-checkbox">
                                        <span class="sub-section-title">Do You Require Property Outlines?</span>
                                        <mat-checkbox color="primary"
                                                      [formControl]="requiresPropertyOutlinesFormControl"
                                        >
                                        </mat-checkbox>
                                    </div>
                                    <div class="form-field form-field-checkbox">
                                        <span class="sub-section-title">Do You Require Location Icons?</span>

                                        <mat-checkbox color="primary"
                                                      [formControl]="requiresLocationIconsFormControl"
                                        >
                                        </mat-checkbox>
                                    </div>
                                    <div class="form-field form-field-input">
                                        <mat-label class="sub-section-title">Other Relevant After Effect Information
                                        </mat-label>
                                        <mat-form-field appearance="outline">
                                            <input matInput [formControl]="otherAfterEffectInformationFormControl"
                                                   placeholder="Enter if relevant">
                                        </mat-form-field>
                                    </div>

                                    <div class="form-field test-field">
                                        <span>Do You Require Callout Titles?</span>
                                        <mat-slide-toggle color="primary"
                                                          [formControl]="requiresCalloutTitlesFormControl"
                                                          (change)="onRequireCalloutTitlesChange($event.checked)"
                                        >
                                        </mat-slide-toggle>
                                    </div>
                                    <div *ngIf="requiresCalloutTitlesFormControl.value">
                                        <div class="callout-title-wrapper" formArrayName="calloutTitles"
                                             *ngFor="let title of calloutTitlesFormArray.controls; let i = index;">
                                            <div class="callout-title-body">
                                                <h6 class="callout-title">{{ 'Callout Title ' + (i + 1) }}</h6>
                                                <div class="form-field form-field-input callout-title-input-wrapper">
                                                    <mat-form-field class="callout-title-input">
                                                        <input matInput type="text" [formControlName]="i"
                                                               placeholder="Enter your callout title">
                                                        <mat-error>Callout title is required</mat-error>
                                                    </mat-form-field>
                                                    <button class="remove-callout-title"
                                                            (click)="removeCalloutTitle(i)"
                                                            mat-icon-button>
                                                        <svg-icon src="/assets/icons/products/bin.svg"></svg-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <button mat-button class="btn add-title-btn" (click)="addCalloutTitle()">Add
                                            Callout Title
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="form-field form-field-select">
                                <mat-label>Music Choice</mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select [formControl]="requiresMusicFormControl"
                                                (selectionChange)="onRequireMusicChange($event.value)"
                                                placeholder="Select">
                                        <mat-option *ngFor="let preference of videoMusicOptions"
                                                    [value]="preference">{{ preference }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="(requiresMusicFormControl.valueChanges | async) === videoMusicOptions[1]">
                                <div *ngIf="musicGenreFormControl" class="form-field form-field-select">
                                    <mat-label>Specific Genre</mat-label>
                                    <mat-form-field appearance="outline">
                                        <mat-select [formControl]="musicGenreFormControl" placeholder="Select">
                                            <mat-option *ngFor="let preference of videoMusicGenreOptions"
                                                        [value]="preference">{{ preference }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="(requiresMusicFormControl.valueChanges | async) === videoMusicOptions[2]">
                                <div *ngIf="trackNameFormControl" class="form-field form-field-input">
                                    <mat-label>Specific Track</mat-label>
                                    <mat-form-field appearance="outline">
                                        <input matInput [formControl]="trackNameFormControl"
                                               placeholder="Enter the track name">
                                    </mat-form-field>
                                </div>
                            </div>
                        </ng-container>
                        <hr>
                        <div class="form-field form-field-input">
                            <mat-label>Reference Video Links</mat-label>
                            <mat-form-field appearance="outline">
                                <input matInput [formControl]="videoLinkReferencesFormControl"
                                       placeholder="Enter your reference video links">
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-input">
                            <mat-label>Key Features</mat-label>
                            <mat-form-field appearance="outline">
                                <input matInput [formControl]="keyFeaturesFormControl"
                                       placeholder="Enter any key features">
                            </mat-form-field>
                        </div>
                        <div class="form-field service">
                            <mat-label>Notes</mat-label>
                            <angular-editor [config]="briefEditorConfig"
                                            [formControl]="notesFormControl"></angular-editor>
                        </div>
                        <div class="form-field test-field">
                            <span>Content can be used on BWRM Social Media?</span>
                            <mat-slide-toggle color="primary"
                                              [formControl]="allowBWRMSocialMediaFormControl"
                                              (change)="onToggleChange($event.checked, allowBWRMSocialMediaFormControl)"
                            >
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #floorplanForm>
                <div class="form-wrapper">
                    <div class="field-group" [formGroup]="floorplanFormGroup">
                        <div class="form-field form-field-input">
                            <mat-label>Property Address</mat-label>
                            <mat-form-field appearance="outline">
                                <input matInput [formControl]="propertyAddressFormControl"
                                       placeholder="Enter an address">
                                <mat-error>Property address is required</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-select">
                            <mat-label>Order Priority</mat-label>
                            <mat-form-field appearance="outline">
                                <mat-select [formControl]="floorplanOrderPriorityFormControl" placeholder="Select">
                                    <mat-option *ngFor="let preference of orderPriorityOptions"
                                                [value]="preference">{{ preference }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>Order priority is required</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-input">
                            <mat-label>Client Name</mat-label>
                            <mat-form-field appearance="outline">
                                <input matInput type="text" [formControl]="clientNameFormControl" placeholder="Select">
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-select">
                            <mat-label>Product Style</mat-label>
                            <mat-form-field appearance="outline">
                                <mat-select [formControl]="productStyleFormControl" placeholder="Select">
                                    <mat-option *ngFor="let preference of productStyleOptions"
                                                [value]="preference">{{ preference }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>Product style is required</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-select">
                            <mat-label>Output Format</mat-label>
                            <mat-form-field appearance="outline">
                                <mat-select [formControl]="outputFormatFormControl" placeholder="Select">
                                    <mat-option *ngFor="let preference of outputFormatOption"
                                                [value]="preference">{{ preference }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>Output format is required</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-select">
                            <mat-label>Land/House size output format</mat-label>
                            <mat-form-field appearance="outline">
                                <mat-select [formControl]="propertySizeOutputFormatFormControl" placeholder="Select">
                                    <mat-option *ngFor="let preference of propertySizeOutputFormatOption"
                                                [value]="preference">{{ preference }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>Size is required</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field form-field-input">
                            <mat-label>Other Instructions</mat-label>
                            <mat-form-field appearance="outline">
                                <input matInput type="text" [formControl]="otherInstructionsFormControl"
                                       placeholder="Enter any other relevant information">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-card-content>
        <mat-card-actions>
            <button class="btn btn-red cancel-btn" mat-button (click)="onCancelClicked()"
                    [disabled]="loadingInProgress">
                Confirm Later
            </button>
            <button class="btn btn-green upload-btn"
                    mat-raised-button
                    [disabled]="(!videoFormGroup?.valid && !floorplanFormGroup?.valid)"
                    (click)="createTicket()">
                Confirm
                <ng-template [ngIf]="loadingInProgress">
                    <div class="lds-hourglass"></div>
                </ng-template>
            </button>
        </mat-card-actions>
    </mat-card>
</div>
