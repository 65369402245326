import {AbstractControl, UntypedFormArray, ValidationErrors} from '@angular/forms';
import {EMAIL_REGEX} from '../helpers';

export class CustomValidators {
    static emailList(formArray: UntypedFormArray): ValidationErrors | null {
        if (formArray.controls.length >= 1) {
            for (const control of formArray.controls) {
                if (control.invalid) {
                    return control.errors;
                }
            }
        }
        return null;
    }

    static validEmail(formControl: AbstractControl): { [key: string]: boolean } {
        return EMAIL_REGEX.test(formControl.value) ? null : {notEmail: true};
    }
}

