import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Agent, MinimalAgent} from '../models';
import {environment} from '../../environments';
import {map} from 'rxjs/operators';
import {PrismHeaders} from '../enums';

@Injectable({
    providedIn: 'root'
})
export class AgentService {
    private agentsTableSizePreference: number;

    constructor(private http: HttpClient) {
        this.agentsTableSizePreference = 10;

    }

    getAgents(
        sort: string,
        order: string,
        page: number,
        limit: number,
        status: string,
        search: string = ''): Observable<{ data: Agent[], availableItems: number }> {
        return this.http.get<{ data: Agent[], availableItems: number }>(environment.apiBaseUrl + '/GetAgents', {
            params: {sort, order, page: page.toString(), limit: limit.toString(), status, search}
        });
    }

    get agentsTableSize() {
        return this.agentsTableSizePreference;
    }

    set updateAgentsTableSize(size: number) {
        this.agentsTableSizePreference = size;
    }

    getAgentNames(): Observable<MinimalAgent[]> {
        return this.http.get<{ data: MinimalAgent[] }>(environment.apiBaseUrl + '/GetAgentNames').pipe(map(res => res.data));
    }

    postAgent(agent: Agent): Observable<string> {
        return this.http.post<{ ref: string }>(environment.apiBaseUrl + '/PostAgent', agent).pipe(map(val => val.ref));
    }

    putAgent(agent: Agent): Observable<string> {
        return this.http.put<{ ref: string }>(environment.apiBaseUrl + '/PutAgent', agent).pipe(map(val => val.ref));
    }

    getAgent(agentId: string): Observable<Agent> {
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({agentId: agentId}));
        return this.http.get<{ data: Agent }>(environment.apiBaseUrl + '/GetAgent', {headers}).pipe(map(res => res.data));
    }

    updateAgentsActiveStatus(agentIds: string[], setActiveStatus: boolean) {
        const headers = new HttpHeaders()
            .append(PrismHeaders.FunctionHelperData, JSON.stringify({activeStatus: setActiveStatus}));

        return this.http.put(environment.apiBaseUrl + '/UpdateAgentsActiveStatus', {ids: JSON.stringify(agentIds)}, {headers});
    }
}
