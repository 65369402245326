import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AgentJobViewComponent} from 'src/app/components/agent-job-view/agent-job-view.component';
import {AgentModule} from '../modules/agent.module';

const agentRoutes: Routes = [
    {
        path: 'job/:shareUid',
        component: AgentJobViewComponent
    }
];

export const AgentRouting: ModuleWithProviders<AgentModule> = RouterModule.forChild(agentRoutes);
