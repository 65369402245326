import {Component, OnInit} from '@angular/core';
import {
    AssetsService,
    AuthenticationService,
    DialogService,
    JobsService,
    SnackbarService
} from '../../services';
import 'firebase/storage';
import {DownloadSelection} from '../../models';

@Component({
    selector: 'prism-download-all-selected-assets-modal',
    templateUrl: './download-all-selected-assets-modal.component.html',
    styleUrls: ['./download-all-selected-assets-modal.component.scss']
})
export class DownloadAllSelectedAssetsModalComponent implements OnInit {
    selectedDownloadTypes: DownloadSelection = {
        print: true,
        web: false,
        watermark: false
    };
    allAssetPaths: string[];
    jobId: number;
    selectedAssets: string[];
    memberProfileUid;

    constructor(private dialogService: DialogService,
                private jobsService: JobsService,
                private snackbarService: SnackbarService,
                private assetsService: AssetsService,
                private authService: AuthenticationService
    ) {
    }

    ngOnInit() {
        const {id: jobId} = {id: this.dialogService.inputData.jobId};
        this.jobId = jobId;
        if (!!this.dialogService.inputData) {
            this.selectedAssets = this.dialogService.inputData.selectedAssets;
        }
    }

    closeDialog() {
        this.dialogService.closeAllDialogs();
    }

    requestDownload() {
        const {address} = this.jobsService.selectedJob || {address: this.dialogService.inputData.address};
        let finalDownloadAssetPaths = this.assetsService.getSelectedAssetsBucketPaths(this.selectedDownloadTypes, this.selectedAssets, this.jobId, this.authService.getUserUid());
        this.assetsService.sendDownloadRequest(address, `job_${this.jobId}`, finalDownloadAssetPaths);
        this.snackbarService.showDismissibleSnackbar();
        this.dialogService.closeAllDialogs();
    }

    changeDownloadTypeSelection(type: string, checked) {
        this.selectedDownloadTypes[type] = checked;
    }
}
