import {NgModule} from '@angular/core';
import {AddEditAgencyComponent} from 'src/app/components/add-edit-agency/add-edit-agency.component';
import {AddEditAgentComponent} from 'src/app/components/add-edit-agent/add-edit-agent.component';
import {AddEditContractorComponent} from 'src/app/components/add-edit-contractor/add-edit-contractor.component';
import {AgenciesPageComponent} from 'src/app/components/agencies-page/agencies-page.component';
import {AgenciesTableComponent} from 'src/app/components/agencies-page/agencies-table/agencies-table.component';
import {AgentsPageComponent} from 'src/app/components/agents-page/agents-page.component';
import {AgentsTableComponent} from 'src/app/components/agents-page/agents-table/agents-table.component';
import {ContractorsPageComponent} from 'src/app/components/contractors-page/contractors-page.component';
import {ContractorsTableComponent} from 'src/app/components/contractors-page/contractors-table/contractors-table.component';
import {SharedModule} from './shared.module';

import {UserRouting} from '../routing/users.routing';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RequestContractorComponent} from '../../components';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        ReactiveFormsModule,
        UserRouting
    ],
    declarations: [
        AgenciesPageComponent,
        AgenciesTableComponent,
        AgentsPageComponent,
        AgentsTableComponent,
        AddEditContractorComponent,
        AddEditAgencyComponent,
        AddEditAgentComponent,
        ContractorsPageComponent,
        ContractorsTableComponent,
        RequestContractorComponent
    ],
    exports: []
})

export class UsersModule {
}
