import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

import {DialogService} from '../../../services';

@Component({
    selector: 'prism-invoice-display',
    templateUrl: './invoice-display.component.html',
    styleUrls: ['./invoice-display.component.scss']
})
export class InvoiceDisplayComponent implements OnInit {

    url: SafeResourceUrl;

    constructor(private dialogService: DialogService, private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        const {url} = this.dialogService.inputData;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

}
