import {Component} from '@angular/core';

@Component({
    selector: 'prism-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss']
})
export class BaseComponent {
    constructor() {
    }
}
