import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {JobsService} from '../jobs.service';

@Injectable({
    providedIn: 'root'
})
export class JobGuard {
    constructor(private jobsService: JobsService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        if (this.jobsService.selectedJob) {
            return true;
        } else {
            this.router.navigate(['home', 'jobs']);
            return false;
        }
    }
}
