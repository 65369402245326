import {Component, Input} from '@angular/core';

@Component({
    selector: 'prism-final-display',
    templateUrl: './final-display.component.html',
    styleUrls: ['./final-display.component.scss']
})

export class FinalDisplayComponent {
    loadingAsset: boolean = true;

    constructor() {
    }

    @Input('finalAsset')
    set finalAssetSetter(finalAsset: FinalAsset) {
        const {finalLoaded, element: finalElement, type, videoUrl} = finalAsset;
        if (type === 'image' || type === 'video-preview-image') {
            this.loadingAsset = !finalLoaded;
            let element = window.document.getElementsByClassName('final-display-wrapper')[0];
            (type === 'image') ? finalElement.classList.add('final-asset') : finalElement.classList.add('video-preview-image');

            if (element.childNodes.length > 0) {
                let oldChild = element.childNodes[0];
                oldChild.replaceWith(finalElement);
            } else {
                element.appendChild(finalElement);
            }
        } else if (type === 'video') {
            if (!!videoUrl) {
                this.loadingAsset = !finalLoaded;
                let element = window.document.getElementsByClassName('final-display-wrapper')[0];
                const video: HTMLMediaElement = document.createElement('video');
                video.classList.add('final-asset');
                video.src = videoUrl;
                video.style.width = '1000px';
                video.style.height = '600px';
                video.controls = true;
                video.setAttribute('controlsList', 'nodownload');

                if (element.childNodes.length > 0) {
                    let oldChild = element.childNodes[0];
                    oldChild.replaceWith(video);
                } else {
                    element.appendChild(video);
                }
            }
        }
    }
}

interface FinalAsset {
    finalLoaded: boolean;
    element: HTMLImageElement;
    type: 'image' | 'video' | 'video-preview-image';
    videoUrl: string;
}
