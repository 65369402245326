import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Job, Service} from '../../models';
import {JobsService, ProductsService} from '../../services';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {VideoFloorplanUploadComponent} from '../add-job-product/video-floorplan-upload/video-floorplan-upload.component';
import {FLOORPLAN_SERVICES, VIDEO_SERVICES} from '../../enums';

@Component({
    selector: 'prism-form-upload-dialog',
    templateUrl: './form-upload-dialog.component.html',
    styleUrls: ['./form-upload-dialog.component.scss']
})
export class FormUploadDialogComponent implements OnInit {
    service: Service;
    productId: string;
    job: Job;
    productsLoading$: Observable<boolean>;

    constructor(
        private jobService: JobsService,
        private productsService: ProductsService,
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<VideoFloorplanUploadComponent>
    ) {
        this.job = this.jobService.selectedJob;
        this.productsLoading$ = this.productsService.areMemberProductsLoading$;
    }

    ngOnInit() {
        let {service, product} = this.data;
        this.service = service;
        this.productId = product.id;
    }

    closeAllDialogHandler(event: {cancelled: boolean, fileCount: number, serviceId: string}) {
        this.dialogRef.close(event);
    }

    protected readonly FLOORPLAN_SERVICES = FLOORPLAN_SERVICES;
    protected readonly VIDEO_SERVICES = VIDEO_SERVICES;
}