<div class="update-password-form" [formGroup]="updatePasswordForm">
    <mat-form-field appearance="outline">
        <mat-label>New Password</mat-label>
        <input matInput formControlName="password" type="text">
        <mat-error *ngIf="updatePasswordForm.get('password').hasError('required')">Please enter new password</mat-error>
        <mat-error
                *ngIf="!updatePasswordForm.get('password').hasError('required') && updatePasswordForm.get('password').hasError('minlength')">
            Password needs to be at least 6 characters
        </mat-error>
    </mat-form-field>


    <mat-form-field appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input matInput formControlName="passwordConfirm" type="text">
        <mat-error *ngIf="updatePasswordForm.get('passwordConfirm').hasError('required')">Please confirm password
        </mat-error>
        <mat-error
                *ngIf="!updatePasswordForm.get('passwordConfirm').hasError('required') && passwordsMatch && updatePasswordForm.get('passwordConfirm').hasError('minlength')">
            Password needs to be at least 6 characters
        </mat-error>
        <mat-error
                *ngIf="updatePasswordForm.get('passwordConfirm').touched && !!updatePasswordForm.get('passwordConfirm').value && !passwordsMatch">
            Passwords needs to match
        </mat-error>
    </mat-form-field>
    <button mat-raised-button class="btn btn-blue" (click)="updatePassword()" [disabled]="updatingPassword">Update
        Password
    </button>
</div>

