<div class="mobile-swiper-wrapper">
    <div class="option-btns">
        <button mat-icon-button [ngClass]="caterForIOSStyle ? 'file-download-btn-ios' : 'file-download-btn'"
                (click)="requestDownload()">
            <mat-icon>get_app</mat-icon>
        </button>
        <button mat-icon-button [ngClass]="caterForIOSStyle  ? 'gallery-close-btn-ios' : 'gallery-close-btn'"
                (click)="requestClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="swiper-wrapper" *ngIf="assetsList.length > 0">
        <prism-swiper
                [assets]="assetsList"
                [initialIndex]="initialIndex"
                [mobileData]="mobileData"
        >

        </prism-swiper>
    </div>
</div>



