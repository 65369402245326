import {Component, OnDestroy, OnInit, ViewChildren} from '@angular/core';
import {FormControl, FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import clone from 'lodash/clone';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {FLOORPLAN_SERVICES, IMAGE_PROCESSING, VIDEO_SERVICES} from '../../enums';
import {Job, JobProduct, Response, Service} from '../../models';
import {DialogService, JobsService, ProductsService, SnackbarService} from '../../services';
import {ImageUploadComponent} from './image-upload/image-upload.component';

@Component({
    selector: 'prism-add-job-product',
    templateUrl: './add-job-product.component.html',
    styleUrls: ['./add-job-product.component.scss'],
})
export class AddJobProductComponent implements OnInit, OnDestroy {
    private productCreating: boolean;
    productsLoading$: Observable<boolean>;
    productToAdd: Observable<JobProduct>;
    productCreatedAndServicesAdded: BehaviorSubject<{
        onlyEdit: boolean,
        productCreated: boolean,
        servicesClosed: boolean
    }>;
    productToAddSub: Subscription;
    services: Service[];
    uploadsRefArray: any[];
    parentJob: Job;
    addJobProductForm: FormGroup;
    productBriefs: Service[];
    serviceFileCount: { [serviceId: string]: number };
    productId: string;
    openServicesCount: number;
    closedServicesCount: number;
    cancelledServiceCount: number;
    productBriefsCompleted: number;
    uploadsStarted: number;
    uploadsCancelled: number;
    addProductHandled: boolean;
    displayForms: boolean;

    @ViewChildren('imageServiceUploads') imageServiceUploads: ImageUploadComponent[];

    constructor(private formBuilder: UntypedFormBuilder,
                private dialogService: DialogService,
                private jobsService: JobsService,
                private snackbarService: SnackbarService,
                private productsService: ProductsService) {
        this.productCreating = false;
        this.productsLoading$ = this.productsService.areMemberProductsLoading$;
        this.productToAdd = this.productsService.productToAdd$;
        this.productCreatedAndServicesAdded = new BehaviorSubject({productCreated: false, onlyEdit: false, servicesClosed: false});
        this.services = [];
        this.closedServicesCount = 0;
        this.cancelledServiceCount = 0;
        this.addProductHandled = false;
        this.uploadsRefArray = [];
        this.parentJob = this.jobsService.selectedJob;
        this.addJobProductForm = this.formBuilder.group({
            product: new FormControl(['', [Validators.required]]),
            job: new FormControl(this.jobsService.selectedJob)
        });
        this.productBriefs = [];
        this.productBriefsCompleted = 0;
        this.uploadsStarted = 0;
        this.uploadsCancelled = 0;
        this.displayForms = false;
        this.serviceFileCount = {};
    }

    ngOnInit() {
        this.parentJob = this.jobsService.selectedJob;
        this.dialogService.currentDialogRef.disableClose = true;
        const jobId = this.parentJob.id;
        this.productCreatedAndServicesAdded.asObservable()
            .subscribe(({productCreated, servicesClosed, onlyEdit}) => {
                if (onlyEdit) {
                    this.uploadsRefArray.forEach(({uploadComponent, destination, serviceId, onlyEdit}) => {
                        uploadComponent.startUpload(jobId, destination, serviceId, onlyEdit);
                    });
                } else if (servicesClosed) {
                    if (productCreated) {
                        this.uploadsRefArray.forEach(({uploadComponent, destination, serviceId, onlyEdit}) => {
                            uploadComponent.startUpload(jobId, destination, serviceId, onlyEdit);
                        });
                    }
                }
            });

        this.productToAddSub = this.productToAdd
            .pipe(
                switchMap(product => {
                    return this.productsService.generateProductAndServiceIds(product)
                        .pipe(
                            map((response: Response) => {
                                const product = response.data;
                                const productRef = clone(product);

                                this.services = product.services;
                                this.openServicesCount = this.services.length;
                                this.productId = product.id;
                                this.addJobProductForm.get('product').setValue(productRef);
                                return product;

                            }),
                            catchError(e => {
                                console.error('Error generating service IDs:', e);
                                return e;
                            })
                        );
                })
            )
            .subscribe(
                () => {
                    this.productsService.switchOffAllJobProductLoaders();
                },
                (err) => {
                    console.error(`Error getting product to add ${err.valueOf()}`);
                    this.productsService.switchOffAllJobProductLoaders();
                }
            );
    }

    addProduct(product) {
        this.addProductHandled = true;
        this.snackbarService.showSnackbar('Loading...');
        return this.productsService.postJobProduct(product, this.parentJob);
    }

    closeUploaderHandler(event: { cancelled: boolean, fileCount: number, serviceId: string }) {
        const {cancelled, serviceId, fileCount} = event;

        if (cancelled) {
            this.uploadsCancelled++;
        } else {
            this.uploadsStarted++;
            this.serviceFileCount[serviceId] = fileCount;
        }

        if ((this.uploadsStarted + this.uploadsCancelled) === this.openServicesCount) {

            if (this.productBriefs.length > 0) {
                this.displayForms = true;
            } else {
                this.dialogService.closeAllDialogs();
            }
        }
    }

    closeServiceBriefHandler() {
        this.productBriefsCompleted++;
        if (this.productBriefs.length === this.productBriefsCompleted) {
            this.dialogService.closeAllDialogs();
        }
    }

    updateClosedServices(updateData: { service, cancelled?, onlyEdit?, uploadComponent, destination }) {
        (updateData.cancelled) && this.cancelledServiceCount++;
        const product = this.addJobProductForm.get('product').value;
        this.closedServicesCount++;

        if (!updateData.cancelled) {
            this.uploadsRefArray.push({
                uploadComponent: updateData.uploadComponent,
                destination: updateData.destination,
                serviceId: updateData.service.id,
                onlyEdit: updateData.onlyEdit
            });

            if (!updateData.onlyEdit) {
                if (updateData.service.SNAKE_CASE !== IMAGE_PROCESSING.SNAKE_CASE && updateData.service.version === 'v2' && updateData.service.processingRequired) {
                    this.productBriefs.push(updateData.service);
                }

                if (!this.productCreating) {
                    this.productCreating = true;
                    this.addProduct(product).then(() => {
                        this.jobsService.jobs
                            .pipe(take(1))
                            .subscribe((jobs) => {
                                if (jobs) {
                                    jobs.every(jobIteration => {
                                        if (this.parentJob.id === jobIteration.id) {
                                            jobIteration.productsToInvoice = true;
                                            this.jobsService.jobsBSubjectSetter = jobs;
                                            return false;
                                        }
                                        return true;
                                    });
                                }
                            });
                        return this.productsService.getJobProducts().toPromise();
                    }).then(() => {
                        this.productCreatedAndServicesAdded.next({...this.productCreatedAndServicesAdded.value, productCreated: true});
                        this.productsService.switchOffAllJobProductLoaders();
                    }).catch(e => {
                        console.error('Could not create Product:', e);
                        this.snackbarService.handleError('Could not create product');
                    });
                }
            }
        }

        if (this.openServicesCount === this.closedServicesCount) {
            this.productCreatedAndServicesAdded.next({
                ...this.productCreatedAndServicesAdded.value,
                servicesClosed: true,
                onlyEdit: updateData.onlyEdit
            });
        }
    }

    ngOnDestroy() {
        if (!!this.productToAddSub) {
            this.productToAddSub.unsubscribe();
        }
    }

    protected readonly FLOORPLAN_SERVICES = FLOORPLAN_SERVICES;
    protected readonly VIDEO_SERVICES = VIDEO_SERVICES;
}

