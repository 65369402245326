<div #dropzone [ngClass]="{'disable-dropzone': disableDropzone}" class="dropzone">
    <div class="dz-message" data-dz-message>
        <span #dropzoneMessage>Drag & drop to upload or
            <button class="mat-button file-select-btn drag-over-btn" mat-button>Select files</button>
        </span>
    </div>
</div>

<div *ngIf="serviceDropzone?.files as queue" class="upload-queue">
    <div *ngIf="queue?.length">
        <span *ngIf="uploadLimited"
              class="upload-limited">Upload limit of 5GB reached. Please reduce upload size.</span>
        <div class="upload-counter-wrapper">
           <span class="upload-amount"><span
                   class="files-amount">{{ queue?.length }} {{ queue?.length === 1 ? 'file' : 'files' }}</span> to be uploaded:</span>
            <div class="upload-total-information">
                <strong *ngIf="formattedFileDuration" class="files-duration">{{ formattedFileDuration }}</strong>
                <strong>{{ formattedFileSizeCounter }}</strong>
            </div>
        </div>

        <ul class="file-queue-list">
            <li *ngFor="let uploadFile of queue" class="file-queue-item">
                <span class="file-item-name">{{ uploadFile?.name }}</span>
                <div class="file-action-wrapper">
                    <span *ngIf="(fileDuration$ | async)[uploadFile.name] as duration"
                          class="file-action-duration">{{ duration.duration }}s</span>
                    <span class="file-item-size">{{ fileSizeCounter[uploadFile.name].size }}</span>
                    <button (click)="removeFileFromQueue(uploadFile)"
                            [disabled]="disableDropzone"
                            aria-label="Remove asset from upload queue."
                            class="remove-file-item"
                            color="warn"
                            mat-icon-button>
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </li>
        </ul>
    </div>
</div>
