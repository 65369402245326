import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Router} from '@angular/router';
import 'firebase/auth';
import 'firebase/firestore';
import {BehaviorSubject, Subject} from 'rxjs';
import {first, map, shareReplay} from 'rxjs/operators';
import {environment} from '../../environments';
import {RefreshDialogComponent} from '../components/refresh-dialog/refresh-dialog.component';
import {Contractor, Member, Response} from '../models';
import {AssetsService} from './assets.service';
import {JobsService} from './jobs.service';
import {SnackbarService} from './snackbar.service';
import {
    confirmPasswordReset,
    getAuth,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInAnonymously,
    signInWithEmailAndPassword,
    User,
    verifyPasswordResetCode
} from 'firebase/auth';
import {collection, doc, getDoc, getFirestore, onSnapshot} from 'firebase/firestore';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    public showLoginLoader = new Subject<boolean>();
    private _memberData: BehaviorSubject<Member | Contractor>;
    private _currentUserEmail: string;
    private _currentUserUid: string;
    private sessionTokenSource: BehaviorSubject<string>;
    private _loggedIn: BehaviorSubject<boolean>;
    private _isContractor: BehaviorSubject<boolean>;
    private _activatedRoute: string;
    private _resetPasswordCode: string;
    private _appHasInternetConnection: BehaviorSubject<boolean>;
    private userSnapshotUnsub: () => void;
    private appDataSub: () => void;
    private memberDataSub: () => void;
    private contractorDataSub: () => void;

    constructor(private http: HttpClient,
                private dialog: MatDialog,
                private assetsService: AssetsService,
                private snackbarService: SnackbarService,
                private jobService: JobsService,
                private router: Router) {
        this.showLoginLoader = new Subject<boolean>();
        this._memberData = new BehaviorSubject<Member | Contractor>(null);
        this._currentUserEmail = null;
        this.sessionTokenSource = new BehaviorSubject<string>(null);
        this._loggedIn = new BehaviorSubject<boolean>(null);
        this._isContractor = new BehaviorSubject<boolean>(null);
        this._activatedRoute = '';
        this._appHasInternetConnection = new BehaviorSubject<boolean>(true);
        this.initFirebase();
    }


    getUserUid() {
        return getAuth().currentUser.uid;
    }

    setAppInternetConnection(isConnected: boolean) {
        this._appHasInternetConnection.next(isConnected);
    }

    updateMemberData(member: Member) {
        this._memberData.next(member);
    }

    initIntercom(email, name) {
        window.Intercom('boot', {
            app_id: 'yesicmj6',
            email: email,
            name: name
        });
    }

    setResetPasswordCode(code: string) {
        this._resetPasswordCode = code;
    }

    openDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        this.dialog.open(RefreshDialogComponent, dialogConfig);
    }

    async updatePassword(newPassword: string) {
        let userEmail = '';
        let auth = getAuth();
        this.snackbarService.showSnackbar('Updating password...');
        const code = this._resetPasswordCode;
        try {
            userEmail = await verifyPasswordResetCode(auth, code);
            await confirmPasswordReset(auth, code, newPassword);
            this.snackbarService.showSnackbar('Your password has been reset. Logging you back in...');
            await this.login(userEmail, newPassword);
            return this.goHome();
        } catch (e) {
            console.error('Could not reset password:', e);
            this.snackbarService.handleError('An error occurred while trying to update your password');
            return false;
        }
    }

    async checkMemberEnabled(user: User) {
        const firestoreRef = getFirestore();
        this._currentUserEmail = user?.email;
        this._currentUserUid = user?.uid;

        if (!!user && !user.isAnonymous) {
            const docRef = doc(collection(firestoreRef, 'memberProfiles'), this._currentUserUid);
            this.memberDataSub = onSnapshot(docRef, appDataSnapshot => {
                const userData = appDataSnapshot.data();

                if (userData.disabled == true) {
                    this.logout();
                }
            });
        }
    }

    async initialiseMember(user: User) {
        const firestoreRef = getFirestore();
        this._currentUserEmail = user?.email;

        if (!!user && !user.isAnonymous) {
            let docRef = doc(collection(firestoreRef, 'memberMisc'), 'appData');
            this.appDataSub = onSnapshot(docRef,
                appDataSnapshot => {
                    const loadedAppVersion = `${environment.version}`;
                    const appVersion = appDataSnapshot.data().version;

                    if ((!!loadedAppVersion && !!appVersion) && loadedAppVersion !== appVersion) {
                        this.openDialog();
                    }
                }
            );

            if (user?.uid) {
                const memberProfilesCollection = collection(firestoreRef, 'memberProfiles');
                const memberProfileDocRef = doc(memberProfilesCollection, user.uid);
                const memberProfileSnapshot = await getDoc(memberProfileDocRef);

                if (memberProfileSnapshot.data()?.isContractor) {
                    const memberContractorsCollection = collection(firestoreRef, 'memberContractors');
                    const memberContractorDocRef = doc(memberContractorsCollection, user.uid);
                    const memberContractorsSnapshot = await getDoc(memberContractorDocRef);
                    this.updateMemberData(null);
                    this._memberData.next({
                        ...<Member>memberContractorsSnapshot.data(), ...memberProfileSnapshot.data(), contractorUid: user.uid
                    });
                } else {
                    this.updateMemberData({...<Member>memberProfileSnapshot.data(), memberProfileUid: user.uid});
                }

                const sessionToken = await user.getIdToken();
                this.sessionTokenSource.next(sessionToken);

                const member = this._memberData.value;

                if (!member?.isContractor) {
                    this.initIntercom(member.loginEmail, `${member.firstName} ${member.lastName}`);
                }
            }
        }
    }

    async logout() {
        window.Intercom('shutdown');
        if (!!this.userSnapshotUnsub) {
            this.userSnapshotUnsub();
        }

        if (!!this.appDataSub) {
            this.appDataSub();
        }

        if (!!this.memberDataSub) {
            this.memberDataSub();
        }
        if (!!this.contractorDataSub) {
            this.contractorDataSub();
        }

        return getAuth().signOut()
            .then(() => {
                this.updateMemberData(null);
                this.setLoggedInState(false);
                this.assetsService.clearCompletedUploads();
                this.assetsService.clearDownloadData();
                this.jobService.initialiseJobsBSubject();
                return this.router.navigate(['/login']);
            })
            .catch(err => {
                console.error('Error signing out:', err);
                window.location.reload();
            });
    }

    isLoggedIn() {
        return this._loggedIn.getValue();
    }

    resetPassword(email: string): Promise<void> {
        const auth = getAuth();
        return new Promise((resolve, reject) => {
            sendPasswordResetEmail(auth, email)
                .then(() => resolve())
                .catch(e => reject(e));
        });
    }

    initFirebase() {
        try {
            const auth = getAuth();
            onAuthStateChanged(auth, async (user: User) => {
                this._activatedRoute = window.location.pathname;

                if (!!user && !user.isAnonymous) {
                    await this.initialiseMember(user);
                    this.setLoggedInState(!!user);
                }

                await this.checkMemberEnabled(user);

                if (this._activatedRoute.startsWith('/users/enable-contractor')) {
                    const sessionToken = await user.getIdToken();
                    this.sessionTokenSource.next(sessionToken);
                } else if (this._activatedRoute.startsWith('/agent')) {
                    const {user} = await signInAnonymously(auth);
                    const sessionToken = await user.getIdToken();
                    this.sessionTokenSource.next(sessionToken);
                } else if (this._activatedRoute.startsWith('/setup/update-password')) {
                } else {
                    if (!!user && !!user.email) {
                        const member = this._memberData?.value;
                        if (!!member) {
                            if (member['acceptedPlatformPolicies'] && (!!member['stripeId'] || member.isContractor)) {
                                this.setLoggedInState(true);

                                if (this._activatedRoute === '/login') {
                                    this.router.navigate(['/home/jobs']);
                                } else {
                                    this.router.navigate([this._activatedRoute]);
                                }

                            } else {
                                this.router.navigateByUrl('/setup');
                            }
                        } else {
                            this.logout();
                        }

                    } else {
                        this.setLoggedInState(false);
                        this.router.navigateByUrl('/login');
                    }
                }
            });
        } catch (e) {
        }
    }

    isLoggedIn$() {
        return this._loggedIn.asObservable();
    }

    setLoggedInState(value: boolean) {
        this._loggedIn.next(value);
    }

    login(email: string, password: string): Promise<boolean | any> {
        const auth = getAuth();
        this.showLoginLoader.next(true);
        return new Promise((resolve, reject) => {
            signInWithEmailAndPassword(auth, email, password)
                .then(user => {
                    resolve(user);
                })
                .catch(e => {
                    this.showLoginLoader.next(false);
                    reject(e);
                });
        });
    }

    goHome() {
        const member = this._memberData?.value;
        if (!!member) {
            if (member['acceptedPlatformPolicies'] && (!!member['stripeId'] || member.isContractor)) {
                this.setLoggedInState(true);
                this.router.navigate(['/home/jobs']);

            } else {
                this.router.navigateByUrl('/setup');
            }
        } else {
            this.logout();
        }
    }

    checkMaintenanceMode() {
        return this.http.get(`${environment.apiBaseUrl}/CheckMaintenanceActive`)
            .pipe(
                first(d => !!d),
                shareReplay(),
                map((response: Response) => {
                    return response.data.maintenanceActive;
                })
            );
    }

    get isContractor() {
        return this._isContractor.getValue();
    }

    get sessionToken$() {
        return this.sessionTokenSource
            .asObservable();
    }

    get sessionTokenRaw() {
        return this.sessionTokenSource.value;
    }

    get memberData$() {
        return this._memberData.asObservable();
    }

    get memberCurrentData(): any {
        return this._memberData.getValue();
    }

    get appHasInternetConnection$() {
        return this._appHasInternetConnection.asObservable();
    }

    get appHasInternetConnection() {
        return this._appHasInternetConnection.value;
    }

    get currentUserEmail() {
        return this._currentUserEmail;
    }
}
