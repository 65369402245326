<h1>My Account</h1>

<mat-button-toggle-group
        [value]="toggleSelected"
        (change)="toggleAccountPageView($event.value)">
    <mat-button-toggle [value]="'personal'">
        Personal Information
    </mat-button-toggle>
    <mat-button-toggle [value]="'payment'">
        Payment Method
    </mat-button-toggle>
</mat-button-toggle-group>

<prism-personal-information *ngIf="toggleSelected === 'personal'"></prism-personal-information>
<prism-payment-method [setupPage]="false" *ngIf="toggleSelected === 'payment'"></prism-payment-method>