import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../services';
import {UploadService} from '../../../services/upload.service';

@Component({
    selector: 'prism-member-options',
    templateUrl: './member-options.component.html',
    styleUrls: ['./member-options.component.scss']
})
export class MemberOptionsComponent {

    @Input() initials;

    constructor(private router: Router, private AuthService: AuthenticationService, private uploadsService: UploadService) {
    }

    logout() {
        if (Object.keys(this.uploadsService.activeUploads).length
            || Object.values(this.uploadsService.uploadingStatusSet).some(finalising => finalising)) {
            let isExecuted = confirm('Are you sure you want to log out? \nChanges you have made might not be saved.');
            if (isExecuted) {
                this.uploadsService.terminateUploads();
                this.AuthService.logout();
                return;
            } else {
                return;
            }
        } else {
            this.AuthService.logout();
            return;
        }
    }

    navToProfilePage() {
        this.router.navigate(['home', 'profile'])
            .then(navigated => {
            })
            .catch(err => {
                console.error('ERROR NAVIGATING TO PROFILE PAGE:', err);
            });
    }
}
