import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StripeElementsComponent} from '../stripe-elements/stripe-elements.component';
import {AuthenticationService, DialogService, MemberService, StripeService} from '../../services';
import {MatDialogRef} from '@angular/material/dialog';
import {ConfirmDefaultCardComponent} from '../confirm-default-card/confirm-default-card.component';
import {Contractor, Member} from '../../models';
import {Router} from '@angular/router';

@Component({
    selector: 'prism-payment-method',
    templateUrl: './payment-method.component.html',
    styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {

    @Output() cardAdded = new EventEmitter<boolean>();

    @Input()
    setupPage: boolean = false;

    selected = '';
    loading = true;
    userCards: any = [];
    member: Member | Contractor;
    client_secret;
    setupIntent;
    defaultCard;
    defaultCardId: string;
    finishAccountSetupAlert: boolean = false;
    stars = '**** **** ****';

    constructor(private dialogService: DialogService, private stripeService: StripeService,
                private authService: AuthenticationService, private router: Router) {
    }

    ngOnInit() {

        this.member = this.authService.memberCurrentData;

        if (!!this.member.stripeId && this.member.stripeId !== 'bypass') {
            this.stripeService.retrieveCustomer(this.member.stripeId).then((customer) => {
                if (!!customer['data'].invoice_settings.default_payment_method) {
                    this.defaultCardId = customer['data'].invoice_settings.default_payment_method;
                }
            });

            if (!this.setupPage) {
                this.stripeService.retrieveCards().then((result) => {
                    for (let card of result.data.data) {
                        this.userCards.push(card);
                        if (card.id === this.defaultCardId) {
                            this.defaultCard = card;
                        }
                    }
                    this.loading = false;
                });
            }
        } else {
            this.loading = false;
        }
    }

    confirmDefault(value: any) {
        if (value.source.value.id !== this.defaultCardId) {
            if (value.isUserInput === true) {
                this.dialogService.openDialog(ConfirmDefaultCardComponent, {
                    payment_method_id: value.source.value.id,
                    last4: value.source.value.card.last4,
                    stripeId: this.member.stripeId
                });
            }
        }

    }

    retrieveCustomerAndCards() {
        this.stripeService.retrieveCustomer(this.member.stripeId).then((customer) => {
            if (!!customer['data'].invoice_settings.default_payment_method) {
                this.defaultCardId = customer['data'].invoice_settings.default_payment_method;
            }
        }).then(() => {
            this.stripeService.retrieveCards().then((result) => {
                for (let card of result.data.data) {
                    this.userCards.push(card);
                    if (card.id === this.defaultCardId) {
                        this.defaultCard = card;
                    }
                }
                this.loading = false;
            });
        });
    }

    showStripe() {
        //update member with newly added account details
        if (!this.member.loginEmail || !this.member.firstName || !this.member.lastName) {
            this.member = this.authService.memberCurrentData;
        }

        if (!!this.member && !!this.member.firstName && !!this.member.lastName && !!this.member.loginEmail) {
            this.finishAccountSetupAlert = false;
            const dialogRef: MatDialogRef<any> = this.dialogService.openDialog(StripeElementsComponent, {}, {disableClose: true});
            dialogRef
                .beforeClosed()
                .toPromise()
                .then(() => {
                    this.cardAdded.emit(true);
                }).catch((err) => {
                console.error('error ', err);
            });

            dialogRef.afterClosed().toPromise().then((result) => {
                if (!result) {
                    this.cardAdded.emit(true);

                    if (this.setupPage) {
                        this.router.navigateByUrl('/home/jobs');
                    } else {
                        this.retrieveCustomerAndCards();
                    }
                }
            });
        } else {

            this.finishAccountSetupAlert = true;
        }

    }
}
