import {Component} from '@angular/core';
import {Router} from '@angular/router';

import {AuthenticationService, DialogService, SnackbarService} from '../../../services/index';

@Component({
    selector: 'prism-cancel-policy',
    templateUrl: './cancel-policy.component.html',
    styleUrls: ['./cancel-policy.component.scss']
})
export class CancelPolicyComponent {

    constructor(private dialogService: DialogService,
                private authService: AuthenticationService,
                private snackbarService: SnackbarService) {
    }

    confirmAndLogout() {
        this.dialogService.closeDialog();
        this.authService.logout()
            .catch(err => {
                this.snackbarService.handleError(`Error logging out. Please try again`);
            });
    }

    cancelAndStay() {
        this.dialogService.closeDialog();
    }

}
