<table
        mat-table
        [dataSource]="contractors"
>
    <section>Contractors Table</section>
    <ng-container matColumnDef="selected">
        <th mat-header-cell scope="col" *matHeaderCellDef></th>
        <td class="clickable-area" mat-cell *matCellDef="let contractor">
            <mat-checkbox
                    [checked]="selectedContractorIds.has(contractor.id)"
                    (change)="onContractorSelectedChanged($event.checked, contractor.id)"
            ></mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="firstName">
        <th mat-header-cell scope="col" *matHeaderCellDef>First Name</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let contractor"
                (click)="onRowClicked(contractor)"
        >
            {{ contractor.firstName }}
        </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
        <th mat-header-cell scope="col" *matHeaderCellDef>Last Name</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let contractor"
                (click)="onRowClicked(contractor)"
        >
            {{ contractor.lastName }}
        </td>
    </ng-container>

    <ng-container matColumnDef="email">
        <th mat-header-cell scope="col" *matHeaderCellDef>Email</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let contractor"
                (click)="onRowClicked(contractor)"
        >
            {{ contractor.email }}
        </td>
    </ng-container>

    <ng-container matColumnDef="loginEmail">
        <th mat-header-cell scope="col" *matHeaderCellDef>Login Email</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let contractor"
                (click)="onRowClicked(contractor)"
        >
            {{ contractor.loginEmail }}
        </td>
    </ng-container>

    <ng-container matColumnDef="address">
        <th mat-header-cell scope="col" *matHeaderCellDef>Address</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let contractor"
                (click)="onRowClicked(contractor)"
        >
            {{ contractor.address }}
        </td>
    </ng-container>

    <ng-container matColumnDef="contactNumber">
        <th mat-header-cell scope="col" *matHeaderCellDef>Contact Number</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let contractor"
                (click)="onRowClicked(contractor)"
        >
            {{ contractor.contactNumber ? contractor.contactNumber : "N/A" }}
        </td>
    </ng-container>

    <ng-container matColumnDef="active">
        <th mat-header-cell scope="col" *matHeaderCellDef>ACTIVE</th>
        <td mat-cell *matCellDef="let contractor">
            <mat-slide-toggle class="slide-toggle"
                              [checked]="contractor.active"
                              [disabled]="(isLoading | async) === true"
                              (change)="putToggleActive(contractor, $event.checked)">
            </mat-slide-toggle>
        </td>
    </ng-container>

    <ng-container matColumnDef="edit">
        <th mat-header-cell scope="col" *matHeaderCellDef>Edit</th>
        <td mat-cell *matCellDef="let contractor">
            <button mat-icon-button (click)="onRowClicked(contractor)">
                <img alt='Update product icon.'
                     src="/assets/icons/jobs/update.svg">
            </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnHeaders"></tr>
    <tr mat-row *matRowDef="let rowData; columns: columnHeaders"></tr>
</table>

<mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoading | async"
></mat-progress-bar>
<mat-paginator
        [length]="totalContractors | async"
        [pageSize]="paginatorControl.pageSize"
        [pageSizeOptions]="paginatorControl.pageSizeOptions"
        (page)="paginate($event)"
        #paginator
>
</mat-paginator>
