import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, combineLatest, fromEvent, Observable, of, Subject,} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap,} from 'rxjs/operators';
import {ServiceStatus} from '../../enums';
import {Contractor, Job, Member, Response} from '../../models';
import {AuthenticationService, DialogService, JobsService, SnackbarService,} from '../../services';
import {AddEditJobComponent} from '../add-edit-job/add-edit-job.component';

@Component({
    selector: 'prism-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit, OnDestroy {
    filterSelection$: BehaviorSubject<'Member Review' | 'In Progress' | 'Not_Invoiced' | 'All_Jobs'>;
    searchString$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    jobs$: Observable<Job[]>;
    jobsStats: {
        numOfJobs: number;
        'Member Review': number;
        'In Progress': number;
        Not_Invoiced: number;
    };
    localJobsLoading = true;
    memberData: Member | Contractor;
    filterSelection;
    memberData$: Observable<Member | Contractor>;
    postJobLoading$: Observable<boolean>;
    putJobLoading$: Observable<boolean>;
    jobsLoading$: Observable<boolean>;
    showMinSearchStringWarning: boolean;
    jobsOverviewData: any;
    subDestroyer$: Subject<any>;
    jobStatuses: any;
    totalJobsForPaginator: number;
    jobsStaging$: any;
    jobsRefresh$: BehaviorSubject<boolean>;
    jobOverviewDataLoading: boolean;
    initialTableLoad: boolean;

    @ViewChild('searchInput', {static: true}) searchBar;

    constructor(
        private jobService: JobsService,
        private authService: AuthenticationService,
        private dialogService: DialogService,
        private jobsService: JobsService,
        private snackbarService: SnackbarService,
        private router: Router
    ) {
        this.memberData$ = this.authService.memberData$;
        this.postJobLoading$ = this.jobsService.isPostJobLoading$;
        this.putJobLoading$ = this.jobService.isPutJobLoading$;
        this.jobsLoading$ = this.jobsService.areJobsLoading$;
        this.jobsRefresh$ = new BehaviorSubject<boolean>(true);
        this.jobOverviewDataLoading = true;
        this.showMinSearchStringWarning = false;
        this.initialTableLoad = true;
        this.subDestroyer$ = new Subject();
        this.jobStatuses = {};
        this.filterSelection$ = new BehaviorSubject<'Member Review' | 'In Progress' | 'Not_Invoiced' | 'All_Jobs'>('All_Jobs');
        this.jobsOverviewData = {};
        this.totalJobsForPaginator = 0;
        this.jobsStats = {
            numOfJobs: 0,
            'Member Review': 0,
            'In Progress': 0,
            Not_Invoiced: 0,
        };
    }

    ngOnInit() {
        this.getOverviewPanelData();
        let {pageSize} = this.jobsService.jobListPaginationData;
        this.jobsService.updateJobsListPagination({pageSize, pageIndex: 0});

        /*
         * Listens to latest job statuses to update job table status icons.
         * Triggered by job uploads, delivering jobs.
         */
        this.jobsService.jobStatusUpdateListener().subscribe((data) => {
            const {jobId, serviceId, status} = data;
            if (!!this.jobsOverviewData[jobId]) {
                let {statuses} = this.jobsOverviewData[jobId];
                if (!!statuses) {
                    statuses[serviceId] = status;
                    this.populateJobStatuses(this.jobsOverviewData);
                }
            }
        });

        /*
         * Listens to the latest job panel data.
         * Triggered when adding jobs and invoicing jobs.
         */
        this.jobsService
            .jobOverviewPanelData()
            .pipe(takeUntil(this.subDestroyer$))
            .subscribe((data) => {
                this.jobsStats = data;
            });

        this.jobs$ = this.jobsService.jobs
            .pipe(
                tap((jobs: Job[]) => {
                    let jobsOverviewData = {};
                    jobs = !!jobs ? jobs : [];
                    if (jobs.length > 0) {
                        jobs.forEach((job) => {
                            jobsOverviewData[job.id] = this.formatJobOverviewData(job);
                        });
                        this.populateJobStatuses(jobsOverviewData);
                        this.jobService.setJobsOverviewData(jobsOverviewData);
                    }
                }),
                map((jobs) => {
                    if (!!jobs) {
                        return jobs.map((job) => {
                            if (!job.unsyncedInvoices) {
                                job.unsyncedInvoices = [];
                            }
                            return job;
                        });
                    }
                    return jobs;
                })
            );

        this.jobsStaging$ = combineLatest([
            this.memberData$,
            this.jobsService.jobListPaginationData$,
            this.filterSelection$.asObservable(),
            this.searchString$.asObservable(),
            this.jobsRefresh$,
        ])
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.subDestroyer$),
                debounceTime(0),
                filter(([member]) => {
                    return !!member;
                }),
                switchMap(
                    (values) => {
                        return this.searchJobsIds(values);
                    }
                ),
                switchMap(
                    (response) => {
                        return this.getJobs(response);
                    }
                )
            )
            .subscribe(() => {
                this.jobsService.switchAllJobLoadersOff();
                this.localJobsLoading = false;
            });

        const memberData = this.authService.memberCurrentData;

        this.jobsService
            .getAgenciesAndContractorNames(
                memberData?.isContractor
                    ? (<Contractor>memberData)?.mainMemberUid
                    : null
            )
            .toPromise()
            .catch((err) => {
                this.snackbarService.handleError(
                    'Agency and Contractor information could not be retrieved.'
                );
                console.error(err);
            });

        this.jobsService.setSelectedJob(null);

        fromEvent(this.searchBar.nativeElement, 'keyup')
            .pipe(
                takeUntil(this.subDestroyer$),
                map((e: KeyboardEvent) => (e.target as HTMLInputElement).value),
                debounceTime(500),
                distinctUntilChanged(),
                switchMap((valueSwitch) => {
                    let {pageSize} = this.jobsService.jobListPaginationData;
                    this.jobsService.updateJobsListPagination({
                        pageSize: pageSize,
                        pageIndex: 0,
                    });
                    this.searchString$.next(valueSwitch);
                    return of();
                })
            )
            .subscribe();
    }

    populateJobStatuses(jobsOverviewData) {
        this.jobsOverviewData = jobsOverviewData;

        if (!!jobsOverviewData && Object.values(jobsOverviewData).length > 0) {
            let relevantJobs = this.jobsOverviewData;
            Object.entries(relevantJobs).forEach(([jobId, jobData]) => {
                const {statuses: statusesObject = {}} = jobData as {
                    statuses: any;
                    invoiceStatus: string;
                    status: string;
                };

                const statusesArray = Object.values(statusesObject);
                const jobUploadInProgress = statusesArray.some(
                    (statusItem) => statusItem === ServiceStatus.uploadInProgress
                );
                if (jobUploadInProgress) {
                    this.jobStatuses[jobId] = [ServiceStatus.uploadInProgress];
                } else {
                    const bundledStatuses = statusesArray.reduce(
                        (acc, curr: ServiceStatus) => {
                            acc[curr].push(curr);
                            return acc;
                        },
                        {
                            [ServiceStatus.delivered]: [],
                            [ServiceStatus.memberReview]: [],
                            [ServiceStatus.inProgress]: [],
                            [ServiceStatus.noProcessing]: [],
                            [ServiceStatus.draft]: [],
                        }
                    );
                    this.jobStatuses[jobId] = [
                        ...bundledStatuses[ServiceStatus.delivered],
                        ...bundledStatuses[ServiceStatus.memberReview],
                        ...bundledStatuses[ServiceStatus.inProgress],
                        ...bundledStatuses[ServiceStatus.noProcessing],
                        ...bundledStatuses[ServiceStatus.draft],
                    ];

                    if (
                        this.jobStatuses[jobId].length &&
                        this.jobStatuses[jobId].every(
                            (status) => status === ServiceStatus.delivered
                        )
                    ) {
                        this.jobStatuses[jobId] = [ServiceStatus.delivered];
                    }
                }
            });
        }
    }

    ngOnDestroy() {
        this.subDestroyer$.next();
    }

    openAddJobDialog() {
        this.jobsService.setEditJob(false);
        let dialog = this.dialogService.openDialog(
            AddEditJobComponent,
            {},
            {width: '600px', panelClass: 'add-edit-job-container'}
        );
        dialog
            .afterClosed()
            .toPromise()
            .then(() => {
                this.jobsRefresh$.next(true);
            });
    }

    goToJob(job: Job) {
        this.jobsService.setSelectedJob(job);
        this.router.navigate(['home', 'jobs', job.id]);
    }

    filterJobs(selection: 'Member Review' | 'In Progress' | 'Not_Invoiced' | 'All_Jobs') {
        this.jobsService.switchGetJobsLoaderOn();
        this.initialTableLoad = true;
        let {pageSize} = this.jobsService.jobListPaginationData;
        if (this.filterSelection$.value === selection) {
            this.filterSelection = 'All_Jobs';
            this.updateTotalTableEntries('All_Jobs');
            this.filterSelection$.next('All_Jobs');
            this.jobsService.updateJobsListPagination({
                pageSize: pageSize,
                pageIndex: 0,
            });
        } else {
            this.updateTotalTableEntries(selection);
            this.filterSelection = selection;
            this.filterSelection$.next(selection);
            this.jobsService.updateJobsListPagination({
                pageSize: pageSize,
                pageIndex: 0,
            });
        }
    }

    updateTotalTableEntries(filter, size = 0) {
        switch (filter) {
            case 'All_Jobs':
                this.totalJobsForPaginator = this.jobsStats['numOfJobs'];
                break;
            case 'In Progress':
                this.totalJobsForPaginator = this.jobsStats['inProgress'];
                break;
            case 'Member Review':
                this.totalJobsForPaginator = this.jobsStats['memberReview'];
                break;
            case 'Not_Invoiced':
                this.totalJobsForPaginator = this.jobsStats['notInvoiced'];
                break;
            case 'Custom_Search':
                this.totalJobsForPaginator = size;
        }
    }

    getOverviewPanelData() {
        this.jobOverviewDataLoading = true;
        let member = this.authService.memberCurrentData;
        this.jobsService
            .getJobOverviewPanelData(this.authService.getUserUid(), member.isContractor)
            .pipe(
                tap((response: any) => {
                    let {hitsCount} = response.data;
                    this.totalJobsForPaginator = hitsCount ? hitsCount : 0;
                    this.jobOverviewDataLoading = false;
                })
            )
            .subscribe(
                () => {
                },
                (err) => {
                    this.snackbarService.handleError(
                        'Failed to load job info panel information.'
                    );
                    console.error(err);
                    this.jobOverviewDataLoading = false;
                }
            );
    }

    updateJobsPage({pageSize, pageIndex}) {
        this.jobsService.updateJobsListPagination({pageSize, pageIndex});
    }

    isJobInvoiced(job: Job) {
        if (job.unsyncedInvoices) {
            if (job.unsyncedInvoices.length) {
                return 'Invoiced';
            }
        }
        if (job.invoices) {
            if (job.invoices.length) {
                return 'Invoiced';
            }
        }
        return 'Not_Invoiced';
    }

    searchJobsIds(values) {
        let [member, {pageIndex, pageSize}, filterSelection, searchString] = values;
        this.jobsService.switchGetJobsLoaderOn();
        this.memberData = member;
        this.localJobsLoading = true;

        if (filterSelection !== 'All_Jobs' || !!searchString) {
            return this.jobsService
                .searchRelevantJobs(
                    filterSelection,
                    searchString !== '' ? searchString : null,
                    {pageSize, pageIndex},
                    this.authService.getUserUid(),
                    member.isContractor
                )
                .pipe(
                    tap((res: Response) => {
                        let hitCount = res.data.estimatedTotalHits
                            ? res.data.estimatedTotalHits
                            : 0;
                        this.updateTotalTableEntries('Custom_Search', hitCount);
                    }),
                    map((res: any) => {
                        return {
                            filteredSearch: true,
                            hits: res.data.hits,
                        };
                    })
                );
        } else {
            return of({
                filteredSearch: false,
                hits: [],
            }).pipe(
                tap(() => {
                    this.updateTotalTableEntries(filterSelection);
                })
            );
        }
    }

    getJobs(values) {
        let ids = values.hits.map((hit) => {
            return hit.jobId;
        });
        if (ids.length > 0 || !values.filteredSearch) {
            let {pageSize, pageIndex} = this.jobsService.jobListPaginationData;
            return this.jobsService
                .getJobs(ids, (<Contractor>this.memberData)?.mainMemberUid, pageSize, pageIndex)
                .pipe(
                    take(1),
                    tap(() => {
                        this.jobsService.switchAllJobLoadersOff();
                        this.initialTableLoad = false;
                        this.localJobsLoading = false;
                    })
                );

        } else {
            this.initialTableLoad = false;
            this.jobsService.updateTableJobs();
            return of(null);
        }
    }

    formatJobOverviewData(job: Job) {
        let invoiceStatus = this.isJobInvoiced(job);
        return {
            address: job.address,
            invoiceStatus: invoiceStatus,
            agencyName: job.agencyName ? job.agencyName : null,
            agentName: job.agentName ? job.agentName : null,
            status: job.status,
            statuses: !!job.services ? job.services : {},
        };
    }
}
