<div class="mat-dialog-container" *ngIf="!contractorAddComplete; else contractorAddedView">
    <h1>Enable Contractor</h1>
    <div class="form-group enable-contractor-form" [formGroup]="enableContractorForm">
        <div class="form-field form-field-input">
            <mat-label>New Contractor Email</mat-label>
            <mat-form-field appearance="outline">
                <input matInput formControlName="contractorEmail" type="text">
                <mat-error *ngIf="enableContractorForm.get('contractorEmail').hasError('required')">Please enter email
                </mat-error>
                <mat-error
                        *ngIf="!enableContractorForm.get('contractorEmail').hasError('required') && enableContractorForm.get('contractorEmail').hasError('notEmail')">
                    Enter a valid email
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-field form-field-input">
            <mat-label>New Contractor Password</mat-label>
            <mat-form-field appearance="outline">
                <input matInput formControlName="contractorPassword" type="text">
                <mat-error *ngIf="enableContractorForm.get('contractorPassword').hasError('required')">Please enter
                    contractor's login password
                </mat-error>
                <mat-error *ngIf="!!enableContractorForm.get('contractorPassword').hasError('minlength')">Password must
                    be at least 6 characters
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-field form-field-input">
            <mat-label>Member Email</mat-label>
            <mat-form-field appearance="outline">
                <input matInput formControlName="memberEmail" type="text">
                <mat-error *ngIf="enableContractorForm.get('memberEmail').hasError('required')">Please enter email
                </mat-error>
                <mat-error
                        *ngIf="!enableContractorForm.get('memberEmail').hasError('required') && enableContractorForm.get('memberEmail').hasError('notEmail')">
                    Enter a valid email
                </mat-error>
            </mat-form-field>
        </div>

        <button mat-raised-button
                class="btn btn-blue"
                [disabled]="enableContractorForm.invalid || loadingEnableContractor"
                (click)="enableContractor()">
            Enable Contractor
            <ng-template [ngIf]="loadingEnableContractor">
                <div class="lds-hourglass"></div>
            </ng-template>
        </button>
    </div>
</div>

<ng-template #contractorAddedView>
    <div class="enable-contractor-page-container">
        <h4>Contractor has been enabled. Member will be notified.</h4>
    </div>
</ng-template>
