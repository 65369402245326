import {Component} from '@angular/core';

@Component({
    selector: 'prism-download-progress-snackbar',
    templateUrl: './download-progress-snackbar.component.html',
    standalone: true,
    styleUrls: ['./download-progress-snackbar.component.scss']
})
export class DownloadProgressSnackbarComponent {

}
