import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

import {AuthenticationService} from '../authentication.service';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceCompleteGuard {

    constructor(private authService: AuthenticationService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {
        return this.authService.checkMaintenanceMode()
            .toPromise()
            .then(maintenanceActive => {
                if (!maintenanceActive) {
                    this.router.navigateByUrl('/home/jobs');
                    return false;
                } else {
                    return true;
                }

            })
            .catch(e => {
                console.error('Error checking maintenance', e);
                return true;
            });


    }
}
