// ======= ANGULAR ======= //

import {NgModule} from '@angular/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatNativeDateModule} from '@angular/material/core';
import {CommonModule} from '@angular/common';
import {AngularEditorModule} from '@kolkov/angular-editor';

@NgModule({
    imports: [
        CommonModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        MatTabsModule,
        MatListModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatMenuModule,
        MatCardModule,
        MatGridListModule,
        MatTableModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatStepperModule,
        MatSortModule,
        MatDialogModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatRadioModule,
        MatSliderModule,
        MatAutocompleteModule,
        AngularEditorModule
    ],
    exports: [
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatStepperModule,
        MatToolbarModule,
        MatIconModule,
        MatDatepickerModule,
        MatSidenavModule,
        MatTabsModule,
        MatListModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatSelectModule,
        MatGridListModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatDialogModule,
        MatCardModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatRadioModule,
        MatSliderModule,
        MatAutocompleteModule,
        AngularEditorModule
    ]
})

export class UiModule {
    static full() {
        return {
            ngModule: UiModule,
            exports: [
                MatInputModule,
                MatButtonModule,
                MatFormFieldModule,
                MatCheckboxModule,
                MatToolbarModule,
                MatIconModule,
                MatSidenavModule,
                MatTabsModule,
                MatListModule,
                MatMenuModule,
                MatButtonToggleModule,
                MatDatepickerModule,
                MatSelectModule,
                MatCardModule,
                MatGridListModule,
                MatTableModule,
                MatPaginatorModule,
                MatSortModule,
                MatDialogModule,
                MatSnackBarModule,
                MatProgressSpinnerModule,
                MatProgressBarModule,
                MatTooltipModule,
                MatSlideToggleModule,
                MatChipsModule,
                MatRadioModule,
                MatSliderModule,
                MatAutocompleteModule,
                AngularEditorModule
            ]
        };
    }

    static core() {
        return {
            ngModule: UiModule,
            exports: [
                MatSnackBarModule,
                MatButtonModule
            ]
        };
    }
}
