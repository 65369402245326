<h1 class="page-title">Contractors</h1>

<div class="content-wrapper table-wrapper contractors-table-wrapper">
    <div class="toolbar table-toolbar contractors-table-toolbar">
        <div class="toolbar-left table-toolbar-left">
            <div class="active-toggle-field">
                <mat-button-toggle-group name="contractorsStatus" [value]="activeContractorsFilter">
                    <mat-button-toggle #archivedToggle [value]="true" (click)="showActiveContractors(true)">
                        Active
                    </mat-button-toggle>
                    <mat-button-toggle [value]="false" (click)="showActiveContractors(false)">
                        Archived
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="toolbar-right table-toolbar-right">
            <button class="btn btn-red"
                    mat-raised-button
                    [disabled]="!selectedContractorsIds.length || processingContractors"
                    (click)="activeContractorsFilter ? updateContractorsActiveStatus(false) : updateContractorsActiveStatus(true)">
                {{ activeContractorsFilter ? 'Archive' : 'Activate' }} Selected
                <ng-template [ngIf]="processingContractors">
                    <div class="lds-hourglass"></div>
                </ng-template>
            </button>
            <button class="btn btn-green" mat-raised-button
                    (click)="requestNewContractor()"
                    [disabled]="processingContractors">Request New Contractor
                Email
            </button>
            <button class="btn btn-green" mat-raised-button
                    (click)="displayContractorModal()"
                    [disabled]="processingContractors">Add Contractor
            </button>
        </div>
    </div>
    <prism-contractors-table #contractorsTable [showActive]="activeContractorsFilter"
                             (selectionUpdated)="onSelectionUpdated($event)"
                             (rowClicked)="onContractorClicked($event)">
    </prism-contractors-table>
</div>
