import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'shortenServiceID'
})
export class ShortenServiceIdPipe implements PipeTransform {

    transform(id: any): any {
        if (id.length > 6) {
            const startIndex = id.length - 6;

            return id.substr(startIndex);
        }
        return id;
    }

}
