<div class="mat-dialog-container">
    <h1 class="mat-dialog-title" mat-dialog-title>
        {{ data && data.id ? "Update Agent" : "New Agent" }}
    </h1>
    <mat-dialog-content>
        <form class="add-agent-form" [formGroup]="agentForm">
            <div formGroupName="primaryDetails">
                <div class="field-group">
                    <div class="form-field form-field-input">
                        <mat-label class="form-field-label">First Name</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput type="text" formControlName="firstName"/>
                            <mat-error
                                    *ngIf="primaryDetailsFormGroup.get('firstName').invalid"
                            >
                                Please enter a first name
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-field form-field-input">
                        <mat-label class="form-field-label">Last Name</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput type="text" formControlName="lastName"/>
                            <mat-error
                                    *ngIf="primaryDetailsFormGroup.get('lastName').invalid"
                            >
                                Please enter a last name
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="field-group">
                    <div class="form-field form-field-input">
                        <mat-label class="form-field-label">Contact Number</mat-label>
                        <mat-form-field appearance="outline">
                            <input
                                    matInput
                                    type="text"
                                    formControlName="contactNumber"
                            />
                            <mat-error
                                    *ngIf="primaryDetailsFormGroup.get('contactNumber').invalid"
                            >
                                Please enter a contact number
                            </mat-error>
                            <mat-error *ngIf="primaryDetailsFormGroup.get('contactNumber').errors?.pattern">
                                Please enter a valid contact number
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-field form-field-select">
                        <mat-label class="form-field-label">Agency</mat-label>
                        <mat-form-field
                                *ngIf="agencies; else noAgencies"
                                appearance="outline"
                        >
                            <mat-select
                                    [placeholder]="agencyPlaceholder"
                                    formControlName="agency"
                            >
                                <mat-option
                                        *ngFor="let agency of agencies"
                                        [value]="agency.id"
                                >
                                    {{ agency.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="primaryDetailsFormGroup.get('agency').invalid">
                                Please select an agency
                            </mat-error>
                        </mat-form-field>
                        <ng-template #noAgencies>
                            No agency available
                        </ng-template>
                    </div>
                </div>
            </div>
            <div formGroupName="emails">
                <div class="field-group">
                    <div class="form-field form-field-input form-field-input-chips">
                        <mat-label class="form-field-label">Invoice Emails</mat-label>
                        <mat-form-field appearance="outline">
                            <mat-chip-grid #chipListInvoice>
                                <mat-chip-row
                                        *ngFor="let email of invoiceEmails.controls; let i = index"
                                        [removable]="true"
                                        (removed)="removeInvoiceEmail(i)"
                                >
                                    {{ email.value }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip-row>
                                <input
                                        [matChipInputFor]="chipListInvoice"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="true"
                                        (matChipInputTokenEnd)="addInvoiceEmail($event)"
                                />
                            </mat-chip-grid>
                        </mat-form-field>
                    </div>
                </div>
                <div class="field-group">
                    <div class="form-field form-field-input form-field-input-chips">
                        <mat-label class="form-field-label">Notification Emails</mat-label>
                        <mat-form-field appearance="outline">
                            <mat-chip-grid #chipListNotification>
                                <mat-chip-row
                                        *ngFor="let email of notificationEmails.controls;let i = index"
                                        [removable]="true"
                                        (removed)="removeNotificationEmail(i)"
                                >
                                    {{ email.value }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip-row>
                                <input
                                        [matChipInputFor]="chipListNotification"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="true"
                                        (matChipInputTokenEnd)="addNotificationEmail($event)"
                                        [disabled]="isFetchingAgency"
                                />
                            </mat-chip-grid>
                        </mat-form-field>
                    </div>
                </div>
                <mat-progress-bar
                        *ngIf="isFetchingAgency"
                        mode="indeterminate"
                ></mat-progress-bar>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button
                class="btn btn-red cancel-btn"
                mat-button
                [disabled]="isPosting"
                (click)="closeDialog()"
        >
            Cancel
        </button>
        <button
                class="btn btn-green"
                mat-raised-button
                [disabled]="agentForm.invalid || isPosting || isFetchingAgency || agentForm.pristine"
                (click)="addAgent()"
        >
            {{ data && data.id ? "Update" : "Add" }}
            <ng-template [ngIf]="isPosting">
                <div class="lds-hourglass"></div>
            </ng-template>
        </button>
    </mat-dialog-actions>
</div>
