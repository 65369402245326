<table mat-table [dataSource]="productsList">
    <section>Products list</section>
    <ng-container matColumnDef="select">
        <th mat-header-cell scope="col" *matHeaderCellDef>
            <mat-checkbox color="primary" #globalActiveArchiveCheckbox
                          [disabled]="showLoader === true"
                          (change)="setAllCheckboxes($event.checked)">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let product;">
            <mat-checkbox color="primary" #activeArchiveCheckboxes
                          [disabled]="showLoader === true"
                          (change)="emitChangeActive(product.id, $event.checked)">
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell scope="col" *matHeaderCellDef>NAME</th>
        <td mat-cell *matCellDef="let product">
            <div [matTooltip]="product.ProductName"
                 matTooltipPosition="right"
                 [matTooltipShowDelay]="500"
                 matTooltipClass="tooltip"
                 [ngStyle]="{pointerEvents: showFullString(product.ProductName, stringLengthLimit)}">
                {{ product.ProductName | shorten:stringLengthLimit }}
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="services">
        <th mat-header-cell scope="col" *matHeaderCellDef>SERVICES</th>
        <td mat-cell
            *matCellDef="let product" id="services-column">
            <div [matTooltip]="displayArray(product.services, 'DISPLAY')"
                 matTooltipPosition="right"
                 [matTooltipShowDelay]="500"
                 matTooltipClass="tooltip"
                 [ngStyle]="{pointerEvents: showFullString(displayArray(product.services, 'DISPLAY'), stringLengthLimit)}">
                {{ displayArray(product.services, 'DISPLAY') | shorten:stringLengthLimit }}
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="price">
        <th mat-header-cell scope="col" *matHeaderCellDef>PRICE</th>
        <td mat-cell *matCellDef="let product"> {{ product.Price | currency }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell scope="col" *matHeaderCellDef>DESCRIPTION</th>
        <td mat-cell *matCellDef="let product">
            <div class="product-description-cell"
                 [matTooltip]="product.Description"
                 matTooltipPosition="right"
                 [matTooltipShowDelay]="500"
                 matTooltipClass="tooltip"
                 [ngStyle]="{pointerEvents: showFullString(product.Description, stringLengthLimit)}">
                {{ product.Description ? product.Description : 'None' | shorten:stringLengthLimit }}
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="accountingCodes">
        <th mat-header-cell scope="col" *matHeaderCellDef>ACC. CODE</th>
        <td mat-cell
            *matCellDef="let product">
            <div [matTooltip]="displayArray(product.lineItems, 'AccountCode')"
                 matTooltipPosition="right"
                 [matTooltipShowDelay]="500"
                 matTooltipClass="tooltip"
                 [ngStyle]="{pointerEvents: showFullString(displayArray(product.lineItems, 'AccountCode'), stringLengthLimit)}">
                {{ displayArray(product.lineItems, 'AccountCode') | shorten:stringLengthLimit }}
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="active">
        <th mat-header-cell scope="col" *matHeaderCellDef>ACTIVE</th>
        <td mat-cell *matCellDef="let product">
            <mat-slide-toggle class="slide-toggle"
                              [checked]="product.active"
                              [disabled]="showLoader === true"
                              (change)="putToggleActive(product, $event.checked)">
            </mat-slide-toggle>
        </td>
    </ng-container>

    <ng-container matColumnDef="edit">
        <th class="edit-cell" scope="col" mat-header-cell *matHeaderCellDef>EDIT</th>
        <td class="edit-cell" mat-cell *matCellDef="let product">
            <button mat-icon-button
                    [disabled]="false"
                    (click)="emitProductForEdit(product)">
                <img alt='Update product icon.'
                     src="/assets/icons/jobs/update.svg">
            </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnHeaders"></tr>
    <tr mat-row *matRowDef="let rowData; columns: columnHeaders;"> #</tr>
</table>
<mat-progress-bar *ngIf="showLoader === true"
                  mode="indeterminate">
</mat-progress-bar>
<mat-paginator [length]="paginatorControl.length"
               [pageSize]="paginatorControl.pageSize"
               [pageSizeOptions]="paginatorControl.pageSizeOptions"
               (page)="paginate($event)"
               #paginator>
</mat-paginator>
