import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {TermsAndConditionsService} from '../../services';

import {DialogService, SnackbarService} from '../../services/';
import {CancelPolicyComponent} from './cancel-policy/cancel-policy.component';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'prism-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsAndConditionsComponent implements OnInit {
    disableAndShowLoader = false;
    platformPolicy$ = this.tacService.platformPolicy$;
    userPolicy$ = this.tacService.userPolicy$;
    @Input() termsAndConditionsType: 'platform' | 'user';

    @Output() acceptedPolicy: EventEmitter<void> = new EventEmitter<void>();

    constructor(private router: Router,
                private sanitizer: DomSanitizer,
                private snackbarService: SnackbarService,
                private tacService: TermsAndConditionsService,
                private dialogService: DialogService) {
    }

    ngOnInit() {
        this.tacService.getPolicies()
            .toPromise()
            .catch(err => {
                console.error(err);
            });
    }

    getSafeUrl(url) {
        if (!!url) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(url);
        } else {
            return '';
        }
    }

    acceptPolicy() {
        this.disableAndShowLoader = true;
        this.tacService.acceptAndUpdateMember(this.termsAndConditionsType)
            .subscribe(
                success => {
                    this.acceptedPolicy.emit();
                },
                err => {
                    this.disableAndShowLoader = false;
                    this.snackbarService.handleError(`Oh no! Something went wrong, please try again.`);
                }
            );
    }

    openCancelPolicyDialog() {
        this.dialogService.openDialog(CancelPolicyComponent);
    }
}

