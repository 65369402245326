<div class="uploader-display"
     (click)="toggleUploaderInfoDisplay()"
     *ngIf="(uploads$ | async) as uploads"
     [ngStyle]="{'pointer-events': canUploaderOpen(true), 'display': (completedTracker | json) !== '{}' ? 'flex' : 'none'}">
    <div class="uploader-progress-container">
        <h3 class="uploader-top-counter">
            <span *ngIf="(uploadStats$ | async) && (uploadStats$ | async)?.completed !== (uploadStats$ | async)?.total">
                <div class="upload-spinner"><div></div><div></div><div></div><div></div></div>
                    Files uploading... ({{ (uploadStats$ | async)?.completed }}/{{ (uploadStats$ | async)?.total }})
                <mat-icon *ngIf="failedUploadTracker.length" [color]="'warn'">sync_problem</mat-icon>
            </span>
            <span
                    *ngIf="(uploadStats$ | async) && (uploadStats$ | async).completed === (uploadStats$ | async).total && (uploadStats$ | async).total !== 0">
                All uploads complete!
            </span>

        </h3>
        <div class="uploader-display-progress">
            <mat-progress-bar class="upload-progress-bar"
                              [color]="(uploadStats$ | async)!.completed === (totalFiles) ? 'accent' : 'primary'"
                              [value]="overallProgress | async">
            </mat-progress-bar>
            <span class="">{{ overallProgress | async }}%</span>
        </div>
    </div>

    <div class="uploader-info-container"
         [ngClass]="{'active': displayUploaderControl}">
        <div class="uploader-info-content"
             (click)="preventDisplayCloseOnClick($event)"
             (clickOutside)="onClickedOutsideUploader($event)">
            <div class="upload-items-container">
                <div *ngIf="failedUploadTracker?.length" class="retry-all-btn-container">
                    <button mat-flat-button *ngIf="failedUploadTracker.length" (click)="retryAllUploads()"><span
                            class="upload-failed-text">Retry</span></button>
                </div>
                <div class="upload-items" *ngFor="let upload of uploads | keyvalue">
                    <div class="upload-item-container" *ngFor="let job of upload.value | keyvalue">
                        <div class="uploader-service-name">
                            <div class="upload-item-service" *ngFor="let service of job.value | keyvalue">
                                <div>
                                    <strong>#{{ service.key | shortenServiceID }}: {{ job.key }}</strong>
                                    <div class="upload-control">
                                        <span class="finalise-text"
                                              *ngIf="completedTracker[upload.key] && (uploadingStatusSet$|async)[service.key]">Finalising Uploads...</span>
                                        <button mat-flat-button
                                                *ngIf="completedTracker[upload.key] && !(uploadingStatusSet$|async)[service.key]"
                                                class="clear-button"
                                                (click)="clearServiceUploads($event, job.key, service.key, upload.key)">
                                            <span class="button-text">Clear Completed</span>
                                        </button>
                                    </div>
                                </div>
                                <div *ngFor="let service of job.value | keyvalue">
                                    <div class="upload-item" *ngFor="let file of service.value | keyvalue">
                                        <span [class]="file?.value.completed ? 'upload-text upload-completed-text' : file?.value.failed ? 'upload-text upload-failed-text' : 'upload-text upload-active-text'">{{ file.key }}</span>
                                        <div *ngIf="file?.value.failed" style="display: inline">
                                            <button mat-icon-button [color]="'warn'"
                                                    (click)="restartUpload(file.value.uploadRestartCancelData, service.value)">
                                                <mat-icon>refresh</mat-icon>
                                            </button>
                                        </div>
                                        <div class="upload-control">
                                            <mat-progress-bar class="upload-progress-bar"
                                                              [color]="file?.value.completed ? file?.value.failed ? 'warn': 'accent' : 'primary'"
                                                              [value]="file?.value.progress">
                                            </mat-progress-bar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

