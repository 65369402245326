import {CommonModule} from '@angular/common';
import {HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DropzoneModule} from 'ngx-dropzone-wrapper';
import {AddProductsTableComponent} from 'src/app/components/add-edit-invoice/add-products-table/add-products-table.component';
import {PageNotFoundComponent} from 'src/app/components/page-not-found/page-not-found.component';
import {UploadDropzoneComponent} from 'src/app/components/upload-dropzone/upload-dropzone.component';
import {MobileSwiperComponent} from '../../components/mobile-swiper/mobile-swiper.component';
import {PipeModule} from './pipe.module';
import {UiModule} from './ui.module';
import {
    BaseComponent,
    HeaderComponent,
    MemberOptionsComponent,
    SidebarComponent,
    UploaderDisplayComponent,
    XeroDisplayComponent
} from '../../components';
import {SwiperComponent} from '../../components/swiper/swiper.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        DropzoneModule,
        HttpClientJsonpModule,
        PipeModule,
        UiModule.full()
    ],
    declarations: [
        BaseComponent,
        HeaderComponent,
        SidebarComponent,
        XeroDisplayComponent,
        MemberOptionsComponent,
        UploaderDisplayComponent,
        PageNotFoundComponent,
        UploadDropzoneComponent,
        AddProductsTableComponent,
        MobileSwiperComponent,
        SwiperComponent
    ],
    exports: [
        MobileSwiperComponent,
        UploadDropzoneComponent,
        AddProductsTableComponent,
        PipeModule,
        UiModule,
        CommonModule,
        RouterModule,
        HttpClientModule
    ]
})

export class SharedModule {
}
