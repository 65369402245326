import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {take} from 'rxjs/operators';
import {EMAIL_REGEX, refreshJobs$, refreshJobsUsers$, validateEmail, validateEmailList} from '../../helpers';
import {Agency, Agent, MinimalAgency} from '../../models';
import {AgencyService, AgentService, SnackbarService} from '../../services';
import {CustomValidators} from '../../validators';

@Component({
    selector: 'prism-add-edit-agent',
    templateUrl: './add-edit-agent.component.html',
    styleUrls: ['./add-edit-agent.component.scss']
})
export class AddEditAgentComponent implements OnInit {
    isPosting: boolean;
    isFetchingAgency: boolean;
    agencies: MinimalAgency[];
    agentForm: FormGroup;
    primaryDetailsFormGroup: FormGroup;
    emailsFormGroup: FormGroup;
    invoiceEmails: FormArray;
    notificationEmails: FormArray;
    separatorKeysCodes: number[];
    agencyPlaceholder;

    @ViewChild('chipListNotification', {static: true}) chipListNotification;
    @ViewChild('chipListInvoice', {static: true}) chipListInvoice;

    constructor(private dialogRef: MatDialogRef<AddEditAgentComponent>,
                @Inject(MAT_DIALOG_DATA) public data: Agent,
                private formBuilder: FormBuilder,
                private agencyService: AgencyService,
                private agentService: AgentService,
                private snackbarService: SnackbarService) {

        this.isFetchingAgency = true;
        this.separatorKeysCodes = [ENTER, COMMA, SPACE];
        this.invoiceEmails = new FormArray([], [Validators.required, CustomValidators.emailList]);
        this.notificationEmails = new FormArray([], [Validators.required, CustomValidators.emailList]);

        if (data && data.invoiceEmails && data.invoiceEmails.length) {
            this.fillEmails(data.invoiceEmails, this.invoiceEmails);
        }
        if (data && data.notificationEmails && data.notificationEmails.length) {
            this.fillEmails(data.notificationEmails, this.notificationEmails);
        }
        if (data) {
            this.agencyPlaceholder = data?.agency ? data.agency.name : 'Select an agency';
        } else {
            this.agencyPlaceholder = 'Select an agency';
        }

        this.primaryDetailsFormGroup = formBuilder.group({
            firstName: formBuilder.control(data ? data.firstName : '', [Validators.required]),
            lastName: formBuilder.control(data ? data.lastName : '', [Validators.required]),
            contactNumber: formBuilder.control(data ? data.contactNumber : ''),
            agency: formBuilder.control(data ? data.agencyId : '', [Validators.required]),
        });

        this.emailsFormGroup = formBuilder.group({
            invoiceEmails: this.invoiceEmails,
            notificationEmails: this.notificationEmails
        });

        this.agentForm = formBuilder.group({
            primaryDetails: this.primaryDetailsFormGroup,
            emails: this.emailsFormGroup
        });
    }

    ngOnInit() {
        this.chipListNotification.errorState = !validateEmailList(this.notificationEmails);
        this.chipListInvoice.errorState = !validateEmailList(this.invoiceEmails);
        this.agencyService.getAgencyNames()
            .pipe(
                take(1)
            )
            .subscribe((data) => {
                this.agencies = data;
                this.isFetchingAgency = false;
            });
        this.primaryDetailsFormGroup.get('agency').valueChanges.subscribe(this.handleAgencyChange);
    }

    handleAgencyChange = (agencyId: string) => {
        if (!agencyId) {
            return;
        }
        this.isFetchingAgency = true;
        this.agencyService.getAgency(agencyId).subscribe((agency: Agency) => {
            if (agency) {
                this.fillEmails(agency.invoiceEmails, this.invoiceEmails);
                this.fillEmails(agency.notificationEmails, this.notificationEmails);
                this.chipListNotification.errorState = !validateEmailList(this.notificationEmails);
                this.chipListInvoice.errorState = !validateEmailList(this.invoiceEmails);
                this.isFetchingAgency = false;
            }
        });
    };

    fillEmails(emails: string[], control: FormArray, clear: boolean = true) {
        if (clear) {
            while (control.length !== 0) {
                control.removeAt(0);
            }
        }
        if (emails) {
            for (const email of emails) {
                control.push(this.formBuilder.control(email, [Validators.email, Validators.pattern(EMAIL_REGEX)]));
            }
        }
    }

    addInvoiceEmail(event: MatChipInputEvent) {
        if ((event.value || '').trim()) {
            const emailString = event.value.trim();
            if (validateEmail(emailString)) {
                this.chipListInvoice.errorState = false;
                this.invoiceEmails.push(this.formBuilder.control(event.value.trim(), [Validators.email, Validators.pattern(EMAIL_REGEX)]));
                event.input.value = '';
                this.invoiceEmails.markAsDirty();
            } else {
                this.chipListInvoice.errorState = true;
            }
        } else {
            this.chipListInvoice.errorState = false;
        }
    }

    addNotificationEmail(event: MatChipInputEvent) {
        if ((event.value || '').trim()) {
            const emailString = event.value.trim();
            if (validateEmail(emailString)) {
                this.notificationEmails.push(this.formBuilder.control(event.value.trim(), [Validators.email, Validators.pattern(EMAIL_REGEX)]));
                this.chipListNotification.errorState = !validateEmailList(this.notificationEmails);
                event.input.value = '';
                this.notificationEmails.markAsDirty();
            } else {
                this.chipListNotification.errorState = true;
            }
        } else {
            this.chipListNotification.errorState = !validateEmailList(this.notificationEmails);
        }
    }

    removeInvoiceEmail(index: number) {
        if (index >= 0 && index < this.invoiceEmails.length) {
            this.invoiceEmails.removeAt(index);
            this.chipListInvoice.errorState = !validateEmailList(this.invoiceEmails);
            this.isPosting = false;
            this.notificationEmails.markAsDirty();
        }
    }

    removeNotificationEmail(index: number) {
        if (index >= 0 && index < this.notificationEmails.length) {
            this.notificationEmails.removeAt(index);
            this.chipListNotification.errorState = !validateEmailList(this.notificationEmails);
            this.isPosting = false;
            this.notificationEmails.markAsDirty();
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    addAgent() {
        const agent: Agent = {
            id: this.data ? this.data.id : null,
            active: this.data ? this.data.active : true,
            firstName: this.primaryDetailsFormGroup.get('firstName').value,
            lastName: this.primaryDetailsFormGroup.get('lastName').value,
            agencyId: this.primaryDetailsFormGroup.get('agency').value,
            contactNumber: this.primaryDetailsFormGroup.get('contactNumber').value,
            invoiceEmails: this.invoiceEmails.controls.map(control => control.value),
            notificationEmails: this.notificationEmails.controls.map(control => control.value)
        };

        if (agent.id) {
            this.isPosting = true;
            this.agentService.putAgent(agent).subscribe(refId => {
                refreshJobs$.next();
                refreshJobsUsers$.next();
                this.snackbarService.showSnackbar(`${agent.firstName} ${agent.lastName} updated`);
                this.isPosting = false;
                this.dialogRef.close({agentId: refId});
            }, err => {
                this.isPosting = false;
                console.error(err);
                this.snackbarService.handleError('Problem updating agent');
            });
        } else {
            this.isPosting = true;
            this.agentService.postAgent(agent).subscribe(refId => {
                refreshJobsUsers$.next();
                this.snackbarService.showSnackbar(`${agent.firstName} ${agent.lastName} added`);
                this.isPosting = false;
                this.dialogRef.close({agentId: refId});
            }, err => {
                this.isPosting = false;
                console.error(err);
                this.snackbarService.handleError('Problem adding agent');
            });
        }
    }
}
