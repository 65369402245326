export enum WatermarkPosition {
    topLeft,
    topMiddle,
    topRight,
    centerLeft,
    centerMiddle,
    centerRight,
    bottomLeft,
    bottomMiddle,
    bottomRight
}
