import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'jobInvoiced'
})
export class JobInvoicedPipe implements PipeTransform {
    transform(job): boolean {
        return (
            ((!!job.invoices && job.invoices.length > 0) && (!job.productsToInvoice)) ||
            ((!!job.unsyncedInvoices && job.unsyncedInvoices.length > 0) && (!job.productsToInvoice)) ||
            ((job.charged === false) && !job.productsToInvoice)
        );
    }
}