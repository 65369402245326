<div class="mat-dialog-container add-edit-invoice-modal">
    <h1 mat-dialog-title>Create Invoice - {{ selectedJob.address }} </h1>

    <mat-dialog-content>
        <div class="template-selection">
            <mat-radio-group (change)="setInvoiceTemplateSelection($event)">
                <mat-radio-button color="primary" value="A" [checked]="true">
                    Display line item amounts
                </mat-radio-button>
                <mat-radio-button color="primary" value="B">
                    Hide line item amounts
                </mat-radio-button>
            </mat-radio-group>
            <span class="tutorial-text" *ngIf="templateSelection === 'B'">Please be sure that this template has been created as shown in
                <a href="#">tutorial</a>
            </span>

            <div class="free-job-container">
                <mat-checkbox id="free-job-checkbox" [checked]="!charged" (change)="setJobChargeStatus()">Free Job
                </mat-checkbox>
                <mat-icon
                        matTooltip="{{tooltipText}}"
                        matTooltipClass="icon-tooltip"
                        [matTooltipPosition]="tooltipPosition"
                        aria-label="Tooltip that describes the function of the adjacent checkbox."
                >
                    info_outline
                </mat-icon>
            </div>
        </div>

        <form class="add-job-form" [formGroup]="addEditInvoiceForm">
            <div class="field-group">
                <div class="form-field form-field-input">
                    <div class="flex-wrapper">
                        <mat-label class="form-field-label">Invoiced To</mat-label>
                        <mat-checkbox
                                *ngIf="!!selectedJob.agent"
                                class="invoice-agent-label"
                                [checked]="false"
                                [labelPosition]="checkboxLabelPosition"
                                [disabled]="!charged"
                                color="primary"
                                (change)="toggleAppendAgentName($event.checked, selectedJob)"
                        >
                            Invoice agent
                        </mat-checkbox>
                    </div>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="invoiceRecipient"/>
                        <mat-error
                                *ngIf="addEditInvoiceForm.controls['invoiceRecipient'].hasError('required')">
                            Recipient for invoice is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-field form-field-input">
                    <mat-label class="form-field-label">Date Created</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput type="date" formControlName="dateCreated"/>
                        <mat-error *ngIf="addEditInvoiceForm.controls['dateCreated'].hasError('required')">Creation date
                            is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-field form-field-input">
                    <mat-label class="form-field-label">Due Date</mat-label>
                    <mat-form-field appearance="outline" disabled>
                        <input
                                matInput
                                type="date"
                                min="{{ dateCreated }}"
                                formControlName="dueDate"
                        />
                        <mat-error *ngIf="addEditInvoiceForm.controls['dueDate'].hasError('required')">Due date is
                            required
                        </mat-error>
                    </mat-form-field>
                    <mat-progress-bar *ngIf="fetchingDueDateData" mode="indeterminate"></mat-progress-bar>
                </div>
            </div>
            <div class="form-field form-field-input form-field-input-chips">
                <mat-label class="form-field-label">Invoice Emails</mat-label>
                <mat-form-field appearance="outline">
                    <mat-chip-grid #chipListInvoice [disabled]="!charged">
                        <mat-chip-row
                                *ngFor="let email of invoiceEmails; let i = index"
                                [removable]="true"
                                [disabled]="!charged"
                                (removed)="removeInvoiceEmail(i)"
                        >
                            {{ email }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-row>
                        <input
                            #invoiceEmailInput
                            [matChipInputFor]="chipListInvoice"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            formArrayName="emails"
                            [matChipInputAddOnBlur]="true"
                            (matChipInputTokenEnd)="addInvoiceEmail($event)"
                            [disabled]="!charged"
                        />
                    </mat-chip-grid>
                    <mat-error> Invalid email address list</mat-error>
                </mat-form-field>
            </div>
        </form>

        <prism-add-products-table
                *ngIf="!loadingProducts else productsLoader"
                [products]="inputProducts"
                (lineItemsUpdated)="updateProducts($event)"
                [charged]="charged"
        >
        </prism-add-products-table>
        <ng-template #productsLoader>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-template>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button
                class="btn btn-red cancel-btn"
                mat-button
                (click)="closeModal()">
            Cancel
        </button>
        <button
                mat-raised-button
                class="btn btn-green"
                (click)="sendInvoice()"
                [disabled]="(addEditInvoiceForm.invalid || hasInvalidLineItem || sendingInvoice) && charged"
        >
            {{ charged ? 'Create Invoice' : 'Confirm' }}
            <ng-template [ngIf]="sendingInvoice">
                <div class="lds-hourglass"></div>
            </ng-template>
        </button>
    </mat-dialog-actions>
</div>
