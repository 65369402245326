<div class="product-carousel-wrapper">
    <div class="product-carousel-header">
        <div class="toolbar-dropdown">
            <button mat-icon-button class="filter-btn" (click)="preventClickThrough($event)">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                     viewBox="0 0 24 24"
                     width="24px"
                     [ngClass]="displayFilterMenu && 'filter-btn-active'">
                    <g>
                        <path d="M0,0h24 M24,24H0" fill="none"/>
                        <path
                                d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"/>
                        <path d="M0,0h24v24H0V0z" fill="none"/>
                    </g>
                </svg>
            </button>
            <div class="new-menu" *ngIf="displayFilterMenu" (click)="preventClickThrough($event)">
                <div class="new-menu-item">
                    <mat-checkbox (click)="$event.stopPropagation();" (change)="filterSelected( 'visible');"
                                  [checked]="filterConfig.visible">Visible
                    </mat-checkbox>
                </div>
                <div class="new-menu-item">
                    <mat-checkbox (click)="$event.stopPropagation();" (change)="filterSelected('hidden');"
                                  [checked]="filterConfig.hidden">Hidden
                    </mat-checkbox>
                </div>
                <div class="new-menu-item">
                    <mat-checkbox (click)="$event.stopPropagation();" (change)="filterSelected( 'rejected');"
                                  [checked]="filterConfig.rejected">
                        Rejected
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <h3 class="product-carousel-title">{{ productTitle }}</h3>
    </div>
    <div class="product-carousel-body">
        <div class="product-carousel-body-left">
            <div class="product-asset-wrapper"
                 #scrollAssets
                 *ngFor="let thumbnail of filteredAssets; let i = index;"
                 [ngClass]="{'selected-asset': currentIndex === i}"
                 (click)="selectImageToDisplay(i, thumbnail)">
                <div class="product-asset"
                     [ngClass]="{'hidden': thumbnail.hidden === 'true', 'rejected': thumbnail.finalRejectReason || thumbnail.savedRejectReason, 'video-thumbnail': thumbnail.contentType.startsWith('video/')}"
                     [ngStyle]="{'background-image': insertUrl(thumbnail.url)}"
                >
                    <div *ngIf="thumbnail.finalRejectReason || thumbnail.savedRejectReason" class="rejected-flag">
                        <svg width="33px" height="41px" viewBox="0 0 33 41"
                             xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <filter x="-3.7%" y="-5.2%" width="107.3%" height="110.4%"
                                        filterUnits="objectBoundingBox" id="filter-1">
                                    <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                                    <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1"
                                                    result="shadowBlurOuter1"></feGaussianBlur>
                                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix"
                                                   in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                                    <feMerge>
                                        <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                                        <feMergeNode in="SourceGraphic"></feMergeNode>
                                    </feMerge>
                                </filter>
                            </defs>
                            <g id="Member" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Member---Job-Album---All-Possible-Statuses"
                                   transform="translate(-801.000000, -987.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                    <g id="Group-19-Copy-4" transform="translate(225.000000, 802.000000)">
                                        <g id="Group-16">
                                            <g id="Group-2" transform="translate(20.000000, 127.000000)">
                                                <g id="548c32_7121831da6444e03949c693969575a87-copy-6"
                                                   filter="url(#filter-1)" transform="translate(463.428446, 0.000000)">
                                                    <g id="noun_758382_cc" transform="translate(96.000000, 60.000000)">
                                                        <g id="Group">
                                                            <path
                                                                    d="M8.49314456,0.152184118 C5.69749456,0.232519412 2.91496644,1.07181941 0.380860188,2.69997824 C0.149744563,2.84685471 0.0009758125,3.11833118 8.12500001e-07,3.39483118 C8.12500001e-07,13.5728312 8.12500001e-07,23.7402941 8.12500001e-07,33.9168529 C8.12500001e-07,34.3716471 0.363757062,34.7403824 0.812500813,34.7403824 C1.26124456,34.7403824 1.62500081,34.3716471 1.62500081,33.9168529 L1.62500081,18.7201824 C2.97622894,17.8918353 8.32613519,15.0697194 14.7138883,17.8709176 C18.2522429,19.4226118 20.9232148,19.7352235 22.7373242,19.6338059 C24.5513117,19.5323471 25.5937492,18.9646882 25.5937492,18.9646882 C25.8378242,18.8218471 25.9980898,18.5425882 26.0000008,18.2569471 L26.0000008,3.38194294 C26.0000008,2.81193706 25.2859742,2.38530765 24.7939242,2.66135471 C24.7939242,2.66135471 24.2059179,3.00892529 22.6611117,3.08600765 C21.1163867,3.16304882 18.7082586,2.91611353 15.3486133,1.49041941 C13.5138664,0.711772353 11.6039633,0.257966471 9.68654456,0.165031176 C9.28703831,0.145678235 8.89252894,0.140737059 8.49314456,0.152266471 L8.49314456,0.152184118 Z M8.58203206,1.79924294 C8.92316019,1.79100765 9.26891956,1.79514042 9.61033206,1.81200765 C11.3315727,1.89827235 13.0453383,2.30064882 14.7138883,3.00867824 C18.2662992,4.51619 20.9319492,4.82299588 22.7373242,4.73294294 C23.4428992,4.69794294 23.9212586,4.57700765 24.3749992,4.46274294 L24.3749992,17.7035353 C24.0470336,17.8174294 23.5401961,17.9496471 22.6484367,17.9995118 C21.1036711,18.0858588 18.6875398,17.8294941 15.3486133,16.3652959 C9.27436331,13.7015076 3.96695081,15.6633606 1.62500081,16.8542706 L1.62500081,14.97559 L1.62500081,3.87083118 C3.82858206,2.54260176 6.19417581,1.85787824 8.58203206,1.79911941 L8.58203206,1.79924294 Z"
                                                                    id="Shape"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <h6 class="product-asset-title">
                        {{ thumbnail.filename.substring(thumbnail.filename.indexOf('_') + 1, thumbnail.filename.lastIndexOf('.')) }}
                    </h6>
                </div>

                <div class="form-field form-field-textarea rejection-description" *ngIf="!!(memberData$|async)">
                    <mat-form-field appearance="outline"
                                    class="rejection-description-textarea"
                                    [ngClass]="thumbnail.processingStatus === 'no-process' && 'no-process-field'">
                        <textarea style="height: 60px" #rejectText matInput
                                  [placeholder]="thumbnail.processingStatus === 'no-process' ? 'You uploaded this asset' : 'Type your resending reasons here...'"
                                  [value]="thumbnail.savedRejectReason || thumbnail.finalRejectReason ? thumbnail.finalRejectReason || thumbnail.savedRejectReason : ''"
                                  [disabled]="!!thumbnail.savedRejectReason || !!thumbnail.finalRejectReason || (memberData$|async)?.isContractor || thumbnail.processingStatus === 'no-process' || (rejectionsPending$|async)[service.id] === true"
                                  (blur)="handleRejectionTextBlur()"
                                  (click)="clickedInRejectionText()"
                                  (input)="updateRejectReason(i, rejectText.value)"
                                  cdkTextareaAutosize
                                  #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="1"
                                  cdkAutosizeMaxRows="5"
                        ></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="product-carousel-body-right">
            <div class="arrow_prev">
                <button mat-icon-button *ngIf="filteredAssets.length > 0" [disabled]="!(filteredAssets.length > 1)"
                        (click)="finalNavButtonClicked('previous')">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
            </div>
            <div class="final-full">
                <div class="no-images-wrapper" *ngIf="filteredAssets.length === 0; else finalDisplay">
                    <span class="no-images-text">
                         No assets meet filter criteria
                    </span>
                    <img src="/assets/icons/no-assets.svg" alt="No assets to display" id="no-assets-img">
                </div>


                <ng-template #finalDisplay>
                    <div class="final-asset-container">
                        <h6 class="final-filename">{{ filename }}</h6>
                        <prism-final-display [finalAsset]="finalAsset"></prism-final-display>
                    </div>
                </ng-template>
            </div>
            <div class="arrow_next">
                <button mat-icon-button *ngIf="filteredAssets.length > 0" [disabled]="!(filteredAssets.length > 1)"
                        (click)="finalNavButtonClicked('next')">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="product-carousel-footer">
        <div class="footer-left">
            <span class="image-count">{{ assets?.length }} files</span>
        </div>

        <div class="footer-right">
            <button class="btn btn-red cancel-btn"
                    mat-button (click)="closeDialog()">Cancel
            </button>
            <button mat-raised-button class="btn btn-green" (click)="openRejectionConfirmation(service.id)"
                    [disabled]="!resendValid[service.id]">Resend
            </button>
        </div>
    </div>
</div>
