import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/portal';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    private _inputData: BehaviorSubject<any>;
    currentDialogRef: MatDialogRef<any>;
    dialogContext: {
        [id: string]: {}
    } = {};

    constructor(private dialog: MatDialog) {
        this._inputData = new BehaviorSubject(null);
    }

    openDialog(component: ComponentType<any>, inputData: any = {}, options?: {
        maxWidth?: string,
        maxHeight?: string,
        height?: string,
        width?: string,
        panelClass?: string,
        autoFocus?: boolean,
        disableClose?: boolean
        id?: string
    }) {
        if (options) {
            this.currentDialogRef = this.dialog.open(component, {
                data: inputData,
                maxWidth: options.maxWidth,
                maxHeight: options.maxHeight,
                width: options.width,
                height: options.height,
                panelClass: options.panelClass,
                autoFocus: options.autoFocus,
                disableClose: options.disableClose,
                id: options.id
            });
        } else {
            this.currentDialogRef = this.dialog.open(component);
        }
        this._inputData.next(inputData);

        this.currentDialogRef.afterClosed()
            .toPromise()
            .then(() => {
                this._inputData.next(null);
            })
            .catch(err => {
                console.error('Could not clear dialog input data');
            });

        return this.currentDialogRef;
    }

    closeDialog() {
        this.currentDialogRef.close();
    }

    closeResultDialog(result) {
        this.currentDialogRef.close(result);
    }

    closeAllDialogs() {
        this.dialog.closeAll();
    }

    openDialogDataShare(component: any, data: any, pannelID?: string) {
        this.dialog.open(component, {
            data: {data},
            id: pannelID
        });
    }

    get inputData() {
        return this._inputData.getValue();
    }

    openUniqueDialog(component: ComponentType<any>, inputData: any = {}, options?: {
        maxWidth?: string,
        maxHeight?: string,
        height?: string,
        width?: string,
        panelClass?: string,
        autoFocus?: boolean,
        disableClose?: boolean
        id?: string
    }) {
        const currentDialog = this.dialog.open(component, {
            data: inputData,
            maxWidth: options.maxWidth,
            maxHeight: options.maxHeight,
            width: options.width,
            height: options.height,
            panelClass: options.panelClass,
            autoFocus: options.autoFocus,
            disableClose: options.disableClose,
            id: options.id
        });

        this.assignDialogContext(currentDialog.id, inputData);
        return currentDialog;
    }

    assignDialogContext(id: string, data: any) {
        this.dialogContext[id] = data;
    }

    unassignDialogContext(id: string) {
        delete this.dialogContext[id];
    }
}
