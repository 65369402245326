import {AfterContentInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import Dropzone from 'dropzone';
import html2pdf from 'html2pdf-fix-jspdf';
import {BehaviorSubject} from 'rxjs';
import {StorageTarget} from '../../enums';
import {Job, Service} from '../../models';
import {JobsService, SnackbarService} from '../../services';
import {UploadService} from '../../services/upload.service';
import moment from 'moment';
import {UUID} from 'angular2-uuid';

@Component({
    selector: 'prism-upload-dropzone',
    templateUrl: './upload-dropzone.component.html',
    styleUrls: ['./upload-dropzone.component.scss']
})
export class UploadDropzoneComponent implements OnInit, AfterContentInit, OnDestroy {
    _briefData: any = {};
    updatedServiceId: string;
    disableDropzone: boolean;
    private service: Service;

    @Input() productId: string;
    @Input() serviceType: string;
    @Input() onlyEdit: boolean;
    @Input() processorJobNotCreated: boolean;
    @Input() serviceIndex: number;
    @Input() allowedFileType: string;

    @Input('service')
    set setService(service) {
        this.service = service;
        if (service.id) {
            this.updatedServiceId = service.id;
        }
    }

    @Input('briefData')
    set briefData(briefData: any) {
        this._briefData = {
            ...briefData,
            id: this.service.id,
            version: this.service.version || 'v1'
        };
    }

    @Input('disableDropzone')
    set setDisableDropzone(bool: any) {
        if (this.disableDropzone !== !!bool) {
            this.disableDropzone = !!bool;
        }
    }

    @Input() parentJob: Job;

    @Output() fileCount: EventEmitter<number> = new EventEmitter<number>();
    @Output() serviceUploading: EventEmitter<boolean> = new EventEmitter<boolean>();

    uploadLimit = 5000000000;
    serviceDropzone: Dropzone;
    dZoneConfig: Dropzone.DropzoneOptions = {
        url: '#',
        autoQueue: true,
        uploadMultiple: true,
        autoProcessQueue: false,
        previewsContainer: false,
        createImageThumbnails: true,
        chunking: false
    };
    fileSizeCounter = {};
    formattedFileSizeCounter: string;
    formattedFileDuration: string;
    fileDuration$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    totalFileDurationSeconds: number = 0;
    uploadsTotal: number = 0;
    uploadLimited: boolean = false;

    @ViewChild('dropzone', {static: true}) dropzone: ElementRef;
    @ViewChild('dropzoneMessage', {static: true}) dropzoneMessage: ElementRef;

    constructor(
        private jobsService: JobsService,
        private uploadService: UploadService,
        private snackbarService: SnackbarService) {
    }


    ngOnInit() {
        this.parentJob = this.jobsService.selectedJob;
    }

    ngAfterContentInit() {
        this.serviceDropzone = new Dropzone(this.dropzone.nativeElement, this.dZoneConfig);
        this.serviceDropzone.accept = ((file: Dropzone.DropzoneFile, done) => {
            const existingItem = this.serviceDropzone.getQueuedFiles().find(f => f.name === file.name);
            if (file.size === 0) {
                this.serviceDropzone.removeFile(file);
                done();
            } else if (!existingItem) {
                let fileSize = this.transformFileSizeFormat(file.size);
                this.fileSizeCounter[file.name] = {
                    size: fileSize
                };
                this.uploadsTotal += file.size;
                this.formattedFileSizeCounter = this.transformFileSizeFormat(this.uploadsTotal);
                this.uploadLimited = this.uploadsTotal > this.uploadLimit;
                this.uploadService.uploadLimited(this.uploadsTotal > this.uploadLimit, this.updatedServiceId);

                if (file.type.startsWith('video')) {
                    let video = document.createElement('video');
                    video.preload = 'metadata';

                    video.onloadedmetadata = () => {
                        window.URL.revokeObjectURL(video.src);
                        this.fileDuration$.value[file.name] = {};
                        let duration = video.duration;
                        this.totalFileDurationSeconds += Math.ceil(duration);
                        this.fileDuration$.value[file.name].duration = Math.ceil(duration);
                        this.fileDuration$.next(this.fileDuration$.value);
                        let totalMinutes = Math.floor(this.totalFileDurationSeconds / 60);
                        let totalSeconds = this.totalFileDurationSeconds % 60;
                        this.formattedFileDuration = `${totalMinutes}:${totalSeconds < 10 ? 0 : ''}${totalSeconds}${totalMinutes > 0 ? 'min' : 's'}`;
                    };
                    video.src = URL.createObjectURL(file);
                }

                if (!file.name.endsWith('.exe')) {
                    file['completed'] = false;
                    this.fileCount.emit(this.serviceDropzone.files.length);
                    done();
                }
            } else {
                this.snackbarService.handleError(`Some files did not get added to upload queue. They might already be in the queue or the file type is not allowed.`);
                this.serviceDropzone.removeFile(file);
                done('Nope');
            }
        });

        this.serviceDropzone.on('dragover', () => this.dropzone.nativeElement.classList.add('drag-over'));
        this.serviceDropzone.on('dragleave', () => this.dropzone.nativeElement.classList.remove('drag-over'));
        this.serviceDropzone.on('drop', () => this.dropzone.nativeElement.classList.remove('drag-over'));
    }

    removeFileFromQueue(file: Dropzone.DropzoneFile) {
        this.serviceDropzone.removeFile(file);
        this.fileCount.emit(this.serviceDropzone.files.length);
        delete this.fileSizeCounter[file.name];
        this.uploadsTotal -= file.size;
        this.uploadLimited = this.uploadsTotal > this.uploadLimit;
        this.uploadService.uploadLimited(this.uploadLimited, this.updatedServiceId);
        this.formattedFileSizeCounter = this.transformFileSizeFormat(this.uploadsTotal);

        if (this.fileDuration$.value[file.name]) {
            this.totalFileDurationSeconds -= this.fileDuration$.value[file.name].duration;
            delete this.fileDuration$.value[file.name];
            this.fileDuration$.next(this.fileDuration$.value);
        }

        let totalMinutes = Math.floor(this.totalFileDurationSeconds / 60);
        let totalSeconds = this.totalFileDurationSeconds % 60;
        if (totalMinutes === 0 && totalSeconds === 0) {
            this.formattedFileDuration = null;
        } else {
            this.formattedFileDuration = `${totalMinutes}:${totalSeconds < 10 ? 0 : ''}${totalSeconds}${totalMinutes > 0 ? 'min' : 's'}`;
        }
    }

    startUpload(jobId: number, destination: StorageTarget.PROCESSOR | StorageTarget.MEMBER, serviceId: string) {
        const uploadUUID = UUID.UUID();
        const statuses = this.jobsService.getServiceStatuses(jobId);
        if (destination === StorageTarget.PROCESSOR) {
            const files = this.serviceDropzone.files;
            this.fileCount.emit(files.length);
            this.serviceUploading.emit(true);
            this.snackbarService.showSnackbar('Generating your pdf');
            this.makePdf().then((pdf) => {
                this.serviceUploading.emit(false);
                this.snackbarService.dismissSnackbar();
                this.uploadService.initializeUpload({
                    uploadUUID,
                    assets: [...files, pdf],
                    serviceData: this._briefData,
                    productId: this.productId,
                    oldServiceStatus: statuses[serviceId],
                    job: this.parentJob,
                    uploadType: 'PROCESSOR',
                    processingType: this.service.SNAKE_CASE
                }).then().catch((e) => console.error(e));
            });
        } else {
            this.serviceUploading.emit(true);
            this.uploadService.initializeUpload({
                uploadUUID,
                assets: this.serviceDropzone.files,
                serviceData: this._briefData,
                productId: this.productId,
                oldServiceStatus: statuses[serviceId],
                job: this.parentJob,
                uploadType: 'MEMBER',
                processingType: null,
            }).then().catch((e) => console.error(e));
        }
    }

    async makePdf() {
        return new Promise<any>((resolve) => {
                const pdf = html2pdf();
                const briefHtml = this.makeBriefHTML(this._briefData);
                resolve(pdf.set({
                    jsPDF: {
                        orientation: 'p',
                        unit: 'mm',
                        format: 'a4'
                    }
                }).from(briefHtml).output('blob'));
            }
        );
    }

    makeBriefHTML(briefData) {
        return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <style>
            .main-container {
                display: flex;
                flex-direction: column;
                justify-items: flex-start;
                align-items: center;
                padding: 40px;
            }
            
            .brief {
              margin: 0;
                width: 600px;
                height: 600px;
                padding: 20px;
                border: 1px solid #EEEEEE;
            }
        </style>
      </head>
      <body>
        <div class="main-container" >
            <h3>Time created (UTC): ${moment().utc().format('LLLL')}</h3>
            <h3>Address: ${this.parentJob.address}</h3>
            <h3>JobId: ${briefData.id}</h3>
            <h3>Finals requested: ${briefData.numOfFinals || 'No finals'}</h3>
            <div class="brief">
                ${!!briefData.brief ? briefData.brief : 'No brief has been added'}
            </div>
        </div>
      </body>
      </html>
    `;
    }

    transformFileSizeFormat(fileSize) {
        if (fileSize > 999999) {
            return `${(fileSize / 1000000).toFixed(2)} MB`;
        } else {
            return `<1MB`;
        }
    }

    ngOnDestroy() {
        this.fileSizeCounter = 0;
    }
}
