import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'prism-refresh-dialog',
    templateUrl: './refresh-dialog.component.html',
    styleUrls: ['./refresh-dialog.component.scss']
})
export class RefreshDialogComponent {

    refresh() {
        window.location.reload();
    }

}
