import {Component} from '@angular/core';
import moment from 'moment';
import {MemberService} from '../../services';

@Component({
    selector: 'prism-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    currentTimestamp = moment().valueOf();

    constructor(private memberService: MemberService) {
    }
}
