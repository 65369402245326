import {NgModule} from '@angular/core';
import {LoginComponent} from '../../components';
import {LoginRouting} from '../routing/login.routing';
import {UiModule} from './ui.module';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        UiModule.core(),
        ReactiveFormsModule,
        LoginRouting
    ],
    declarations: [
        LoginComponent,
    ]
})

export class LoginModule {

}
