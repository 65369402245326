import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccountPageComponent} from 'src/app/components/account-page/account-page.component';
import {InvoicesPageComponent} from 'src/app/components/invoices-page/invoices-page.component';
import {JobAlbumComponent} from 'src/app/components/job-album/job-album.component';
import {JobDetailComponent} from 'src/app/components/job-detail/job-detail.component';
import {JobsComponent} from 'src/app/components/jobs/jobs.component';
import {ProductsPageComponent} from 'src/app/components/products-page/products-page.component';
import {HomeCoreModule} from '../modules/home-core.module';

const homeCoreRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'jobs',
                component: JobsComponent
            },
            {
                path: 'products',
                component: ProductsPageComponent
            },
            {
                path: 'invoices',
                component: InvoicesPageComponent
            },
            {
                path: 'jobs/:id',
                component: JobDetailComponent
            },
            {
                path: 'jobs/:id/album',
                component: JobAlbumComponent
            },
            {
                path: 'profile',
                component: AccountPageComponent
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'jobs'
            },
        ]
    },

];

export const HomeCoreRouting: ModuleWithProviders<HomeCoreModule> = RouterModule.forChild(homeCoreRoutes);
