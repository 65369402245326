import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {filter, take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {isBoolean} from 'lodash';
import {XeroConnectionService} from '../xero-connection.service';

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard implements OnDestroy {

    subDestroyer: Subject<void>;

    constructor(
        private authService: AuthenticationService,
        private xeroConnectionService: XeroConnectionService
    ) {
        this.subDestroyer = new Subject<void>();
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {

        if (!!next.queryParams.at) {
            const at = next.queryParams.at;
            this.xeroConnectionService.saveToken(at);
        }

        if (state.url) {
            const managingPassword = state.url.startsWith('/setup/passwordreset') || state.url.startsWith('/setup/update-password');

            if (state.url.startsWith('/setup/update-password')) {
                const resetCode = state.root.queryParamMap.get('oobCode');
                this.authService.setResetPasswordCode(resetCode);
            }

            if (managingPassword) {
                return new Promise<boolean>((resolve) => {
                    resolve(true);
                });
            }
        }

        return new Promise<boolean>((res) => {
            this.authService.isLoggedIn$()
                .pipe(
                    takeUntil(this.subDestroyer),
                    filter((res) => isBoolean(res)),
                    take(1)
                )
                .subscribe((isLoggedIn) => {
                    if (!!state.url) {
                        window.Intercom('update', {email: this.authService.currentUserEmail});
                        res(isLoggedIn
                            || state.url.startsWith('/agent')
                            || state.url.startsWith('/users/enable-contractor'));
                        return;
                    }
                    res(isLoggedIn);
                    return;
                }, (err) => {
                    console.error(err);
                    res(false);
                });
        });
    }

    ngOnDestroy() {
        this.subDestroyer.next();
    }
}
