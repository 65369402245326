import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {isArray} from 'rxjs/internal-compatibility';
import {catchError, concatMap, delay, first, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import includes from 'lodash/includes';
import {JobsService} from './jobs.service';
import {SnackbarService} from './snackbar.service';
import {environment} from '../../environments';
import {
    AlbumProduct,
    Contractor,
    Job,
    JobProduct,
    JobProductsResponse,
    MemberProduct,
    MemberProductsResponse,
    Response,
    Service
} from '../models';
import {JobStatusRank, PrismHeaders} from '../enums';
import {refreshJobs$, refreshMemberProducts$} from '../helpers';
import {AuthenticationService} from './authentication.service';
import orderBy from 'lodash/orderBy';

@Injectable({
    providedIn: 'root'
})
export class ProductsService {
    private getJobProductsLoadingBool: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private postJobProductsLoadingBool: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private putJobProductsLoadingBool: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    private getMemberProductsLoadingBool: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private postMemberProductsLoadingBool: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private putMemberProductsLoadingBool: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    private albumProducts: BehaviorSubject<AlbumProduct[]> = new BehaviorSubject<AlbumProduct[]>([]);
    private _jobProducts: BehaviorSubject<JobProduct[]> = new BehaviorSubject<JobProduct[]>([]);
    private memberProducts: BehaviorSubject<MemberProduct[]> = new BehaviorSubject<MemberProduct[]>([]);
    private _productToAdd: BehaviorSubject<JobProduct> = new BehaviorSubject<JobProduct>(null);

    private selectedService: BehaviorSubject<Service> = new BehaviorSubject<Service>(null);
    private memberProductForEditing = null;
    private getMemberProductOptions: GetMemberProductsOptions = {
        pageSize: 100,
        getActive: true,
        getAll: false,
        sortDirection: SortDirection.descending,
        page: 1,
        sortBy: SortByKey.id,
        searchField: ''
    };
    private productsTableSizePreference: number = 10;

    constructor(private http: HttpClient,
                private authenticationService: AuthenticationService,
                private snackbarService: SnackbarService,
                private jobsService: JobsService) {
    }

    get productsTableSize() {
        return this.productsTableSizePreference;
    }

    set updateProductsTableSize(size: number) {
        this.productsTableSizePreference = size;
    }

    updateServiceName({productId, serviceId, newServiceName}) {
        return this.http.post(`${environment.apiBaseUrl}/UpdateServiceName`, {productId, serviceId, newServiceName})
            .toPromise();
    }

    clearAllProducts() {
        this.memberProducts.next([]);
        this.setJobProducts([]);
        this.albumProducts.next([]);
    }

    refreshAlbumPage() {
        this.switchGetJobProductsLoaderOn();
        const jobProducts = this._jobProducts.getValue();
        const memberProducts = this.memberProducts.getValue();
        const arrayForAlbum: AlbumProduct[] = jobProducts.reduce((accumulatorArray, currentJobProduct) => {
            const memberProductFound = memberProducts
                .find(memberProduct => currentJobProduct.memberProductId === memberProduct.id);

            if (!!memberProductFound) {
                const tempNewProduct = {};
                Object.assign(tempNewProduct, currentJobProduct, memberProductFound);
                accumulatorArray.push(tempNewProduct);
            }
            return accumulatorArray;
        }, []);

        this.albumProducts.next(arrayForAlbum);
        this.switchOffAllJobProductLoaders();
    }

    get albumProducts$() {
        return this.albumProducts.asObservable();
    }

    setAndGetFetchMemberProductsOptions(options?: GetMemberProductsOptions): GetMemberProductsOptions {
        const defaultOptions = this.getMemberProductOptions;
        this.getMemberProductOptions = Object.assign(defaultOptions, options);

        return this.getMemberProductOptions;
    }

    getInvoiceMemberProducts(options?: GetMemberProductsOptions) {
        let getOptions: GetMemberProductsOptions = this.getMemberProductOptions;

        if (options) {
            getOptions = {
                getAll: options.getAll,
                getActive: options.getActive,
                searchField: options.searchField,
                page: options.page,
                pageSize: options.pageSize,
                sortBy: options.sortBy,
                sortDirection: options.sortDirection
            };
        }

        const getOptionsString = JSON.stringify(getOptions);

        return this.http.get<MemberProductsResponse>(environment.apiBaseUrl + '/GetMemberProducts', {
            params: new HttpParams()
                .set('getOptions', getOptionsString)
        })
            .pipe(
                shareReplay(1),
                first(),
                tap(
                    productsRes => {
                    },
                    err => {
                        console.error(err);
                        this.snackbarService.handleError('Could not get member products');
                    },
                    () => {
                    }
                ),
            );
    }

    getMemberProducts(options?: GetMemberProductsOptions): Observable<MemberProductsResponse> {
        this.switchGetMemberProductsLoaderOn();
        let getOptions: GetMemberProductsOptions = this.getMemberProductOptions;


        if (options) {
            getOptions = {
                ...getOptions,
                ...options
            };
        }

        const getOptionsString = JSON.stringify(getOptions);

        return this.http.get<MemberProductsResponse>(environment.apiBaseUrl + '/GetMemberProducts', {
            params: new HttpParams()
                .set('getOptions', getOptionsString)
        })
            .pipe(
                shareReplay(1),
                first(),
                tap(
                    productsRes => {
                        this.memberProducts.next(productsRes.data);
                        this.switchGetMemberProductsLoaderOff();
                    },
                    err => {
                        console.error(err);
                        this.snackbarService.handleError('Could not get member products');
                    },
                    () => {
                        this.switchGetMemberProductsLoaderOff();
                    }
                ),
            );
    }

    postMemberProduct(product: AlbumProduct): Promise<JobProductsResponse> {
        const headers = new HttpHeaders()
            .set(PrismHeaders.FunctionHelperData, JSON.stringify({product}));

        this.switchPostMemberProductLoaderOn();

        return this.http.get<JobProductsResponse>(environment.apiBaseUrl + '/AddMemberProduct', {headers})
            .pipe(
                tap(() => {
                    refreshMemberProducts$.next();
                })
            )
            .toPromise();
    }

    putMemberProducts(updateData, options: PutProductOptions = {
        onlyUpdateActiveStatus: false,
        setActiveTo: false
    }) {
        this.switchPutMemberProductLoaderOn();
        refreshMemberProducts$.next();

        const headers = new HttpHeaders()
            .append(PrismHeaders.FunctionHelperData, JSON.stringify({options}));

        return this.http.put(`${environment.apiBaseUrl}/PutMemberProducts`, updateData, {headers})
            .toPromise();
    }

    changeMemberProductActiveState(productIds: number[], isActive: boolean): Observable<any> {
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({productIds, isActive}));

        return this.http.get(`${environment.apiBaseUrl}/ChangeMemberProductActive`, {headers});
    }

    get memberProductToEdit(): MemberProduct {
        return this.memberProductForEditing;
    }

    get memberProducts$() {
        return this.memberProducts.asObservable();
    }

    getInvoiceJobProducts(jobId = null) {
        const {isContractor} = (<Contractor>this.authenticationService.memberCurrentData);
        return this.http.get(environment.apiBaseUrl + `${isContractor ? '/GetContractorProducts' : '/GetJobProducts'}`, {
            params: {
                jobId: !!this.jobsService.selectedJob ? String(this.jobsService.selectedJob.id) : String(jobId)
            }
        })
            .pipe(
                tap((res: any) => {
                    let jobStatusAfter: string = null;
                    let highestNewStatusRankFound = 0;
                    let {data: productsData} = res;

                    productsData.forEach(product => {
                        const services = Object.values(product.services);
                        services.forEach(({status: serviceStatus}) => {
                            const currentStatusRank = JobStatusRank[serviceStatus];

                            if (currentStatusRank > highestNewStatusRankFound) {
                                highestNewStatusRankFound = currentStatusRank;
                                jobStatusAfter = serviceStatus;
                            }
                        });
                    });
                }),
            );
    }

    getJobProducts(jobId = null, share = true): Observable<JobProductsResponse> {
        const {isContractor} = (<Contractor>this.authenticationService.memberCurrentData);
        if (share) {
            this.switchGetJobProductsLoaderOn();
        }

        return this.http.get(environment.apiBaseUrl + `${isContractor ? '/GetContractorProducts' : '/GetJobProducts'}`, {
            params: {
                jobId: !!this.jobsService.selectedJob ? String(this.jobsService.selectedJob.id) : String(jobId)
            }
        })
            .pipe(
                tap((res: any) => {
                    let highestNewStatusRankFound = 0;
                    let {data: productsData} = res;

                    productsData.forEach(product => {
                        const services = Object.values(product.services);
                        services.forEach(({status: serviceStatus}) => {
                            const currentStatusRank = JobStatusRank[serviceStatus];

                            if (currentStatusRank > highestNewStatusRankFound) {
                                highestNewStatusRankFound = currentStatusRank;
                            }
                        });
                    });
                    if (share) {
                        this.setJobProducts(productsData);
                    }
                }),
            );
    }

    postJobProduct(productData, job: Job) {
        this.switchPostJobProductLoaderOn();
        const {agency, agent, contractor, agencyName, agentName, id, address} = job;

        productData.usersData = {
            agency,
            agent,
            contractor,
            agencyName,
            agentName,
            jobIdDB: id,
            address
        };

        return this.http.post(environment.apiBaseUrl + '/PostJobProduct', {
            jobId: job.id,
            productData
        }).pipe(
            tap(() => refreshJobs$.next())
        ).toPromise();
    }

    updateProductServiceStatus({targetStatus, serviceId}: { targetStatus: string, serviceId: string }) {
        const user = this.authenticationService.memberCurrentData;
        const memberProfileUid = !!user.isContractor ? user.mainMemberUid : this.authenticationService.getUserUid();
        return this.http.put(`${environment.apiBaseUrl}/UpdateServiceStatus`, {targetStatus, serviceId, memberProfileUid})
            .pipe(
                concatMap(val => of(val).pipe(delay(2500))),
                catchError(e => {
                    console.error(e);
                    return e;
                }),
                map((response: Response) => {
                    const responseData = response.data;
                    const jobProduct = this._jobProducts.value.find(product => responseData.productId === product.id);

                    // Details page uses services in Object form, Gallery page uses services in array form
                    if (isArray(jobProduct?.services)) {
                        const service = jobProduct?.services.find(service => service.id === serviceId);
                        service.status = targetStatus;
                    } else {
                        if (!!jobProduct && !!jobProduct?.services[responseData.serviceId]) {
                            (<Service>jobProduct.services[responseData.serviceId]).status = targetStatus;
                        }
                    }
                    return responseData;
                })
            );
    }

    setMemberProductToEdit(product) {
        this.memberProductForEditing = product;
    }

    setJobProducts(jobProducts: JobProduct[]) {
        this._jobProducts.next(jobProducts);
    }

    setSelectedService(service: Service) {
        this.selectedService.next(service);
    }

    setProductToAdd(value: any) {
        this._productToAdd.next(value);
    }

    generateProductAndServiceIds(product) {
        return this.http.post(`${environment.apiBaseUrl}/GenerateProductAndServiceIds`, {product});
    }

    get productToAdd$(): Observable<JobProduct> {
        return this._productToAdd.asObservable();
    }

    get jobProducts$(): Observable<JobProduct[]> {
        return this._jobProducts.asObservable()
            .pipe(
                switchMap(products => {
                    products.forEach(product => {
                        product.services = orderBy(product.services, ['id'], ['asc']);
                    });

                    return of(products);
                })
            );
    }

    switchGetJobProductsLoaderOn() {
        this.getJobProductsLoadingBool.next(true);
    }

    switchPostJobProductLoaderOn() {
        this.postJobProductsLoadingBool.next(true);
    }

    switchPostJobProductLoaderOff() {
        this.postJobProductsLoadingBool.next(false);
    }

    switchPutJobProductLoaderOff() {
        this.putJobProductsLoadingBool.next(false);
    }

    switchGetJobProductsLoaderOff() {
        this.getJobProductsLoadingBool.next(false);
    }

    switchOffAllJobProductLoaders() {
        this.switchGetJobProductsLoaderOff();
        this.switchPostJobProductLoaderOff();
        this.switchPutJobProductLoaderOff();
    }

    switchGetMemberProductsLoaderOn() {
        this.getMemberProductsLoadingBool.next(true);
    }

    switchPostMemberProductLoaderOn() {
        this.postMemberProductsLoadingBool.next(true);
    }

    switchPutMemberProductLoaderOn() {
        this.putMemberProductsLoadingBool.next(true);
    }

    switchPostMemberProductLoaderOff() {
        this.postMemberProductsLoadingBool.next(false);
    }

    switchPutMemberProductLoaderOff() {
        this.putMemberProductsLoadingBool.next(false);
    }

    switchGetMemberProductsLoaderOff() {
        this.getMemberProductsLoadingBool.next(false);
    }

    get isPostMemberProductLoading$(): Observable<boolean> {
        return this.postMemberProductsLoadingBool.asObservable();
    }

    get isGetMemberProductsLoading$(): Observable<boolean> {
        return this.getMemberProductsLoadingBool.asObservable();
    }

    get isPutMemberProductsLoading$(): Observable<boolean> {
        return this.putMemberProductsLoadingBool.asObservable();
    }

    get areMemberProductsLoading$() {
        return combineLatest(this.isPutMemberProductsLoading$, this.isPostMemberProductLoading$, this.isGetMemberProductsLoading$)
            .pipe(
                map(memberProductLoading => includes(memberProductLoading, true))
            );
    }

    get isPostJobProductLoading$(): Observable<boolean> {
        return this.postJobProductsLoadingBool.asObservable();
    }

    get isGetJobProductsLoading$(): Observable<boolean> {
        return this.getJobProductsLoadingBool.asObservable();
    }

    get isPutJobProductsLoading$(): Observable<boolean> {
        return this.putJobProductsLoadingBool.asObservable();
    }

    get areJobProductsLoading$() {
        return combineLatest(this.isPutJobProductsLoading$, this.isPostJobProductLoading$, this.isGetJobProductsLoading$)
            .pipe(
                map(jobProductLoading => includes(jobProductLoading, true))
            );
    }
}

export interface PutProductOptions {
    onlyUpdateActiveStatus: boolean;
    setActiveTo: boolean;
}

export interface GetMemberProductsOptions {
    getAll?: boolean;
    getActive?: boolean;
    searchField?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    sortDirection?: SortDirection;
}

export enum SortDirection {
    ascending = 'asc',
    descending = 'desc'
}

export interface PutProductOptions {
    onlyUpdateActiveStatus: boolean;
    setActiveTo: boolean;
}

export enum SortByKey {
    name = 'name',
    services = 'services',
    Price = 'Price',
    Description = 'Description',
    AccountingCode = 'AccountingCode',
    id = 'id'
}

