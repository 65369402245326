import {Component, OnInit} from '@angular/core';
import {
    AbstractControl, FormBuilder,
    FormGroup,
    UntypedFormArray,
    UntypedFormControl,
    Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {UUID} from 'angular2-uuid';
import {DialogService, ProductsService, SnackbarService} from '../../services';
import {MemberProduct, Response} from '../../models';
import {FINANCIAL_NUMBER_REGEX, NUMBER_REGEX} from '../../helpers';
import {first} from 'rxjs/operators';

@Component({
    selector: 'prism-add-edit-member-product',
    templateUrl: './add-edit-member-product.component.html',
    styleUrls: ['./add-edit-member-product.component.scss'],
})
export class AddEditMemberProductComponent implements OnInit {
    invalidAccountCodes: string[];
    productToEdit: MemberProduct = this.productsService.memberProductToEdit;
    putLoading$ = this.productsService.isPutMemberProductsLoading$;
    postLoading$ = this.productsService.isPostMemberProductLoading$;
    getProductsSub: Subscription;
    createEditProductForm: FormGroup;
    lineItemTotals = [];

    constructor(private dialogService: DialogService,
                private formbuilder: FormBuilder,
                private snackbarService: SnackbarService,
                private productsService: ProductsService) {
    }

    ngOnInit() {
        this.invalidAccountCodes = this.dialogService.inputData.invalidAccountCodes || [];

        this.createEditProductForm = this.formbuilder.group({
            ProductName: new UntypedFormControl(this.productToEdit
                ? this.productToEdit.ProductName
                : '', [Validators.required]),
            Price: new UntypedFormControl({
                value: this.productToEdit ? this.productToEdit.Price : null,
                disabled: true
            }, [
                Validators.min(0),
                Validators.required,
                Validators.pattern(NUMBER_REGEX),
                Validators.max(999999999999)
            ]),
            Description: new UntypedFormControl(this.productToEdit ? this.productToEdit.Description : ''),
            lineItems: !this.productToEdit
                ? this.formbuilder.array([
                    this.createLineItem({
                        Description: null,
                        AccountCode: null,
                        UnitPrice: null,
                        Quantity: null,
                        ItemCode: null,
                        lineItemUID: UUID.UUID(),
                        LineAmount: null
                    }),
                    this.createLineItem({
                        Description: null,
                        AccountCode: null,
                        UnitPrice: null,
                        Quantity: null,
                        ItemCode: null,
                        lineItemUID: UUID.UUID(),
                        LineAmount: null
                    })
                ])
                : this.formbuilder.array(this.productToEdit.lineItems.map(lineItem => {
                    return this.createLineItem(lineItem);
                })),
            services: new UntypedFormControl(!!this.productToEdit ? this.productToEdit.services : [], [Validators.minLength(1), Validators.required])
        });

        if (!!this.productToEdit) {
            this.lineItemTotals.push(+this.productToEdit.Price);
        }
    }

    createLineItem = ({Description, Quantity, UnitPrice, AccountCode, ItemCode, lineItemUID, LineAmount}: {
        Description: string,
        Quantity: number,
        UnitPrice: number,
        AccountCode: string,
        ItemCode: string,
        lineItemUID: string,
        LineAmount: number
    }): UntypedFormControl => {
        return this.formbuilder.control({
            Description: this.formbuilder.control(Description, [Validators.required]),
            Quantity: this.formbuilder.control(Quantity, [Validators.required, Validators.pattern(NUMBER_REGEX)]),
            AccountCode: this.formbuilder.control(AccountCode, [Validators.required]),
            UnitPrice: this.formbuilder.control(UnitPrice, [Validators.required, Validators.pattern(FINANCIAL_NUMBER_REGEX)]),
            ItemCode: this.formbuilder.control(ItemCode),
            LineAmount: this.formbuilder.control(LineAmount),
            lineItemUID: this.formbuilder.control(lineItemUID)
        }) as UntypedFormControl;
    };

    createUpdateMemberProducts() {
        const product = this.productsService.memberProductToEdit;
        if (!!product) {
            const rawLineItems = this.createEditProductForm.get('lineItems').value.reduce((acc, curr) => {
                const lineItem = {};
                for (const key in curr) {
                    if (curr.hasOwnProperty(key)) {
                        lineItem[key] = curr[key].value;
                    }
                }
                acc.push(lineItem);
                return acc;
            }, []);
            const updateData = this.createEditProductForm.getRawValue();
            updateData.lineItems = rawLineItems;
            const updatedProduct = product;
            Object.assign(updatedProduct, updateData);
            this.updateMemberProduct(updatedProduct);
        } else {
            this.createProduct();
        }
    }

    updateMemberProduct(memberProduct: any) {
        memberProduct.services = this.createEditProductForm.getRawValue().services;
        this.productsService.putMemberProducts(memberProduct)
            .then(() => {
                this.productsService.switchPutMemberProductLoaderOff();
                this.productsService.switchGetJobProductsLoaderOn();
                this.productsService.getMemberProducts()
                    .pipe(
                        first(d => !!d)
                    )
                    .subscribe(
                        () => null,
                        (err) => console.error(err),
                        () => {
                            this.productsService.switchGetMemberProductsLoaderOff();
                        }
                    );

                this.dialogService.closeDialog();
            })
            .catch((err: Response) => {
                console.error('Error Updating Member Product:', err);
                this.productsService.switchPutMemberProductLoaderOff();
                this.snackbarService.handleError(`Please try again. Error occurred while adding product`);
            });
    }

    createProduct() {
        const product = this.createEditProductForm.getRawValue();
        product.lineItems = this.mappedLineItems;
        this.productsService.postMemberProduct(product)
            .then(() => {
                this.productsService.switchGetJobProductsLoaderOn();
                this.getProductsSub = this.productsService.getMemberProducts().subscribe(
                    () => null,
                    (err) => console.error(err),
                    () => {
                        this.productsService.switchGetMemberProductsLoaderOff();
                        this.getProductsSub.unsubscribe();
                    }
                );

                this.productsService.switchPostMemberProductLoaderOff();
                this.dialogService.closeDialog();
            })
            .catch((err) => {
                console.error(err);
                this.productsService.switchPostMemberProductLoaderOff();
                this.snackbarService.handleError(`Please try again. Error occurred while adding product`);
            });
    }

    setServices(services) {
        this.createEditProductForm.controls.services.setValue(services.map(c => c.value));
    }

    get lineItemsFormArray(): UntypedFormArray {
        return this.createEditProductForm.get('lineItems') as UntypedFormArray;
    }

    closeModal() {
        this.dialogService.closeDialog();
    }

    get mappedLineItems() {
        return this.lineItemsFormArray.controls.map(control => {
            const {AccountCode, Description, ItemCode, Quantity, UnitPrice, lineItemUID} = control.value;
            return {
                AccountCode: AccountCode.value,
                Description: Description.value,
                ItemCode: ItemCode.value,
                Quantity: Quantity.value,
                UnitPrice: UnitPrice.value,
                LineAmount: +UnitPrice.value * +Quantity.value,
                lineItemUID: lineItemUID.value
            };
        });
    }

    get invalidLineItem() {
        return this.lineItemsFormArray.controls.find(control => {
            const {AccountCode, Description, Quantity, UnitPrice} = control.value;
            return (AccountCode.invalid || Description.invalid || Quantity.invalid || UnitPrice.invalid);
        });
    }

    updateLineItem(event, control: AbstractControl, prop: 'Description' | 'Quantity' | 'AccountCode' | 'UnitPrice' | 'ItemCode', index?) {
        const fieldValue = event.target.value;
        control.value[prop].setValue(fieldValue);

        if (prop === 'Quantity' || prop === 'UnitPrice') {
            const lineTotal = +control.value.Quantity.value * +control.value.UnitPrice.value;
            control.value.LineAmount.setValue(lineTotal);
            this.lineItemTotals[index] = lineTotal;
            this.calculateProductTotal();
        }

        control.updateValueAndValidity();
    }

    addLineItem() {
        this.lineItemsFormArray.push(this.createLineItem({
            Description: null,
            AccountCode: null,
            UnitPrice: null,
            Quantity: null,
            ItemCode: null,
            LineAmount: null,
            lineItemUID: UUID.UUID()
        }));
    }

    removeLineItem(index) {
        if (this.lineItemsFormArray.length > 1) {
            this.lineItemsFormArray.removeAt(index);
            delete this.lineItemTotals[index];
            this.calculateProductTotal();
        }
    }

    ignoreEnterKey(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    calculateProductTotal() {

        const productTotalPrice = Object.values(this.lineItemTotals).reduce((acc: number, curr: number) => {
            return acc + curr;
        }, 0);
        this.createEditProductForm.get('Price').setValue((+productTotalPrice).toFixed(2));
    }
}
