<div class="mat-dialog-container">
    <div class="confirm-rejects-wrapper">
        <h3>Are you sure you want to send rejected assets back to the processor?</h3>
        <div class="confirm-rejects-actions">
            <button mat-button class="btn btn-red cancel-btn" (click)="closeDialog()">Cancel</button>
            <button mat-raised-button class="btn btn-green" (click)="sendBackRejects()">Yes, send back to processor
            </button>
        </div>
    </div>
</div>
