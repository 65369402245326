import {NgModule} from '@angular/core';
import {LoginExtraRouting} from '../routing/login-extra.routing';
import {CommonModule} from '@angular/common';
import {UiModule} from './ui.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PaymentMethodComponent} from '../../components/payment-method/payment-method.component';
import {PersonalInformationComponent} from '../../components/personal-information/personal-information.component';
import {UpdatePasswordComponent} from 'src/app/components/update-password/update-password.component';
import {TermsAndConditionsComponent} from 'src/app/components/terms-and-conditions/terms-and-conditions.component';
import {FirstTimeAccountSetupComponent} from 'src/app/components/first-time-account-setup/first-time-account-setup.component';
import {AccountPageComponent} from 'src/app/components/account-page/account-page.component';
import {ResetPasswordComponent} from 'src/app/components/reset-password/reset-password.component';
import {CancelPolicyComponent} from 'src/app/components/terms-and-conditions/cancel-policy/cancel-policy.component';

@NgModule({
    imports: [
        CommonModule,
        UiModule.core(),
        ReactiveFormsModule,
        LoginExtraRouting,
        FormsModule
    ],
    declarations: [
        UpdatePasswordComponent,
        TermsAndConditionsComponent,
        FirstTimeAccountSetupComponent,
        PersonalInformationComponent,
        AccountPageComponent,
        PaymentMethodComponent,
        ResetPasswordComponent,
        CancelPolicyComponent
    ]
})
export class LoginExtraModule {
}
