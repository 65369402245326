<div class="upload-modal" [ngClass]="{'hide': closeServiceUploader}">
    <mat-card appearance="outlined" class="service-container-card">
        <mat-card-header>
            <mat-card-title>{{ service.DISPLAY }}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <form [formGroup]="serviceForm">
                <mat-slide-toggle color="primary" #processingRequired
                                  [disabled]="loadingInProgress"
                                  (change)="handleProcessingRequiredSelection($event.checked)"
                                  formControlName="processingRequired"
                                  labelPosition="after">
                    Processing Required
                </mat-slide-toggle>

                <div class="form-field form-field-input finals-count-field" *ngIf="processingRequired.checked">
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="Num. of Finals" [formControl]="numOfFinalsFormControl">
                        <mat-hint *ngIf="serviceForm.get('numOfFinals').value < 1">
                            You must request at least 1 final
                        </mat-hint>
                        <mat-error *ngIf="serviceForm.get('numOfFinals').hasError('min')">
                            You must request at least 1 final
                        </mat-error>
                        <mat-error *ngIf="serviceForm.get('numOfFinals').hasError('required')">
                            Please choose how many finals required
                        </mat-error>
                        <mat-error *ngIf="serviceForm.get('numOfFinals').hasError('pattern')">
                            Must be a valid number
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-field service" *ngIf="processingRequired.checked">
                    <angular-editor [config]="briefEditorConfig"
                                    #angularEditor
                                    [formControl]="briefFormControl"></angular-editor>
                </div>

                <prism-upload-dropzone
                    #uploadDropzone
                    [serviceIndex]="serviceIndex"
                    [parentJob]="parentJob"
                    [onlyEdit]="onlyEdit"
                    [serviceType]="service.SNAKE_CASE"
                    [allowedFileType]="allowedFile"
                    [service]="service"
                    [productId]="productId"
                    [disableDropzone]="serviceForm.disabled || loadingInProgress"
                    [briefData]="serviceForm.getRawValue()"
                    (fileCount)="setFileCount($event)"
                ></prism-upload-dropzone>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button class="btn btn-red cancel-btn" mat-button (click)="onCancelClicked()"
                    [disabled]="serviceForm.disabled || loadingInProgress">Cancel
            </button>
            <button class="btn btn-green upload-btn"
                    mat-raised-button
                    [disabled]="((processingRequired.checked ? serviceForm.invalid
                    || loadingInProgress
                    || serviceForm.pristine
                    || !serviceForm.get('numOfFinals').value
                    || !fileCount: !fileCount || loadingInProgress)
                    || (uploadLimited | async)[service.id])"
                    (click)="startUpload()">
                Upload
                <ng-template [ngIf]="loadingInProgress">
                    <div class="lds-hourglass"></div>
                </ng-template>
            </button>
        </mat-card-actions>
    </mat-card>
</div>