import {UntypedFormArray} from '@angular/forms';

export const createNestedObject = function (base, names) {
    for (let i = 0; i < names.length; i++) {
        base = base[names[i]] = base[names[i]] || {};
    }
};

export const hexEncode = function (str) {
    var hex, i;

    var result = '';
    for (i = 0; i < str.length; i++) {
        hex = str.charCodeAt(i).toString(16);
        result += ('000' + hex).slice(-4);
    }

    return result;
};

export const hexDecode = function (str) {
    var j;
    var hexes = str.match(/.{1,4}/g) || [];
    var back = '';
    for (j = 0; j < hexes.length; j++) {
        back += String.fromCharCode(parseInt(hexes[j], 16));
    }

    return back;
};

export const createForLoop = (codeToLoop, array) => {
    const func = () => {
        for (let i = 0; i < array.length; i++) {
        }
    };
    return func();
};

export const createWhileLoop = (codeToLoop, array) => {
    const func = () => {
        let i = 0;

        while (i < array.length) {
            i++;
        }
    };
    return func();
};

export const createForOFLoop = (codeToLoop, array) => {
    const func = () => {
        for (const item of array) {
        }
    };
    return func();
};

export const timeFunction = (func) => {
    const startTime = Date.now();
    func();
    return Date.now() - startTime;
};

export const createWhileIterator = function* (obj) {
    let i = 0;
    const array = Object.keys(obj);
    while (i < array.length) {
        const key = array[i];

        yield obj[key];
        i++;
    }
};
export const createForIterator = function* (obj) {
    const array = Object.keys(obj);
    for (let i = 0; i < array.length; i++) {
        const key = array[i];

        yield obj[key];
    }
};

export const validateEmail = (value: string) => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(value.toLowerCase());
};

export const validateEmailList = (list: UntypedFormArray, minElements: number = 1) => {
    if (!list || list.controls.length < minElements) {
        return false;
    }
    for (const entry of list.controls.map(control => control.value)) {
        if (!validateEmail(entry)) {
            return false;
        }
    }
    return true;
};


