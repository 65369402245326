import {NgModule} from '@angular/core';
import {ShortenServiceIdPipe} from 'src/app/pipes/shorten-service-id.pipe';
import {ShortenPipe} from 'src/app/pipes/shorten.pipe';
import {JobInvoicedPipe} from '../../pipes';
import {OrderBy} from '../../pipes/order-by.pipe';

@NgModule({
    declarations: [
        JobInvoicedPipe,
        ShortenPipe,
        OrderBy,
        ShortenServiceIdPipe
    ],
    exports: [
        OrderBy,
        ShortenPipe,
        JobInvoicedPipe,
        ShortenServiceIdPipe
    ]
})
export class PipeModule {
}
