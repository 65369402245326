<div class="mat-dialog-container">
    <h2 mat-dialog-title>
        Contractor details
    </h2>
    <mat-dialog-content>
        <p>You will be receive an email once the contractor email has been generated.</p>

        <div class="form-group" [formGroup]="requestContractorForm">
            <div class="form-field form-field-input">
                <mat-label>First Name</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="firstName">
                </mat-form-field>
            </div>
            <div class="form-field form-field-input">
                <mat-label>Last Name</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="lastName">
                </mat-form-field>
            </div>
            <div class="form-field form-field-input">
                <mat-label>External Email Address</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="email">
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions>
        <button class="btn btn-red cancel-btn" mat-button (click)="closeDialog()" [disabled]="loadingRequest">Cancel
        </button>
        <button mat-raised-button class="btn btn-green" (click)="sendContractorRequest()"
                [disabled]="requestContractorForm.invalid || loadingRequest">
            Send Request
            <ng-template [ngIf]="loadingRequest">
                <div class="lds-hourglass"></div>
            </ng-template>
        </button>
    </mat-dialog-actions>

</div>