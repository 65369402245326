import {Component, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ContractorsTableComponent} from './contractors-table/contractors-table.component';
import {ContractorService, DialogService, SnackbarService} from '../../services';
import {AddEditContractorComponent} from '../add-edit-contractor/add-edit-contractor.component';
import {Contractor} from '../../models';
import {RequestContractorComponent} from './request-contractor/request-contractor.component';

@Component({
    selector: 'prism-contractors-page',
    templateUrl: './contractors-page.component.html',
    styleUrls: ['./contractors-page.component.scss']
})
export class ContractorsPageComponent {
    activeContractorsFilter = true;
    searchString: Observable<string>;
    selectedContractorsIds: string[] = [];
    processingContractors: boolean = false;
    // @ViewChild('searchAddressInput', {static: true}) searchAddressInput: ElementRef;
    @ViewChild('contractorsTable', {static: false}) contractorsTable: ContractorsTableComponent;

    dialogRef: MatDialogRef<AddEditContractorComponent>;

    constructor(private contractorService: ContractorService, private snackbarService: SnackbarService, private dialog: MatDialog, private dialogService: DialogService) {
    }

    showActiveContractors(active: boolean) {
        this.activeContractorsFilter = active;
    }

    onSelectionUpdated(contractorIds: string[]) {
        this.selectedContractorsIds = contractorIds;
    }

    onContractorClicked(contractor: Contractor) {
        this.displayContractorModal(contractor);
    }

    displayContractorModal(contractor: Contractor = null) {
        this.dialogRef = this.dialog.open(AddEditContractorComponent, {data: contractor});
        this.dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult && dialogResult.contractorId) {
                this.contractorsTable.getContractors();
            }
        });
    }

    updateContractorsActiveStatus(setStatus) {
        this.processingContractors = true;
        this.contractorService.updateContractorsActiveStatus(this.selectedContractorsIds, setStatus)
            .subscribe(
                () => {
                    const numArchived = this.selectedContractorsIds.length;
                    const snackbarString = `${setStatus ? 'Activated' : 'Archived'} ${numArchived} contractor${numArchived > 1 ? 's' : ''}`;
                    this.snackbarService.showSnackbar(snackbarString);
                    this.contractorsTable.getContractors();
                },
                err => {
                    console.error('Error archiving contractors:', err);
                    this.snackbarService.handleError('An error occurred while archiving the contractors. Please try again.');
                },
                () => {
                    this.processingContractors = false;
                });
    }

    requestNewContractor() {
        this.dialogService.openDialog(RequestContractorComponent, {}, {maxWidth: '500px', autoFocus: false});
    }
}
