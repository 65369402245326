import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {FLOORPLAN_SERVICES, IMAGE_PROCESSING, VIDEO_SERVICES} from '../../../enums';
import {ProductsService, SnackbarService} from '../../../services';
import {MatInput} from '@angular/material/input';
import {MatOption} from '@angular/material/core';
import {MatSelectChange} from '@angular/material/select';
import {MemberProduct} from '../../../models';

@Component({
    selector: 'prism-service-list',
    templateUrl: './service-list.component.html',
    styleUrls: ['./service-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceListComponent implements OnInit {
    productToEdit: MemberProduct;
    noServiceSelected;
    selectedService;
    services: { SNAKE_CASE: string, DISPLAY: string }[];
    serviceSelectionDisplay;
    servicesGroup: FormGroup;

    @Output() servicesUpdate: EventEmitter<FormArray> = new EventEmitter();
    @ViewChild('selectService', {static: true}) serviceMatSelection;
    @ViewChildren('serviceCount') serviceCounters: QueryList<ElementRef<MatInput>>;

    constructor(
        private productsService: ProductsService,
        private snackbarService: SnackbarService) {
        this.productToEdit = this.productsService.memberProductToEdit;
        this.noServiceSelected = null;
        this.selectedService = null;
        this.services = [IMAGE_PROCESSING, VIDEO_SERVICES, FLOORPLAN_SERVICES];
        this.serviceSelectionDisplay = [];
    }

    ngOnInit() {
        this.servicesGroup = new FormGroup({
            servicesArray: new FormArray([])
        }, [Validators.nullValidator]);

        if (this.productToEdit) {
            const servicesArrayForm: FormArray = this.servicesControls.value;
            this.productToEdit.services.forEach(serviceItem => {
                const serviceToAdd: FormControl = new FormControl(serviceItem, [Validators.required]);
                servicesArrayForm.push(serviceToAdd);
            });
        }
    }

    setSelectedService(serviceSelection) {
        this.noServiceSelected = false;
        this.selectedService = serviceSelection;
    }

    addServices() {
        if (this.serviceMatSelection.value) {
            const servicesData = this.serviceMatSelection.value;
            const servicesToAdd = servicesData ? servicesData.reduce((acc, curr) => {
                const serviceRequestCount = curr.count;
                for (let i = 0; i < serviceRequestCount; i++) {
                    acc = acc.concat({
                        display: curr.DISPLAY,
                        snake_case: curr.SNAKE_CASE
                    });
                    curr.count--;
                }
                return acc;
            }, []) : [];
            const serviceFormArray: FormArray = this.servicesGroup.get('servicesArray').value;
            servicesToAdd.forEach(service => {
                const serviceToAdd: FormGroup = new FormGroup({
                    DISPLAY: new FormControl(service.display, [Validators.required]),
                    SNAKE_CASE: new FormControl(service.snake_case, [Validators.required])
                });
                serviceFormArray.push(serviceToAdd);
                this.servicesUpdate.emit(serviceFormArray);
            });
        } else {
            this.snackbarService.handleError('Please select a service');
        }
        this.setSelectedService('None');
    }

    checkForNone(e: MatSelectChange) {
        this.serviceSelectionDisplay = e.value;
        if (e.value[0] === undefined) {
            this.serviceMatSelection.value = undefined;
        }
    }

    clearSelection() {
        this.serviceMatSelection.value = undefined;
    }

    removeService(index) {
        const servicesArray = this.servicesGroup.controls.servicesArray.value;
        servicesArray.splice(index, 1);
        this.servicesUpdate.emit(servicesArray);
    }

    emitFormsArray() {
        this.clearSelection();
    }

    get servicesControls() {
        return this.servicesGroup.get('servicesArray');
    }

    decreaseServiceCount(event, service: any, serviceOption: MatOption) {
        event.stopPropagation();
        service.count >= 1 ? service.count-- : service.count = 0;

        if (service.count === 0) {
            serviceOption.deselect();
        }
    }

    increaseServiceCount(event, service: any, serviceOption: MatOption) {
        event.stopPropagation();

        service.count >= 0 ? service.count++ : service.count = 1;

        if (service.count > 0 && !serviceOption.selected) {
            serviceOption.select();
        }
    }

    ensureNonZero(event: MatSelectChange) {
        if (event.source.selected && !!event.value[0] && (!event.value[0].count || event.value[0].count === 0)) {
            event.value[0].count = 1;
        }
    }

    clickOnInput(event) {
        event.stopPropagation();
    }

    inputServiceCount(value: string, service, serviceOption: MatOption) {
        const convertedValue: number = +value;
        if (!!convertedValue) {
            serviceOption.select();
            service.count = convertedValue;
        } else {
            serviceOption.deselect();
            service.count = 0;
        }
    }
}
