<div class="jobs-container">
    <h1 class="page-title">Jobs</h1>
    <div class="jobs-body">
        <div class="jobs-top-wrapper" *ngIf="!!(memberData$ | async)">
            <div class="jobs-figures">
                <div class="jobs-figures-inner">
                    <div
                            class="jobs-figure member-review-tab"
                            (click)="filterJobs('Member Review')"
                            [ngClass]="filterSelection === 'Member Review' ? 'member-review-selected' : null"
                    >
                        <div class="filter-icon">
                            <svg
                                    width="60"
                                    height="60"
                                    viewBox="0 0 35 35"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                        cx="17.5"
                                        cy="17.5"
                                        r="17"
                                        fill="white"
                                        stroke="#2990B7"
                                        stroke-width="0.5"
                                />
                                <g clip-path="url(#clip0)">
                                    <path
                                            d="M25.8546 9.14672C25.7137 9.00581 25.5024 8.96248 25.3175 9.03645L9.31358 15.4379C9.13046 15.5112 9.00784 15.6856 9.0008 15.8827C8.9938 16.0798 9.10377 16.2624 9.2812 16.3485L15.592 19.4092L18.6528 25.72C18.7362 25.8922 18.9106 26.0008 19.1008 26.0008C19.1067 26.0008 19.1126 26.0007 19.1186 26.0004C19.3156 25.9934 19.4901 25.8708 19.5633 25.6877L25.9648 9.68388C26.0388 9.49887 25.9955 9.2876 25.8546 9.14672ZM10.7302 15.9442L23.4345 10.8625L15.8632 18.4337L10.7302 15.9442ZM19.0571 24.2711L16.5676 19.138L24.1389 11.5668L19.0571 24.2711Z"
                                            fill="#2990B7"
                                            stroke-width="0.40"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect
                                                width="17"
                                                height="17"
                                                fill="white"
                                                transform="translate(9 9)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div class="filter-info">
                            <h4>
                                {{ !!jobsStats["Member Review"] ? jobsStats["Member Review"] : 0 }}
                            </h4>
                            <span>Member Review</span>
                        </div>
                    </div>

                    <div
                            class="jobs-figure in-progress-tab"
                            (click)="filterJobs('In Progress')"
                            [ngClass]="filterSelection === 'In Progress' ? 'in-progress-selected' : null"
                    >
                        <div class="filter-icon">
                            <svg
                                    width="60"
                                    height="60"
                                    viewBox="0 0 35 35"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    stroke-width="0.5"
                            >
                                <circle cx="17.5" cy="17.5" r="17" fill="white" stroke="#9BC9E3"/>
                                <path
                                        d="M17.5 17.5H17V17.7071L17.1464 17.8536L17.5 17.5ZM18 15C18 14.7239 17.7761 14.5 17.5 14.5C17.2239 14.5 17 14.7239 17 15H18ZM19.6464 20.3536C19.8417 20.5488 20.1583 20.5488 20.3536 20.3536C20.5488 20.1583 20.5488 19.8417 20.3536 19.6464L19.6464 20.3536ZM18 17.5V15H17V17.5H18ZM17.1464 17.8536L19.6464 20.3536L20.3536 19.6464L17.8536 17.1464L17.1464 17.8536Z"
                                        fill="#9BC9E3"
                                />
                                <path
                                        d="M17.5 10.9375C13.8764 10.9375 10.9375 13.8764 10.9375 17.5C10.9375 21.1236 13.8764 24.0625 17.5 24.0625C21.1236 24.0625 24.0625 21.1236 24.0625 17.5C24.0625 13.8764 21.1236 10.9375 17.5 10.9375ZM17.5 23.4918C14.1902 23.4918 11.5082 20.8098 11.5082 17.5C11.5082 14.1902 14.1902 11.5082 17.5 11.5082C20.8098 11.5082 23.4918 14.1902 23.4918 17.5C23.4918 20.8098 20.8098 23.4918 17.5 23.4918Z"
                                        fill="#9BC9E3"
                                        stroke="#9BC9E3"
                                        stroke-width="0.3"
                                />
                            </svg>
                        </div>
                        <div class="filter-info">
                            <h4>
                                {{ !!jobsStats["In Progress"] ? jobsStats["In Progress"] : 0 }}
                            </h4>
                            <span>In Progress</span>
                        </div>
                    </div>
                    <div
                            class="jobs-figure not-invoiced-tab"
                            (click)="filterJobs('Not_Invoiced')"
                            [ngClass]="filterSelection === 'Not_Invoiced' ? 'not-invoiced-selected' : null"
                    >
                        <div class="filter-icon">
                            <svg
                                    width="60"
                                    height="60"
                                    viewBox="0 0 125 125"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="62.5" cy="62.5" r="62" fill="white" stroke="#ACA8A8"/>
                                <path
                                        d="M60.5806 37.5547V41.5728C55.1387 42.4862 50.9863 47.2449 50.9863 52.9056C50.9863 56.3154 52.5828 58.9461 54.7051 60.6405C56.8254 62.3348 59.3813 63.2789 61.7799 64.2383C64.1785 65.1978 66.4197 66.1726 67.8972 67.3565C69.3709 68.5404 70.1749 69.7397 70.1749 72.0942C70.1749 76.2312 66.7344 79.7696 62.4995 79.7696C58.2415 79.7696 54.824 76.3521 54.824 72.0942V70.1753H50.9863V72.0942C50.9863 77.7836 55.1387 82.5136 60.5806 83.4269V87.445H64.4183V83.4269C69.8756 82.5059 74.0126 77.7087 74.0126 72.0942C74.0126 68.6767 72.4162 66.0593 70.2939 64.3592C68.1736 62.6572 65.6176 61.6594 63.2191 60.7C60.8205 59.7405 58.5793 58.8195 57.1017 57.6432C55.6261 56.465 54.824 55.2447 54.824 52.9056C54.824 48.7013 58.2492 45.2301 62.4995 45.2301C66.7574 45.2301 70.1749 48.6476 70.1749 52.9056V54.8244H74.0126V52.9056C74.0126 47.2161 69.8602 42.4862 64.4183 41.5728V37.5547H60.5806Z"
                                        fill="#ACA8A8"
                                />
                            </svg>
                        </div>
                        <div class="filter-info">
                            <h4>
                                {{ !!jobsStats["Not_Invoiced"] ? jobsStats["Not_Invoiced"] : 0 }}
                            </h4>
                            <span>Not Invoiced</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-wrapper table-wrapper jobs-table-wrapper">
            <div class="toolbar table-toolbar jobs-table-toolbar">
                <div class="toolbar-left table-toolbar-left">
                    <div class="form-field form-field-search">
                        <mat-form-field id="jobs-toolbar-item" appearance="outline">
                            <input
                                    id="search-input-bar"
                                    matInput
                                    #searchInput
                                    [value]="searchString$.getValue()"
                                    placeholder="Search..."
                            />
                            <img src="../../../assets/icons/search.svg" alt="Search"/>
                            <div *ngIf="showMinSearchStringWarning" class="search-helper-text">
                                Please enter at least 2 characters
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="toolbar-right table-toolbar-right" *ngIf="(memberData$ | async) as memberData">
                    <button
                            class="btn btn-green slide-in-btn"
                            mat-raised-button
                            *ngIf="!memberData?.isContractor"
                            [disabled]="jobsLoading$ | async"
                            (click)="openAddJobDialog()"
                    >
                        Add Job
                        <ng-template [ngIf]="(postJobLoading$ | async) || (putJobLoading$ | async)">
                            <div class="lds-hourglass"></div>
                        </ng-template>
                    </button>
                </div>
            </div>
            <div class="progress-bar" *ngIf="((jobsLoading$ | async) && initialTableLoad === true)">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div *ngIf="initialTableLoad === false"
                 [ngClass]="!((jobs$ | async)?.length <= 0) ? 'jobs-table-container' : 'jobs-empty-container'">
                <prism-jobs-table
                        *ngIf="(jobs$ | async)?.length > 0; else noJobs"
                        [jobs]="(jobs$ | async)"
                        [jobsLoading]="localJobsLoading"
                        [jobStatuses]="jobStatuses"
                        [totalJobsForPaginator]="totalJobsForPaginator"
                        (pageUpdated)="updateJobsPage($event)"
                        (jobSelected)="goToJob($event)"
                >
                </prism-jobs-table>
                <ng-template #noJobs>
                    <div
                            class="jobs-list-empty"
                            *ngIf="(jobs$ | async)?.length <= 0 && !(jobsLoading$ | async)"
                    >
                        <h5>No jobs to display...</h5>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>

