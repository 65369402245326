import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';

import {Job} from '../../../models';
import {
    AgentService,
    AuthenticationService,
    DialogService,
    InvoicesService,
    JobsService,
    SnackbarService
} from '../../../services';
import {AddEditInvoiceComponent} from '../../add-edit-invoice/add-edit-invoice.component';
import {NotifyAgentDialogComponent} from '../../notify-agent-dialog/notify-agent-dialog.component';

@Component({
    selector: 'prism-jobs-table',
    templateUrl: './jobs-table.component.html',
    styleUrls: ['./jobs-table.component.scss']
})
export class JobsTableComponent implements OnDestroy {
    member$ = this.authService.memberData$;
    private paginator: MatPaginator;
    columnHeaders = [
        'id',
        'address',
        'status',
        'agent',
        'agency',
        'contractor',
        'products',
        'details',
        'invoice',
        'albums',
        'share'
    ];
    jobsList = new MatTableDataSource<Job>([]);
    @Output() pageUpdated: EventEmitter<any> = new EventEmitter<any>();
    paginatorControl = {
        length: 0,
        pageSize: this.jobsService.jobsTableSize,
        pageSizeOptions: [10, 20, 40, 50, 100]
    };

    @Input() jobsLoading: boolean;

    @Input()
    set totalJobsForPaginator(jobsTotalCount) {
        if (jobsTotalCount > -1) {
            this.paginatorControl.length = jobsTotalCount;
            this.paginator.length = jobsTotalCount;
            this.paginator.firstPage();
        }
    }

    @Input() jobStatuses;

    @Input()
    set jobs(jobs: Job[]) {
        if (jobs) {
            this.jobsList.data = jobs.map(job => {
                return {
                    ...job,
                    productNames: !!job.productNames ? Object.values(job.productNames) : ['-']
                };
            });

        }
    }

    @Output()
    jobSelected = new EventEmitter<Job>();

    @ViewChild(MatPaginator, {static: true}) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
    }

    constructor(
        private router: Router,
        private dialogService: DialogService,
        private snackbarService: SnackbarService,
        private authService: AuthenticationService,
        private agentService: AgentService,
        private invoicesService: InvoicesService,
        private jobsService: JobsService
    ) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    openAlbum(job: Job) {
        this.selectJob(job);
        this.router.navigate(['home', 'jobs', job.id, 'album']);
    }

    selectJob(job: Job) {
        this.jobSelected.emit(job);
    }

    paginate(pageEvent: PageEvent) {
        const {pageSize, pageIndex} = pageEvent;
        this.jobsService.updateJobsListPagination({pageSize, pageIndex});
    }

    openInvoiceDialog(job) {
        this.jobsService.setSelectedJob(job);
        this.dialogService.openDialog(AddEditInvoiceComponent, {job}, {width: '90vw'});
    }

    shareJobAlbum(job: Job) {
        this.dialogService.openDialog(NotifyAgentDialogComponent, {job}, {width: '600px', autoFocus: false});
    }
}
