<div class="product-carousel-wrapper">
    <div class="product-carousel-header">
        <h3 class="product-carousel-title">{{ data.data.title }}</h3>
    </div>
    <div class="product-carousel-body">
        <div class="product-carousel-body-left" #assetList>
            <div class="product-asset-wrapper" #scrollAssets *ngFor="let x of assets,let i = index"
                 [ngClass]="{'selected-asset': currentIndex === i}" (click)="displayAsset(i)">
                <div class="product-asset" [ngStyle]="{'background-image': 'url(' + x.url + ')'}">
                    <h6 class="product-asset-title">
                    </h6>
                </div>
            </div>
        </div>

        <div class="product-carousel-body-right" *ngIf="!isMobile; else mobileSwiper">
            <div class="arrow_prev">
                <button mat-icon-button [autofocus]="false" (click)="previousToggle()">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
            </div>
            <div class="final-asset-container">
                <h6 class="final-filename">{{ selectedAsset?.originalFileName.substring(selectedAsset?.originalFileName.indexOf('_') + 1, selectedAsset?.originalFileName.lastIndexOf('.')) }}</h6>
                <prism-final-display [finalAsset]="finalAsset"></prism-final-display>
            </div>
            <div class="arrow_next">
                <button mat-icon-button [autofocus]="false" (click)="nextToggle()">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
        </div>
        <ng-template #mobileSwiper>
            <prism-mobile-swiper [assets]="assets" (closeGallery)="closeDialog()"></prism-mobile-swiper>
        </ng-template>
    </div>

    <div class="product-carousel-footer">
        <span class="image-count">{{ assets.length }} {{ assets.length < 2 ? 'file' : 'files' }} </span>

        <div class="footer-right">
            <button mat-raised-button
                    class="btn btn-blue"
                    (click)="closeDialog()"
                    [autofocus]="false"
            >Close
            </button>
            <button mat-raised-button
                    class="btn btn-green"
                    (click)="downloadAsset()"
                    [autofocus]="false"
            >Download
            </button>
        </div>
    </div>
</div>
