import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import Swiper, {SwiperOptions} from 'swiper';
import {SwiperContainer} from 'swiper/element/swiper-element';

@Component({
    selector: 'prism-swiper',
    templateUrl: './swiper.component.html',
    styleUrls: ['./swiper.component.scss']
})
export class SwiperComponent implements OnInit, AfterViewInit {
    @Input() initialIndex;
    @Input() mobileData: { isMobile: boolean, browser: string, os: string };

    @Input('assets')
    set assets(value: any) {
        this.assetsList = value;
    }

    @Output() closeGallery: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild('videoPlayer', {static: false}) videoElement: ElementRef<HTMLVideoElement>;
    showImageLoader = false;
    initialSlideBusy = true;
    assetsList = [];
    address: string = null;
    memberProfileUid: string = null;
    jobId: number = null;
    config: SwiperOptions;
    caterForIOSStyle: boolean;
    swiperRef: Swiper;
    swiperIndex: number = 0;

    constructor() {
    }

    ngOnInit() {
        const {os, browser} = this.mobileData;
        this.caterForIOSStyle = browser === 'Safari' && os === 'iOS';
        this.swiperIndex = this.initialIndex;
    }

    ngAfterViewInit() {
        this.swiperRef = new Swiper('.mySwiper', {
            slidesPerView: 1,
            spaceBetween: 30,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
                slideChangeTransitionEnd: (swiper) => {
                    this.swiperIndex = swiper.realIndex;
                    if (swiper.realIndex === this.initialIndex) {
                        this.initialSlideBusy = false;

                        const currentSlide = swiper.slides[swiper.activeIndex];

                        const slideContentType = currentSlide.classList[1];

                        if (slideContentType === 'video') {
                            this.showImageLoader = false;
                        }
                    }
                },
                slideChange: swiper => {
                    if (swiper.previousIndex) {
                        const previousSlide = swiper.slides[swiper.previousIndex];
                        const previousSlideClassList = previousSlide.classList.value.toLowerCase();
                        const isVideo = previousSlideClassList.includes('video');

                        if (isVideo) {
                            previousSlide.childNodes.forEach(node => {
                                if (node.nodeName.toLowerCase() === 'video') {
                                    (<HTMLVideoElement>node).pause();
                                }
                            });
                        }
                    }
                }
            }
        });

        if (this.caterForIOSStyle) {
            this.swiperRef.slideTo(this.initialIndex + 1, 1000);
            this.swiperRef.realIndex = this.initialIndex;
        } else {
            this.swiperRef.slideTo(this.initialIndex + 1, 1000);
            this.swiperRef.realIndex = this.initialIndex;
        }
    }
}
