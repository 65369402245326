<table
        mat-table
        [dataSource]="agencies"
>
    <ng-container matColumnDef="selected">
        <caption>Acencies Table</caption>
        <th mat-header-cell scope="col" *matHeaderCellDef></th>
        <td class="clickable-area"
            mat-cell
            *matCellDef="let agency">
            <mat-checkbox
                    [checked]="false"
                    (change)="onAgencySelectedChanged($event.checked, agency.id)"
            ></mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell scope="col" *matHeaderCellDef>Name</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let agency"
                (click)="onRowClicked(agency)"
        >
            {{ agency.name }}
        </td>
    </ng-container>

    <ng-container matColumnDef="address">
        <th mat-header-cell scope="col" *matHeaderCellDef>Address</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let agency"
                (click)="onRowClicked(agency)"
        >
            {{ agency.address }}
        </td>
    </ng-container>

    <ng-container matColumnDef="imageSize">
        <th mat-header-cell scope="col" *matHeaderCellDef>Image Websize</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let agency"
                (click)="onRowClicked(agency)"
        >
            {{ agency.imageSize.width }}x{{ agency.imageSize.height }} ({{
                (1.0e-6 * agency.imageSize.width * agency.imageSize.height).toFixed(1)
            }}
            MP)
        </td>
    </ng-container>

    <ng-container matColumnDef="watermarkUrl">
        <th mat-header-cell scope="col" *matHeaderCellDef>Watermark</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let agency"
                (click)="onRowClicked(agency)"
        >
            {{ agency?.hasWatermark ? "Yes" : "No" }}
        </td>
    </ng-container>
    <ng-container matColumnDef="active">
        <th mat-header-cell scope="col" *matHeaderCellDef>ACTIVE</th>
        <td mat-cell *matCellDef="let agency">
            <mat-slide-toggle class="slide-toggle"
                              [checked]="agency.active"
                              [disabled]="(isLoading | async) === true"
                              (change)="putToggleActive(agency, $event.checked)">
            </mat-slide-toggle>
        </td>
    </ng-container>

    <ng-container matColumnDef="edit">
        <th mat-header-cell scope="col" *matHeaderCellDef>Edit</th>
        <td mat-cell *matCellDef="let agency">
            <button mat-icon-button (click)="onRowClicked(agency)">
                <img alt='Update product icon.'
                     src="/assets/icons/jobs/update.svg">
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnHeaders"></tr>
    <tr mat-row *matRowDef="let rowData; columns: columnHeaders"></tr>
</table>

<mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoading | async"
></mat-progress-bar>
<mat-paginator
        [length]="totalAgencies | async"
        [pageSize]="paginatorControl.pageSize"
        [pageSizeOptions]="paginatorControl.pageSizeOptions"
        (page)="paginate($event)"
        #paginator
>
</mat-paginator>
