import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {Contractor} from '../models';
import {environment} from '../../environments';
import {PrismHeaders} from '../enums';
import {AuthenticationService} from './authentication.service';
import {JobsService} from './jobs.service';

@Injectable({
    providedIn: 'root'
})
export class ContractorService {
    contractorsTableSizePreference: number;

    constructor(private http: HttpClient, private authService: AuthenticationService) {
        this.contractorsTableSizePreference = 10;

    }

    get contractorsTableSize() {
        return this.contractorsTableSizePreference;
    }

    set updateContractorsTableSize(size: number) {
        this.contractorsTableSizePreference = size;
    }

    getContractors(
        sort: string,
        order: string,
        page: number,
        limit: number,
        status: string,
        search: string = ''): Observable<{ data: Contractor[], availableItems: number }> {
        return this.http.get<{ data: Contractor[], availableItems: number }>(environment.apiBaseUrl + '/GetContractors', {
            params: {sort, order, page: page.toString(), limit: limit.toString(), status, search}
        });
    }

    getContractorNames() {
        const memberData = this.authService.memberCurrentData;
        const headers = new HttpHeaders()
            .set(PrismHeaders.QueryData, JSON.stringify({mainMemberUid: memberData?.isContractor ? (<Contractor>memberData)?.mainMemberUid : null}));
        return this.http.get<any>(environment.apiBaseUrl + '/GetContractorNames', {headers})
            .pipe(
                take(1),
                map(res => {
                    return res.data;
                })
            );
    }

    postContractor(contractor: Contractor): Observable<string> {
        return this.http.post<{ ref: string }>(environment.apiBaseUrl + '/PostContractor', contractor).pipe(map(val => val.ref));
    }

    putContractor(contractor: Contractor): Observable<string> {
        return this.http.put<{ ref: string }>(environment.apiBaseUrl + '/PutContractor', contractor).pipe(map(val => val.ref));
    }

    updateContractorsActiveStatus(contractorIds: string[], setStatus: boolean) {
        const headers = new HttpHeaders()
            .append(PrismHeaders.FunctionHelperData, JSON.stringify({activeStatus: setStatus}));
        return this.http.put(environment.apiBaseUrl + '/UpdateContractorsActiveStatus', {ids: JSON.stringify(contractorIds)}, {headers});
    }

    requestContractor({firstName, lastName, contractorEmail}) {
        const memberEmail = this.authService.currentUserEmail;
        const headers = new HttpHeaders()
            .set(PrismHeaders.FunctionHelperData, JSON.stringify({firstName, lastName, contractorEmail, memberEmail}));
        return this.http.get(`${environment.apiBaseUrl}/RequestContractor`, {headers})
            .toPromise();
    }

    validateContractor(email) {
        const headers = new HttpHeaders()
            .set(PrismHeaders.QueryData, JSON.stringify({email}));

        return this.http.get(`${environment.apiBaseUrl}/ValidateContractorEmail`, {headers});
    }
}
