import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {first} from 'rxjs/operators';
import {environment} from '../../environments';
import {PrismHeaders} from '../enums';
import {Response} from '../models';
import {SnackbarService} from './snackbar.service';

@Injectable({
    providedIn: 'root'
})
export class AssetsService {
    private allAccessedAssets: BehaviorSubject<any>;
    private downloadData: BehaviorSubject<any>;
    private productNameToDisplay: BehaviorSubject<string>;
    private assetsLoaded: BehaviorSubject<boolean>;
    private downloadProgress: BehaviorSubject<any>;
    private watermarkBS: BehaviorSubject<any>;
    private uploadQueue: BehaviorSubject<any>;
    private finalisedUploadQueue: BehaviorSubject<any>;
    private _uploadTracker: BehaviorSubject<any>;
    private _currentJobAssetPaths: BehaviorSubject<string[]>;
    private _assetPathsLoading: BehaviorSubject<boolean>;
    private _zipProcessing: BehaviorSubject<boolean>;
    private _rejectedAssetNotification: Subject<any>;
    private selectedAssets: BehaviorSubject<any>;
    private selectedAssetsCount: BehaviorSubject<any>;

    constructor(
        private snackBarService: SnackbarService,
        private http: HttpClient
    ) {
        this.allAccessedAssets = new BehaviorSubject<any>({});
        this.downloadData = new BehaviorSubject<any>(null);
        this.productNameToDisplay = new BehaviorSubject<string>('');
        this.assetsLoaded = new BehaviorSubject<boolean>(false);
        this.downloadProgress = new BehaviorSubject<any>({});
        this.watermarkBS = new BehaviorSubject<any>({});
        this.uploadQueue = new BehaviorSubject<any>({});
        this.finalisedUploadQueue = new BehaviorSubject<any>({});
        this._uploadTracker = new BehaviorSubject<any>({});
        this._currentJobAssetPaths = new BehaviorSubject<string[]>([]);
        this._assetPathsLoading = new BehaviorSubject<boolean>(true);
        this._zipProcessing = new BehaviorSubject<boolean>(false);
        this._rejectedAssetNotification = new Subject<any>();
        this.selectedAssets = new BehaviorSubject<any>({});
        this.selectedAssetsCount = new BehaviorSubject<any>(0);
    }

    get currentJobAssetPaths() {
        return this._currentJobAssetPaths.value;
    }

    get currentJobAssetsPaths$() {
        return this._currentJobAssetPaths.asObservable();
    }

    get rejectedAssetNotification$() {
        return this._rejectedAssetNotification.asObservable();
    }

    get selectedAssetsCount$() {
        return this.selectedAssetsCount.asObservable();
    }

    get watermarks$() {
        return this.watermarkBS.asObservable();
    }

    get watermarks() {
        return this.watermarkBS.getValue();
    }

    get accessedAssets$() {
        return this.allAccessedAssets.asObservable();
    }

    get downloadProgress$() {
        return this.downloadProgress.asObservable();
    }

    set setProductNameToDisplay(ProductName: string) {
        this.productNameToDisplay.next(ProductName);
    }

    updateAssetRejectionStatus(serviceId, thumbUUID, reason) {
        this._rejectedAssetNotification.next({serviceId, thumbUUID, reason});
    }

    getAssetsBucketPaths(jobId, servicePath?, memberProfileUid?) {
        this._assetPathsLoading.next(true);
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({jobId, servicePath, memberProfileUid}));

        return this.http.get(`${environment.apiBaseUrl}/GetAssetsList`, {headers});
    }

    getAgencyAssetsBucketPaths(shareUid, services) {
        const encodedServices = encodeURIComponent(JSON.stringify({shareUid, services}));
        this._assetPathsLoading.next(true);
        const headers = new HttpHeaders().append(PrismHeaders.QueryData, encodedServices);

        this.http.get(`${environment.apiBaseUrl}/GetAgencyDownloadUrls`, {headers})
            .pipe(
                first(d => !!d)
            )
            .subscribe(
                (assetsResponse: Response) => {
                    this._currentJobAssetPaths.next(assetsResponse.data);
                },
                err => {
                    console.error('Error getting asset paths:', err);
                    this.snackBarService.handleError('Problem while collecting file paths. You will not be able to download your jobs files.');
                },
                () => {
                    this._assetPathsLoading.next(false);
                }
            );
    }

    clearCompletedUploads() {
        const uploadsCopy = this._uploadTracker.value;
        const queueCopy = this.uploadQueue.value;
        for (const uploadUid in uploadsCopy) {
            if (uploadsCopy[uploadUid].completed) {
                delete uploadsCopy[uploadUid];
                delete queueCopy[uploadUid];
            }
        }
        this.finalisedUploadQueue.next(queueCopy);
        this._uploadTracker.next(uploadsCopy);
    }

    setAssetsLoaded(assetsLoaded) {
        this.assetsLoaded.next(assetsLoaded);
    }

    setAccessedAssets(serviceId, serviceAssets) {
        const assetsHolder = this.allAccessedAssets.value;
        assetsHolder[serviceId] = serviceAssets;
        this.allAccessedAssets.next(assetsHolder);
        this.assetsLoaded.next(true);
    }

    clearDownloadData() {
        this.downloadData.next(null);
    }

    getProcessedAssetFinalSignedUrls(pathsToFinal) {
        return this.http.post(environment.apiBaseUrl + '/GetProcessedAssetUrls', {
            pathsToFinal
        });
    }

    addSelectedAsset(jobId, productId, serviceId, thumbnail) {
        this.objectSetup([jobId, productId, serviceId], this.selectedAssets.value);
        this.selectedAssets.next(this.selectedAssets.value);
        if (!this.selectedAssets.value[jobId][productId][serviceId][thumbnail.thumbUUID]) {
            this.selectedAssets.value[jobId][productId][serviceId][thumbnail.thumbUUID] = thumbnail;
            this.selectedAssets.next(this.selectedAssets.value);
            let assetsCounter = this.selectedAssetsCount.value + 1;
            this.selectedAssetsCount.next(assetsCounter);
        }
    }

    removeSelectedAsset(jobId, productId, serviceId, thumbnail) {
        this.objectSetup([jobId, productId, serviceId], this.selectedAssets.value);
        this.selectedAssets.next(this.selectedAssets.value);
        if (this.selectedAssets.value[jobId][productId][serviceId][thumbnail.thumbUUID]) {
            delete this.selectedAssets.value[jobId][productId][serviceId][thumbnail.thumbUUID];
            this.selectedAssets.next(this.selectedAssets.value);
            let assetsCounter = this.selectedAssetsCount.value - 1;
            this.selectedAssetsCount.next(assetsCounter);
        }
    }

    getSelectedAssets() {
        let assetsList = [];
        Object.values(this.selectedAssets.getValue()).forEach((job) => {
            Object.values(job).forEach((services) => {
                Object.values(services).forEach((assets) => {
                    Object.values(assets).forEach((asset) => {
                        assetsList.push(asset);
                    });
                });
            });
        });
        return assetsList;
    }

    setWatermarkLocal(agencyId, watermarkUrl) {
        const holder = this.watermarkBS.value;
        holder[agencyId] = watermarkUrl;
        this.watermarkBS.next(holder);
    }

    getWatermarkUploadUrl(contentType, agencyId, fileName) {
        const headers = new HttpHeaders()
            .set(PrismHeaders.FunctionHelperData, JSON.stringify({contentType, agencyId, fileName}));
        return this.http.get(`${environment.apiBaseUrl}/GetWatermarkUploadUrl`, {
            headers
        });
    }

    uploadWatermark(url, file) {
        return this.http.put(`${url}`, file);
    }

    removeWatermark(agencyId) {
        const headers = new HttpHeaders()
            .set(PrismHeaders.FunctionHelperData, JSON.stringify({agencyId}));

        return this.http.get(`${environment.apiBaseUrl}/RemoveWatermark`, {headers});
    }

    getAgencyWatermark(agencyId) {
        if (agencyId) {
            const headers = new HttpHeaders()
                .set(PrismHeaders.QueryData, JSON.stringify({agencyId}));
            return this.http.get(`${environment.apiBaseUrl}/GetWatermarkAgency`, {headers})
                .pipe();
        }
    }

    hideAssets(finalPath, thumbUUIDList, hidden) {
        return this.http.post(`${environment.apiBaseUrl}/HideAsset`, {finalPath, thumbUUIDList, hidden});
    }

    getRawDownloadData(jobId, version) {
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({prefix: jobId, version}));

        return this.http.get(`${environment.apiBaseUrl}/GetDownloadData`, {headers});
    }

    sanitizeAddress(address) {
        const disallowedChars = ['/', '@', '|', '<', '>', ':', '"', '\\', '?', '*', '.'];
        return address
            .split('')
            .map(char => {
                if (disallowedChars.includes(char)) {
                    return '_';
                } else {
                    return char;
                }
            })
            .join('');
    }

    sendRawDownloadRequest(address, prefix, filePaths, memberId, jobId, version) {
        address = this.sanitizeAddress(address);
        this._zipProcessing.next(true);

        const filePathObjects = filePaths.map(path => {
            const [serviceId_timestamp, filenameTail] = path.split('/');
            return {
                path,
                name: `${address}/RAW/${filenameTail}`
            };
        });

        const body = {
            outputName: `${prefix}_${address}`,
            files: filePathObjects,
            memberId,
            jobId,
            rawFilesRequested: true,
            version
        };

        return this.http.post(`${environment.assetsServiceUrl}`, body)
            .toPromise()
            .then(({data: {signedUrl}}: Response) => {
                this.snackBarService.showDownloadSnackbar('Assets ready', 'Download', signedUrl);
                this._zipProcessing.next(false);
            })
            .catch(e => {
                console.error(e);
            });
    }

    sendDownloadRequest(address, prefix, filePaths) {
        address = this.sanitizeAddress(address);
        let memberId, jobId = null;
        this._zipProcessing.next(true);

        const filePathObjects = filePaths.map(path => {
            let innerFolder;
            const [mUid, jUid, pUid, serviceUid, finals, filenameTail] = path.split('/');
            if (!memberId) {
                memberId = mUid;
            }
            if (!jobId) {
                jobId = +jUid;
            }
            if (filenameTail.startsWith('web')) {
                innerFolder = 'WEB';
            } else if (filenameTail.startsWith('watermark')) {
                innerFolder = 'WATERMARK';
            } else {
                innerFolder = 'PRINT';
            }
            return {
                path,
                name: `${address}/${innerFolder}/${filenameTail}`
            };
        });
        const body = {
            outputName: `${prefix}_${address}`,
            files: filePathObjects,
            memberId,
            jobId,
        };

        return this.http.post(`${environment.assetsServiceUrl}`, body)
            .toPromise()
            .then(({data: {signedUrl}}: Response) => {
                this.snackBarService.showDownloadSnackbar('Assets ready', 'Download', signedUrl);
                this._zipProcessing.next(false);
            })
            .catch(e => {
                console.error(e);
            });
    }

    sendAgencyDownloadRequest(address, prefix, filePaths) {
        address = this.sanitizeAddress(address);
        let memberId, jobId = null;
        this._zipProcessing.next(true);
        const filePathObjects = filePaths.map(path => {
            const [mUid, jUid, pUid, serviceUid, finals, filenameTail] = path.split('/');
            if (!memberId) {
                memberId = mUid;
            }
            if (!jobId) {
                jobId = +jUid;
            }

            let folder = 'PRINT';
            if (filenameTail.startsWith('web_')) {
                folder = 'WEB';
            } else if (filenameTail.startsWith('watermark_')) {
                folder = 'WATERMARK';
            }

            return {
                path,
                name: `${folder}/${filenameTail}`
            };
        });
        const body = {
            outputName: `${prefix}_${address}`,
            files: filePathObjects,
            memberId,
            jobId
        };

        return this.http.post(`${environment.assetsServiceUrl}`, body)
            .toPromise()
            .then(({data: {signedUrl}}: Response) => {
                this.snackBarService.showDownloadSnackbar('Assets ready', 'Download', signedUrl);
                this._zipProcessing.next(false);
            })
            .catch(e => {
                console.error(e);
            });
    }

    getSelectedAssetsBucketPaths(selection, assets, jobId, memberProfileUid) {
        let files = [];

        for (let i = 0; i < Object.keys(assets).length; i++) {
            let {serviceId, filename, productId} = assets[i];

            if (!assets[i].hidden) {
                let filePath = `${memberProfileUid}/${jobId}/${productId}/${serviceId}/finals/${filename}`;
                files.push(filePath);
                if (filename.endsWith('.mov') || filename.endsWith('.mp4')) {

                } else {
                    if (selection['watermark']) {
                        filePath = `${memberProfileUid}/${jobId}/${productId}/${serviceId}/finals/watermark_${filename}`;
                        files.push(filePath);
                    }
                    if (selection['web']) {
                        filePath = `${memberProfileUid}/${jobId}/${productId}/${serviceId}/finals/web_${filename}`;
                        files.push(filePath);
                    }
                }
            }
        }
        return files;
    }

    getServiceAssets(jobId, productId, serviceId) {
        return this.selectedAssets.value[jobId][productId][serviceId];
    }

    jobAlbumOnDestroy(jobId) {
        delete this.selectedAssets.value[jobId];
        this.selectedAssets.next(this.selectedAssets.value);
        this.selectedAssetsCount.next(0);
    }

    objectSetup(objectList, obj, t = 0) {
        if (t === objectList.length - 1) {
            obj[objectList[t]] = !obj[objectList[t]] ? {} : obj[objectList[t]];
            return;
        } else {
            if (!obj[objectList[t]]) {
                obj[objectList[t]] = {};
                this.objectSetup(objectList, obj[objectList[t]], ++t);
                return;
            }
            this.objectSetup(objectList, obj[objectList[t]], ++t);
            return;
        }
    }
}
