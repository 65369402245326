import {Component, OnInit} from '@angular/core';
import {
    AssetsService,
    DialogService,
    JobsService,
    SnackbarService
} from '../../services';
import 'firebase/storage';
import {Asset, DownloadAssetTypeSelection, DownloadSelection} from '../../models';

@Component({
    selector: 'prism-filter-download-selection-modal',
    templateUrl: './filter-download-selection-modal.component.html',
    styleUrls: ['./filter-download-selection-modal.component.scss']
})
export class FilterDownloadSelectionModalComponent implements OnInit {
    selectedDownloadFormats: DownloadSelection = {
        print: true,
        web: true,
        watermark: false
    };
    selectedDownloadAssetTypes: DownloadAssetTypeSelection;
    downloadDisabled: boolean = false;
    assets: Asset[] = [];
    productId: string;
    shareUid: string;
    downloadPrefix: string;
    allAssetPaths: string[];
    serviceId: string;
    jobId: number;
    hasDownloadOptions: boolean;
    hasWebOptions: boolean;
    hasPrintOptions: boolean;
    hasWatermarkOptions: boolean;
    hasRejectedOptions: boolean;
    hasVisibleOptions: boolean;
    hasHiddenOptions: boolean;
    selectedAssets: string[];
    categorizedAssetPaths = {visible: [], hidden: [], rejected: []};

    constructor(private dialogService: DialogService,
                private jobsService: JobsService,
                private snackbarService: SnackbarService,
                private assetsService: AssetsService) {
    }

    ngOnInit() {
        const {id: jobId} = this.jobsService.selectedJob || {id: this.dialogService.inputData.jobId};
        this.jobId = jobId;
        this.hasPrintOptions = true;
        this.hasWebOptions = true;
        this.downloadPrefix = this.dialogService.inputData.downloadPrefix;
        this.serviceId = this.dialogService.inputData.serviceId;
        this.productId = this.dialogService.inputData.productId;
        this.productId = this.dialogService.inputData.productId;
        this.allAssetPaths = this.dialogService.inputData.allAssetPaths;
        this.assets = this.dialogService.inputData.assets;
        const inputFilter = this.dialogService.inputData.inputFilter || null;

        this.hasRejectedOptions = this.assets.some((asset) => !!(asset.finalRejectReason || asset.savedRejectReason));
        this.hasHiddenOptions = this.assets.some((asset) => asset.hidden);
        this.hasVisibleOptions = this.assets.some((asset) => !asset.hidden);

        this.selectedDownloadAssetTypes = inputFilter || {
            visible: this.hasVisibleOptions,
            hidden: this.hasHiddenOptions,
            rejected: this.hasRejectedOptions
        };

        this.assets.forEach((asset) => {
            if (asset.hidden && !(asset.finalRejectReason || asset.savedRejectReason)) {
                this.categorizeFilenameByFilter(this.categorizedAssetPaths, asset, 'hidden');
            }
            if (!asset.hidden) {
                this.categorizeFilenameByFilter(this.categorizedAssetPaths, asset, 'visible');
            }
            if ((asset.finalRejectReason || asset.savedRejectReason)) {
                this.categorizeFilenameByFilter(this.categorizedAssetPaths, asset, 'rejected');
            }
        });
        this.hasDownloadOptions = (this.categorizedAssetPaths.visible.some((filePath) => filePath.includes('/watermark_')) || this.categorizedAssetPaths.visible.some((filePath) => filePath.includes('/web_')));
        this.hasWatermarkOptions = this.categorizedAssetPaths.visible.some((filePath) => filePath.includes('/watermark_'));
        this.checkDownloadAvailable();
    }

    closeDialog() {
        this.dialogService.closeAllDialogs();
    }

    requestDownload() {
        const {address} = this.jobsService.selectedJob || {address: this.dialogService.inputData.address};
        let uniqFilteredFilePaths = [];

        Object.entries(this.categorizedAssetPaths).forEach(([key, val]) => {
            if (this.selectedDownloadAssetTypes[key]) {
                val.forEach((filePath) => {
                    if (this.selectedDownloadFormats.print && !filePath.includes('/watermark_') && !filePath.includes('/web_')) {
                        uniqFilteredFilePaths.push(filePath);
                    } else if (this.selectedDownloadFormats.web && filePath.includes('/web_')) {
                        uniqFilteredFilePaths.push(filePath);
                    } else if (this.selectedDownloadFormats.watermark && filePath.includes('/watermark_')) {
                        uniqFilteredFilePaths.push(filePath);
                    }
                });
            }
        });
        this.assetsService.sendDownloadRequest(address, !!this.serviceId ? this.serviceId : `job_${this.jobId}`, uniqFilteredFilePaths);
        this.snackbarService.showDismissibleSnackbar();
        this.dialogService.closeAllDialogs();
    }

    changeDownloadTypeSelection(type: string, checked) {
        if (checked) {
            this.hasPrintOptions = true;
            this.hasWebOptions = true;
        }
        this.hasWatermarkOptions = false;
        this.selectedDownloadAssetTypes[type] = checked;

        if (!this.hasWatermarkOptions && this.selectedDownloadAssetTypes['visible']) {
            this.hasWatermarkOptions = this.categorizedAssetPaths['visible'].some(assetPath => assetPath.includes('/watermark_'));
        }
        if (!this.hasWatermarkOptions && this.selectedDownloadAssetTypes['hidden']) {
            this.hasWatermarkOptions = this.categorizedAssetPaths['hidden'].some(assetPath => assetPath.includes('/watermark_'));
        }
        if (!this.hasWatermarkOptions && this.selectedDownloadAssetTypes['rejected']) {
            this.hasWatermarkOptions = this.categorizedAssetPaths['rejected'].some(assetPath => assetPath.includes('/watermark_'));
        }

        if (!this.selectedDownloadAssetTypes['visible'] && !this.selectedDownloadAssetTypes['hidden'] && !this.selectedDownloadAssetTypes['rejected']) {
            this.selectedDownloadFormats.watermark = false;
            this.selectedDownloadFormats.web = false;
            this.selectedDownloadFormats.print = false;
            this.hasPrintOptions = false;
            this.hasWebOptions = false;
            this.hasWatermarkOptions = false;
        }
        this.checkDownloadAvailable();
    }

    changeDownloadFormatSelection(type: string, checked) {
        this.selectedDownloadFormats[type] = checked;
        this.checkDownloadAvailable();
    }

    categorizeFilenameByFilter(filteredFilePaths, asset: Asset, filter) {
        const filepath = asset.finalPath;
        filteredFilePaths[filter].push(filepath);

        let formatFilePath = `${filepath.slice(0, filepath.lastIndexOf('/'))}/web_${asset.filename}`;
        if (this.allAssetPaths.includes(formatFilePath)) {
            filteredFilePaths[filter].push(formatFilePath);
        }

        formatFilePath = `${filepath.slice(0, filepath.lastIndexOf('/'))}/watermark_${asset.filename}`;
        if (this.allAssetPaths.includes(formatFilePath)) {
            filteredFilePaths[filter].push(formatFilePath);
        }

    }

    checkDownloadAvailable() {
        const typeSelected = (this.selectedDownloadAssetTypes.visible || this.selectedDownloadAssetTypes.hidden || this.selectedDownloadAssetTypes.rejected);
        const formatSelected = (this.selectedDownloadFormats.watermark || this.selectedDownloadFormats.web || this.selectedDownloadFormats.print);
        this.downloadDisabled = (!typeSelected || !formatSelected);
    }
}
