import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {SnackbarService} from './snackbar.service';
import {AssetsService} from './assets.service';
import {environment} from '../../environments';
import {Asset, Response} from '../models';

@Injectable({
    providedIn: 'root'
})
export class AcceptRejectService implements OnDestroy {
    private servicesWithRejections: BehaviorSubject<{ [serviceId: string]: any[] }> = new BehaviorSubject<any>({});
    private updatableAssets$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private stopCheckingForRejections$ = new Subject<void>();
    private rejectionPending: BehaviorSubject<any> = new BehaviorSubject<any>({});

    constructor(private assetsService: AssetsService,
                private http: HttpClient,
                private snackbarService: SnackbarService) {
        this.assetsService.accessedAssets$
            .pipe(
                first(d => !d),
            )
            .subscribe(services => {
                    const serviceKeys = Object.keys(services);
                    for (let i = serviceKeys.length; i-- > 0;) {
                        if (!!services[serviceKeys[i]] && typeof services[serviceKeys[i]].filter === 'function') {
                            const tempServiceRejections = services[serviceKeys[i]]
                                .filter(asset => {
                                    if (asset.hasOwnProperty('savedRejectReason') || asset.hasOwnProperty('finalRejectReason') || (asset.hasOwnProperty('hidden') && asset['hidden'] === 'true' || asset['hidden'] === true)) {
                                        asset['hidden'] = true;
                                    } else if (asset['savedRejectReason'] === null) {
                                        asset['hidden'] = false;
                                    }
                                    return asset.hasOwnProperty('savedRejectReason') || asset.hasOwnProperty('finalRejectReason');
                                });
                            this.assetsService.setAccessedAssets(serviceKeys[i], services[serviceKeys[i]]);
                            this.servicesWithRejections.value[serviceKeys[i]] = tempServiceRejections;
                            this.servicesWithRejections.next(this.servicesWithRejections.value);
                        }
                    }
                },
                (err) => console.error('Could not check for rejections', err));
    }

    get rejectionPending$() {
        return this.rejectionPending.asObservable();

    }

    setRejectionPending(serviceId: string, loading: boolean) {
        let rejectionObjCopy = this.rejectionPending.value;

        if (loading) {
            rejectionObjCopy[serviceId] = true;
            this.rejectionPending.next(rejectionObjCopy);
        } else {
            delete rejectionObjCopy[serviceId];
            this.rejectionPending.next(rejectionObjCopy);
        }

    }

    setUpdatableRejectedServices(assets, serviceId) {
        this.updatableAssets$.value[serviceId] = assets;
        this.updatableAssets$.next(this.updatableAssets$.value);
    }

    modifyAssetRejection(serviceId, rejectReason, thumbUUID) {
        const tempHolder = this.updatableAssets$.value;

        tempHolder[serviceId].find((asset, index) => {
            if (asset.thumbUUID === thumbUUID) {
                if (rejectReason.length) {
                    tempHolder[serviceId][index].savedRejectReason = rejectReason;
                    return true;
                } else {
                    tempHolder[serviceId][index].savedRejectReason = null;
                    return true;
                }
            }
            return false;
        });

        this.updatableAssets$.next(tempHolder);
        this.setRejectedServicesAfterMod(serviceId, tempHolder[serviceId]);
    }

    setRejectedServicesAfterMod(serviceId, assets) {
        const assetsHolder = this.servicesWithRejections.value;
        let i = 0;
        let hasRejection;
        while (i < assets.length) {
            hasRejection = !!assets[i]['savedRejectReason'] || !!assets[i]['finalRejectReason'];
            if (hasRejection) break;
            i++;
        }

        if (hasRejection) {
            assetsHolder[serviceId] = assets;
            this.servicesWithRejections.next(assetsHolder);
        } else {
            assetsHolder[serviceId].forEach(asset => asset.savedRejectReason = null);
            this.servicesWithRejections.next(assetsHolder);
        }
    }

    rejectAndOrUpdateAssetsRejections(rejectAssetsBool: boolean, pathToFinals: string, serviceRef: {
        key: string,
        value: Asset[]
    }) {
        const updatedAssets = this.updatableAssets$.value[serviceRef.key];
        this.assetsService.setAccessedAssets(serviceRef.key, updatedAssets);
        this.updateRejectionsOnDatabase(rejectAssetsBool, pathToFinals, serviceRef);
    }

    updateRejectionsOnDatabase(sendRejections: boolean, pathToFinals: string, serviceRef: {
        key: string,
        value: Asset[]
    }) {
        this.setRejectionPending(serviceRef.key, true);
        let rejectionCollection = [];
        if (!!this.servicesWithRejections.value[serviceRef.key]) {
            this.servicesWithRejections.value[serviceRef.key]
                .forEach(({savedRejectReason, finalRejectReason, filename, thumbName, thumbUUID, finalPath}) => {
                    if (!finalRejectReason) {
                        rejectionCollection.push({
                            thumbName,
                            filename,
                            finalPath,
                            savedRejectReason,
                            finalRejectReason,
                            sendRejections,
                            thumbUUID
                        });
                    }
                });
        }
        if (rejectionCollection.length || !sendRejections) {
            this.http.post(`${environment.apiBaseUrl}/SaveRejectedAssets`, {
                serviceId: serviceRef.key,
                rejectionCollection,
                processedFinalsPath: pathToFinals
            })
                .toPromise()
                .then((response: Response) => {
                    const returnedAssets = response.data;
                    returnedAssets.forEach(assetIteration => {
                        if (!!assetIteration.finalRejectReason) {
                            const assetRefFound = serviceRef.value.find(assetInnerIteration => assetIteration.thumbUUID === assetInnerIteration.thumbUUID);
                            assetRefFound.finalRejectReason = assetIteration.finalRejectReason;
                            assetRefFound.hidden = true;
                        }
                    });

                    this.setRejectionPending(serviceRef.key, false);

                    Object.values(this.servicesWithRejections.value[serviceRef.key]).forEach((rejection: any, i) => {
                        if (sendRejections) {
                            this.servicesWithRejections.value[serviceRef.key][i].finalRejectReason = rejection.savedRejectReason || rejection.finalRejectReason;
                        }
                    });

                    this.snackbarService.showSnackbar(response.message);

                })
                .catch(err => {
                    console.error('Error occurred while returning assets:', err);
                    this.setRejectionPending(serviceRef.key, false);
                });
        } else {
            this.snackbarService.showSnackbar('All rejections have already been sent');
            this.setRejectionPending(serviceRef.key, false);

        }
    }

    ngOnDestroy() {
        this.stopCheckingForRejections$.next();
    }
}
