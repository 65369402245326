<mat-dialog-content>
    <ng-container *ngIf="displayForms">
        <div *ngFor="let service of productBriefs; let i = index">
            <prism-service-brief-form
                    [service]="service"
                    [fileCount]="serviceFileCount[service.id]"
                    (closeFormHandler)="closeServiceBriefHandler()"
            ></prism-service-brief-form>
        </div>
    </ng-container>
    <div *ngFor="let service of services; let i = index">
        <ng-container
                *ngIf="!(service.SNAKE_CASE === VIDEO_SERVICES.SNAKE_CASE || service.SNAKE_CASE === FLOORPLAN_SERVICES.SNAKE_CASE) else googleForm">
            <prism-image-upload
                #imageServiceUploads
                [service]="service"
                [serviceIndex]="i"
                [productId]="productId"
                [parentJob]="parentJob"
                [onlyEdit]="false"
                (closeAllDialogHandler)="closeUploaderHandler($event)"
                (serviceBoxClosed)="updateClosedServices($event)"
            >
            </prism-image-upload>
        </ng-container>
        <ng-template #googleForm>
            <prism-video-floorplan-upload
                    [service]="service"
                    [productId]="productId"
                    [parentJob]="parentJob"
                    [productsLoading]="productsLoading$ | async"
                    [onlyEdit]="false"
                    (closeAllDialogHandler)="closeUploaderHandler($event)"
                    (serviceBoxClosed)="updateClosedServices($event)">
            </prism-video-floorplan-upload>
        </ng-template>
    </div>
</mat-dialog-content>
