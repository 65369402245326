import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/';
import {PrismHeaders} from '../enums';

@Injectable({
    providedIn: 'root'
})
export class MemberService {
    constructor(private http: HttpClient) {
    }

    getMember(updateData?) {
        const headers = new HttpHeaders()
            .set(PrismHeaders.UpdateCacheData, JSON.stringify({updateData}));
        return this.http.get(environment.apiBaseUrl + '/GetMember', {headers});
    }

    updateMember(body) {
        return this.http.put(environment.apiBaseUrl + '/PutMember', body);
    }
}
