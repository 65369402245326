import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/performance';
import {FirebaseConfig} from '../../../environments';
import {AppComponent, EnableContractorComponent} from '../../components';
import {RetrySrcDirective} from '../../directives/retry-src.directive';
import {AuthInterceptor} from '../../services';
import {AppRouting} from '../routing';
import {UiModule} from './ui.module';
import {MatDialogModule} from '@angular/material/dialog';
import {RefreshDialogComponent} from '../../components/refresh-dialog/refresh-dialog.component';
import {ErrorHandler, NgModule} from '@angular/core';
import {TraceService, createErrorHandler} from '@sentry/angular-ivy';
import {MAT_SELECT_CONFIG} from '@angular/material/select';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        UiModule.core(),
        MatDialogModule,
        HttpClientJsonpModule,
        AppRouting,
        AngularSvgIconModule.forRoot()
    ],
    declarations: [
        AppComponent,
        RefreshDialogComponent,
        RetrySrcDirective,
        EnableContractorComponent
    ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        ServiceWorkerModule,
    ],
    providers: [
        {
            provide: MAT_SELECT_CONFIG,
            useValue: {overlayPanelClass: 'mat-form-field-select'}
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: {overlayPanelClass: 'mat-table-tooltip'}
        },

        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {subscriptSizing: 'dynamic'}},

        {
            useClass: AuthInterceptor,
            provide: HTTP_INTERCEPTORS,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: TraceService,
            deps: [Router],
            useValue: undefined
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
        firebase.initializeApp(FirebaseConfig.MemberAppConfig);
        // firebase.analytics();
    }
}
