<h1 class="page-title">Agents</h1>

<div class="content-wrapper table-wrapper agents-table-wrapper">
    <div class="toolbar table-toolbar agents-table-toolbar">
        <div class="toolbar-left table-toolbar-left">
            <div class="active-toggle-field">
                <mat-button-toggle-group name="agencyStatus" [value]="activeAgentsFilter">
                    <mat-button-toggle [value]="true" (click)="showActiveAgents(true)">Active</mat-button-toggle>
                    <mat-button-toggle [value]="false" (click)="showActiveAgents(false)">Archived</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="toolbar-right table-toolbar-right">
            <button class="btn btn-red" mat-raised-button [disabled]="!selectedAgentIds.length"
                    (click)="activeAgentsFilter ? updateAgentsActiveStatus(false) : updateAgentsActiveStatus(true)">{{ activeAgentsFilter ? 'Archive' : 'Activate' }}
                Selected
            </button>
            <button class="btn btn-green" mat-raised-button (click)="displayAgentsModal()">New Agent</button>
        </div>
    </div>
    <prism-agents-table #agentsTable [showActive]="activeAgentsFilter"
                        (selectionUpdated)="onSelectionUpdated($event)"
                        (rowClicked)="onAgentClicked($event)"
    ></prism-agents-table>
</div>