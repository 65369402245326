import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {catchError, combineLatest, first, map, shareReplay, take, takeUntil, tap} from 'rxjs/operators';
import {environment} from '../../environments';
import {PrismHeaders} from '../enums';
import {Response} from '../models';
import {JobsService} from './jobs.service';
import {SnackbarService} from './snackbar.service';
import uniq from 'lodash/uniq';

@Injectable({
    providedIn: 'root'
})
export class InvoicesService {
    private invoices: BehaviorSubject<object[]>;
    private queuedInvoicesCount$: BehaviorSubject<number>;
    private syncedInvoicesCount$: BehaviorSubject<number>;
    private queueHasErrors: BehaviorSubject<boolean>;
    private selectedInvoiceTab: BehaviorSubject<'synced' | 'unsynced'>;
    private _loadingQueue: BehaviorSubject<boolean>;
    private _reconBusyQueue: BehaviorSubject<boolean>;
    private _reconBusySingle: BehaviorSubject<boolean>;
    private _destroyPostInvoiceSubscription;
    private invoiceTableSizePreference: number = 10;
    private getInvoicesLoadingBool: Subject<boolean>;
    private putInvoicesLoadingBool: Subject<boolean>;
    private deleteInvoicesLoadingBool: Subject<boolean>;
    private syncedInvoicesPaginationData$: BehaviorSubject<any>;
    private queuedInvoicesPaginationData$: BehaviorSubject<any>;

    constructor(private http: HttpClient, private snackbarService: SnackbarService, private jobsService: JobsService) {
        this.invoices = new BehaviorSubject<object[]>(null);
        this.queuedInvoicesCount$ = new BehaviorSubject<number>(null);
        this.syncedInvoicesCount$ = new BehaviorSubject<number>(null);
        this.queueHasErrors = new BehaviorSubject<boolean>(false);
        this.selectedInvoiceTab = new BehaviorSubject<'synced' | 'unsynced'>('unsynced');
        this._loadingQueue = new BehaviorSubject<boolean>(false);
        this._reconBusyQueue = new BehaviorSubject<boolean>(false);
        this._reconBusySingle = new BehaviorSubject<boolean>(false);
        this._destroyPostInvoiceSubscription = new Subject<void>();
        this.invoiceTableSizePreference = 10;
        this.getInvoicesLoadingBool = new Subject<boolean>();
        this.putInvoicesLoadingBool = new Subject<boolean>();
        this.deleteInvoicesLoadingBool = new Subject<boolean>();
        this.syncedInvoicesPaginationData$ = new BehaviorSubject<any>({
            pageSize: 10,
            pageIndex: 0
        });
        this.queuedInvoicesPaginationData$ = new BehaviorSubject<any>({
            pageSize: 10,
            pageIndex: 0
        });

    }

    get loadingQueue$() {
        return this._loadingQueue.asObservable();
    }

    get reconBusyQueue$() {
        return this._reconBusyQueue.asObservable();
    }

    get reconBusySingle$() {
        return this._reconBusySingle.asObservable();
    }

    get invoiceTableSize() {
        return this.invoiceTableSizePreference;
    }

    get invoicesInQueueCount() {
        return this.queuedInvoicesCount$.asObservable();
    }

    get syncedInvoicesCount() {
        return this.syncedInvoicesCount$.asObservable();
    }

    get queueHasErrors$() {
        return this.queueHasErrors.asObservable();
    }

    get selectedInvoiceTab$() {
        return this.selectedInvoiceTab.asObservable();
    }

    get selectedInvoiceTabValue() {
        return this.selectedInvoiceTab.getValue();
    }

    refreshQueuedInvoicesTableIndex() {
        this.updateQueuedInvoiceListPagination({
                pageSize: this.queuedInvoicesPaginationValue.pageSize,
                pageIndex: this.queuedInvoicesPaginationValue.pageIndex
            }
        );
    }

    refreshSyncedInvoicesTableIndex() {
        this.updateSyncedInvoiceListPagination({
            pageSize: this.syncedInvoicesPaginationValue.pageSize,
            pageIndex: this.syncedInvoicesPaginationValue.pageIndex
        });
    }


    toggleLoadingQueue(loadingInProgress: boolean) {
        this._loadingQueue.next(loadingInProgress);
    }

    setSelectedInvoiceTab(tab: 'synced' | 'unsynced') {
        this.selectedInvoiceTab.next(tab);
    }

    setSyncedInvoices(newInvoices, updatedInvoices) {
        const invoiceList = this.invoices.getValue() || [];

        for (let invoice of updatedInvoices) {
            const index = invoiceList.findIndex((p: any) => p.InvoiceNumber === invoice.InvoiceNumber);

            if (index !== -1) {
                invoiceList[index] = {
                    ...invoice,
                    DueDateString: invoice.DueDate,
                    DateString: invoice.Date
                };
            }
        }

        this.invoices.next(invoiceList.concat(newInvoices));
    }

    getInvoices(pageSize, pageIndex) {
        this.switchGetInvoicesLoaderOn();
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({pageSize, pageIndex}));

        return this.http.get(environment.apiBaseUrl + '/GetInvoices', {
            headers
        })
            .pipe(
                take(1),
                map((response: Response) => {
                    return response.data;
                }),
                tap((invoices) => {
                    if (invoices && invoices.length > 0) {
                        this.invoices.next(invoices);
                    } else if (invoices && invoices.length === 0) {
                        this.invoices.next([]);
                    } else {
                        this.invoices.next(null);
                    }
                })
            );
    }

    getInvoicesQueueCount() {
        return this.http.get(environment.apiBaseUrl + '/GetInvoicesQueueCount')
            .pipe(
                take(1),
                tap(
                    (response: Response) => {
                        this.queuedInvoicesCount$.next(response.data);
                    }
                )
            );
    }

    getInvoicesCount() {
        return this.http.get(environment.apiBaseUrl + '/GetInvoicesCount')
            .pipe(
                take(1),
                map((response: Response) => response.data),
                tap((count) => {
                    this.syncedInvoicesCount$.next(count);
                })
            );

    }

    getQueuedInvoices(pageSize, pageIndex) {
        this.toggleLoadingQueue(true);
        this.switchGetInvoicesLoaderOn();
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({pageSize, pageIndex}));
        return this.http.get(environment.apiBaseUrl + '/GetInvoiceQueue', {
            headers
        })
            .pipe(
                first(d => !!d),
                map((response: Response) => {
                    return response.data;
                }),
                tap(() => {
                    this.toggleLoadingQueue(false);
                })
            );
    }

    get syncedInvoicesPaginationData() {
        return this.syncedInvoicesPaginationData$.asObservable();
    }

    get queuedInvoicesPaginationData() {
        return this.queuedInvoicesPaginationData$.asObservable();
    }

    updateSyncedInvoiceListPagination({pageSize, pageIndex}) {
        this.syncedInvoicesPaginationData$.next({pageSize, pageIndex});
    }

    updateQueuedInvoiceListPagination({pageSize, pageIndex}) {
        this.queuedInvoicesPaginationData$.next({pageSize, pageIndex});
    }

    get syncedInvoicesPaginationValue() {
        return this.syncedInvoicesPaginationData$.getValue();
    }

    get queuedInvoicesPaginationValue() {
        return this.queuedInvoicesPaginationData$.getValue();
    }

    postInvoice(invoice, invoiceEmails, templateSelection, jobId, masterProductIds, charged = true) {
        const lineItems = invoice.lineItems;
        const headers = new HttpHeaders().append(PrismHeaders.FunctionHelperData, JSON.stringify({
            jobId,
            masterProductIds,
            templateSelection
        }));
        const addressArray = this.jobsService.selectedJob.address.split(',');
        const [primaryEmail, ...otherEmails] = invoice.emails;
        const invoiceToPost = {
            Contact: {
                Name: invoice.invoiceRecipient,
                EmailAddress: primaryEmail,
                ContactPersons: otherEmails.map(email => ({
                    EmailAddress: email,
                    IncludeInEmails: true
                })),
                Addresses: [
                    {
                        'AddressType': 'STREET',
                        'AddressLine1': addressArray[0]
                    }
                ]
            },
            Type: 'ACCREC',
            DateString: invoice.dateCreated,
            LineAmountTypes: 'Inclusive',
            Status: 'AUTHORISED',
            DueDateString: invoice.dueDate,
            Reference: invoice.invoiceReference,
            LineItems: lineItems
        };
        const body = charged ? {invoice: invoiceToPost, invoiceEmails, charged} : {charged};

        return this.http.post<Response>(environment.apiBaseUrl + '/PostInvoice', body, {headers})
            .pipe(
                shareReplay(1)
            )
            .toPromise()
            .then(async (response) => {
                if (charged) {
                    await this.syncInvoice(response.data.newQueuedInvoiceDocUID);
                    return response.data.newQueuedInvoiceDocUID;
                } else {
                    return null;
                }
            })
            .then(
                (queuedInvoiceId: string | null) => {
                    if (queuedInvoiceId) {
                        this.getInvoicesQueueCount()
                            .pipe(
                                combineLatest(this.jobsService.jobs),
                                takeUntil(this._destroyPostInvoiceSubscription)
                            )
                            .subscribe(
                                (data) => {
                                    let success = data[0];
                                    let jobs = data[1];
                                    this._destroyPostInvoiceSubscription.next();
                                    jobs.every(jobIteration => {
                                        if (jobId === jobIteration.id) {
                                            jobIteration.unsyncedInvoices.push(queuedInvoiceId);
                                            jobIteration.productsToInvoice = false;
                                            jobIteration.charged = true;
                                            this.jobsService.jobsBSubjectSetter = jobs;
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    });
                                    this.queuedInvoicesCount$.next(success['data']);
                                }
                            );
                        this.snackbarService.showSnackbar(`Sending invoice to Xero`);
                    } else if (!!this.jobsService.jobsValue) {
                        let jobs = this.jobsService.jobsValue;
                        jobs.every(jobIteration => {
                            if (jobId === jobIteration.id) {
                                jobIteration.charged = false;
                                jobIteration.productsToInvoice = false;
                                this.jobsService.jobsBSubjectSetter = jobs;
                                return false;
                            } else {
                                return true;
                            }
                        });
                    }
                })
            .catch((err) => {
                console.error('Error processing invoice:', err);
                this.snackbarService.handleError(`Error processing invoice. Please try again.`);
            });
    }

    deleteQueuedInvoice(invoiceUID: string) {
        this.switchDeleteInvoicesLoaderOn();
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({invoiceUID}));

        return this.http.get(`${environment.apiBaseUrl}/DeleteQueuedInvoice`, {headers});
    }

    updateMiscAccountCode(miscAccountCode) {
        const headers = new HttpHeaders()
            .append(PrismHeaders.FunctionHelperData, JSON.stringify({miscAccountCode}));

        return this.http.get(`${environment.apiBaseUrl}/UpdateAllMiscAccountCodes`, {headers});
    }

    getInvoicePdf(invoiceId: string) {
        return this.http.post(`${environment.apiBaseUrl}/GetInvoicePdf`, {invoiceId}, {
            responseType: 'blob',
        })
            .toPromise();
    }

    fetchInvoicesCSVDataFromXero(startDate?, endDate?) {
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({startDate, endDate}));

        return this.http.get(`${environment.apiBaseUrl}/GetInvoiceCSVData`, {headers});
    }

    updateSyncedInvoices() {
        this._reconBusyQueue.next(true);
        this._loadingQueue.next(true);
        return this.http.get(`${environment.apiBaseUrl}/UpdateSyncedInvoices`)
            .pipe(
                tap((res: Response) => {
                    const {newInvoices, updatedInvoices} = res.data;
                    this.setSyncedInvoices(newInvoices, updatedInvoices);
                    this._loadingQueue.next(false);
                    this._reconBusyQueue.next(false);
                }),
                catchError(err => {
                    this.snackbarService.handleError('Something went wrong while reaching out to Xero. Please reconnect and try again.');
                    this._loadingQueue.next(false);
                    this._reconBusyQueue.next(false);
                    return err;
                })
            );
    }

    updateInvoice(updateInvoiceData) {
        this.switchPutInvoicesLoaderOn();
        return this.http.put(`${environment.apiBaseUrl}/UpdateInvoice`, updateInvoiceData);
    }

    switchGetInvoicesLoaderOn() {
        this.getInvoicesLoadingBool.next(true);
    }

    switchGetInvoicesLoaderOff() {
        this.getInvoicesLoadingBool.next(false);
    }

    get isGetInvoicesLoading$(): Observable<boolean> {
        return this.getInvoicesLoadingBool.asObservable();
    }

    switchPutInvoicesLoaderOn() {
        this.putInvoicesLoadingBool.next(true);
    }

    switchPutInvoicesLoaderOff() {
        this.putInvoicesLoadingBool.next(false);
    }

    get isPutInvoicesLoading$(): Observable<boolean> {
        return this.putInvoicesLoadingBool.asObservable();
    }

    switchDeleteInvoicesLoaderOn() {
        this.deleteInvoicesLoadingBool.next(true);
    }

    switchDeleteInvoicesLoaderOff() {
        this.deleteInvoicesLoadingBool.next(false);
    }

    get isDeleteInvoicesLoading$(): Observable<boolean> {
        return this.deleteInvoicesLoadingBool.asObservable();
    }

    async getUserInvoiceEmails({agency: agencyId, agent: agentId}) {
        return new Promise(async (resolve, reject) => {
            let allInvoiceEmails = [];
            let agencyObs;
            let agentObs;
            let promiseList = [];

            if (!!agentId) {
                const agentHeaders = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify({agentId})).append(PrismHeaders.SkipHeaders, '');
                agentObs = this.http.get(`${environment.apiBaseUrl}/GetAgent`, {headers: agentHeaders})
                    .pipe(
                        map((res: Response) => res.data.invoiceEmails)
                    ).toPromise();
                promiseList.push(agentObs);
            }

            if (!!agencyId) {
                const agencyHeaders = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify({agencyId})).append(PrismHeaders.SkipHeaders, '');
                agencyObs = this.http.get(`${environment.apiBaseUrl}/GetAgency`, {headers: agencyHeaders})
                    .pipe(
                        map((res: Response) => res.data.invoiceEmails)
                    ).toPromise();
                promiseList.push(agencyObs);
            }

            if (promiseList.length > 0) {
                await Promise.all(promiseList).then(
                    (data) => {
                        for (let i = 0; i < data.length; i++) {
                            allInvoiceEmails = allInvoiceEmails.concat(data[i]);
                        }
                        resolve(uniq(allInvoiceEmails));
                    })
                    .catch((e) => reject(e));
            } else {
                resolve(allInvoiceEmails);
            }
        });
    }

    private async syncInvoice(newQueuedInvoiceDocUID: string) {
        this._reconBusySingle.next(true);
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({newQueuedInvoiceDocUID}));
        try {
            await this.http.get(`${environment.apiBaseUrl}/SyncNewInvoice`, {headers})
                .toPromise();
            this._reconBusySingle.next(false);
            if (this.selectedInvoiceTabValue === 'synced') {
                this.refreshSyncedInvoicesTableIndex();
            }
        } catch {
            this._reconBusySingle.next(false);
        }
    }
}
