import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import includes from 'lodash/includes';
import differenceWith from 'lodash/differenceWith';
import sortedUniq from 'lodash/sortedUniq';
import remove from 'lodash/remove';
import isEqual from 'lodash/isEqual';
import {AlbumProduct, Contractor, Member, Service, MemberProduct} from '../../../models';
import {AuthenticationService, ProductsService, SnackbarService} from '../../../services';
import {Observable} from 'rxjs';

@Component({
    selector: 'prism-products-table',
    templateUrl: './products-table.component.html',
    styleUrls: ['./products-table.component.scss'],
})
export class ProductsTableComponent implements OnInit {
    memberData$: Observable<Member | Contractor>;
    active: boolean;
    showLoader: boolean;
    stringLengthLimit: number;
    allIds: number[];
    pendingActiveArchiveProducts: number[];
    columnHeaders: string[];
    paginator: MatPaginator;
    productsList: MatTableDataSource<MemberProduct>;
    paginatorControl: {
        length: number,
        pageSize: number,
        pageSizeOptions: number[]
    };

    constructor(private authService: AuthenticationService, private productsService: ProductsService, private snackbarService: SnackbarService) {
        this.memberData$ = this.authService.memberData$;
        this.active = true;
        this.showLoader = null;
        this.stringLengthLimit = 20;
        this.allIds = [];
        this.pendingActiveArchiveProducts = [];
        this.columnHeaders = ['select', 'name', 'services', 'price', 'description', 'accountingCodes', 'active', 'edit'];
        this.productsList = new MatTableDataSource<MemberProduct>([]);
        this.paginatorControl = {
            length: this.productsList.data.length,
            pageSize: this.productsService.productsTableSize,
            pageSizeOptions: [10, 20, 40, 50, 100]
        };
    }

    @Input()
    set showActive(bool: boolean) {
        this.active = bool;
    }

    @Input()
    set products(products: MemberProduct[]) {
        this.productsList.data = products || [];
        this.pendingActiveArchiveProducts = [];
        this.setForArchive.emit(this.pendingActiveArchiveProducts);
        this.allIds = this.productsList.data.map(product => product.id);
        if (this.globalCheckbox) {
            this.globalCheckbox.checked = false;
        }
    }

    @Input()
    set setShowLoader(showLoader: boolean) {
        this.showLoader = showLoader;
    }

    @Output()
    openProductEdit = new EventEmitter<AlbumProduct>();

    @Output()
    setForArchive = new EventEmitter<number[]>();

    @Output()
    emitActiveToggle = new EventEmitter<{ productId: number, activeStatus: boolean }>();

    @ViewChild(MatPaginator, {static: true}) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    @ViewChild('globalActiveArchiveCheckbox', {static: false}) globalCheckbox: MatCheckbox;

    @ViewChildren('activeArchiveCheckboxes') checkboxes: MatCheckbox[];

    setDataSourceAttributes() {
        this.productsList.paginator = this.paginator;
    }

    ngOnInit() {
        this.productsList.filterPredicate = data => data.active === this.active;
    }

    emitProductForEdit(product: AlbumProduct) {
        this.openProductEdit.emit(product);
    }

    displayArray(servicesItemsArray: Service[], prop): string {
        return servicesItemsArray.map(serviceItem => {
            return !!serviceItem ? serviceItem[prop] : '';
        }).join(', ');
    }

    showFullString(string, limit) {
        if (string.length > limit) {
            return 'auto';
        } else {
            return 'none';
        }
    }

    emitChangeActive(id: number, checked) {
        const idIncluded = includes(this.pendingActiveArchiveProducts, id);
        if (checked && !idIncluded) {
            this.pendingActiveArchiveProducts.push(id);

        } else if (!checked && idIncluded) {
            this.pendingActiveArchiveProducts = remove(this.pendingActiveArchiveProducts, productId => productId !== id);
            this.globalCheckbox.checked = false;
        }
        this.pendingActiveArchiveProducts = sortedUniq(this.pendingActiveArchiveProducts);
        if (differenceWith(this.allIds, this.pendingActiveArchiveProducts, isEqual).length === 0) {
            this.globalCheckbox.checked = true;
        }
        this.setForArchive.emit(this.pendingActiveArchiveProducts);
    }

    putToggleActive(product: MemberProduct, activeStatus: boolean) {
        this.snackbarService.showSnackbar(`${activeStatus ? 'Activating' : 'Archiving'} product...`);
        if (includes(this.pendingActiveArchiveProducts, product.id)) {
            this.pendingActiveArchiveProducts = remove(this.pendingActiveArchiveProducts, productId => productId !== product.id);
            this.setForArchive.emit(this.pendingActiveArchiveProducts);
        }

        this.productsService.switchPutMemberProductLoaderOn();
        this.productsService.changeMemberProductActiveState([product.id], activeStatus)
            .toPromise()
            .then(() => {
                this.productsService.switchPutMemberProductLoaderOff();
                this.productsList.data = this.productsList.data.filter(currProduct => currProduct !== product);
            })
            .catch(err => {
                console.error('Put Products Error:', err);
                this.snackbarService.handleError(`There was an error while archiving your products. Please try again.`);
                this.productsService.switchPutMemberProductLoaderOff();
            });
    }

    setAllCheckboxes(bool: boolean) {
        this.checkboxes.forEach(checkbox => {
            checkbox.checked = bool;
            checkbox.change.emit({checked: bool, source: checkbox});
        });
    }

    paginate(event) {
        this.productsService.updateProductsTableSize = event.pageSize;
    }

}

