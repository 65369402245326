<div class="invoice-container">
    <h1 class="page-title">Invoices</h1>
    <div class="invoice-body">
        <div class="toolbar invoices-toolbar">
            <div class="toolbar-left table-toolbar-left">
                <mat-button-toggle-group
                        [value]="selectedInvoiceTab$|async"
                        (change)="changeShowSynced($event.value)"
                        #invoicesDisplayToggle>
                    <mat-button-toggle [checked]="(selectedInvoiceTab$|async) === 'synced'" value="synced">
                        Synced
                    </mat-button-toggle>
                    <mat-button-toggle [checked]="(selectedInvoiceTab$|async) === 'unsynced'" value="unsynced">
                        Unsynced
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="toolbar-right">
                <button mat-raised-button class="btn btn-blue retrieve-csv-btn"
                        [disabled]="!(invoicesLength$ | async)?.length || !((xeroExpiryTimer$ | async) !== 0)"
                        (click)="openInvoiceDataFetchModal()">Retrieve Invoice Data
                </button>
            </div>
        </div>
        <div class="progress-bar" *ngIf="((invoicesLength$ | async) === null  || (invoices$ | async) === null)">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <div [ngClass]="(!!(invoicesLength$ | async) && ((invoices$ | async)?.length)) ? 'invoices-table-container' : 'no-invoices-placeholder'">
            <prism-invoices-table
                    *ngIf="(!!(invoicesLength$ | async) && ((invoices$ | async)?.length) && (selectedInvoiceTab$|async) === 'synced')"
                    [invoicesLength]="(invoicesLength$ | async)"
                    [invoices]="(invoices$ | async)"
                    [sessionExpired]="!((xeroExpiryTimer$ | async) !== 0)"
                    [setShowLoader]="(isGetInvoicesLoading$ | async) === true"
            >
            </prism-invoices-table>

            <prism-unsynced-invoices-table
                    *ngIf="(!!(invoicesLength$ | async) && ((invoices$ | async)?.length) && (selectedInvoiceTab$|async) === 'unsynced')"
                    [invoicesLength]="(invoicesLength$ | async)"
                    [invoices]="(invoices$ | async)"
                    [setShowLoader]="(isGetInvoicesLoading$ | async) === true || (isPutInvoicesLoading$ | async) === true || (isDeleteInvoicesLoading$ | async) === true"
            >
            </prism-unsynced-invoices-table>

            <div
                    class="invoice-list-empty"
                    *ngIf="!(isGetInvoicesLoading$ | async) && (((invoices$ | async)?.length && ((invoices$ | async).length === 0)) || ((invoicesLength$ | async) === 0))"
            >
                <h5>No {{ ((selectedInvoiceTab$|async) === 'synced') ? 'synced' : 'unsynced' }} invoices to
                    display...</h5>
            </div>

        </div>

    </div>


</div>
