<table mat-table [dataSource]="jobsList">
    <section>Jobs list</section>

    <ng-container matColumnDef="id">
        <th mat-header-cell scope="col" *matHeaderCellDef>ID</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let job"
                (click)="selectJob(job)"
        >
            <div class="table-data-text">
                <span>{{ job.id }}</span>
            </div>
        </td>
        <ng-container matColumnDef="address">

            <th mat-header-cell scope="col" *matHeaderCellDef>Address</th>
            <td
                    class="clickable-area address-col"
                    mat-cell
                    *matCellDef="let job"
                    (click)="selectJob(job)"
            >
                <div class="table-data-text">
                    <span class="address-col-text"
                          [matTooltip]="job.address"
                          matTooltipPosition="right"
                          [matTooltipShowDelay]="500"
                          matTooltipClass="tooltip"
                    >
                        {{ job.address.substr(0, job.address.indexOf(",")) }}
                    </span>
                </div>

            </td>
        </ng-container>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell scope="col" *matHeaderCellDef>Status</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let job"
                (click)="selectJob(job)"
        >
            <div class="status-items-container">
                <div class="table-data-text" *ngIf="!jobStatuses[job.id]?.length">No Products</div>
                <div *ngFor="let status of jobStatuses[job.id]; let i = index">
                    <div [ngSwitch]="status" *ngIf="i < 6" class="status-icon"
                         [ngStyle]="{position: 'relative', 'z-index': -i + 1000}">
                        <svg-icon *ngSwitchCase="'Draft'"
                                  src="/assets/icons/product-status/draft-status.svg"></svg-icon>
                        <svg-icon *ngSwitchCase="'In Progress'"
                                  src="/assets/icons/product-status/in-progress-status.svg"></svg-icon>
                        <svg-icon *ngSwitchCase="'Member Review'"
                                  src="/assets/icons/product-status/member-review-status.svg"></svg-icon>
                        <svg-icon *ngSwitchCase="'No Processing'"
                                  src="/assets/icons/product-status/no-processing-status.svg"></svg-icon>
                        <svg-icon *ngSwitchCase="'Uploading'"
                                  src="/assets/icons/product-status/uploading-status.svg"></svg-icon>
                        <svg-icon *ngSwitchCase="'Delivered'"
                                  src="/assets/icons/product-status/delivered-status.svg"></svg-icon>
                    </div>
                </div>
                <svg *ngIf="jobStatuses[job.id]?.length > 6" class="status-remainder" width="35" height="35"
                     viewBox="0 0 35 35"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17.5" cy="17.5" r="17"/>
                    <g text-anchor="middle">
                        <text x="17" y="22" stroke-width="0.01">
                            +{{ jobStatuses[job.id].length - 6 }}
                        </text>
                    </g>
                </svg>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="agent">
        <th mat-header-cell scope="col" *matHeaderCellDef>Agent</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let job"
                (click)="selectJob(job)"
        >
            <div class="table-data-text">
                {{ job.agentName ? job.agentName : "None" }}
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="agency">
        <th mat-header-cell scope="col" *matHeaderCellDef>Agency</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let job"
                (click)="selectJob(job)"
        >
            <div class="table-data-text">
                {{ job.agencyName ? job.agencyName : "None" }}
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="contractor">
        <th mat-header-cell scope="col" *matHeaderCellDef>Contractor</th>
        <td
                class="clickable-area"
                mat-cell
                *matCellDef="let job"
                (click)="selectJob(job)"
        >
            <div class="table-data-text">
                {{
                    job.contractor
                        ? job.contractor.firstName + " " + job.contractor.lastName
                        : "None"
                }}
            </div>

        </td>
    </ng-container>

    <ng-container matColumnDef="products">
        <th mat-header-cell scope="col" *matHeaderCellDef>Products</th>
        <td
                class="clickable-area product-col"
                mat-cell
                *matCellDef="let job"
                (click)="selectJob(job)"
        >
            <div class="table-data-text">
                <span [matTooltip]="job.productNames.join(', ')"
                      matTooltipPosition="after"
                      [matTooltipShowDelay]="500"
                      matTooltipClass="tooltip" class="product-col-text"
                >
                    {{ job.productNames.length ? job.productNames.join(", ") : "No Products" }}
                </span>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="details">
        <th mat-header-cell scope="col" *matHeaderCellDef>Details</th>
        <td mat-cell *matCellDef="let job">
            <button class="table-btn details-btn" mat-icon-button (click)="selectJob(job)">
                <svg-icon src="/assets/icons/not-eye.svg"></svg-icon>
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="invoice">
        <th mat-header-cell scope="col" *matHeaderCellDef>
            Invoice
        </th>
        <td mat-cell *matCellDef="let job,let i = index">
            <button class="table-btn invoice-btn" mat-icon-button (click)="openInvoiceDialog(job)"
                    [disabled]="(member$|async)?.isContractor"
            >
                <svg-icon class="invoice-icon" *ngIf="(job | jobInvoiced) else noInvoice"
                          src="/assets/icons/invoices/invoice.svg"></svg-icon>

                <ng-template #noInvoice>
                    <svg-icon class="invoice-icon"
                              src="/assets/icons/invoices/no-invoice.svg"></svg-icon>
                </ng-template>
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="albums">
        <th mat-header-cell scope="col" *matHeaderCellDef>
            <div class="albums-header-container">
                Albums
            </div>
        </th>
        <td mat-cell *matCellDef="let job">
            <div>
                <a class="table-btn album-btn" mat-icon-button (click)="openAlbum(job)"
                   [routerLink]="['/home', 'jobs', job['id'], 'album']">
                    <svg-icon src="/assets/icons/jobs/album.svg"></svg-icon>
                </a>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="share">
        <th mat-header-cell scope="col" *matHeaderCellDef>
            <div class="albums-header-container">
                Share
            </div>
        </th>
        <td mat-cell *matCellDef="let job">
            <div>
                <button class="table-btn share-btn" mat-icon-button (click)="shareJobAlbum(job)">
                    <svg-icon src="/assets/icons/jobs/share.svg"></svg-icon>
                </button>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnHeaders"></tr>
    <tr mat-row *matRowDef="let rowData; columns: columnHeaders"></tr>
</table>
<mat-progress-bar
        *ngIf="jobsLoading"
        mode="indeterminate"
></mat-progress-bar>
<mat-paginator
        [length]="paginatorControl.length"
        [pageSize]="paginatorControl.pageSize"
        [pageSizeOptions]="paginatorControl.pageSizeOptions"
        (page)="paginate($event)"
        #paginator
>
</mat-paginator>

