<ng-template #loadingThumbs>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>

<div *ngFor="let viewers of amountOfMobileViewers">
    <prism-mobile-swiper *ngIf="openMobileViewer[selectedGalleryServiceId] > -1"
                         [initialIndex]="openMobileViewer[selectedGalleryServiceId]"
                         [assets]="serviceThumbUrls[selectedGalleryServiceId]"
                         [productData]="bucketProduct"
                         [jobData]="jobData"
                         [mobileData]="mobileData"
                         (closeGallery)="closeGallery(selectedGalleryServiceId)"></prism-mobile-swiper>
</div>
<mat-card
        appearance="outlined"
        class="product-bucket"
        *ngFor="let service of bucketProduct?.services | keyvalue"
        [ngStyle]="{display: !!serviceThumbUrls && !!serviceThumbUrls[service.value.id] ? 'block' : 'none'}"
>
    <div class="product-bucket-header">
        <h3 class="product-name">{{ !!service?.value['alternativeName'] ? service?.value['alternativeName'] : bucketProduct.ProductName }}</h3>
    </div>
    <div class="product-bucket-content"
         *ngIf="
      service.value['delivered'] === true ||
      service.value.status === 'No Processing'"
    >
        <div class="product-bucket-toolbar">
        </div>
        <div class="product-bucket-assets" *ngIf="!!serviceThumbUrls; else loadingThumbs">
            <div [ngClass]="mobileData.isMobile ? 'mobile-asset' : 'product-bucket-asset'"

                 *ngFor="let thumb of serviceThumbUrls[service.value.id]; let i = index">
                <div class="product-bucket-asset-inner">
                    <div class="product-bucket-asset-image-wrapper outer-wrapper"
                         [ngClass]="thumb.contentType.startsWith('video/') && !mobileData.isMobile ? 'video-asset-wrapper': null">
                        <div draggable="false">

                            <img class="product-bucket-asset-image"
                                 (load)="onThumbLoaded(bucketProduct)"
                                 [src]="thumb.url" alt=""
                                 (click)="isSelecting ? includeAssetSelection(assetCheck, thumb) : openBigPicture($event, service, i, bucketProduct.ProductName);"
                                 [ngClass]="{'share-album-image': shareAlbumView, 'video-thumbnail' : thumb.contentType.startsWith('video/') && !mobileData.isMobile, 'video-thumbnail-mobile': thumb.contentType.startsWith('video/') && mobileData.isMobile}">
                        </div>
                    </div>
                </div>
                <div class="image-tools" draggable="false">
                    <mat-checkbox #assetCheckboxes color="primary"
                                  class="asset-select-checkbox"
                                  *ngIf="isSelecting"
                                  [hidden]="!isSelecting"
                                  [ngClass]="mobileData.isMobile ? 'asset-checkbox-mobile' : 'asset-checkbox'"
                                  (change)="selectAsset(thumb, $event)" #assetCheck>
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>
</mat-card>


